import React, { Component} from 'react'
import {css,cx} from 'react-emotion'
import { Modal, colors, mixins, Input, Button} from '@anarock/pebble';

const fbPageModalStyle = css({
    height: "250px",
    maxWidth: "450px",
    margin: "auto",
    borderRadius: "6px",
    background: colors.white.base,
    display: "flex",
    flexDirection: "column",
  });
  
const fbPageModalBackDropStyle = css({
    padding: "20px 0"
});
const fbPageHeader = css({
  lineHeight: "40px",
  boxShadow: `0 4px 10px 0 rgba(0,0,0,0.05)`,
  padding: "10px 24px"
})
  const fbIconStyle = css({
    height: "40px",
    width: "40px",
    lineHeight: "40px",
    textAlign: "center",
    cursor: "pointer",
    position: "absolute",
    right: "10px",
    top: "10px"
  });
const fbHeaderName = css({
    fontSize: "15px"
  })
  
const fbPageBody = css({
    padding: "10px 24px",
    margin: "auto 0px"
  });
const fbPageInput = css({ 
    height: "50px",
    
});
  
export default class AddFbPageModal extends Component {
    state = {
      fbPageId: ""
    };
  
    componentDidMount() {
      document.body.style.overflow = "hidden";
    }
  
    componentWillUnmount() {
      document.body.style.overflow = "initial";
    }
  
    render() {
      const {onClose, onSubmit, loading} = this.props;  
      const {fbPageId} = this.state;
      return (
        <Modal
          visible
          modalClassName={fbPageModalStyle}
          backDropClassName={fbPageModalBackDropStyle}
        >
          <div className={css({display: "flex", flexDirection: "column"})}>
            <div className={fbPageHeader}>
              <div className={fbHeaderName}>Add New Facebook Page</div>
              <i
                className={cx("pi pi-close", fbIconStyle)}
                onClick={onClose}
              />
            </div>
            <div className={fbPageBody}>
              <div className={css({...mixins.flexSpaceBetween})}>  
              <div className={css({margin: "auto 0px", marginRight: "25px", whiteSpace: "nowrap"})}>
                Enter FB Page ID
              </div>
              <Input
                 placeholder="For Example: 123456789012345"
                 value={fbPageId}
                 onChange={value => this.setState({fbPageId: value})}
                 className={fbPageInput}
                 inputClassName={css({
                      "::placeholder": {
                        fontSize: "11px"
                      }
                  })}
              />
              </div>
              <div className={css({...mixins.flexRow, marginTop: "30px"})}>
              <Button
              type="primary"
              size="small"
              onClick={() => onSubmit(fbPageId)}
              className={css({ width: "25%", marginRight: "12px"})}
              loading={loading}
            >
              Submit
            </Button>

            <Button
              type="primary"
              size="small"
              onClick={onClose}
              className={css({ width: "25%"})}
              loading={loading}
            >
              Cancel
            </Button>
              </div>
            </div>
          </div>
        </Modal>
      );
    }
  }