import React, { Component } from "react";
import { css, cx } from "react-emotion";
import { colors, Button, mixins, Option } from "pebble-web";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import _ from "lodash";
import "./agGridstyles.css";
import { TypeAhead } from "@anarock/pebble";

const itemHeader = css({
  fontSize: "13px",
  fontWeight: "lighter",
  color: colors.gray.dark,
});
const required = css`
  &:after {
    content: " *";
    color: red;
  }
`;
const line = css({
  width: "175px",
  height: "25px",
  fontSize: "13px",
  color: colors.gray.dark,
  borderBottom: `1px solid ${colors.gray.lighter}`,
});
const projectTableBody = css({
  marginTop: "20px",
  boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
  width: "100%",
  padding: "10px 18px",
  backgroundColor: colors.white.base,
  borderRadius: "3px",
});
export const SubSourceInput = (P) => {
  const { data, mode, filteredOptions, isDisabled } = P;
  const value = P.getValue(P.name);
  const developerProject =
    data.developerProjects &&
    data.developerProjects.filter((e) => e.asem_id == value)[0];
  const options = mode === "resolve error" ? filteredOptions : P.options;
  const [filter, setFilter] = React.useState("");
  return (
    <div
      className={css({ marginLeft: "10px", marginTop: "10px", width: "25%" })}
    >
      <div className={cx(itemHeader, required)}>Sub Source</div>
      <div className={css({ ...mixins.flexSpaceBetween })}>
        <TypeAhead
          disabled={isDisabled}
          placeholder=""
          className={css({ height: "45px", width: "90%" })}
          initialValue={
            value && typeof value == "number"
              ? developerProject && developerProject.name
              : value
          }
          onChange={(value) => setFilter(value)}
          onSelect={(value) =>
            P.handleChange({ type: "keyval", key: P.name, value: value })
          }
          selected={
            value && typeof value == "number"
              ? developerProject && developerProject.name
              : value
          }
          valueExtractor={(val) => {
            const option = options && options.find((c) => c === val);
            return option || "";
          }}
        >
          {options
            .filter((option) =>
              option.toLowerCase().includes(filter.toLowerCase())
            )
            .map((option) => (
              <Option key={option} value={option} label={option} />
            ))}
        </TypeAhead>
        <div
          className={cx(
            line,
            css({
              width: "10%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginTop: "23px",
              cursor: "pointer",
            })
          )}
        >
          <Button
            type="link"
            className={css({
              height: "24px",
              fontSize: "12px",
              marginBottom: "5px",
            })}
            onClick={(e) =>
              window.open(
                `https://lead.${window._TDOMAIN}/channel_sub_sources`,
                "_blank"
              )
            }
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export class ProjectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Project Name",
          field: "asem_id",
        },
        {
          headerName: "Channel type",
          field: "channel",
        },
        {
          headerName: "Source",
          field: "source",
        },
        {
          headerName: "Sub source",
          field: "subsource",
        },
        {
          headerName: "Actions",
          field: "action",
          cellRenderer: "actionCellComponent",
          cellRendererParams: {
            onClick: this.props.onClick,
          },
        },
      ],
      frameworkComponents: {
        actionCellComponent: ActionCellComponent,
      },
    };
  }
  setTime = () => {
    this.setState({ currentTime: new Date() });
  };
  componentWillMount() {
    this.setTime();
  }
  componentDidMount() {
    window.setInterval(
      function () {
        this.setTime();
      }.bind(this),
      1000
    );
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ syncTime: new Date() });
  }
  render() {
    const { columnDefs } = this.state;
    const { data, developerProjects } = this.props;
    const projectTableData = data.map((project) => {
      const devloperProject =
        developerProjects &&
        developerProjects.filter((e) => e.asem_id == project.asem_id)[0];
      return {
        ...project,
        asem_id: devloperProject && devloperProject.name,
        action: "View/Edit",
      };
    });
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      fitGridTable();
    };
    const fitGridTable = () => {
      if (this.gridApi) this.gridApi.sizeColumnsToFit();
      if (this.columnApi) this.columnApi.autoSizeColumns();
    };
    return (
      <div className={projectTableBody}>
        <div className={css({ ...mixins.flexSpaceBetween })}>
          <div className={css({ ...mixins.flexSpaceBetween })}>
            <div
              className={css({
                ...mixins.flexMiddleAlign,
                fontSize: "18px",
                padding: "10px 0px",
              })}
            >
              Campaign List
            </div>
          </div>
        </div>
        <div>
          <div
            className={cx(
              "ag-theme-alpine",
              css({ width: "100%", margin: "10px 0px 15px" })
            )}
          >
            <AgGridReact
              suppressRowTransform={true}
              onGridReady={onGridReady}
              defaultColDef={{
                cellStyle: {
                  border: `0.5px solid ${colors.gray.lighter}`,
                  fontSize: "14px",
                  color: colors.gray.darker,
                  backgroundColor: colors.white.base,
                  margin: "auto 0px",
                },
              }}
              domLayout="autoHeight"
              headerHeight={50}
              rowHeight={50}
              animateRows={false}
              columnDefs={columnDefs}
              frameworkComponents={this.state.frameworkComponents}
              rowData={projectTableData}
            />
          </div>
        </div>
      </div>
    );
  }
}

class ActionCellComponent extends Component {
  render() {
    const { onClick, value, rowIndex } = this.props;
    return (
      <div
        className={css({
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        })}
        onClick={() => onClick(rowIndex)}
      >
        {value}
      </div>
    );
  }
}
