import React, { Component } from "react";
import { css } from "react-emotion";
import _ from "lodash";
import { mixins, colors } from "pebble-web";

export const flexInBetween = css({
  display: "flex",
  justifyContent: "space-between",
});
export const multiEditModalWrapper = css({
  minWidth: "264px",
  height: "50px",
});
const mappingContainer = css({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden",
  borderRadius: 3,
});

const mappingBody = css({
  padding: "10px 24px",
  fontSize: 13,
  boxShadow: "0 5px 5px -5px rgba(0, 0, 0, 0.1)",
});
const iconStyle = css({
  display: "inline-block",
  marginTop: "5px",
  cursor: "pointer",
  fontSize: 12,
  color: colors.gray.dark,
});
const listValues = css({
  padding: "0px, 7px",
  maxWidth: "250px",
  overflow: "hidden",
  whiteSpace: "no-wrap",
  textOverflow: "ellipsis",
});
const Days = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};
export default class RuleCard extends Component {
  state = {
    isOpen: false,
    campaignList: this.props.value,
    filter: "",
  };
  subsourceValue = (value) => {
    const { subsourceMapping } = this.props;
    return value.map(
      (subsource) => subsourceMapping.find((item) => item.id === subsource).name
    );
  };
  render() {
    const { rule, onClose, assignment } = this.props;
    const frequencyValue =
      rule.frequency.length === 7
        ? "Daily"
        : rule.frequency.map((day) => Days[day]).join(",");
    return (
      <div className={mappingContainer}>
        <div className={mappingBody}>
          <div
            className={css({
              ...mixins.flexSpaceBetween,
              padding: "10px 0px",
            })}
          >
            <div className={css({ fontSize: "13px" })}>{rule.name}</div>
            <div onClick={onClose} className={iconStyle}>
              <i className={`pi pi-close`} />
            </div>
          </div>
        </div>
        <div className={css({ padding: "16px 24px" })}>
          <div className={css({ display: "flex" })}>
            <CardElement
              name="Assignment"
              value={assignment.extra_details.assignment_name}
            />
            <CardElement name="Project" value="All Projects" />
            <CardElement name="Source" value={rule.source} />
          </div>
          <div className={css({ display: "flex" })}>
            <CardElement
              name="Sub Source"
              value={this.subsourceValue(rule.subsource_ids).join()}
            />
            <CardElement name="Start Date" value={rule.start_date} />
            <CardElement name="End Date" value={rule.end_date} />
          </div>

          <div className={css({ display: "flex" })}>
            <div className={css({ margin: "10px 0px", flex: 1 })}>
              <div className={css({ color: colors.gray.base })}>
                Frequency
                <span className={css({ fontSize: 11 })}>
                  (Checks @12PM at selected time period)
                </span>
              </div>
              <div className={listValues}>{frequencyValue}</div>
            </div>

            <div className={css({ margin: "13px 0px", flex: 1 })}>
              <div className={css({ color: colors.gray.base })}>Email</div>
              {rule.email_ids ? (
                rule.email_ids.map((id) => <div>{id}</div>)
              ) : (
                <div>NA</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CardElement extends Component {
  render() {
    const { name, value } = this.props;
    return (
      <div className={css({ margin: "13px 0px", flex: 1 })}>
        <div className={css({ color: colors.gray.base })}>{name}</div>
        <div className={listValues}>{value}</div>
      </div>
    );
  }
}
