import React, { PureComponent } from 'react'
import Table from './table'
import _ from 'lodash';
import { css } from 'react-emotion';
import {Connect} from './events'
import {Option,DropDown, OptionGroupRadio} from '@anarock/pebble';
import '/styles/panel.scss';
import '/styles/vendor.scss';

Connect()

class Vendor extends PureComponent {
  constructor (props) {
    super(props);
    const {vendorId} = this.getProps();
    this.state = {
      vendorAccesses:[],
      vendorId,
      selectedAssignment:undefined,
      assignments: []
    };
    this.getVendorAccess();
  }
  getVendorAccess() {
    this.setState({loading:true});
    let self = this;
    fetch(`${window._MDOMAIN}/developer/api/v0/getvendor?vendor_id=${this.state.vendorId}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        const assignments = [];
        data.data.forEach(element => {
          if(assignments.length===0||assignments.findIndex(a=>a.assignment_id===element.assignment_id)<0){
            assignments.push({
              assignment_id:element.assignment_id,
              assignment_name:element.assignment_name
            })
          }
        });
        self.setState({
          vendorAccesses:data.data,
          selectedAssignment:assignments[0],
          assignments
        });
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }
  
  getProps = () => {
    let props = this.props
    let vendorId = parseInt(_.get(props,"match.params.vendor_details").split("-")[1])
    return {vendorId}
  }

  getDerivedFields = (o) => {
    let derived = {}
    derived['status'] = 'unknown'
    if(o.start_date && o.end_date) {
      let sd = new Date(o.start_date), ed = new Date(o.end_date), cd = new Date()
      if(ed < cd) {
        derived['status'] = 'completed'
      } else if(sd>cd) {
        derived['status'] = 'upcoming'
      } else if(sd < cd < ed) {
        derived['status'] = 'ongoing'
      }
    }
    derived['fields'] = 'complete'
    if(!(o.assignment_id && o.start_date && o.end_date && o.meta && o.meta.agency_type && o.poc)) {
      derived['fields'] = 'incomplete'
    }
    o['derived'] = derived
    return o
  }

  
  handleTabClick(idx) {
    var tabs = document.querySelectorAll('.record-tab')
    var divs = document.querySelectorAll('.vendor-names')
    tabs.forEach((e) => { e.classList.remove('active-table') })
    divs.forEach((e) => { e.classList.remove('active') })
    tabs[idx].classList.add('active-table')
    divs[idx].classList.add('active')
  }

  render() {
    const { vendorAccesses,assignments,selectedAssignment } = this.state;
    const vendorNameStyle = css`padding:4px;min-width:170px;overflow:hidden;text-overflow:elipsis;cursor:pointer;margin-right:10px;position:relative`;
    const vendorRecords = vendorAccesses.filter(vendorAccess=>vendorAccess.assignment_id===selectedAssignment.assignment_id);
		return (vendorAccesses.length? <div style={{paddingLeft:'10px'}}>
        <DropDown 
          buttonLabel={selectedAssignment.assignment_name}>
          {({ toggle }) => (
            <OptionGroupRadio
              onChange={value => {
                value && this.setState({selectedAssignment:value})
                toggle();
              }}
              selected={selectedAssignment}
            >
              {assignments.map(option => (
                <Option
                  key={option.assignment_id}
                  value={option}
                  label={option.assignment_name}
                />
              ))}
            </OptionGroupRadio>
          )}
      </DropDown>
      {new Date(vendorRecords[0].expirydate) < new Date()
        ? "The link is expired, please contact your POC for more details"
        : <div>
          <div style={{ width: '100%', display: 'flex', margin: '10px 0px' }}>{vendorRecords.map((record, index) => <div index={index} className={'vendor-names ' + vendorNameStyle + (index === 0 ? ' active' : '')} onClick={() => this.handleTabClick(index)}>
            <div className='strip'></div>
            Vendor: {record.name}
          </div>)}</div>
          {vendorRecords.map((record, index) => {
            const { project, startdate, enddate, channel, source, subsource } = record || {};
            const filters = {
              project,
              startdate,
              enddate,
              channel,
              source,
              subsource
            };
            const vendor_access_id = record.id
          return (
              <div>
                <div className={'record-tab '+(index===0?'active-table':'')}>
                    {filters && <Table
                      params={{ assignment_id: selectedAssignment.assignment_id, filters }}
                      customURL={`${window._MDOMAIN}/developer/api/v0/leads?assignment_id=${selectedAssignment.assignment_id}&vendor_access_id=${vendor_access_id}`} />}
                </div>
              </div>
            );
          }
          )}</div>}
      </div>:"Vendor not found")
	}
}

export default Vendor