import React, { Component } from "react";
import { css } from "react-emotion";
import _ from "lodash";
import { colors } from "pebble-web";
import { Divider } from "semantic-ui-react";
import { RulesTable } from "./rules-table";

const rulesTableBody = css({
  margin: "15px 0px",
  padding: "24px 24px 10px",
  background: colors.white.base,
});
export default class RulesTableList extends Component {
  render() {
    const {
      rules,
      onEdit,
      campaigns,
      updateRule,
      deleteRule,
      subsourceMapping,
      assignment
    } = this.props;
    return (
      <div className={rulesTableBody}>
        <div>Rules Based on {rules[0].parameter}</div>
        <Divider />
        {rules.map((rule) => (
          <RulesTable
            subsourceMapping={subsourceMapping}
            onEdit={onEdit}
            rule={rule}
            campaigns={campaigns}
            updateRule={updateRule}
            deleteRule={deleteRule}
            assignment={assignment}
          />
        ))}
      </div>
    );
  }
}
