import React, { Component } from "react";
import _ from "lodash";
import CustomRuleTable from "./custom-rule-table";

export class RulesFormTable extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { getValue, campaigns, isRenderExample, handleChange } = this.props;
    const data = getValue("conditions");
    const columnDefs = [
      {
        headerName: "Sub Source",
        field: "subsource",
      },
      {
        headerName: "Apply Rule To",
        field: "campaign_ids",
      },
      {
        headerName: `MediaPlan ${this.props.data.parameter || "cpl"}`,
        field: "rule_params",
      },
      {
        headerName: `Notify if ${this.props.data.parameter || "cpl"} Crosses`,
        field: "rule_params",
      },
      {
        headerName: `Turn off Campaign at ${
          this.props.data.parameter || "cpl"
        }`,
        field: "rule_params",
      },
    ];
    return (
      <CustomRuleTable
        handleChange={handleChange}
        isRenderExample={isRenderExample}
        data={data}
        columns={columnDefs}
        campaigns={campaigns}
        source={this.props.data.source}
      />
    );
  }
}
