import React, { PureComponent } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { css } from 'react-emotion';
import Ionicon from 'react-ionicons'
import Notify from './notify'

const closestyle = css`
cursor:pointer;
right:0px;
top:-5px;
position:absolute;
 :hover{
   fill:#eb7785;
  }
`;

class InputDropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { val: "", open: this.props.open }
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value, open: nextProps.open });
  }
  handleClickOutside(event) {
    if (this.state.open && this.ref.current && !this.ref.current.contains(event.target)) {
      this.setState({ open: false, val: "" })
    }
  };
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  };

  render() {
    let { value, inputOnClick, dropdownOnClick, options, width, onClick, classname } = this.props
    let { val, open } = this.state;
    return (
      <td className={classname}>
        <div ref={this.ref} className={css`position:relative;`}>
          <div className={css`width:${width};padding:10px;text-align:center;`}
            onClick={inputOnClick}
          >{value}</div>
          {open &&
            <div className={css_dropdown} style={{ minWidth: width }} onClick={(e) => { this.setState({ val: "" }) }}>
              <div title={value} className='content'>
                <Ionicon className='icon' icon="md-search" color="#6b7785" />
                <input className='input'
                  value={val}
                  placeholder='search'
                  spellCheck={false}
                  onChange={(e) => this.setState({ val: e.target.value })}
                />
                {val && <div className='crossbutton'
                  onClick={(e) => { this.setState({ val: "" }), onClick(e) }}
                >x</div>}
              </div>
              {options
                .filter(o => (o || '').toLowerCase().indexOf(val && val.toLowerCase() || '') != -1)
                .map(l => (
                  <div className='options' onClick={(e) => dropdownOnClick(e, l)} >{l}</div>
                ))}
            </div>
          }
          {value && <Ionicon onClick={(e) => dropdownOnClick(e, "")} className={closestyle} icon="md-close" fontSize="14px" color="#ccc" />}
        </div>
      </td>
    )
  }
}

class DatePicker extends PureComponent {
  render() {
    let { val, onDayChange, onClick } = this.props
    const closestyle = css`cursor:pointer;right:0px;top:-5px;position:absolute; :hover{fill:#eb7785;}`;

    return (
      <td key={val} className={css_datePicker} >
        <div className='content'>
          <DayPickerInput className='daypicker'
            placeholder="DD-MM-YY"
            inputProps={{ style: { width: 100 } }}
            value={val}
            formatDate={(o) => {
              let [m, d, y] = o.toString().split(" ").slice(1, 4)
              return `${d}-${m}-${y}`
            }}
            onDayChange={onDayChange}
          />
          {val && <Ionicon onClick={onClick} className={closestyle} style={{ top: "-15px" }} icon="md-close" fontSize="16px" color="#ccc" />}
        </div>
      </td>
    )
  }
}

class TaxsRows extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      assignment: this.props.assignment || {},
      sourceOptions: [],
      sourceOpen: false,
    }
  }
  componentDidMount() {
    let { assignment_id } = this.state.assignment

    fetch(`${window._MDOMAIN}/api/v0/url-generator?rtype=get-v2&assignment_id=${assignment_id}`, {
      method: 'post',
      credentials: "include",
    }).then(function (response) {
      return response.json();
    }).then(data => {
      var sourceList = data.data.spends_config && data.data.spends_config.map(a => a.source);
      this.setState({
        sourceOptions: sourceList.filter((item, index) => sourceList.indexOf(item) == index)
      })
    })

  }

  setDropdowns = () => {
    this.setState({
      sourceOpen: false,
    });
  }
  render() {
    const { sourceOptions, sourceOpen } = this.state;
    const { row, index, onHandle, handleRemoveSpecificRow, overlappingDates } = this.props;
    var isValDate = row.end && row.start && new Date(row.start).getTime() >= new Date(row.end).getTime() ? true : false;

    return (
      <tr className={"rows " + (((row.date && isValDate) || (!isValDate && overlappingDates.length >= 2 && row.isDateOverlap && row.isOverlap)) ? "highlight" : "")}
        key={index}>
        <InputDropdown classname={row.empty && !row.source ? "borderHighlight" : ""}
          value={row.source}
          inputOnClick={(e) => {
            e.preventDefault();
            this.setState({ sourceOpen: !sourceOpen })
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ sourceOpen: true })
          }}
          dropdownOnClick={(e, l) => {
            e.preventDefault();
            onHandle({ index: index, type: "source", value: l })
            this.setDropdowns();
          }}
          open={sourceOpen}
          options={sourceOptions}
          width="150px"
        />

        <td className={row.empty && !row.tax ? "borderHighlight" : ""}>
          <input style={{ width: "120px", textAlign: 'center' }}
            name='tax'
            value={row.tax ? row.tax : ""}
            type="number"
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onHandle({ index: index, type: "tax", value: e.target.value })
              this.setDropdowns()
            }}
          />
        </td>
        <DatePicker val={row.start}
          onDayChange={day => {
            if (day) {
              let [m, d, y] = day.toString().split(" ").slice(1, 4)
              var pt = `${d}-${m}-${y.slice(2)}`
              onHandle({ index: index, type: "start", value: pt })
            }
            if (!day) {
              onHandle({ index: index, type: "start" })
            }
          }}
          onClick={e => {
            onHandle({ index: index, type: "start" })
          }}
        />
        <DatePicker val={row.end}
          onDayChange={day => {
            if (day) {
              let [m, d, y] = day.toString().split(" ").slice(1, 4)
              var pt = `${d}-${m}-${y.slice(2)}`
              onHandle({ index: index, type: "end", value: pt })
            }
            if (!day) {
              onHandle({ index: index, type: "end" })
            }
          }}
          onClick={e => {
            onHandle({ index: index, type: "end" })
          }}
        />
        <td>
          <div style={{ textAlign: 'center' }}>{(row.spends || row.spends == 0) ? row.spends : ''}</div>
        </td>

        <td>
          <div style={{ textAlign: 'center' }}>{row.totalSpends}</div>
        </td>
        <div title="Delete" style={{ cursor: "pointer", border: "none" }} onClick={e=> handleRemoveSpecificRow(e,index)}><Ionicon title="Delete" style={{ margin: "20px" }} icon="md-trash" color="#6b7785" /></div>
      </tr>
    );
  }
}

class TaxsConfiguration extends PureComponent {
  state = {
    assignment: this.props.assignment || {},
    rows: [{}],
    spendsConfig: [{}],
    spendsData: [],
    message: "",
    dateOverlap: false,
    message_id: new Date()
  };

  componentDidMount() {
    let { assignment_id } = this.state.assignment

    fetch(`${window._MDOMAIN}/api/v0/url-generator?rtype=get-v2&assignment_id=${assignment_id}`, {
      method: 'post',
      credentials: "include",
    }).then(function (response) {
      return response.json();
    }).then(data => {
      this.setState({
        spendsConfig: data.data.spends_config && data.data.spends_config
      })
    })

    fetch(`${window._MDOMAIN}/api/v0/spends?rtype=get&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials: "include"
    }).then(function (response) {
      return response.json();
    }).then(data => {
      this.setState({
        spendsData: data.data
      });
    });
  }

  handleAddRow = () => {
    const item = {};
    this.setState({
      rows: [...this.state.rows, item]
    });
  };

  handleRemoveSpecificRow = (e,index) => {
    e.preventDefault();
    e.stopPropagation();
    const rows = [...this.state.rows]
    rows.splice(index, 1)
    this.setState({ rows })
  }

  calculateMultipleSpends = (source, startDate, endDate) => {
    var sum = 0;
    var config = this.state.spendsConfig.map(o => {
      o.spends = (parseFloat(o.spends) || 0).toFixed(2);
      return o;
    });
    var spendsList = [];
    var id = [];
    var spendData = []
    config.filter(a => {
      if (source && a.source == source && a.spends) {
        id = [...id, a.id]
      }
    });
    spendData = this.state.spendsData.filter(item => id.indexOf(item.config_id) != -1);
    if (startDate && !endDate) {
      spendData = spendData.filter((item) => {
        return new Date(item.date).getTime() >= new Date(startDate).getTime()
      })
    }
    else if (endDate && !startDate) {
      spendData = spendData.filter((item) => {
        return new Date(item.date).getTime() <= new Date(endDate).getTime()
      })
    }
    else if (startDate && endDate) {
      spendData = spendData.filter((item) => {
        return new Date(item.date).getTime() >= new Date(startDate).getTime() && new Date(item.date).getTime() <= new Date(endDate).getTime()
      })
    }
    else {
      spendData = this.state.spendsData.filter(item => id.indexOf(item.config_id) != -1);
    }
    spendData.map(a => {
      spendsList = [...spendsList, a.spends];
    })
    var numberArray = spendsList && spendsList.map(Number);
    numberArray.map(x => sum += x)
    return sum;
  }

  calculatePercentage = (spends, tax) => {
    var percentage = (spends && tax) && +spends + ((tax / 100) * spends);
    return ~~percentage;
  }

  handleSpendsWithDate = (row, index) => {
    var spends = this.calculateMultipleSpends(row[index].source, row[index].start, row[index].end);
    row[index].spends = spends;
    if (row[index].tax) {
      row[index].totalSpends = this.calculatePercentage(spends, row[index].tax)
    }
  }

  checkDateOverlapping = (rows) => {
    const isSourceExist = rows.map(function (value) {
      return value.source
    }).some(function (value, index, row) {
      return row.indexOf(value) !== row.lastIndexOf(value);
    })
    if (isSourceExist) {
      return this.checkDates(rows, "isDateOverlap");
    }
    else {
      return false;
    }

  }

  saveRows = () => {
    var newRows = this.state.rows.filter(e => Object.keys(e).length !== 0)
    this.setState({ rows: newRows });

    var config = newRows.map(o => {
      let { source, tax } = o
      return { source, tax };
    });
    const emptyAction = config && config.map(data => {
      return Object.values(data).some(x => x == '' || x == undefined)
    });
    const isEmpty = emptyAction.length > 0 && emptyAction.reduce((a, b) => a || b)
    this.setState({ isEmpty: isEmpty })

    const dateValidation = newRows.map(o => {
      if (o.start && o.end && new Date(o.start).getTime() >= new Date(o.end).getTime()) {
        return true;
      } else {
        return false;
      }
    });

    let updateRows = JSON.parse(JSON.stringify(newRows));

    config && config.map((data, index) => {
      if (config[index] && Object.values(config[index]).some(x => x == '' || x == undefined)) {
        updateRows[index].empty = true
      } else {
        updateRows[index].empty = false
      }
    });

    newRows.map((val, index) => {
        if (newRows[index].start && newRows[index].end && new Date(newRows[index].start).getTime() >= new Date(newRows[index].end).getTime()) {
          updateRows[index].date = true
        }
        else {
          updateRows[index].date = false
        }      
    })
    this.setState({ rows: updateRows });

    var dateOverlap = !isEmpty && !dateValidation.includes(true) && this.checkDateOverlapping(newRows);

    if (dateOverlap) {
      this.setState({ message: "Duplicate Dates not allowed", message_id: new Date() });
    }
    if (dateValidation.includes(true) || isEmpty) {
      this.setState({ message: `${dateValidation.includes(true) ? "Start date is not smaller than End date  " : ""} ${isEmpty ? "Required All fields  " : ""} `, message_id: new Date() });
    }
    if (!dateOverlap && !isEmpty && !dateValidation.includes(true)) {
      this.setState({ message: "Saved Successfully", message_id: new Date() });
    }
  }

  handleChange = (event) => {
    var index = event.index;

    let updateRows = JSON.parse(JSON.stringify(this.state.rows));
    if (event.type == 'source') {
      updateRows[index].source = event.value;
      updateRows[index].id = index;
      this.handleSpendsWithDate(updateRows, index)
    }
    if (event.type == 'start' || event.type == 'end') {
      updateRows[index][event.type] = event.value;
      this.handleSpendsWithDate(updateRows, index)
    }
    if (event.type == 'tax') {
      updateRows[index].tax = event.value;
      var spends = this.calculateMultipleSpends(updateRows[index].source, updateRows[index].start, updateRows[index].end);
      updateRows[index].spends = spends;
      updateRows[index].totalSpends = this.calculatePercentage(spends, event.value)
    }

    this.setState({
      rows: updateRows
    })

    var isEmpty = updateRows[index].source && updateRows[index].tax
    isEmpty && this.checkDates(updateRows, "isOverlap");
  }

  checkDates = (rows, isOverlap) => {
    rows.forEach(a => a[isOverlap] = false)
    var dateValRows = JSON.parse(JSON.stringify(rows));

    var dateOverlap = false;

    dateValRows.forEach(function (object) {
      if (!('start' in object)) {
        object['start'] = "01-Jan-21";
      }
      if (!('end' in object)) {
        object['end'] = new Date();
      }

    });
    let updateRows = JSON.parse(JSON.stringify(rows));

    const dupRows = dateValRows.sort((a, b) => new Date(a.start) - new Date(b.start))
      .filter(a => a['source'] !== undefined)
      .map(e => e['source']).map((e, i, final) => final.indexOf(e) !== i && i)
      .filter(obj => dateValRows[obj])
      .map(e => dateValRows[e]['source']);

    var response = dateValRows.reduce((c, v) => {
      if (dupRows.includes(v.source)) {
        c[v.source] = c[v.source] || [];
        c[v.source].push({ start: v.start, end: v.end, id: v.id });
      }
      return c;
    }, {})

    for (var k in response) {
      for (var i = 0; i < response[k].length; i++) {
        for (var j = i + 1; j < response[k].length; j++) {

          if (((new Date(response[k][i].start).getTime() <= new Date(response[k][j].start).getTime() && new Date(response[k][i].end).getTime() >= new Date(response[k][j].end).getTime()) ||
          (new Date(response[k][i].start).getTime() == new Date(response[k][j].start).getTime() && (new Date(response[k][i].end).getTime() >= new Date(response[k][j].end).getTime() || new Date(response[k][i].end).getTime() <= new Date(response[k][j].end).getTime())))) {
            updateRows[response[k][i].id][isOverlap] = true
            updateRows[response[k][j].id][isOverlap] = true

            dateOverlap = true;
          }
        }
      }
    }
    this.setState({ dateOverlap: dateOverlap, rows: updateRows })
    return dateOverlap;
  }
  render() {
    let { message, message_id, assignment } = this.state;
    let columns = ['Source', 'Tax/GST (in %)', 'Start Date', 'End Date', 'Media Spends', 'Total Spends'];
    var overlappingDates = this.state.rows.filter(a => a.isDateOverlap == true && a.isOverlap == true)

    return (
      <div className={css_taxTable}>
          <span>
            <h3 className='name'>Tax Slabs</h3>
            <div className='refresh'>
              <input className='button' type="button" value="Refresh Spends" style={{ marginRight: '5px' }} />
            </div>
            <table className={css_table}>
              <thead>
                <tr>
                  {columns.map(data => {
                    return <th>{data}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.rows.map((item, index) => {

                  return <TaxsRows
                    row={this.state.rows[index]}
                    index={index}
                    overlappingDates={overlappingDates}
                    assignment={assignment}
                    onHandle={this.handleChange}
                    handleRemoveSpecificRow={this.handleRemoveSpecificRow}
                  />
                })}
              </tbody>
            </table>
            <Notify message={message} message_id={message_id} />
            <div className='newrow' onClick={this.handleAddRow}>
              + New Row
            </div>
            <div className='save'>
              <input onClick={this.saveRows} className='button' type="button" value="save" style={{ marginRight: '5px', cursor: "pointer" }} />
            </div>
          </span>
      </div>
    );
  }
}

class Taxs extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      assignment: this.props.assignment || {},
      toggleState: 1
    }
  }

  render() {
    let { toggleState } = this.state;
    let { assignment } = this.props
    return (
      <div className={css`
        display:flex;
        flex-direction:column;
        .tabs {
          color:#6b7785;
          margin-right:20px;
          cursor:pointer;
          :hover {
            color:#6161ff;
          }
        }
        .active-tabs {
          color:#6161ff;
        }
        `
      }>
        <ul style={{ display: 'flex', listStyleType: 'none', marginRight: '5px', marginBottom: '20px' }}>
          <li
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => {
              this.setState({ toggleState: 1 })
            }}>
            default
          </li>
        </ul>

        <div className="content-tabs">
          {toggleState === 1 &&
            <TaxsConfiguration assignment={assignment} />
          }

        </div>
      </div>
    );
  }
}

export default Taxs;

const css_dropdown = css`
min-width:150px;
background:#fff;
max-height:300px;
overflow:auto;
text-align:start;
box-shadow: 0px 8px 15px 0px rgba(0,0,0,.15);
position:absolute;
background:#fff;
padding:0px 15px;
z-index:100000;
}
.content{
  width:100%;
  margin-bottom:10px;
  background:#F7F7F9;
  border-radius:3px;
  display:flex;
  align-items:center;
  margin-top:20px;
  padding:0 20px;
}
.icon{
  font-size:12px;
  padding-right:5px;
}
.input{
  padding:13px;
  text-align:start;
  flex-grow:1;
  font-weight:400;
}
.crossbutton{
  height:16px;
  width:16px;
  border-radius:16px;
  font-size:14px;
  cursor:pointer;
}
.options{
  padding: 10px 5px;
  cursor: pointer;
}
`
const css_datePicker = css`
width:150px;
.content{
  display:flex;
  justify-content:space-around;
  position:relative;
}
.daypicker{
  text-align:center;
}
`

const css_table = css`
border-spacing:0;
border-collapse:separate;
th{
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  position: sticky;
  cursor: pointer;
  height: 48px;
  color:#6B7785;
}
td{
  border:1px solid #ddd;
  padding:13px 15px;
  font-size:15px;
  white-space: nowrap;
  min-width:100px;
  text-align:center;
  text-overflow:ellipsis;
  z-index : 0;
  background:#ffffff
}
.highlight td{
  border:1px solid red;
}
.borderHighlight{
  border: 1px solid red;
}
input{
  border:none;
  background:none;
}
.rows td:last-child {
  border: none;
}
.button{
  padding: 8px 20px;
  border:1px solid #0000ff;
  border-radius:5px;
  margin:5px;
  :hover {
    background:#0000ff;
    color:white;
  }
}
.css-poiah input{
  width: 100px;
}
`
const css_taxTable=css`
span{
  position:relative;
  margin:10px;
  display:inline-block;
}
.name{
  position:absolute;
  left:0px;
  color:blue;
}
.refresh{
  display:flex;
  justify-content:flex-end;
  padding:5px;
}
.button{
  padding: 8px 20px;
  border:1px solid #0000ff;
  border-radius:5px;
  margin:5px;
  :hover {
    background:#0000ff;
    color:white;
  }
}
.newrow{
  position:absolute;
  left:0px;
  bottom:25px;
  color:blue;
  cursor:pointer;
}
.save{
  display:flex;
  justify-content:flex-end;
  padding:15px 0px;
}
`