import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom';
import Ionicon from 'react-ionicons';
import Notify from './components/notify'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {OptionGroupCheckBox,Option,DropDown, OptionGroupRadio} from '@anarock/pebble/dist/pebble.umd';
import {css} from 'react-emotion'


import 'codemirror/lib/codemirror.css';
  
import 'codemirror/theme/monokai.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/theme/twilight.css';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/search/matchesonscrollbar.css';

import FileTree from './filetree'
import '/styles/panel.scss';

import {UnControlled as CodeMirror} from 'react-codemirror2'
import { Loader, colors } from 'pebble-web';

// console.log(CodeMirror)

const css_labels = css`
min-width:200px;
background:#fff;
max-height:200px;
overflow:auto;
box-shadow: 0px 8px 15px 0px rgba(0,0,0,.15);
position:absolute;
background:#fff;
z-index:100000;
> div {
  padding: 10px 5px;
	cursor: pointer;
	:hover {
		background: #fbfbfb;
	}
}
`

export class Popper extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    setTimeout(() => document.addEventListener('click', this.handleClick, true),100)
    document.addEventListener('keydown',this.handleKeyPress,true)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true);
    document.removeEventListener('keydown',this.handleKeyPress,true)
  }
                              
	handleKeyPress = (e) => {
    try {
      if(e.repeat){
        return
      }
      let valid = false
      if(e.keyCode==27){
        this.props.close()
        valid = true
      }
      if(valid){
        e.preventDefault()
        e.stopPropagation()  
      }
    } catch(e) {  
    }
  }
  handleClick = (e) => {
    try{
    	if(!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.props.close()
        if(!this.props.allowClicks){
          e.preventDefault()
          e.stopPropagation()
        }
      }
    } catch(e) {
      // console.log(e,e.toString())
      // if(e.toString().indexOf('Unable to find node on an unmounted component')!=-1){
      //   console.log("removing unmounted node")
      //   document.removeEventListener('click', this.handleClick, true);
      // }
    }
  }
  render() {
    let {html,align} = this.props
    return (
      <div className={css`
z-index:100000001;
position: absolute;
top:0px;
${align=='left'?'left:0px;':'right:0px;'}
box-shadow: 0px 8px 15px 0px rgba(0,0,0,.15);
`}>
        {html}
      </div>
    )
  }
}
export class Button extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }
  
  render() {
    let {closed=new Date(),label,popper,html,icon,align='left'} = this.props
    let {show} = this.state
    return (
      <div 
        className={css`
user-select: none;
display:flex;  
align-items: center;
height:28px;
.pi {
	display: block;
}
.cnt {
	height:28px;
	padding-left: 10px;
	padding-right: 10px;
	display:flex;
	align-items: center;
}
position:relative;
border: 1px solid #e0e0e0;
border-radius: 3px;
color: #6b7785;
font-size:12px;
cursor: pointer;
.text {
	margin:5px;
}
:hover {
	background: #e0e0e0;
}
.popper {
	position:absolute;
	${align=='left'?'left:0px;':'right:0px;'}
	top:30px;
}
`}>
        {html}
        {!html &&
          <div 
            onClick={(e) => {
              if(this.props.onClick){
              	this.props.onClick()  
              }
              if(new Date() - closed > 100){
                if(this.props.onOpen){
                	this.props.onOpen()
                }
              	this.setState({show:true})  
              }
            }}
            className="cnt">
            <i className={`pi pi-${icon}`} />
            {
              label &&
              <div className="text">{label}</div>
            }
            {
              label &&
              <i className={`pi pi-arrow-drop-${show?"up":"down"}`} />
            }
          </div>
        }
        <div className="popper">
          {show && 
            <Popper 
              align={align}
              allowClicks={true}
              close={() => this.setState({show:false,closed: new Date()})}
              html={popper}/>
          }
        </div>
      </div>
    )
  }
}

let CM; 

if (typeof window !== 'undefined' ) {
  CM = require("codemirror/lib/codemirror");
  window.CM = CM;
  require('codemirror/mode/meta.js');

  require('codemirror/addon/edit/closebrackets.js');
  require('codemirror/addon/edit/closetag.js');
  require('codemirror/addon/edit/trailingspace.js');
  require('codemirror/addon/edit/matchtags.js');
  require('codemirror/addon/edit/matchbrackets.js');
  require('codemirror/addon/search/search.js');
  require('codemirror/addon/search/searchcursor.js');
  require('codemirror/addon/search/jump-to-line.js');
  require('codemirror/addon/search/matchesonscrollbar');
  require('codemirror/addon/dialog/dialog.js');


  require('codemirror/mode/javascript/javascript');
  require('codemirror/mode/jsx/jsx');
  require('codemirror/mode/xml/xml');
  require('codemirror/mode/htmlmixed/htmlmixed');
  require('codemirror/mode/css/css');
  require('codemirror/mode/python/python');
  require('codemirror/mode/shell/shell');
  require('codemirror/mode/xml/xml');
  require('./sql');
  require('codemirror/mode/markdown/markdown');
  require('codemirror/keymap/sublime');
  require('codemirror/addon/fold/foldcode');
  require('./brace-fold');
  require('codemirror/addon/fold/comment-fold');
  require('codemirror/addon/fold/xml-fold');
  require('codemirror/addon/comment/comment');
  require('codemirror/addon/fold/indent-fold');
  require('codemirror/addon/fold/foldgutter');
  require('codemirror/addon/selection/active-line');

  require('codemirror/addon/hint/show-hint');
  require('codemirror/addon/hint/sql-hint');
  require('codemirror/addon/hint/css-hint');
  require('codemirror/addon/hint/anyword-hint');
  require('codemirror/addon/hint/javascript-hint');
}

class QueryTool extends PureComponent {
  constructor (props) {
    super(props)
    this.recentfile = {}
    this.state = {
      code: '',
      mode: 'text/x-pgsql',
      theme: 'default',
      file: {},
      contw: 1366,
      conth: window.innerHeight-30,
      framew: 1366,
      frameh:window.innerHeight-30,
      contscale:1,
      showtree:true,
      lineWrapping:"nowrap",
      prefix: "",
      projects: [],
      buffers: {},
      proxy: [],
      isProxyLoading: false
    }
  }
  
  onChange(newValue) {
    // console.log('change',newValue);
  }
  
  changeFileBuffer = (name) => {
    let {buffers} = this.state
    this.recentfile[this.state.prefix] = name
    let e = this.refs.editor.editor;
    let {doc,mode,file} = buffers[name]
    
    e.swapDoc(doc)
    e.focus()
    // e.setCursor(e.getCursor())
    e.setOption({mode,styleActiveLine: true})
    this.setState({mode,file:JSON.parse(JSON.stringify(file))})
  }

  changeFile = (code,extension,file) => {
		let {buffers} = this.state
    this.recentfile[this.state.prefix] = file.name
    if(code.type=='link'){
      console.log("should render link")
      this.setState({previewlink:code.link})
      return
    }
    try{
      let mode = ''
      if(extension=='js') {
        mode='text/jsx'
      } else if(extension=='svelte') {
        mode='text/html'
      } else {
        mode = ((CM.findModeByExtension(extension[0]) || '').mime) || 'text/plain'
      }
      if(code.startsWith('/*scss*/')){
        mode = 'text/x-scss'
      }
      if(!buffers[file.name]){
        buffers[file.name] = {doc:CM.Doc(code,mode),mode,file}
      }
      let e = this.refs.editor.editor;

      e.swapDoc(buffers[file.name]['doc'])
      if(e.getValue()!=code){
        alert("code changed")
      }
      e.focus()
      // e.setCursor(e.getCursor())
      e.setOption({mode,styleActiveLine: true})
      this.setState({file:JSON.parse(JSON.stringify(file)),mode})  
    } catch(e) {
      console.error(e)
      delete buffers[file.name];
      this.setState({cb: new Date()})
    }
  }

  getProjects = () => {
    let self = this
    fetch(`${window._MICROSITEDOMAIN}/service/microsites?rtype=projects`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        self.setState({projects:data.data})
      } else {
        throw new Error()
      }
    }).catch(function(){
      self.setState({message:"Unable to load Projects",message_id:new Date()})
    })
  }
  
  stopServer = (name) => {
    let self = this
    self.setState({serverdisable:true})
    fetch(`https://marketing-dashboards.anarock.com/api/v0/servers?rtype=stop&name=${name}`,{
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data){
      self.getServers()
      self.setState({serverdisable:false})
    }).catch(function(){
      self.setState({serverdisable:false})
    })
  }
  
  startServer = (name) => {
    let self = this
    self.setState({serverdisable:true})
    
    fetch(`https://marketing-dashboards.anarock.com/api/v0/servers?rtype=start&name=${name}`,{
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data){
      self.setState({serverdisable:false})
      self.getServers()
    }).catch(function(){
      self.setState({serverdisable:false})
    })
  }
  
  getProxy = () => {
    let self = this
    let {project_name} = this.state
    this.setState({ isProxyLoading: true})
    fetch(`${window._MICROSITEDOMAIN}/service/microsites?rtype=list_configurations&project=${project_name}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        self.setState({proxy:data.data, isProxyLoading: false})
      } else {
        throw new Error()
      }
    }).catch(function(){
      self.setState({message:"Unable to load",isProxyLoading: false, message_id:new Date()})
    })
  }
  
  getServers = () => {
    let self = this
    fetch(`${window._MDOMAIN}api/v0/servers?rtype=get`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({servers:data.data})
      } else {
        self.setState({message:"Unable to load Servers",message_id:new Date()})
      }
    }).catch(function(){
      self.setState({message:"Unable to load Servers",message_id:new Date()})
    })
  }

  saveFile = (code,file) => {
    let self = this
    fetch(`${window._MICROSITEDOMAIN}/service/save_file?rtype=save_file&path=${file.name}`, {
      method: 'POST',
      body: JSON.stringify(code),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        self.setState({message:"Saved Successfully",message_id:new Date()})
      } else {
        throw new Error()
      }
    }).catch(function(){
      self.setState({message:"Unable to Save Files",message_id:new Date()})
    })
  }
  
  addMappings = () => {
    let self = this
    let {t_domain,t_path,project_name} = this.state
    fetch(`${window._MICROSITEDOMAIN}/service/microsites?rtype=configure_domain_path&domain=${t_domain}&path=${t_path}&project=${project_name}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        self.setState({message:"Saved Successfully",message_id:new Date()})
        self.setState({t_domain:'',t_path:''});
        self.getProxy();
      } else {
        throw new Error()
      }
    }).catch(function(e){
      console.error(e)
      self.setState({message:"Unable to Save Mappings",message_id:new Date()})
    })
  }
  
  deleteMapping = (config) => {
    let self = this
    let {project_name} = this.state
    fetch(`${window._MICROSITEDOMAIN}/service/microsites?rtype=delete_configuration&domain=${config.domain}&path=${config.path}&project=${project_name}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success){
        self.setState({message:"Deleted Successfully",message_id:new Date()})
        let {proxy=[]} = self.state
        proxy = proxy.filter(o => o.id!=config.id)
        self.setState({t_domain:'',t_path:'',proxy})
      } else {
        throw new Error()
      }
    }).catch(function(e){
      console.error(e)
      self.setState({message:"Unable to Delete Mappings",message_id:new Date()})
    })
  }
  
  componentDidMount() {    
    try {
      this.recent = JSON.parse(localStorage['recent_sites'] || '[]')
    } catch(e){}
    window.editor = this.refs.editor
    this.getProjects()
  }

  closeFileTree = () => {
    this.setState({
      showtree: false, path: 'microsites/developer-panel', project_name: ''
    });
  }

  render() {
    let {paths=[],previewlink,serverdisable,servers=[],proxy=[],t_domain,t_path,preview_url='http://test-01.node.anarockdigital.com',project_name='',filterproject='',buffers,lineWrapping,showtree,code,theme,mode,message_id,message,file,contw,conth,framew,frameh,reload,contscale,preview,prefix,projects, isProxyLoading} = this.state
    return (
      <div style={{padding:5,paddingTop:50,display:'flex',flexDirection:'column',height:"100vh",width:"100vw"}} className="customscrollbig panel-rand">
        <div style={{position:'fixed',left:10,top:5,display:'flex',alignItems:'center',zIndex:1000}}>
          <Link to={'/'}>
          	<Ionicon onClick={() => this.setState({preview:false})} icon={'md-arrow-back'} fontSize="20px" color={"#6B7785"} style={{cursor:'pointer',marginRight:10}}/>
          </Link>          
          
          <DropDown 
            ref={(r) => {
              this.dropdown=r
            }}
            buttonLabel={project_name || 'Select Project'}>
            {() => (
              <OptionGroupRadio 
                searchBox
                searchBoxProps={{
                  placeholder: 'Search',
                  onChange: value => this.setState({filterproject:value}),
                  value: filterproject
                }}
                onChange={(value) => {
                  this.dropdown.toggleDropdown()
                  try {
                    let recent = JSON.parse(localStorage['recent_sites'] || '[]')
                    let name = projects.filter(o => o.path==value)[0].name
                    recent = [name,...recent.filter(x => x!=name)].slice(0,5)
                    localStorage['recent_sites']  = JSON.stringify(recent)
                    this.recent = recent
                  } catch(e){console.log(e)}
                  let project = projects.filter(o => o.path==value)[0]
                  let {path,name} = project
                  this.setState({prefix:path,project_name:name,showtree:false,filterproject:''})
                  if(this.recentfile[path]){
                    setTimeout(() => this.changeFileBuffer(this.recentfile[path]),500)
                  }
                  setTimeout(() => this.setState({showtree:true}),500)
                }}>
                {
                  projects
                  .sort((a,b) => ((this.recent.indexOf(a.name)+1 || 100) - (this.recent.indexOf(b.name)+1 || 100)))
                  .filter(o => o.name.toLowerCase().indexOf((filterproject||'').toLowerCase())!=-1)
                  .map((o) => (
                    <Option value={o.path} label={o.name} />
                  ))
                }
              </OptionGroupRadio>
            )}
          </DropDown>
          <div style={{padding:'3px 10px',border: '1px solid #ddd',marginLeft:10,borderRadius:3}}
            onClick={() => {
              console.log(this.state.file)
              if(this.state.file.name){
                this.saveFile(this.refs.editor.editor.getValue(),this.state.file)
              }
            }}>
          	Save
          </div>
          <div style={{marginLeft:10}}>
            <Button 
              onOpen={() => this.getServers()}
              label={"Servers"} popper={
              <div style={{background:"white",minWidth:200}}>
                {
                	serverdisable &&     
                  <div style={{position:'absolute',zIndex:100,width:'100%',height:'100%',background:'rgba(0,0,0,0.15)'}}></div>
                }
                {
                  servers.map(o => (
                    <div style={{display:'flex',padding:5,justifyContent:'space-between'}}>
                      <a style={{color:'inherit',whiteSpace:'nowrap'}} href={o.link} target="_blank" >
                        {o.name}
                      </a>
                      <div onClick={() => this.stopServer(o.name)} style={{cursor:'pointer',color:'inherit',paddingLeft:10}}>stop</div>
                    </div>
                  ))
                }
                <div onClick={() => this.startServer(prefix.split('/').slice(-1))}>
                  <button style={{margin:5}}>start server</button>
                </div>
              </div>
              }>
            </Button>
          </div>
          {project_name &&
          <div style={{marginLeft:10,display:'inline-block'}}>
            <Button onClick={(e) => {
                let c = confirm("Are you sure, you want to deploy?");
                if(c){
                	window.open(`${window._MICROSITEDOMAIN}/service/deploy?project=${project_name}`,'_blank')
                }
              }} label={"Depoly"}>
            </Button>
        	</div>
          }
          {project_name && 
          <div style={{marginLeft:10,userSelect:'select'}}>
            <Button 
              label={"Configure"}
              onOpen={() => this.getProxy()}
              popper={
              <div style={{background:"white",minWidth:200,padding:"20px 0px"}}>
                 {isProxyLoading ? (
                      <Loader scale={0.5} color={colors.purple.base} />
                    ) : (
                      <div>
                        {proxy.length === 0 ? (
                          <div style={{ minWidth: 400, textAlign: 'center', fontFamily: 'monospace' }}>
                            -- no mappings --
                          </div>
                        ) : (
                          <table>
                            <tbody>
                              {proxy.map((o) => (
                                <tr key={o.id} className={css`:hover { background: #e8e8ff; }`}>
                                  <td style={{ minWidth: 20 }}></td>
                                  <td>
                                    <a
                                      style={{ color: 'inherit', whiteSpace: 'nowrap' }}
                                      href={`https://${o.domain}`}
                                      target="_blank"
                                    >
                                      {o.domain}
                                    </a>
                                  </td>
                                  <td>
                                    <span style={{ margin: '0px 10px' }}>{'=>'}</span>
                                  </td>
                                  <td>
                                    <a
                                      style={{ color: 'inherit', whiteSpace: 'nowrap' }}
                                      href={`http://anarock-digital.s3.amazonaws.com/microsites/${project_name}/${o.path}`}
                                      target="_blank"
                                    >
                                      {o.path}
                                    </a>
                                  </td>
                                  <td>
                                    <div style={{ paddingTop: 5 }}>
                                      <Ionicon
                                        onClick={(e) => {
                                          const confirmed = confirm('Are you sure, you want to delete Mapping?');
                                          if (confirmed) {
                                            this.deleteMapping(o);
                                          }
                                        }}
                                        className={css`:hover { fill: #eb7785; }`}
                                        style={{ cursor: 'pointer' }}
                                        icon="md-close-circle"
                                        fontSize="14"
                                        color="#6b7785"
                                      />
                                    </div>
                                  </td>
                                  <td style={{ minWidth: 20 }}></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    )}
                <div onClick={() => {}} style={{border:'1px solid #ededed',padding:5,margin:10}}>
                  <div style={{display:'flex'}}>
                  	<div style={{width:60}}>domain</div>
                    <div><input style={{width:'250px',padding:3,marginBottom:10}} value={t_domain} onChange={e => this.setState({t_domain:e.target.value})} /></div>
                  </div>
                  <div style={{display:'flex'}}>
                  	<div style={{width:60}}>path</div>
                    <div>
                      <input 
                        style={{width:'250px',padding:3}} 
                        value={t_path} 
                        onChange={e => this.setState({t_path:e.target.value})} 
                        onFocus={() => this.setState({tpathsugg:true})}
                        onBlur={(e) => {
                          let target = e.target
                          setTimeout(() => target!=document.activeElement && this.setState({tpathsugg:false}),100)
                        }}
                        />
                      <div className={css_labels}>
                        {this.state.tpathsugg && 
                          paths
                          .filter(l => l && l.toLowerCase().indexOf(t_path)!=-1)
                          .map(l => (
                          <div onClick={() => this.setState({t_path:l})}>{l}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {<div onClick={() => t_path && t_domain && this.addMappings()} style={{background:t_path && t_domain?'#6161ff':'#6b7785',color:'#fff',display:'inline-block',marginTop:10,padding:'5px 10px',borderRadius:3}}>add mapping</div>}
                </div>
              </div>
              }>
            </Button>
          </div>
          }
        </div>
        <Notify message={message} message_id={message_id}/>
        <div style={{maxWidth:preview?Math.max(600,window.innerWidth-contw-10):null,width:"100%",height:"100%",border:'1px solid #e0e0e0'}}>
          {
            previewlink && 
              <div>
                {previewlink.indexOf('ftype=pdf')!=-1?
                	<iframe
                  frameborder="0" width="60%" height="60%"
                  style={{background:'#e0e0e0',position:'absolute',zIndex:1000,left:250,top:100,boxShadow:'rgba(0, 0, 0, 0.15) 0px 8px 15px 0px'}} 
                  src={previewlink} />  
                  :
                  <img
                    style={{maxWidth:'60%',maxHeight:'60%',background:'#e0e0e0',position:'absolute',zIndex:1000,left:250,top:100,boxShadow:'rgba(0, 0, 0, 0.15) 0px 8px 15px 0px'}} 
                    src={previewlink} />
                }
                
              </div>
          }
          <div style={{display:"flex",height:"100%",width:"100%"}} className="filebrowser">      
            <div style={{cursor:"pointer",position:'absolute',zIndex:100,bottom:10,left:10,border:"1px solid #e0e0e0",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {!showtree?
              <Ionicon icon="md-arrow-forward" onClick={() => this.setState({showtree:!showtree})}/>:
              <Ionicon icon="md-arrow-back" onClick={() => this.setState({showtree:!showtree})}/>
              }
            </div>
            {showtree &&
              <FileTree prefix={prefix} name={project_name} changeFile={this.changeFile} getProjects={this.getProjects} updatePaths={paths => this.setState({paths})} onClose={()=>this.closeFileTree()}/>
            }
            <div style={{flex:1,overflow:"hidden",display:'flex',flexDirection:"column"}}>
              <div style={{overflow:"auto",display:'flex',borderBottom:"1px solid #e0e0e0",borderLeft:"1px solid #e0e0e0"}}>
                {
                  Object.keys(buffers)
                  	.filter(o => o.startsWith(prefix))
                    .map(o => (
                    <div 
                      onClick={() => {this.changeFileBuffer(o)}}
                      style={{display:'flex',alignItems:'center',background:file.name==o?'#e8e8ff':null,cursor:"pointer",padding:5,borderRight:"1px solid #e0e0e0"}}>
                      {o.split('/').slice(-1)}
                      <Ionicon fontSize="22px" style={{padding:5}} icon="md-close" color="#6161ff" onClick={(e) => {e.stopPropagation();delete buffers[o];this.setState({cb:new Date()})}}/>
                    </div>
                  ))
                }
              </div>
              <div style={{flex:1,overflow:"hidden",borderLeft:"1px solid #e0e0e0"}}>
                 {
                  previewlink && 
                <div onClick={() => this.setState({previewlink:null})} style={{position:'absolute',width:'100%',height:'100%',zIndex:100,background:'#e0e0e0',opacity:.6}}></div>
                    }
                
                <CodeMirror 
                  ref="editor"
                  value={code || 'hello'}
                  onChange= {(editor, input) => {
                  }}
                  onKeyDown= {(editor, e)=> {
                    // console.log(e.keyCode)
                    if(e.repeat){
                      return
                    }
                    if(!e.metaKey && !e.ctrlKey && !e.altKey && e.key.replace(/[^a-zA-Z]/g,'').length==1) {
                      editor.showHint({ completeSingle: false })
                    }
                    
                    let valid = false
                    if(e.keyCode==83 && (e.metaKey || e.ctrlKey)){
                      if(this.state.file.name){
                        this.saveFile(editor.getValue(),this.state.file)
                      }
                      valid = true
                    } else if(e.keyCode==80 && (e.metaKey || e.ctrlKey)){
                      if(e.shiftKey) {
                        console.log("refresh files")
                      }
                      console.log("show all files in a priority order")
                      valid = true
                    }
                    if(valid){
                      e.preventDefault()
                      e.stopPropagation()  
                    }
                  }}
                  options={{
                    lineWrapping:lineWrapping=="wrap",
                    theme,
                    mode,
                    lineNumbers:true,
                    showHint: true,
                    fold: "indent",
                    keyMap: "sublime",
                    foldGutter: true,
                    styleActiveLine: true,
                    matchTags: true,
                    matchBrackets:true,
                    autoCloseBrackets: true,
                    showTrailingSpace: true,
                    tabSize:2,
                    indentUnit:2,
                    autoCloseTags: true,
                    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                }} />
              </div>
              <div style={{background:"rgb(247,247,247)",borderTop:"1px solid #e0e0e0"}}>
                <select 
                  value={theme} 
                  onChange={e => this.setState({theme:e.currentTarget.value})}>
                  {
                    ["default","monokai","eclipse"].map(o => (
                      <option value={o}>{o}</option>
                    ))
                  }
                </select>  
                <select 
                  value={mode} 
                  onChange={e => this.setState({mode:e.currentTarget.value})}>
                  {
                    CM.modeInfo.map(o => (
                      <option value={o.mime}>{o.name}</option>
                    ))
                  }
                </select>  
                <select 
                  value={lineWrapping} 
                  onChange={e => this.setState({lineWrapping:e.currentTarget.value})}>
                  {
                    ["wrap","nowrap"].map(o => (
                      <option value={o}>{o}</option>
                    ))
                  }
                </select>  
              </div>
            </div>
            {preview? 
              <div style={{width:contw,height:conth,position:'fixed',right:0,zIndex:10,top:0,background:"#e0e0e0",border:'1px solid #ccc',overflow:'hidden',resize:"horizontal"}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                  <Ionicon onClick={() => this.setState({preview:false})} icon={'md-close'} fontSize="14px" color={"#6B7785"} style={{}}/>
                  <input value={preview_url} onChange={e=>this.setState({preview_url:e.target.value})} />
                  <select onChange={(e) => {
                    let [w,h] = e.currentTarget.value.split("x")
                    if(parseInt(w)<500) {
                      contscale=1
                    }
                    this.setState({framew:parseInt(w),frameh:parseInt(h),contw:parseInt(w)*contscale,reload:new Date()})
                  }}>
                    <option value="1300x660">Desktop 1300x660</option>
                    <option value="800x800">Desktop 800x800</option>
                    <option value="450x800">Mobile 450x800</option>
                    <option value="500x800">Mobile 500x800</option>
                    <option value="500x1000">Mobile 500x1000</option>
                  </select>
                  <select onChange={(e) => {
                    let contscale = parseFloat(e.currentTarget.value)
                    this.setState({contscale,contw:framew*contscale})
                  }}>
                    <option value="1">Big</option>
                    <option value=".75">Medium</option>
                    <option value=".5">Small</option>
                  </select>
                  <Ionicon onClick={() => this.setState({reload:new Date()})} icon={'md-refresh'} fontSize="14px" color={"#6B7785"} style={{}}/>
                </div>
                <div style={{width:framew,height:frameh,transform:`translateX(-50%) translateY(-50%) scale(${Math.min((conth-30)/frameh,contw/framew)})`,transformOrigin:'center',background:'#fff',marginLeft:contw/2,marginTop:conth/2-10,border:"1px solid #000",display:'flex',justifyContent:'flex-end'}}>
                  <iframe src={`${preview_url}?reload=${reload}`} frameBorder="none" style={{width:"100%",height:"100%",resize:"none"}}/>
                </div>
              </div>:
              <div>
                <div style={{position:'fixed',display:'flex',right:0,top:0}}>
                  <div style={{marginRight:10,cursor:"pointer",color:"#6B7785",display:'flex',alignItems:'center'}} onClick={() => this.setState({preview:true})}>
                    <Ionicon icon={'md-browsers'} fontSize="14px" color={"#6B7785"} style={{}}/>
                    preview
                  </div>
                  <a style={{cursor:"pointer",color:"#6B7785",display:'flex',alignItems:'center'}}
                    target="_blank"
                    href={`http://test-01.node.anarockdigital.com`}>
                    <Ionicon icon={'md-browsers'} fontSize="14px" color={"#6B7785"} style={{}}/>
                    New tab
                  </a>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default QueryTool;