import { isAfter, isBefore, isEqual } from "date-fns";
import _ from "lodash";
export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true;

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    return deepArrayEqual(obj1, obj2);
  }

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

function deepArrayEqual(arr1, arr2) {
  if (arr1 === arr2) return true;

  if (
    !Array.isArray(arr1) ||
    !Array.isArray(arr2) ||
    arr1.length !== arr2.length
  ) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort(compareArrays);
  const sortedArr2 = arr2.slice().sort(compareArrays);

  for (let i = 0; i < sortedArr1.length; i++) {
    if (!deepEqual(sortedArr1[i], sortedArr2[i])) {
      return false;
    }
  }

  return true;
}

function compareArrays(a, b) {
  return JSON.stringify(a) > JSON.stringify(b) ? 1 : -1;
}

export const isValid = (data) => {
  return (
    data &&
    data.source &&
    data.parameter &&
    data.subsource_ids &&
    data.subsource_ids.length > 0 &&
    data.start_date &&
    data.end_date &&
    data.frequency &&
    data.frequency.length > 0 &&
    data.conditions &&
    data.conditions.length > 0 &&
    (isAfter(data.start_date, data.subsource_data.start_date) ||
      isEqual(data.start_date, data.subsource_data.start_date)) &&
    (isBefore(data.end_date, data.subsource_data.end_date) ||
      isEqual(data.end_date, data.subsource_data.end_date)) &&
    isAfter(data.end_date, data.start_date) &&
    _.every([
      ...data.conditions.map((item) => isValidCondition(item.rule_params[0])),
    ])
  );
};
const isValidCondition = (condition) =>
  condition.mediaplan_param >= 0 &&
  condition.notify_param >= 0 &&
  condition.cutoff_param >= 0;
