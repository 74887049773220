import * as React from "react";
import { css } from "emotion";
import { LogoProps } from "./typings/Logo";

const Logo: React.SFC<LogoProps> = ({ height = 40, color = "#000000" }) => {
  const style = css({
    fill: color
  });

  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={height}
      viewBox="0 0 413.31 92.91"
    >
      <defs>
        <clipPath id="clip-path" transform="translate(-57.88 -61.39)">
          <rect className="cls-1" width="529.46" height="215.51" />
        </clipPath>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g className="cls-2" id="Layer_1-2" data-name="Layer 1">
          <polygon
            className={style}
            points="7.31 91.39 3.84 82.98 2.62 82.98 6.81 92.81 7.79 92.81 11.98 82.98 10.8 82.98 7.31 91.39"
          />
          <path
            className={style}
            d="M89.05 144.31l-4.45 9.82h1.13l1.16-2.57h5.31l1.14 2.57h1.18l-4.44-9.82zm-1.73 6.24l2.23-5 2.22 5z"
            transform="translate(-57.88 -61.39)"
          />
          <polygon
            className={style}
            points="54.71 82.98 53.61 82.98 53.61 92.74 60.2 92.74 60.2 91.72 54.71 91.72 54.71 82.98"
          />
          <path
            className={style}
            d="M141.75 150.05c0 2.13-1.14 3.22-2.94 3.22s-3-1.18-3-3.29v-5.6h-1.1v5.67c0 2.76 1.64 4.24 4.05 4.24s4.07-1.48 4.07-4.32v-5.59h-1.1z"
            transform="translate(-57.88 -61.39)"
          />
          <polygon
            className={style}
            points="103.72 88.32 109.04 88.32 109.04 87.32 103.72 87.32 103.72 83.99 109.67 83.99 109.67 82.98 102.62 82.98 102.62 92.74 109.74 92.74 109.74 91.74 103.72 91.74 103.72 88.32"
          />
          <path
            className={style}
            d="M186.15 148.73c-2.14-.46-2.65-1-2.65-1.9 0-.87.81-1.57 2.08-1.57a4.3 4.3 0 0 1 2.82 1.06l.64-.85a5.07 5.07 0 0 0-3.43-1.2c-1.85 0-3.21 1.13-3.21 2.66 0 1.6 1 2.33 3.28 2.81 2 .44 2.54.95 2.54 1.86 0 1-.87 1.65-2.18 1.65a4.63 4.63 0 0 1-3.34-1.38l-.69.81a5.69 5.69 0 0 0 4 1.56c1.94 0 3.33-1.08 3.33-2.76 0-1.49-1-2.29-3.18-2.76m44.24-4.51a5 5 0 0 0-5 5 5 5 0 1 0 10 0 4.9 4.9 0 0 0-5-5m3.82 5.07a3.83 3.83 0 0 1-3.82 4 3.88 3.88 0 0 1-3.85-4 3.83 3.83 0 0 1 3.82-4 3.88 3.88 0 0 1 3.85 4z"
            transform="translate(-57.88 -61.39)"
          />
          <polygon
            className={style}
            points="198.14 91.39 194.67 82.98 193.44 82.98 197.64 92.81 198.61 92.81 202.81 82.98 201.62 82.98 198.14 91.39"
          />
          <polygon
            className={style}
            points="220.87 88.32 226.2 88.32 226.2 87.32 220.87 87.32 220.87 83.99 226.82 83.99 226.82 82.98 219.77 82.98 219.77 92.74 226.9 92.74 226.9 91.74 220.87 91.74 220.87 88.32"
          />
          <path
            className={style}
            d="M309.76 147.31a2.73 2.73 0 0 0-.75-1.93 3.88 3.88 0 0 0-2.78-1H302v9.75h1.1v-3.79h2.78l2.83 3.79h1.35l-3-4a2.86 2.86 0 0 0 2.65-2.84m-6.62 2v-3.89h3c1.57 0 2.49.72 2.49 1.92 0 1.25-1 2-2.51 2z"
            transform="translate(-57.88 -61.39)"
          />
          <polygon
            className={style}
            points="292.36 91.39 288.89 82.98 287.66 82.98 291.86 92.81 292.83 92.81 297.03 82.98 295.84 82.98 292.36 91.39"
          />
          <path
            className={style}
            d="M374.09 144.31l-4.44 9.82h1.13l1.15-2.57h5.31l1.15 2.57h1.18l-4.45-9.82zm-1.73 6.24l2.23-5 2.22 5z"
            transform="translate(-57.88 -61.39)"
          />
          <polygon
            className={style}
            points="339.75 82.98 338.65 82.98 338.65 92.74 345.24 92.74 345.24 91.72 339.75 91.72 339.75 82.98"
          />
          <path
            className={style}
            d="M426.8 150.05c0 2.13-1.14 3.22-2.94 3.22s-3-1.18-3-3.29v-5.6h-1.11v5.67c0 2.76 1.65 4.24 4.06 4.24s4.07-1.48 4.07-4.32v-5.59h-1.1z"
            transform="translate(-57.88 -61.39)"
          />
          <polygon
            className={style}
            points="388.76 88.32 394.09 88.32 394.09 87.32 388.76 87.32 388.76 83.99 394.72 83.99 394.72 82.98 387.66 82.98 387.66 92.74 394.79 92.74 394.79 91.74 388.76 91.74 388.76 88.32"
          />
          <rect className={style} y="76.12" width="399.11" height="0.74" />
          <polygon
            className={style}
            points="72.54 33.07 117.31 71.74 117.31 11.02 107.61 11.03 107.61 51.1 63.42 11.18 63.42 71.06 72.54 71.06 72.54 33.07"
          />
          <path
            className={style}
            d="M193.23 120.52h27.68l5.81 12h10.95l-30.41-62L177 132.5h10.44zm4.12-9.93l9.33-19.19 9.32 19.19zm-122.42 9.93h27.68l5.82 12h10.95L89 70.47l-30.29 62h10.4zm4.12-9.93l9.33-19.19 9.33 19.19zM248.39 120h2.89v13h2.81v-15.81h-8.5v13.05H242v-18.56l7.22-6.61 27.3 27.66h13.89l-26.89-26.54c5.63-.34 21.73-2.47 21.73-15.85 0-11.29-8.31-18-22.23-18h-23.71V133h9.09zm14.13-22.2H250V82.33h12.3c7.59 0 11.94 2.59 11.94 7.1.04 7.26-7.33 8.34-11.72 8.34zm55.55 34.72c18.06 0 30.67-12.37 30.67-30.09A30 30 0 0 0 340.28 81c-5.55-5.55-13.44-8.6-22.21-8.6-17.84 0-30.78 12.65-30.78 30.08s12.94 30.09 30.78 30.09m-21.41-30.1c0-13.3 8.81-22.6 21.43-22.6s21.27 9.3 21.27 22.6-8.75 22.74-21.27 22.74-21.43-9.4-21.43-22.79zm84.28 30.26c10.83 0 19-4.43 24.42-13.17l.25-.4-6.25-5.06-.31.57c-4.47 8.19-11.83 9.91-17.21 9.91-12.38 0-20.69-8.94-20.69-22.24 0-12.2 8.59-21.4 20-21.4 7.29 0 13.67 2.68 16.24 6.82l.37.6 5.77-6.15-.27-.36c-4.64-6.25-12-9.29-22.53-9.29-17.09 0-30 12.83-30 29.85 0 17.85 12.42 30.32 30.2 30.32"
            transform="translate(-57.88 -61.39)"
          />
          <polygon
            className={style}
            points="360.13 46.74 362.55 44.05 386.41 70.99 398.53 70.99 369.14 37.48 394.27 11.15 382.73 11.15 360.13 34.58 360.13 11.02 349.68 11.02 349.68 71.02 360.13 71.02 360.13 46.74"
          />
          <path
            className={style}
            d="M461.59 68.58h1.6a2.58 2.58 0 0 0 1.57-.34 1.08 1.08 0 0 0 .42-.9 1.1 1.1 0 0 0-.2-.64 1.28 1.28 0 0 0-.56-.43 4.3 4.3 0 0 0-1.33-.14h-1.5zm-1.31 4.6V65h2.8a6.88 6.88 0 0 1 2.09.23 2 2 0 0 1 1 .79 2.1 2.1 0 0 1 .38 1.19 2.17 2.17 0 0 1-.64 1.56 2.53 2.53 0 0 1-1.7.74 2.27 2.27 0 0 1 .69.43 9.29 9.29 0 0 1 1.21 1.62l1 1.6h-1.6l-.73-1.29a6.64 6.64 0 0 0-1.39-1.9 1.67 1.67 0 0 0-1.05-.27h-.78v3.46zm3.33-10.53a6.55 6.55 0 0 0-3.12.82 5.89 5.89 0 0 0-2.37 2.35 6.31 6.31 0 0 0 0 6.31 5.9 5.9 0 0 0 2.35 2.35 6.33 6.33 0 0 0 6.3 0 5.88 5.88 0 0 0 2.34-2.35 6.35 6.35 0 0 0 .84-3.14 6.47 6.47 0 0 0-.85-3.17 5.89 5.89 0 0 0-2.37-2.35 6.57 6.57 0 0 0-3.12-.82zm0-1.26a7.72 7.72 0 0 1 3.73 1 7 7 0 0 1 2.84 2.8 7.82 7.82 0 0 1 1 3.81 7.63 7.63 0 0 1-1 3.77 7 7 0 0 1-2.8 2.81 7.58 7.58 0 0 1-7.56 0 7 7 0 0 1-2.82-2.82 7.64 7.64 0 0 1-1-3.76 7.72 7.72 0 0 1 1-3.81 7 7 0 0 1 2.84-2.8 7.76 7.76 0 0 1 3.77-1z"
            transform="translate(-57.88 -61.39)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
