import React, { Component } from "react";
import { css } from "react-emotion";
import _ from "lodash";
import { mixins, colors, Button } from "pebble-web";
import RulesTableList from "./rules-table-list";
import FilterDropdown from "./filter-dropdown";

const invalidResults = css({
  margin: "15px 0px",
  padding: "24px 24px 10px",
  background: colors.white.base,
  ...mixins.flexMiddleAlign,
  flexDirection: "column",
  height: 300,
});

export default class RulesList extends Component {
  state = {
    rulesFilter: ["cpl", "cpql"],
  };
  render() {
    const {
      onCreate,
      onEdit,
      rulesMapping,
      campaigns,
      updateRule,
      deleteRule,
      subsourceMapping,
      assignment
    } = this.props;
    const { rulesFilter } = this.state;
    return (
      <div className={css({ padding: "10px 15px 30px" })}>
        <div className={css({ ...mixins.flexSpaceBetween })}>
          <div
            className={css({
              fontWeight: "bold",
              height: "40px",
              lineHeight: "40px",
              display: "flex",
              justifyContent: "center",
            })}
          >
            Manage Rules
          </div>
          <div className={css({ ...mixins.flexRow })}>
            <FilterDropdown
              value={rulesFilter}
              onChange={(value) => this.setState({ rulesFilter: value })}
            />
            <Button
              size="small"
              className={css({ marginLeft: "15px" })}
              onClick={() => onCreate()}
            >
              Create Rules
            </Button>
          </div>
        </div>
        <div>
          {rulesFilter.length === 0 && (
            <div className={invalidResults}>
              <div
                className={css({
                  marginBottom: "40px",
                  color: colors.gray.base,
                })}
              >
                Select valid parameter filters for results
              </div>
            </div>
          )}
          {Object.keys(rulesMapping)
            .filter((rules) => rulesFilter.includes(rules))
            .map((param) => (
              <RulesTableList
                subsourceMapping={subsourceMapping}
                rules={rulesMapping[param]}
                onEdit={onEdit}
                campaigns={campaigns}
                deleteRule={deleteRule}
                updateRule={updateRule}
                assignment={assignment}
              />
            ))}
        </div>
      </div>
    );
  }
}
