import React, { Component } from "react";
import { css, cx } from "react-emotion";
import Ionicon from "react-ionicons";
import { Popper } from "../segments";
import { DateRange } from "../../components";
import Notify from "../../components/notify";
import { Tooltip } from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {
  Modal,
  colors,
  Button,
  mixins,
  Select,
  Option,
  Search,
  DateInput,
  Input,
  Loader,
  Toast,
  Switch,
  TypeAhead,
} from "pebble-web";
import { Divider } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import _ from "lodash";
import "./agGridstyles.css";
import { SubSourceInput } from "./campaignComponents";
import { differenceInMinutes } from "date-fns";
function copy(text) {
  let div = document.createElement("input");
  div.value = text;
  document.body.appendChild(div);
  div.select();
  div.setSelectionRange(0, 99999);
  document.execCommand("copy");
  setTimeout(() => div.parentNode.removeChild(div));
}

const css_labels = css`
  min-width: 200px;
  background: #fff;
  max-height: 200px;
  overflow: auto;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  background: #fff;
  z-index: 100000;
  > div {
    padding: 10px 5px;
    cursor: pointer;
    :hover {
      background: #fbfbfb;
    }
  }
`;
export const purposeButtonText = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
const campaignPage = css({
  margin: "15vh 0px",
  height: "30px",
  flexDirection: "column",
  ...mixins.flexMiddleAlign,
  color: colors.gray.dark,
});
const adListBody = css({
  maxWidth: "130px",
  overflow: "hidden",
  whiteSpace: "no-wrap",
  textOverflow: "ellipsis",
});
const iconStyle = css({
  display: "inline-block",
  margin: "auto 0px",
  cursor: "pointer",
  fontSize: 12,
  color: colors.gray.dark,
});
const modalBackDropStyle = css({
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const modalContainerBody = css({
  background: colors.white.base,
  borderRadius: "10px",
  position: "fixed",
  top: 50,
  bottom: 50,
});
const mappingContainer = css({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden",
});
const mainPage = css({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden",
});
const bottomBox = css({
  display: "flex",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
  borderRadius: "0px 0px 10px 10px",
  flexDirection: "column",
  alignItems: "flex-end",
  backgroundColor: colors.white.base,
  padding: "10px 24px",
  zIndex: 5000,
});
const genericBody = css({
  overflow: "auto",
  padding: "10px 24px",
  height: "100vh",
});
const mappingBody = css({
  padding: "10px 24px",
});
const inputCampaignBody = css({
  margin: "15px 0px",
  padding: "24px 24px 10px",
  background: colors.white.base,
});
const campaignComponent = css({
  margin: "10px 0px 0px",
  width: "25%",
  fontSize: "10px",
  color: colors.gray.dark,
  marginRight: "15px",
  "&:last-child": {
    marginRight: "0px",
  },
});
const spendComponent = css({
  fontSize: "15px",
  color: colors.gray.dark,
});
const headerBody = css({
  margin: "10px 0px",
});
const mainBody = css({
  ...mixins.flexSpaceBetween,
  marginBottom: 10,
});
const itemHeader = css({
  fontSize: "13px",
  fontWeight: 100,
  color: colors.gray.dark,
});
const required = css`
  &:after {
    content: " *";
    color: red;
  }
`;
const listBody = css({
  display: "flex",
});
const searchBox = css({
  borderRadius: 0,
  minWidth: 250,
  backgroundColor: colors.gray.base,
  ">input": {
    width: "92%",
    fontFamily: "Anarock",
  },
  ".icon-close": {
    fontSize: 7,
  },
  ">input::placeholder": {
    color: colors.gray.base,
  },
});
const line = css({
  width: "175px",
  height: "25px",
  fontSize: "13px",
  color: colors.gray.dark,
  borderBottom: `1px solid ${colors.gray.lighter}`,
});

const LEADGEN_FORM = "Leagen_form";
const URL = "URL";
const VIRTUAL_NUM = "Virtual_Num";

const flexCenter = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});
const modalPadding = 30;
const modalContainer = css({
  background: colors.white.base,
  width: "440px",
  alignSelf: "center",
  position: "relative",
});
const iconCloseClassName = css({
  cursor: "pointer",
  position: "absolute",
  right: modalPadding,
  top: 25,
  fontSize: "14px",
  color: colors.gray.base,
  "&:hover": {
    color: colors.gray.darker,
  },
});
export class Table extends Component {
  state = {};
  render() {
    let {
      data,
      keys,
      columns = {},
      actions,
      select,
      add,
      addclick,
      stats,
      statsclick,
      developerProjects,
    } = this.props;
    const newData = data.map((d) => {
      const devloperProject =
        developerProjects &&
        developerProjects.filter((e) => e.asem_id == d.asem_id)[0];
      return {
        ...d,
        asem_id: devloperProject && devloperProject.name,
      };
    });
    return (
      <div
        className={css`
          table,
          tr,
          td,
          th {
            border-collapse: collapse;
            border: 1px solid #ededed;
            background: #fff;
            .right {
              text-align: right;
            }
          }
          td {
            padding: 10px 15px;
          }
          th {
            padding: 10px 15px;
            background: #fbfbfb;
            color: #6b7785;
          }
          .link {
            cursor: pointer;
            :hover {
              text-decoration: underline;
            }
          }
        `}
      >
        <table>
          <tr>
            {keys.map((k) => (
              <th>
                {k == "channel"
                  ? "channel_type"
                  : k == "asem_id"
                  ? "project name"
                  : k}
              </th>
            ))}
            {actions && <th colspan={10}>actions</th>}
          </tr>
          {newData.map((row, _r) => (
            <tr>
              {keys.map((k) => (
                <td className={`table-cell ${k == "spends" ? "right" : ""}`}>
                  {columns[k] ? columns[k](row[k], _r) : row[k]}
                </td>
              ))}
              {actions && (
                <td
                  className={css(`cursor:pointer;`)}
                  onClick={() => select(row)}
                >
                  edit
                </td>
              )}
              {add && add(row) && (
                <td onClick={() => addclick(row)}>add users</td>
              )}
              {stats && (
                <td
                  className={css(`cursor:pointer;`)}
                  onClick={() => statsclick(row)}
                >
                  stats
                </td>
              )}
            </tr>
          ))}
        </table>
      </div>
    );
  }
}

class ListSelector extends Component {
  state = {};
  render() {
    let { ftext = "", addafter = "" } = this.state;
    const item = css`
      display: flex;
      padding: 10px 20px;
      cursor: pointer;
      :hover {
        background: #fbfbfb;
      }
    `;
    const input = css`
      margin: 5px;
      padding: 5px 10px;
      outline: none;
      border: none;
      border: 1px solid #e0e0e0;
      flex: 1;
      width: 190px;
    `;
    const btn = css`
      padding: 10px 20px;
      background: #6161ff;
      color: #fff;
      border-radius: 3px;
      cursor: pointer;
      display: inline-block;
    `;
    let { getValue, handleChange, name, all_options } = this.props;
    let options = getValue(name) || [];
    let omap = {};
    options.map((o) => (omap[o.key] = 1));
    return (
      <div
        style={{
          background: "#fff",
          borderRadius: 3,
          overflow: "hidden",
          border: "1px solid #ededed",
        }}
      >
        <div style={{ width: 300, height: 200, overflow: "auto" }}>
          <div style={{ position: "absolute", right: 50 }}>
            {(addafter != "" || options.length == 0) && (
              <Popper
                close={() => this.setState({ addafter: "" })}
                html={
                  <div
                    className={css`
                      background: #fff;
                      width: 200px;
                      overflow: hidden;
                    `}
                  >
                    <input
                      className={input}
                      placeholder="Add fields"
                      onChange={(e) => this.setState({ ftext: e.target.value })}
                      value={ftext}
                    />
                    <div
                      className={css`
                        max-height: 200px;
                        overflow: scroll;
                        div {
                          padding: 5px;
                          cursor: pointer;
                          &:hover {
                            background: #e0e0e0;
                          }
                        }
                      `}
                    >
                      {all_options
                        .filter((o) => o.indexOf(ftext) != -1 && !omap[o])
                        .map((o) => (
                          <div
                            onClick={() => {
                              options.push({ key: o, sel: false });
                              handleChange({
                                type: "keyval",
                                key: name,
                                value: options,
                              });
                              this.setState({ addafter: o.key });
                            }}
                          >
                            {o}
                          </div>
                        ))}
                    </div>
                  </div>
                }
              />
            )}
          </div>
          {options.map((o) => (
            <div className={item}>
              <Ionicon
                icon={`md-${o.sel ? "checkbox" : "square-outline"}`}
                color="#6b7785"
                onClick={() => {
                  o.sel = !o.sel;
                  handleChange({ type: "keyval", key: name, value: options });
                }}
              />
              <div style={{ marginLeft: 10, flex: 1 }}>{o.key}</div>
              <Ionicon
                icon="md-add"
                onClick={() => this.setState({ addafter: o.key })}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

class SimpleList extends Component {
  state = {};
  render() {
    let {
      getValue,
      handleChange,
      name,
      all_options,
      single = false,
      close = () => {},
      sty = "",
    } = this.props;
    let options = getValue(name) || [];
    if (!(options instanceof Array)) {
      options = [options];
    }
    const item = css`
      display: flex;
      padding: 10px 20px;
      cursor: pointer;
      :hover {
        background: #fbfbfb;
      }
    `;
    let omap = {};
    options.map((o) => (omap[o] = 1));
    let cont = css`
      min-width: 200px;
      max-height: 200px;
      min-height: 100px;
      overflow: auto;
      background: #fff;
      white-space: nowrap;
      &.small {
        min-width: 100px;
      }
    `;
    return (
      <div className={cont + " " + sty}>
        {all_options.map((o) => (
          <div
            className={item}
            onClick={() => {
              // o.sel = !o.sel
              let k = o;
              let v = o;
              if (o.hasOwnProperty("id")) {
                k = o.id;
                v = o.name;
              }
              if (single) {
                options = [k];
              } else {
                if (omap[k]) {
                  options = options.filter((item) => item != k);
                } else {
                  options.push(k);
                }
              }
              handleChange({ type: "keyval", key: name, value: options });
              if (single) {
                close();
              }
            }}
          >
            {!single && (
              <Ionicon
                icon={`md-${omap[o.id || o] ? "checkbox" : "square-outline"}`}
                color="#6b7785"
              />
            )}
            <div style={{ marginLeft: 10, flex: 1 }}>{o.name || o.id || o}</div>
          </div>
        ))}
      </div>
    );
  }
}

export class Dropdown extends Component {
  state = {};
  render() {
    let { label, getValue, name, all_options, sty = "" } = this.props;
    let options = getValue(name) || [];
    if (!(options instanceof Array)) {
      options = [options];
    }
    let { open } = this.state;
    const toggle = () => this.setState({ open: !open });
    const container = css`
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 1px solid #e0e0e0;
      padding: 5px 10px;
      min-width: 200px;
      border-radius: 3px;
      &.small {
        font-weight: bold;
        color: #000;
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
        padding: 0px;
        min-width: 100px;
        svg {
          display: none;
        }
      }
    `;
    const chip = css`
      display: inline-block;
      margin: 2px;
      font-size: 12px;
      padding: 0px 5px;
      white-space: nowrap;
    `;

    let idNames = {};
    if (all_options) {
      all_options.map((o) => {
        idNames[o.id] = o.name;
      });
    }
    const isDisabled = !all_options || all_options.length === 0;
    return (
      <div style={{ position: "relative", ...(this.props.style || {}) }}>
        <div className={cx(isDisabled && css({ pointerEvents: "none" }))}>
          <div className={cx(container,sty)} onClick={toggle}>
            <div
              style={{
                flex: 1,
                maxWidth: 300,
                overflow: "hidden",
                maxHeight: 100,
                whiteSpace: "normal",
              }}
            >
              {options.length ? (
                options.map((o) => (
                  <span className={chip}>{idNames[o] || o}</span>
                ))
              ) : (
                <span
                  style={{
                    color: "#888",
                    padding: "0px 5px",
                    margin: 2,
                    display: "inline-block",
                  }}
                >
                  Select {label}
                </span>
              )}
            </div>
            <Ionicon icon="md-arrow-dropdown" color="#6b7785" />
          </div>
        </div>
        {open && (
          <div style={{ position: "absolute", width: "100%" }}>
            <Popper
              allowClicks={true}
              align={"left"}
              close={toggle}
              html={<SimpleList {...this.props} close={toggle} />}
            />
          </div>
        )}
      </div>
    );
  }
}

class CustomInput extends Component {
  state = { local: this.props.getValue(this.props.name) };
  componentWillReceiveProps(nextProps) {
    this.setState({ local: nextProps.getValue(nextProps.name) });
  }
  render() {
    let { open = false, local } = this.state;
    let {
      getValue,
      handleChange,
      name,
      readonly,
      data,
      options = [],
      scheme,
    } = this.props;
    const developerProject =
      data.developerProjects &&
      data.developerProjects.filter((e) => e.asem_id == local)[0];
    var project =
      data.developerProjects &&
      data.developerProjects.filter((e) => e.asem_id == local);
    return (
      <div
        style={{ position: "relative" }}
        onClick={(e) => e.stopPropagation()}
      >
        <input
          className={css`
            width: 200px;
            padding: 5px;
          `}
          style={{
            border: scheme["empty"] && !local ? "1px solid #e97451" : "",
          }}
          autocomplete="off"
          spellCheck={false}
          name={name}
          value={
            local && typeof local == "number"
              ? developerProject && developerProject.name
              : local
          }
          onChange={(e) => this.setState({ local: e.target.value })}
          readOnly={
            scheme.readscheme &&
            scheme.readscheme[name] &&
            scheme.readscheme[name].readonly
          }
          onFocus={() => this.setState({ open: true })}
          onBlur={(e) => {
            let target = e.target;
            setTimeout(() => this.setState({ open: false }), 300);
          }}
        />
        {!(
          scheme.readscheme &&
          scheme.readscheme[name] &&
          scheme.readscheme[name].readonly
        ) && (
          <Ionicon
            icon="md-close"
            fontSize="12"
            color="#6b7785"
            onClick={() => {
              handleChange({ type: "keyval", key: name, value: "" });
            }}
          />
        )}
        {name == "subsource" && (
          <div style={{ marginLeft: "35px", display: "inline" }}>
            <a
              target="_blank"
              href={`https://lead.${window._TDOMAIN}/channel_sub_sources`}
            >
              create missing subsource here
            </a>
          </div>
        )}

        {open && (
          <div className={css_labels}>
            {options
              .filter(
                (l) =>
                  l &&
                  l
                    .toLowerCase()
                    .indexOf(
                      (local && typeof local == "number" && project.length > 0
                        ? developerProject.name
                        : !local ||
                          (typeof local == "number" && project.length == 0)
                        ? ""
                        : local
                      ).toLowerCase()
                    ) != -1
              )
              .map((l) => (
                <div
                  onClick={() => {
                    let value = l; //[...this.getValue(o.key).split(',').slice(0,-1),l].join(',')
                    handleChange({ type: "keyval", key: name, value });
                  }}
                >
                  {l}
                </div>
              ))}
          </div>
        )}
        {scheme["empty"] && !local && (
          <p style={{ color: "red" }}>This field is required</p>
        )}
      </div>
    );
  }
}

class Segments extends Component {
  state = {};
  render() {
    return (
      <div
        className={css`
          display: flex;
        `}
      >
        <Dropdown
          all_options={["Segment 1", "Segment 2", "Segment 3"]}
          {...this.props}
          style={{ marginRight: 10 }}
          label="Select Segments"
          name="segments.projects"
        />
      </div>
    );
  }
}

class Schedule extends Component {
  state = { show: false };
  render() {
    let { getValue, handleChange } = this.props;
    let { show } = this.state;
    let slots = [];
    for (let i = 8; i < 20; i++) {
      let hour = ("000" + (i % 12)).slice(-2);
      if (hour == "00") hour = "12";
      slots.push(hour + ":00 " + (i >= 12 ? "PM" : "AM"));
      slots.push(hour + ":30 " + (i >= 12 ? "PM" : "AM"));
    }
    return (
      <div
        className={css`
          color: #888;
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
          `}
        >
          <Dropdown
            sty="small"
            all_options={["Now", "Once", "Every"]}
            {...this.props}
            label="Freq"
            single={true}
            name="json.schedule.freq"
          />
          {getValue("json.schedule.freq") && (
            <div
              onClick={() =>
                handleChange({
                  type: "keyval",
                  key: "json.schedule.freq",
                  value: null,
                })
              }
              className={css`
                margin-left: 5px;
                cursor: pointer;
                font-size: 10px;
                :hover {
                  text-decoration: underline;
                }
              `}
            >
              clear
            </div>
          )}
        </div>
        {getValue("json.schedule.freq") && (
          <span>
            {getValue("json.schedule.freq") == "Every" && (
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  margin-top: 10px;
                `}
              >
                <Dropdown
                  sty="small"
                  all_options={[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ]}
                  {...this.props}
                  style={{ marginRight: 10 }}
                  label="Week Days"
                  name="json.schedule.days"
                />
              </div>
            )}
            {getValue("json.schedule.freq") != "Now" && (
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  margin-top: 10px;
                  .DayPickerInput {
                    position: relative;
                    height: 24px;
                    border-bottom: 1px solid #ccc;
                    display: flex;
                    align-items: flex-end;
                    .DayPickerInput-OverlayWrapper {
                      position: absolute;
                      left: 0px;
                      margin-top: 5px;
                    }
                    input {
                      text-align: right;
                      color: #000;
                      font-family: Anarock;
                      width: 60px;
                      font-size: 14px;
                      font-weight: bold;
                      border: none;
                      padding-bottom: 5px;
                    }
                  }
                `}
              >
                <span>at&nbsp;&nbsp;</span>
                {getValue("json.schedule.freq") == "Once" && (
                  <DayPickerInput
                    value={
                      getValue("json.schedule.date")
                        ? new Date(getValue("json.schedule.date"))
                        : ""
                    }
                    placeholder="dd mmm"
                    formatDate={(o) => {
                      let [m, d, y] = o.toString().split(" ").slice(1, 4);
                      return `${d} ${m}`;
                    }}
                    format="dd mmm"
                    onDayChange={(day) => {
                      let pts = day
                        .toLocaleDateString("en-GB")
                        .split(",")[0]
                        .split("/");
                      handleChange({
                        type: "keyval",
                        key: "json.schedule.date",
                        value: `${pts[2]}-${pts[1]}-${pts[0]}`,
                      });
                    }}
                  />
                )}
                <Dropdown
                  sty="small"
                  all_options={slots}
                  {...this.props}
                  label="Time"
                  single={true}
                  name="json.schedule.time"
                />
              </div>
            )}
            <div
              className={css`
                display: flex;
                align-items: center;
                margin-top: 10px;
              `}
            >
              <span>to &nbsp;&nbsp;</span>
              <Dropdown
                sty="small"
                all_options={["All Users", "New Users"]}
                {...this.props}
                label="Users"
                single={true}
                name="json.schedule.utype"
              />
              <Tooltip
                classes={{ popper: css({ zIndex: " 10000012 !important" }) }}
                title={
                  <div className={css(`font-size:12px`)}>
                    <div>a. All users - All the users in the segment</div>{" "}
                    <div className={css(`padding-top:4px;`)}>
                      b. New users - New users added after the last scheduled
                      run (only for campaigns with multiple schedules)
                    </div>
                  </div>
                }
                onOpen={() => this.setState({ show: true })}
                onClose={() => this.setState({ show: false })}
                arrow
              >
                <div className={css(`margin-left:5px;`)}>
                  <Ionicon
                    fontSize="16px"
                    icon="md-information-circle"
                    color={show ? "#000" : "#ccc"}
                  ></Ionicon>
                </div>
              </Tooltip>
              <span>&nbsp;&nbsp;added to segments </span>
            </div>
          </span>
        )}
      </div>
    );
  }
}

const Tabs = (P) => {
  const item = css`
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    margin: 3px;
    padding: 5px;
    cursor: pointer;
    :hover {
      background: #e1e1ff;
    }
  `;
  const sel = css`
    border-color: #6161ff;
    color: #6161ff;
  `;
  return (
    <div
      className={css`
        display: flex;
        margin-bottom: 10px;
      `}
    >
      {P.options.map((o) => (
        <div
          className={item + " " + ((o.key || o) == P.sel ? sel : "")}
          onClick={() => P.onClick && P.onClick(o.key || o)}
        >
          {o.value || o}
        </div>
      ))}
    </div>
  );
};
class SpendsIntegration extends Component {
  state = {};
  getHashTag = (conf) => {
    let { id, subsource } = conf;
    if (!id) return "";
    return "#" + id.toString(36) + "|" + id.toString(35) + "-" + subsource;
  };
  render() {
    // mode, project wise data, mandate data. with save functionality.
    let { getValue, handleChange, name, data } = this.props;
    let obj = getValue(name) || {};
    let projects = getValue("projects") || [{ key: "" }];
    let assignment_id = getValue("assignment_id");
    let { mode = "mandate" } = obj;
    let { project = projects[0].key } = this.state;
    let conf_key = "m_" + assignment_id;

    let default_value = {};
    var modeId = `m_${assignment_id}`;
    let { channel, source, subsource, asem_id } = getValue("");
    let status =
      data["spends_integration"] &&
      data["spends_integration"][modeId] &&
      data["spends_integration"][modeId].status;
    let json =
      data["spends_integration"] &&
      data["spends_integration"][modeId] &&
      data["spends_integration"][modeId].json;
    let id =
      data["spends_integration"] &&
      data["spends_integration"][modeId] &&
      data["spends_integration"][modeId].id;
    if (!obj[conf_key]) {
      let defaultjson = { type: "manual" };
      if (["facebook", "google", "taboola"].indexOf(source) != -1) {
        defaultjson = { type: "api" };
      }
      let o = {
        json: json || [defaultjson],
        status,
        channel,
        source,
        subsource,
        asem_id,
        dirty: true,
        id: id,
      };
      if (mode == "mandate") {
        o["assignment_id"] = assignment_id;
      }
      default_value = o;
    }
    let conf = obj[conf_key] || default_value;
    conf.json = conf.json || [{ type: "manual" }];
    var developerProject =
      conf.asem_id &&
      data.developerProjects &&
      data.developerProjects.filter((e) => e.name == asem_id)[0];
    conf.asem_id =
      conf.asem_id && typeof conf.asem_id == "number"
        ? conf.asem_id
        : developerProject && developerProject.asem_id;

    if (!(channel && source && subsource)) {
      return <div>select channel, project name, source, subsource</div>;
    }
    return (
      <div
        className={css`
          font-size: 11px;
        `}
      >
        {
          //Disabling Project ,'project'
        }
        <Tabs
          options={["mandate"]}
          sel={mode}
          onClick={(tab) =>
            handleChange({ type: "keyval", key: name + ".mode", value: tab })
          }
        />
        <div
          style={{
            border: "10px solid #e0e0e0",
            padding: 10,
            borderRadius: 3,
            position: "relative",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              right: 10,
              top: 0,
            }}
            onClick={() => {
              conf.status = conf.status == "active" ? "inactive" : "active";
              conf.dirty = true;
              handleChange({
                type: "keyval",
                key: name + "." + conf_key,
                value: conf,
              });
            }}
          >
            {conf.status == "active" ? (
              <img
                style={{ width: 40 }}
                src="https://cdn-icons-png.flaticon.com/512/889/889754.png"
              ></img>
            ) : (
              <img
                style={{ width: 40 }}
                src="https://cdn-icons-png.flaticon.com/512/889/889758.png"
              ></img>
            )}
          </div>
          {conf.json.map((o, i) => (
            <div
              className={css`
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                > * {
                  margin-right: 10px;
                }
              `}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <DateRange
                  key1="start_date"
                  key2="end_date"
                  single={true}
                  getValue={(k) => o[k] || ""}
                  handleDayClick={(key, day) => {
                    if (!day && o[key]) {
                      delete o[key];
                    } else if (day) {
                      let pts = day
                        .toLocaleDateString("en-GB")
                        .split(",")[0]
                        .split("/");
                      if (Math.abs(parseInt(pts[2]) - 2019) < 10) {
                        o[key] = `${pts[2]}-${pts[1]}-${pts[0]}`;
                      } else {
                        o[key] = null;
                      }
                    }
                    handleChange({
                      type: "keyval",
                      key: name + "." + conf_key,
                      value: conf,
                    });
                  }}
                />
              </div>
              <select
                value={o["type"]}
                onChange={(e) => {
                  o["type"] = e.target.value;
                  handleChange({
                    type: "keyval",
                    key: name + "." + conf_key,
                    value: conf,
                  });
                }}
                className={css`
                  outline: none;
                  background: #fff;
                  border: 1px solid #ededed;
                  height: 28px;
                `}
              >
                {["manual", "api", "cpsv", "cpb", "cpl", "cpql", "bulk"].map(
                  (o) => (
                    <option>{o}</option>
                  )
                )}
              </select>
              {["cpsv", "cpb", "cpl", "cpql", "bulk"].indexOf(o["type"]) !=
                -1 && (
                <input
                  value={o["amount"]}
                  type="number"
                  onChange={(e) => {
                    o["amount"] = parseInt(e.target.value);
                    handleChange();
                    handleChange({
                      type: "keyval",
                      key: name + "." + conf_key,
                      value: conf,
                    });
                  }}
                  className={css`
                    width: 100px;
                    height: 28px;
                    padding: 5px;
                    border: 1px solid #ededed;
                    outline: none;
                  `}
                />
              )}
              {o["type"] == "api" && (
                <div
                  onClick={() => {
                    copy(this.getHashTag(conf));
                    this.setState({ background: true });
                    setTimeout(() => {
                      this.setState({ background: false });
                    }, 1000);
                  }}
                  style={{
                    position: "relative",
                    color: "#6161ff",
                    minWidth: 60,
                    maxWidth: 250,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#fff",
                      transition: "opacity .3s linear",
                      background: "#000",
                      opacity: this.state.background ? 1 : 0,
                      display: "flex",
                      justifyContent: "center",
                      position: "absolute",
                      zIndex: 100,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    key copied!
                  </div>
                  {this.getHashTag(conf)}
                  <Ionicon
                    icon="md-copy"
                    color="#6b7785"
                    fontSize="14px"
                    className={css`
                      margin-top: -2px;
                      margin-left: 5px;
                      cursor: pointer;
                      :hover {
                        fill: #eb7785;
                      }
                    `}
                  />
                </div>
              )}
              {false && (
                <div>
                  <Ionicon
                    onClick={() => {
                      conf["json"] = conf["json"].filter((_, j) => j != i);
                      handleChange({
                        type: "keyval",
                        key: name + "." + conf_key,
                        value: conf,
                      });
                    }}
                    icon="md-close-circle"
                    color="#6b7785"
                    fontSize="14px"
                    className={css`
                      margin-top: 7px;
                      cursor: pointer;
                      :hover {
                        fill: #eb7785;
                      }
                    `}
                  />
                </div>
              )}
              <div style={{ paddingRight: 50 }}></div>
            </div>
          ))}
          {false && (
            <div
              className={css`
                padding: 5px 10px;
                background: #6b7785;
                border-radius: 3px;
                cursor: pointer;
                color: #fff;
                display: inline-block;
                margin: 10px 0px;
              `}
              onClick={() => {
                conf.json = conf.json || [];
                conf.json.push({ type: "manual" });
                handleChange({
                  type: "keyval",
                  key: name + "." + conf_key,
                  value: conf,
                });
              }}
            >
              add spend
            </div>
          )}
        </div>
      </div>
    );
  }
}

const Tip = (P) => {
  const sty = css`
    width: fit-content;
    margin: 10px 0px;
    border: 1px solid darkgoldenrod;
    border-radius: 3px;
    color: darkgoldenrod;
    background: #fff;
    padding: 5px;
  `;
  return <div className={sty}>{P.tip}</div>;
};

class LeadsIntegration extends Component {
  state = {};
  render() {
    let { getValue, handleChange, name } = this.props;
    let { channel, source, subsource } = getValue("");
    let obj = getValue(name);
    let { projects, virtual_numbers = {}, channels = {} } = obj;
    let { project = projects[0].key } = this.state;
    let guide = ({ cname, asemc_id }) => {
      if (cname == "facebook") {
        return (
          <a target="_blank" href="leadgen">
            goto leadgen
          </a>
        );
      } else if (cname == "google") {
        return "google leadgen guide";
      } else if (["99acres", "quikr", "commonfloor"].indexOf(cname) != -1) {
        return (
          <a
            target="_blank"
            href={`https://lead.anarock.com/portals/${asemc_id}/edit`}
          >
            portal integration
          </a>
        );
      } else {
        return (
          <a target="_blank" href="https://anarock.github.io/leads/">
            microsite guide
          </a>
        );
      }
    };

    let requires = {
      facebook: "facebook",
      google: "google",
      linkedin: "zapier",
      portals: "99acres",
      quikr: "quikr",
    };
    let reversemap = {};
    Object.keys(requires).map((k) => {
      if (!reversemap[requires[k]]) reversemap[requires[k]] = [];
      reversemap[requires[k]].push(k);
    });
    let showif = ({ source, cname }) => {
      if (reversemap[cname]) return reversemap[cname].indexOf(source) != -1;
      return true;
    };
    return (
      <div
        className={css`
          font-size: 11px;
          table,
          td,
          tr,
          th {
            border: 1px solid #e0e0e0;
            border-collapse: collapse;
          }
          td,
          th {
            padding: 5px;
          }
          th {
            background: #555;
            color: #fff;
          }
        `}
      >
        <div>
          <Tabs
            options={projects}
            sel={project}
            onClick={(project) => this.setState({ project })}
          />
        </div>
        {requires[source] &&
          (!channels[project] || !channels[project].keys[requires[source]]) && (
            <Tip
              tip={`add ${requires[source]} channel mapping to start integration`}
            />
          )}

        {channels[project] ? (
          <table>
            <tr>
              <th>channel</th>
              <th>campaign-key</th>
              <th>integrations</th>
            </tr>
            {channels[project].values
              .filter((o) => showif({ source, ...o }))
              .map((o) => (
                <tr>
                  <td>{o.cname}</td>
                  <td>{o.campaign_id}</td>
                  <td>{guide(o)}</td>
                </tr>
              ))}
          </table>
        ) : (
          <div style={{ padding: 30, color: "#6b7785" }}>
            no channel mappings available. add them on mandate dashboard
          </div>
        )}
        {virtual_numbers[project] &&
          virtual_numbers[project].numbers.length > 0 && (
            <div
              style={{
                display: "flex",
                padding: "10px 0px",
                alignItems: "center",
              }}
            >
              virtual numbers:{" "}
              {virtual_numbers[project].numbers.map((o) => (
                <div style={{ padding: 10 }}>{o}</div>
              ))}
            </div>
          )}
        <div style={{ marginTop: 10, display: "flex" }}>
          Url: <div className="link">http://test.com/abcd</div>
        </div>
      </div>
    );
  }
}

export const Status = (P) => {
  const _status = css`
    display: flex;
    > div {
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #ccc;
      cursor: pointer;
      &.sel {
        background: #00c;
        color: #fff;
      }
    }
    > div:last-child {
      margin-left: 10px;
      &.sel {
        background: #c00;
        color: #fff;
      }
    }
  `;
  return (
    <div className={_status}>
      <div
        className={P.getValue(P.name) == "active" && "sel"}
        onClick={() =>
          P.handleChange({ type: "keyval", key: P.name, value: "active" })
        }
      >
        active
      </div>
      <div
        className={P.getValue(P.name) == "inactive" && "sel"}
        onClick={() =>
          P.handleChange({ type: "keyval", key: P.name, value: "inactive" })
        }
      >
        inactive
      </div>
    </div>
  );
};

class SMSTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = { testphones: localStorage["test-phones"], error: false };
  }

  onChange(e, P) {
    const template = P.getValue("json.template")
      .replaceAll("^", "\\^")
      .replaceAll(".", "\\.")
      .replaceAll("*", "\\*")
      .replaceAll("$", "\\$")
      .replaceAll("{#var#}", ".*");
    const regex = new RegExp("^" + template + "$");
    if (regex.test(e.target.value)) {
      P.handleChange(e);
    } else {
      this.setState({ error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 1000);
    }
  }

  sendTestSMS(phones) {
    let P = this.props;
    let self = this;
    fetch(`${window._DIGIXDOMAIN}/api/v0/sms?rtype=send-test`, {
      method: "post",
      credentials: "include",
      body: JSON.stringify({ phones, sms_campaign: P.getValue("") }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          self.setState({ message: "Test SMS Sent", message_id: new Date() });
          self.setState({ showtest: false });
        } else {
          self.setState({
            message: data.info || "Unable to send test sms",
            message_id: new Date(),
          });
        }
      })
      .catch(function () {
        self.setState({
          message: "Unable to send test sms",
          message_id: new Date(),
        });
      });
  }
  sendWhatsappText(phones) {
    let P = this.props;
    let self = this;
    fetch(`${window._DIGIXDOMAIN}/api/v0/whatsapp?rtype=send-test`, {
      method: "post",
      credentials: "include",
      body: JSON.stringify({ whatsapp_numbers: phones, whatsapp_campaign: P.getValue("") }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          self.setState({ message: "Test Whatsapp Message Sent", message_id: new Date() });
          self.setState({ showtest: false });
        } else {
          self.setState({
            message: "Unable to send test message",
            message_id: new Date(),
          });
        }
      })
      .catch(function () {
        self.setState({
          message: "Unable to send test message",
          message_id: new Date(),
        });
      });
  }

  render() {
    let { showtest, testphones, message, message_id, show, error } = this.state;
    let P = this.props;
    let value = P.getValue(P.name);
    const tooltip =
      "Use ‘[NAME], [EMAIL] or [PHONE]’ to dynamically use user’s details in SMS text. ex - Hi [NAME], As you done site visit on ABC project, we have an offer just for you ….. ";
    const errorTooltip = "Only {#var#} fields are editable";
    const link = css`
      font-size: 11px;
      color: #6b7785;
      text-decoration: underline;
      position: relative;
      cursor: pointer;
    `;
    const smsCount = css`
      font-size: 11px;
      color: #6b7785;
      position: relative;
    `;
    return (
      <div>
        <Notify message={message} message_id={message_id} />
        <div className={css(`display:flex;`)}>
          <textarea
            type={P.type}
            className={css`
              padding: 5px;
              border: 1px solid #ededed;
              outline: none;
              width: 300px;
              height: 100px;
            `}
            value={value}
            checked={value}
            name={P.name}
            onChange={(e) => this.onChange(e, P)}
            disabled={true}
          />
        </div>
        <div
          className={css`
            display: flex;
          `}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              alignContent: "space-between",
              justifyContent: "space-between",
              width: "300px",
            }}
          >
            <div
              className={link}
              onClick={() => this.setState({ showtest: true })}
            >
              send test message
            </div>
            <div>
              <div className={smsCount}>Char Count: {value.length}</div>
              <div className={smsCount}>
                SMS Count: {Math.ceil(value.length / 160)}
              </div>
            </div>
            {showtest && (
              <Popper
                close={() => this.setState({ showtest: false })}
                align={"left"}
                html={
                  <div
                    className={css`
                      position: absolute;
                      z-index: 100;
                      background: #fff;
                      box-shadow: 0px 0px 10px #0003;
                      padding: 10px;
                      border-radius: 3px;
                      margin-top: 20px;
                      width: 300px;
                    `}
                  >
                    <textarea
                      value={testphones || ""}
                      onChange={(e) => {
                        let testphones = e.target.value.replace(/[^0-9,]/g, "");
                        localStorage["test-phones"] = testphones;
                        this.setState({ testphones });
                      }}
                      style={{
                        width: "100%",
                        height: 100,
                        outline: "none",
                        padding: 10,
                        color: "#666",
                        fontSize: 12,
                      }}
                    ></textarea>
                    <div
                      className={css`
                        color: #888;
                        font-size: 8px;
                      `}
                    >
                      enter comma seperated 10 digit mobile numbers to send test
                      message
                    </div>
                    <div
                      className={css`
                        padding: 10px;
                        background: #6161ff;
                        color: #fff;
                        border-radius: 3px;
                        width: 100px;
                        text-align: center;
                        margin-top: 10px;
                      `}
                      onClick={() => {
                        if (P.label === "Whatsapp Template") {
                          this.sendWhatsappText(testphones);
                        } else {
                          this.sendTestSMS(testphones);
                        }
                      }}
                    >
                      send
                    </div>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

class EmailTemplate extends Component {
  state = { testemails: localStorage["test-emails"] };
  sendTestEmail(emails) {
    let P = this.props;
    let self = this;
    fetch(`${window._DIGIXDOMAIN}/api/v0/emails?rtype=send-test`, {
      method: "post",
      credentials: "include",
      body: JSON.stringify({ emails, email_campaign: P.getValue("") }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          self.setState({ message: "Test Email Sent", message_id: new Date() });
          self.setState({ showtest: false });
        } else {
          self.setState({
            message: "Unable to send test Email",
            message_id: new Date(),
          });
        }
      })
      .catch(function () {
        self.setState({
          message: "Unable to send test Email",
          message_id: new Date(),
        });
      });
  }
  render() {
    let { showtest, testemails, message, message_id } = this.state;
    let P = this.props;
    let value = P.getValue(P.name);
    const link = css`
      font-size: 11px;
      color: #6b7785;
      text-decoration: underline;
      margin-right: 10px;
      position: relative;
      cursor: pointer;
    `;
    return (
      <div>
        <Notify message={message} message_id={message_id} />
        <textarea
          type={P.type}
          className={css`
            padding: 5px;
            border: 1px solid #ededed;
            outline: none;
            width: 300px;
            height: 100px;
          `}
          value={value}
          checked={value}
          name={P.name}
          onChange={P.handleChange}
        />
        <div
          className={css`
            display: flex;
          `}
        >
          <div style={{ position: "relative" }}>
            <div
              className={link}
              onClick={() => this.setState({ showtest: true })}
            >
              send test email
            </div>
            {showtest && (
              <Popper
                close={() => this.setState({ showtest: false })}
                align={"left"}
                html={
                  <div
                    className={css`
                      position: absolute;
                      z-index: 100;
                      background: #fff;
                      box-shadow: 0px 0px 10px #0003;
                      padding: 10px;
                      border-radius: 3px;
                      margin-top: 20px;
                      width: 300px;
                    `}
                  >
                    <textarea
                      value={testemails || ""}
                      onChange={(e) => {
                        let testemails = e.target.value;
                        localStorage["test-emails"] = testemails;
                        this.setState({ testemails });
                      }}
                      style={{
                        width: "100%",
                        height: 100,
                        outline: "none",
                        padding: 10,
                        color: "#666",
                        fontSize: 12,
                      }}
                    ></textarea>
                    <div
                      className={css`
                        color: #888;
                        font-size: 8px;
                      `}
                    >
                      enter comma seperated emails to send test email
                    </div>
                    <div
                      className={css`
                        padding: 10px;
                        background: #6161ff;
                        color: #fff;
                        border-radius: 3px;
                        width: 100px;
                        text-align: center;
                        margin-top: 10px;
                      `}
                      onClick={() => this.sendTestEmail(testemails)}
                    >
                      send
                    </div>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const SimpleEdit = (P) => {
  const types = {
    string: { elem: "input", type: "text" },
    int: { elem: "input", type: "text" },
    text: { elem: "textarea", type: "text" },
    sms: { elem: "sms", type: "text" },
    email: { elem: "email", type: "text" },
    boolean: { elem: "input", type: "checkbox" },
    senderID: { elem: "senderID", type: "text" },
    placeholders: { elem: "placeholders", type: "text" },
    gateway: { elem: "gateway", type: "text" },
  };

  let { elem, type } = types[P.type] || {};

  const link = css`
    font-size: 11px;
    color: #6b7785;
    text-decoration: underline;
    margin-right: 10px;
    position: relative;
  `;

  let value = P.getValue(P.name);
  return (
    <div>
      {elem == "senderID" && (
        <div className={css({ ...mixins.flexRow })}>
          <input
            type={type}
            placeholder={'For E.g: ANRLMS'}
            className={css`
              padding: 5px;
              border: 1px solid #ededed;
              outline: none;
              width: 200px;
            `}
            value={value}
            checked={value}
            name={P.name}
            onChange={(e) => {
              P.handleChange({
                type: "keyval",
                key: P.name,
                value: e.target.value,
              });
            }}
          />
          {P.templateLoading && (
            <Loader
              className={css({ marginLeft: "10px" })}
              scale={0.6}
              color={colors.violet.base}
            />
          )}
        </div>
      )}
      {elem == "gateway" && (
        <input
          type={type}
          className={css`
            padding: 5px;
            border: 1px solid #ededed;
            outline: none;
            width: 200px;
          `}
          value={value}
          checked={value}
          name={P.name}
          onChange={P.handleChange}
          disabled={true}
        />
      )}
      {elem == "input" && (
        <input
          type={type}
          className={css`
            padding: 5px;
            border: 1px solid #ededed;
            outline: none;
            width: 200px;
          `}
          value={value}
          checked={value}
          name={P.name}
          onChange={P.handleChange}
        />
      )}
      {elem == "placeholders" && <Placeholder {...P} />}
      {elem == "textarea" && (
        <textarea
          type={type}
          className={css`
            padding: 5px;
            border: 1px solid #ededed;
            outline: none;
            width: 300px;
            height: 60px;
            font-size: 12px;
          `}
          value={value}
          checked={value}
          name={P.name}
          onChange={P.handleChange}
        />
      )}
      {elem == "sms" && <SMSTemplate {...P} type={type} />}
      {elem == "email" && <EmailTemplate {...P} type={type} />}
      {P.type == "select-open" && (
        <div style={{ display: "flex" }}>
          {P.options.map((o) => (
            <div
              className={
                css`
                  cursor: pointer;
                  color: #999;
                  border: 1px solid #999;
                  margin-right: 5px;
                  padding: 5px;
                  border-radius: 3px;
                  &.sel {
                    border-color: #6161ff;
                    color: #6161ff;
                  }
                ` + ` ${value == o ? "sel" : ""}`
              }
              onClick={() =>
                !P.readonly &&
                P.handleChange({ type: "keyval", key: P.name, value: o })
              }
            >
              {o}
            </div>
          ))}
        </div>
      )}
      {P.type == "status" && <Status {...P} />}
      {P.type == "dropdown" && (
        <Dropdown
          {...P}
          getValue={P.getValue}
          handleChange={P.handleChange}
          name={P.name}
          all_options={P.currentOptions}
        />
      )}
      {P.type == "custom-input" && <Input {...P} />}
      {P.type == "schedule" && <Schedule {...P} />}
      {P.type == "segments" && <Segments {...P} />}
      {P.type == "spends_integration" && <SpendsIntegration {...P} />}
      {P.type == "leads_integration" && <LeadsIntegration {...P} />}
    </div>
  );
};

export class Block extends Component {
  state = { show: new Array(this.props.ui.length).fill(false) };
  flatten = (obj) => {
    return Object.keys(obj).map((k) => ({ id: k, name: obj[k] }));
  };
  render() {
    const P = this.props;
    const { show } = this.state;
    const item = css`
      padding: 10px 20px;
      display: flex;
      align-items: flex-start;
    `;
    const column = css`
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      > div {
        margin-bottom: 10px;
      }
    `;
    const label = css`
      width: 120px;
      color: #6b7785;
      margin-right: 10px;
      padding-top: 7px;
    `;
    const required = css`
      &:after {
        content: " *";
        color: red;
      }
    `;
    return (
      <div>
        {P.ui.map((o, idx) => {
          const currentOptions =  P.options && P.options.hasOwnProperty(o) && (
            P.options[o] instanceof Array ? P.options[o] :
            this.flatten(P.options[o])
           ) || [];
          return (
          <React.Fragment>
            <div
              className={
                (P.scheme[o].column ? column : item) +
                " " +
                (P.scheme[o].sep
                  ? css`
                      border-top: 2px solid #e0e0e0;
                      margin-top: 20px;
                      padding-top: 20px;
                    `
                  : "")
              }
            >
              <div
                className={
                  label +
                  " " +
                  (P.scheme[o].type == "custom-input" ? required : "")
                }
              >
                {P.scheme[o].label}
              </div>
              <SimpleEdit
                templateLoading={P.loading}
                getValue={P.getValue}
                handleChange={P.handleChange}
                data={P.data}
                name={o}
                scheme={P.scheme}
                currentOptions={currentOptions}
                {...P.scheme[o]}
              />
              {P.remove && (
                <Ionicon
                  icon="md-close"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let data = P.getValue(P.prefix.slice(0, -1));
                    delete data[o];
                    P.handleChange({
                      type: "keyval",
                      key: P.prefix.slice(0, -1),
                      value: data,
                    });
                  }}
                />
              )}
              {P.scheme[o].tooltip ? (
                <div>
                  <Tooltip
                    classes={{
                      popper: css({ zIndex: " 10000012 !important" }),
                    }}
                    title={
                      <span
                        className={css`
                          font-size: 12px;
                        `}
                      >
                        {P.scheme[o].tooltip}
                      </span>
                    }
                    onOpen={() => {
                      let temp = [...show];
                      temp[idx] = true;
                      this.setState({ show: temp });
                    }}
                    onClose={() => {
                      let temp = [...show];
                      temp[idx] = false;
                      this.setState({ show: temp });
                    }}
                    arrow
                  >
                    <div className={css(`margin-left:5px;`)}>
                      <Ionicon
                        fontSize="16px"
                        icon="md-information-circle"
                        color={show[idx] ? "#000" : "#ccc"}
                      />
                    </div>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </React.Fragment>
        )})}
      </div>
    );
  }
}
export class DetailCard extends Component {
  state = { data: JSON.parse(JSON.stringify(this.props.data)) };
  componentDidMount = () => {
    let { data } = this.state;
    if (data && data.developerProjects && data.developerProjects.length == 1) {
      this.setState({
        data: {
          ...data,
          asem_id:
            data.developerProjects &&
            data.developerProjects.map((i) => i.asem_id)[0],
        },
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)) {
      this.setState({ data: JSON.parse(JSON.stringify(nextProps.data)) });
    }
  }
  setValue = (key, value, dtype) => {
    let assignment_id = this.getValue("assignment_id");
    var modeId = `m_${assignment_id}`;

    if (dtype == "int") {
      value = parseInt(value) || 0;
    } else if (dtype == "float") {
      value = parseFloat(value) || 0;
    }
    let { data } = this.state;
    let output = data;
    let last = key.split(".").slice(-1);
    key
      .split(".")
      .slice(0, -1)
      .map((k) => {
        if (!output[k]) {
          output[k] = {};
        }
        output = output[k];
      });
    output[last] = value;
    data["dirty"] = true;

    if (output["spends_integration"] && output["spends_integration"][modeId]) {
      output["spends_integration"][modeId].dirty = true;
    }

    if (key.split(".")[0] == "spends_integration") {
      output[last].dirty = true;
    }
  };

  getValue = (key) => {
    let { data } = this.state;
    let output = data;
    if (typeof output["json"] != "object") {
      output["json"] = {};
    }
    key.split(".").map((k) => {
      if (output) {
        output = output[k];
      }
    });
    if (key == "") {
      output = data;
    }
    let value =
      typeof output == "object" ? JSON.parse(JSON.stringify(output)) : output;
    return value === undefined ? "" : value;
  };
  handleTemplateVariableChange = (type, val) => {
    const {data} = this.state;
    const {fetchTemplates} = this.props;
    this.setValue(type,val);
    fetchTemplates(data.json.api_token, data.json.url);
  }
  handleSenderIdChange = (val) => {
    let { fetchTemplates } = this.props;
    fetchTemplates(val);
    this.setValue("json.display_name", val);
    this.setValue("template_id", undefined);
    this.setValue("json.placeholder", undefined);
    this.setValue("json.template", undefined);
  };
  handleTemplateChange = (val) => {
    this.setValue("json.template", val[0]);
    const template = this.props.mapping[val[0]];
    const placeholders = template.placeholders;
    this.setValue("template_id", template.id);
    this.setValue("json.placeholder", placeholders);
  };
  handleWATItemplateChange = (val) => {
    this.setValue("template_name", val[0]);
    const template = this.props.mapping[val[0]];
    const message = template.content;
    const placeholders = template.placeholders;
    this.setValue("json.template_text", message);
    this.setValue("json.placeholder", placeholders);
    this.setValue("params",{});
  };

  handleChange = (event) => {
    let { data } = this.state;
    if (!event) {
      data.dirty = true;
    } else if (event.type == "keyval") {
      if (event.key == "json.display_name") {
        this.handleSenderIdChange(event.value);
      }
      else if (event.key == "template_name") {
        this.handleWATItemplateChange(event.value);
      }
      else if (event.key == "json.template") {
        this.handleTemplateChange(event.value);
      } else {
        this.setValue(event.key, event.value);
      }
    } else if (event.target.name == "meta.developer_key") {
      let key = event.target.value.replace(/-/g, "");
      let pass = md5("jhdsb" + key)
        .words[0].toString(36)
        .replace(/-/g, "");
      this.setValue(event.target.name, key + "-" + pass);
    } else if (event.target.type == "checkbox") {
      this.setValue(event.target.name, event.target.checked);
    } else if ( event.target.name == 'json.api_token' || event.target.name == 'json.url') {
      this.handleTemplateVariableChange(event.target.name,event.target.value)
    }  
    else {
      let dtype = event.target.getAttribute("dtype");
      this.setValue(event.target.name, event.target.value, dtype);
    }
    this.setState({ cb: new Date(), data: JSON.parse(JSON.stringify(data)) });
  };
  render() {
    let { ropen, copen, showoverrides, data, message, message_id } = this.state;
    const item = css`
      padding: 10px 0px;
      display: flex;
      align-items: center;
    `;
    const label = css`
      min-width: 100px;
      color: #6b7785;
      margin-right: 10px;
    `;
    let { save, updateScheme, updateSMS } = this.props;
    return (
      <div
        className={css`
          background: #fff;
          padding: 20px 0px;
          min-width: 500px;
          min-height: 400px;
        `}
      >
        <Notify message={message} message_id={message_id} />
        <div>
          <Block
            {...this.props}
            ui={this.props.ui}
            getValue={this.getValue}
            handleChange={this.handleChange}
          />
        </div>
        {data.dirty && (
          <div
            className={css`
              border-top: 2px solid #e0e0e0;
              padding: 10px;
              display: flex;
              div {
                padding: 5px;
                margin: 5px;
                border: 1px solid #000;
                cursor: pointer;
              }
            `}
          >
            <div
              style={{ background: "#000", color: "#fff" }}
              onClick={() => save(data)}
            >
              Save Changes
            </div>
            <div
              onClick={() => {
                this.setState({
                  data: JSON.parse(JSON.stringify(this.props.data)),
                });
                if (updateScheme) updateScheme(false);
                if (updateSMS) updateSMS();
              }}
            >
              Cancel
            </div>
          </div>
        )}
      </div>
    );
  }
}
export class CampaignForm extends Component {
  state = {
    data: JSON.parse(JSON.stringify(this.props.data)),
    spendData: this.props.spendData,
    loading: false,
  };
  componentDidMount = () => {
    let { data, spendData } = this.state;
    if (data) {
      this.setState({
        data: {
          ...data,
          hashkey: this.getHashTag(
            this.getProjectId(data),
            this.getProjectChannel(data),  
            data.source,
            data.subsource,
            spendData
          ),
        },
      });
      if (data.developerProjects && data.developerProjects.length == 1) {
        this.setState({
          data: {
            ...data,
            hashkey: this.getHashTag(
              this.getProjectId(data),
              this.getProjectChannel(data),
              data.source,
              data.subsource,
              spendData
            ),
            asem_id:
              data.developerProjects &&
              data.developerProjects.map((i) => i.asem_id)[0],
          },
        });
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)) {
      const newSpendsData = JSON.parse(JSON.stringify(nextProps.data));
      const newData = JSON.parse(JSON.stringify(nextProps.spendData));
      if (newData) {
        this.setState({
          data: {
            ...newData,
            hashkey: this.getHashTag(
              this.getProjectId(newData),
              this.getProjectChannel(newData),
              newData.source,
              newData.subsource,
              newSpendsData
            ),
          },
        });
        if (
          newData.developerProjects &&
          newData.developerProjects.length == 1
        ) {
          this.setState({
            data: {
              ...newData,
              hashkey: this.getHashTag(
                this.getProjectId(newData),
                this.getProjectChannel(newData),
                newData.source,
                newData.subsource,
                newSpendsData
              ),
              asem_id:
                newData.developerProjects &&
                newData.developerProjects.map((i) => i.asem_id)[0],
            },
          });
        }
      }
    }
  }
  setValue = (key, value, dtype) => {
    let assignment_id = this.getValue("assignment_id");
    var modeId = `m_${assignment_id}`;

    if (dtype == "int") {
      value = parseInt(value) || 0;
    } else if (dtype == "float") {
      value = parseFloat(value) || 0;
    }
    let { data } = this.state;
    let output = data;
    let last = key.split(".").slice(-1);
    key
      .split(".")
      .slice(0, -1)
      .map((k) => {
        if (!output[k]) {
          output[k] = {};
        }
        output = output[k];
      });
    output[last] = value;
    data["dirty"] = true;

    if (output["spends_integration"] && output["spends_integration"][modeId]) {
      output["spends_integration"][modeId].dirty = true;
    }

    if (key.split(".")[0] == "spends_integration") {
      output[last].dirty = true;
    }
  };
  updateData = (conf) => {
    let {
      channel,
      source,
      subsource,
      asem_id,
      spends_integration = { mode: "mandate" },
    } = conf;
    var project_id =
      asem_id &&
      this.props.data.developerProjects &&
      this.props.data.developerProjects.filter((e) => e.name == asem_id)[0];
    let changes = [];
    Object.keys(spends_integration).map((k) => {
      if (spends_integration[k] && spends_integration[k].dirty) {
        changes.push(spends_integration[k]);
        changes[0].asem_id =
          changes[0].asem_id !== project_id && project_id
            ? project_id && project_id.asem_id
            : changes[0].asem_id;
      }
    });
    if (!source) {
      scheme["empty"] = true;
    }
    if (!subsource) {
      scheme["empty"] = true;
    }
    if (!asem_id) {
      scheme["empty"] = true;
    }
    let body = {
      mode: spends_integration.mode || "mandate",
      channel,
      source,
      subsource,
      asem_id: project_id && project_id.asem_id,
      changes,
    };

    let { assignment_id } = this.props.data.assignment_id;
    let self = this;
    this.setState({ loading: true });
    fetch(
      `${window._MDOMAIN}api/v0/url-generator?rtype=update&assignment_id=${assignment_id}`,
      {
        method: "post",
        credentials: "include",
        body: JSON.stringify(body),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.status == 200) {
          let { spendData } = self.state;
          let sconf = {};
          spendData.map((o) => (sconf[o.id] = o));
          data.data.map((o) => (sconf[o.id] = o));
          spendData = Object.keys(sconf)
            .map((k) => sconf[k])
            .sort((a, b) =>
              new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
            );
          self.setState({ spendData: spendData });
          self.setState({
            data: {
              ...self.state.data,
              hashkey: self.getHashTag(source, subsource, spendData),
            },
          });
          self.setState({ loading: false });
        } else {
          self.setState({
            message: "Unable to Generate HasKey",
            message_id: new Date(),
          });
          self.setState({ loading: false });
        }
      })
      .catch(function (e) {
        console.error(e);
        self.setState({
          message: "Error! Unable to Generate HasKey",
          message_id: new Date(),
        });
        self.setState({ loading: false });
      });
  };
  getValue = (key) => {
    let { data } = this.state;
    let output = data;
    if (typeof output["json"] != "object") {
      output["json"] = {};
    }
    key.split(".").map((k) => {
      if (output) {
        output = output[k];
      }
    });
    if (key == "") {
      output = data;
    }
    let value =
      typeof output == "object" ? JSON.parse(JSON.stringify(output)) : output;
    return value === undefined ? "" : value;
  };
  handleTemplateChange = (val) => {
    var id;
    Object.entries(this.props.mapping).some(([k, v]) => {
      if (v.indexOf(val) != -1) {
        id = k;
        return true;
      }
    });
    this.setValue("json.template", val);
    this.setValue("json.display_name", [id]);
  };
  getProjectId = (projectData) =>{
   if(projectData.asem_id && projectData.developerProjects){
    if(Number.isInteger(projectData.asem_id)) return projectData.asem_id;
    return projectData.developerProjects.find( prj => prj.name === projectData.asem_id).asem_id;
   } 
  }
  getProjectChannel = (projectData) => projectData && projectData.channel;
  getHashTag = (asem_id, channel, source, subsource, spendsData) => {
    let id;
    if (source && subsource) {
      let item = spendsData.find(
        (item) => item.source === source && item.subsource === subsource && item.asem_id === asem_id &&  item.channel === channel
      );
      if (item) id = item.id;
    }
    if (!id) return undefined;
    return "#" + id.toString(36) + "|" + id.toString(35) + "-" + subsource;
  };
  handleChange = (event) => {
    let { data } = this.state;
    if (!event) {
      data.dirty = true;
    } else if (event.type == "keyval") {
      if (event.key == "json.template") {
        this.handleTemplateChange(event.value[0]);
      } else {
        this.setValue(event.key, event.value);
      }
    } else if (event.target.name == "meta.developer_key") {
      let key = event.target.value.replace(/-/g, "");
      let pass = md5("jhdsb" + key)
        .words[0].toString(36)
        .replace(/-/g, "");
      this.setValue(event.target.name, key + "-" + pass);
    } else if (event.target.type == "checkbox") {
      this.setValue(event.target.name, event.target.checked);
    } else {
      let dtype = event.target.getAttribute("dtype");
      this.setValue(event.target.name, event.target.value, dtype);
    }
    data.hashkey = this.getHashTag(
      this.getProjectId(data),
      this.getProjectChannel(data),
      data.source,
      data.subsource,
      this.state.spendData
    );
    this.setState({ cb: new Date(), data: JSON.parse(JSON.stringify(data)) });
  };

  render() {
    let { data, loading, spendData } = this.state;
    let { save, updateScheme, onClick, adData, mode, filteredSubsources, updateLoading } = this.props;
    let conf_key = "m_" + this.props.data.assignment_id;
    if (loading)
      return (
        <div className={campaignPage}>
          <Loader scale={1.5} />
        </div>
      );
    const isApiMode =
      data.spends_integration &&
      data.spends_integration[`${conf_key}`] &&
      data.spends_integration[`${conf_key}`].json &&
      data.spends_integration[`${conf_key}`].json[0] &&
      data.spends_integration[`${conf_key}`].json[0].type === "api";
    return (
      <div className={mainPage}>
        <div className={genericBody}>
          <Button size="small" type="secondary" onClick={onClick}>
            <span className={purposeButtonText}>
              <i className={"pi pi-back"} />
              &nbsp; Back to Campaigns
            </span>
          </Button>
          <div className={inputCampaignBody}>
            <div>
              <div>Campaign</div>
            </div>
            <Divider />
            <div>
              <FormComponent
                mode={mode}
                facebook_data={this.props.facebook_data}
                filteredSubsources={filteredSubsources}
                updateScheme={this.props.updateScheme}
                scheme={this.props.scheme}
                spendData={spendData}
                data={data}
                ui={this.props.ui}
                getValue={this.getValue}
                handleChange={this.handleChange}
              />
            </div>
          </div>
          {mode === "resolve error" &&
            data.source === "facebook" &&
            data.subsource != undefined && (
              <div>
                {data.hashkey != undefined ? (
                  <CampaignMapping mappingData={data} {...this.props} />
                ) : (
                  <div className={campaignPage}>
                    <div className={css({ fontSize: "40px" })}>
                      <i className="pi pi-warning" />
                    </div>
                    <div className={css({ marginTop: "15px" })}>
                      No Campaigns to display for the selected details
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>
        {mode != "resolve error" && data.dirty && (
          <div className={bottomBox}>
            <div className={css({ ...mixins.flexRow })}>
              <Button
                type="secondary"
                loading={updateLoading}
                className={css({ marginRight: "10px" })}
                onClick={() => {
                  this.setState({
                    data: JSON.parse(JSON.stringify(this.props.data)),
                  });
                  updateScheme(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => save(data)}
                disabled={!data.dirty}
                loading={updateLoading}
              >
                Save{" "}
                {isApiMode &&
                  data.hashkey === undefined &&
                  "and Generate HasKey"}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { show: new Array(this.props.ui.length).fill(false) };
  }
  render() {
    const P = this.props;
    const spendsInfo = P.ui.filter((o) => o.label == "spends_integration")[0];
    return (
      <div>
        <div className={css({ color: colors.gray.darker, fontSize: "16px" })}>
          Project Details
        </div>

        <div className={mainBody}>
          {P.ui
            .filter((o) => o.section == "Project Details")
            .map((o, idx) => (
              <CampaignInput
                key={P.getValue(o.label)+o.label}
                filteredSubsources={P.filteredSubsources}
                mode={P.mode}
                required={o.required}
                getValue={P.getValue}
                handleChange={P.handleChange}
                data={P.data}
                name={o.label}
                scheme={P.scheme}
                {...P.scheme[o.label]}
              />
            ))}
        </div>
        <CampaignSpends
          key={JSON.stringify(P.getValue(spendsInfo.label))}
          campaignMode={P.mode}
          required={spendsInfo.required}
          getValue={P.getValue}
          handleChange={P.handleChange}
          spendsMapping={this.props.spendData}
          data={P.data}
          name={spendsInfo.label}
          scheme={P.scheme}
          {...P.scheme[spendsInfo.label]}
        />
      </div>
    );
  }
}
class CampaignInput extends Component {
  state = {
    isDisabled: false,
    filter: "",
  };
  componentDidMount = () => {
    let { mode, label, handleChange, name } = this.props;
    if (mode === "resolve error") {
      if (label === "Source") {
        handleChange({ type: "keyval", key: name, value: "facebook" });
        this.setState({ isDisabled: true });
      }
      if (label === "Channel Type") {
        handleChange({ type: "keyval", key: name, value: "online" });
        this.setState({ isDisabled: true });
      }
    }
  };
  render() {
    let {
      data,
      mode,
      filteredSubsources,
      getValue,
      name,
      options,
      label,
      handleChange,
    } = this.props;
    let { isDisabled, filter } = this.state;
    let value = getValue(name);
    const developerProject =
      data.developerProjects &&
      data.developerProjects.filter((e) => e.asem_id == value)[0];
    if (label === "Sub Source")
      return (
        <SubSourceInput
          isDisabled={isDisabled}
          filteredOptions={filteredSubsources}
          key={getValue(name) + name}
          mode={mode}
          {...this.props}
        />
      );
    return (
      <div className={campaignComponent}>
        <div>
          <div className={cx(itemHeader, this.props.required && required)}>
            {label}
          </div>
          <div>
            <TypeAhead
              disabled={isDisabled}
              placeholder=""
              initialValue={
                label === "Project Name"
                  ? (options && options.find((c) => c === value)) ||
                    (developerProject && developerProject.name)
                  : value
              }
              className={css({
                height: "45px",
                fontSize: "13px",
                fontWeight: "bold",
              })}
              onChange={(value) => this.setState({ filter: value })}
              onSelect={(value) =>
                handleChange({ type: "keyval", key: name, value: value })
              }
              selected={
                label === "Project Name"
                  ? options && options.find((c) => c === value)
                  : value
              }
              valueExtractor={(val) => {
                const option = options && options.find((c) => c === val);
                return option || "";
              }}
            >
              {options
                .filter((option) =>
                  option.toLowerCase().includes(filter.toLowerCase())
                )
                .map((option) => (
                  <Option key={option} value={option} label={option} />
                ))}
            </TypeAhead>
          </div>
        </div>
        {/* {
        P.label === 'Source' &&
        <div>
          <div className={css({...mixins.flexRow, lineHeight: "24px"})}>
            <div className={css({marginRight: "10px"})}>Managed by Anarock agency <i className={cx(`pi pi-info`, iconStyle )}/></div>
               <Button
               type='link'
               className={css({height: "24px",fontSize: "12px"})}
               onClick={()=> {}}>
                 Change
               </Button>
            </div>
          <div>18% GST Applicable <i className={cx(`pi pi-info`, iconStyle )}/></div>
        </div>
      } */}
      </div>
    );
  }
}
class CampaignSpends extends Component {
  state = {};
  getHashTag = (source, subsource) => {
    const id = this.props.spendsMapping.find(
      (item) => item.source === source && item.subsource === subsource
    ).id;
    if (!id) return "";
    return "#" + id.toString(36) + "|" + id.toString(35) + "-" + subsource;
  };
  render() {
    const modeList = ["manual", "api", "cpsv", "cpb", "cpl", "cpql", "bulk"];
    let { getValue, handleChange, name, data, campaignMode } = this.props;
    let obj = getValue(name) || {};
    let assignment_id = getValue("assignment_id");
    let { mode = "mandate" } = obj;
    let conf_key = "m_" + assignment_id;

    let default_value = {};
    var modeId = `m_${assignment_id}`;
    let { channel, source, subsource, asem_id, hashkey } = getValue("");
    let status =
      data["spends_integration"] &&
      data["spends_integration"][modeId] &&
      data["spends_integration"][modeId].status;
    let json =
      data["spends_integration"] &&
      data["spends_integration"][modeId] &&
      data["spends_integration"][modeId].json;
    let id =
      data["spends_integration"] &&
      data["spends_integration"][modeId] &&
      data["spends_integration"][modeId].id;
    if (!obj[conf_key]) {
      let defaultjson = { type: "manual" };
      if (["facebook", "google", "taboola"].indexOf(source) != -1) {
        defaultjson = { type: "api" };
      }
      let o = {
        json: json || [defaultjson],
        status,
        channel,
        source,
        subsource,
        asem_id,
        dirty: true,
        id: id,
      };
      if (mode == "mandate") {
        o["assignment_id"] = assignment_id;
      }
      default_value = o;
    }
    if (!obj[conf_key]) {
      let defaultjson = { type: "manual" };
      if (["facebook", "google", "taboola"].indexOf(source) != -1) {
        defaultjson = { type: "api" };
      }
      let o = {
        json: json || [defaultjson],
        status,
        channel,
        source,
        subsource,
        asem_id,
        dirty: true,
        id: id,
      };
      if (mode == "mandate") {
        o["assignment_id"] = assignment_id;
      }
      default_value = o;
    }
    let conf = obj[conf_key] || default_value;
    conf.json = conf.json || [{ type: "manual" }];
    var developerProject =
      conf.asem_id &&
      data.developerProjects &&
      data.developerProjects.filter((e) => e.name == asem_id)[0];
    conf.asem_id =
      conf.asem_id && typeof conf.asem_id == "number"
        ? conf.asem_id
        : developerProject && developerProject.asem_id;
    const disabled = campaignMode === "resolve error";
    return (
      <div>
        <div
          className={css({ display: "flex", justifyContent: "space-between" })}
        >
          <div
            className={css({
              width: 100,
              height: 44,
              ...mixins.flexSpaceBetween,
            })}
          >
            <div
              className={css({
                color: colors.gray.darker,
                fontSize: "15px",
                ...mixins.flexMiddleAlign,
              })}
            >
              Spends
            </div>
            <div>
              {channel && source && subsource && (
                <div>
                  <Switch
                    disabled={disabled}
                    initialValue={conf.status === "active"}
                    onChange={(value) => {
                      conf.status = value ? "active" : "inactive";
                      conf.dirty = true;
                      handleChange({
                        type: "keyval",
                        key: name + "." + conf_key,
                        value: conf,
                      });
                    }}
                    label={""}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {!(channel && source && subsource) ? (
          <div
            className={css({
              color: colors.gray.base,
              fontSize: "13px",
              margin: "10px 0px",
            })}
          >
            Select Channel, Project name, Source, Subsource
          </div>
        ) : (
          <div className={css({ fontSize: "13px" })}>
            {conf.json.map((o, i) => (
              <div
                className={css({
                  display: "flex",
                  justifyContent: "flex-start",
                })}
              >
                <CampaignDate
                  disabled={disabled}
                  key1="start_date"
                  key2="end_date"
                  single={true}
                  getValue={(k) => o[k] || ""}
                  handleDayClick={(key, day) => {
                    if (!day && o[key]) {
                      delete o[key];
                    } else if (day) {
                      let pts = day
                        .toLocaleDateString("en-GB")
                        .split(",")[0]
                        .split("/");
                      if (Math.abs(parseInt(pts[2]) - 2019) < 10) {
                        o[key] = `${pts[2]}-${pts[1]}-${pts[0]}`;
                      } else {
                        o[key] = null;
                      }
                    }
                    handleChange({
                      type: "keyval",
                      key: name + "." + conf_key,
                      value: conf,
                    });
                  }}
                />
                <div className={css({ marginLeft: "10px", width: "25%" })}>
                  <div className={cx(itemHeader, required)}>Mode</div>
                  <Select
                    disabled={disabled}
                    placeholder=""
                    className={css({ height: "45px" })}
                    onChange={(value) => {
                      o["type"] = value;
                      handleChange({
                        type: "keyval",
                        key: name + "." + conf_key,
                        value: conf,
                      });
                    }}
                    value={o["type"]}
                  >
                    {modeList.map((option) => (
                      <Option key={option} value={option} label={option} />
                    ))}
                  </Select>
                </div>
                {["cpsv", "cpb", "cpl", "cpql", "bulk"].indexOf(o["type"]) !=
                  -1 && (
                  <div className={css({ marginLeft: "10px", width: "25%" })}>
                    <div className={itemHeader}>{o["type"].toUpperCase()}</div>
                    <Input
                      disabled={disabled}
                      type="number"
                      onChange={(value) => {
                        o["amount"] = parseInt(value);
                        handleChange();
                        handleChange({
                          type: "keyval",
                          key: name + "." + conf_key,
                          value: conf,
                        });
                      }}
                      value={o["amount"]}
                      placeholder=""
                    />
                  </div>
                )}
                {o["type"] === "api" && (
                  <HashKeyElement
                    disabled={disabled}
                    handleChange={() => copy(hashkey)}
                    hashkey={hashkey}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
const HashKeyElement = (P) => {
  const { hashkey, handleChange } = P;
  return (
    <div className={css({ marginLeft: "10px", width: "25%" })}>
      <div className={cx(css({ height: "43px" }), itemHeader)}>HasKey</div>
      <div
        className={cx(line, css({ ...mixins.flexSpaceBetween, width: "100%" }))}
      >
        <div
          className={css({
            whiteSpace: "nowrap",
            color: colors.gray.darker,
            overflow: "hidden",
            marginBottom: "5px",
            textOverflow: "ellipsis",
          })}
        >
          {hashkey || ""}
        </div>
        {hashkey && (
          <i
            className={cx(
              `pi pi-copy`,
              css({
                cursor: "pointer",
                color: colors.violet.dark,
                fontSize: "15px",
              })
            )}
            onClick={handleChange}
          />
        )}
      </div>
    </div>
  );
};
const CampaignDate = ({
  key1,
  key2,
  handleDayClick,
  getValue,
  single,
  readonly,
  disabled,
}) => {
  return (
    <div
      className={cx(
        css({ ...mixins.flexSpaceBetween, width: "50%" }),
        spendComponent
      )}
    >
      <div className={css({ width: "50%" })}>
        <div className={itemHeader}>Start date</div>
        <DateInput
          disabled={disabled}
          placeholder=""
          value={getValue(key1) ? new Date(getValue(key1)) : ""}
          calendarProps={{
            onChange: () => {},
            tileDots: [],
          }}
          inputProps={{
            placeholder: "",
          }}
          onChange={(value) => {
            if (value) handleDayClick(key1, new Date(value));
          }}
        />
      </div>
      <div className={css({ marginLeft: "10px", width: "50%" })}>
        <div className={itemHeader}>End date</div>
        <DateInput
          disabled={disabled}
          placeholder=""
          value={getValue(key2) ? new Date(getValue(key2)) : ""}
          calendarProps={{
            onChange: () => {},
            tileDots: [],
          }}
          inputProps={{
            placeholder: "",
          }}
          onChange={(value) => {
            if (value) handleDayClick(key2, new Date(value));
          }}
        />
      </div>
    </div>
  );
};
export class CampaignMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: this.props.facebook_data,
      loading: false,
      adData: this.props.adData,
      adLoading: false,
    };
  }
  urlData = ({ campaign_data }) => {
    let facebook_map = {};

    const addValueToKey = (key, value) => {
      facebook_map[key] = facebook_map[key] || [];
      facebook_map[key].push(value);
    };
    campaign_data.facebook_data.forEach((campaign) =>
      addValueToKey(campaign.campaign.name, campaign)
    );

    let facebook_data = Object.values(facebook_map).map((ads) => {
      const source = _.uniq(ads.map((item) => item.source));
      const subsource = _.uniq(ads.map((item) => item.subsource));
      const mode = _.uniq(ads.map((item) => item.mode));
      return {
        campaign: ads[0].campaign.name,
        mode: mode,
        ads: ads.length,
        source: source,
        subsource: subsource,
        adList: ads,
      };
    });
    this.setState({
      loading: false,
      rowData: facebook_data,
      facebook_data,
      adData: campaign_data,
    });
  };
  fetchCampaigns = () => {
    let self = this;
    this.setState({ loading: true });
    fetch(
      `${window._MDOMAIN}/api/v0/url-generator?rtype=get-v2&assignment_id=${this.props.data.assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.status == 200) {
          self.urlData(data.data);
        }
        this.setState({ loading: false, adLoading: false });
      })
      .catch((e) => {
        this.setState({ loading: false, adLoading: false });
      });
  };

  integrateAndMap = (ad, isIntegrate) => {
    const { source, subsource, hashkey } = this.props.mappingData;
    let newName = ad.campaign.name;
    if (isIntegrate) {
      const nameArray = ad.campaign.name.split(" ");
      if (nameArray.pop()[0] == "#") {
        newName = nameArray.join(" ") + " " + hashkey;
      }
    }
    const ad_config = {
      adid: ad.id,
      ad_source: ad.source,
      virtual_num: ad.virtual_num || null,
      lead_gen_form_id: ad.lead_gen_form_id || null,
      newsource: source,
      newsubsource: subsource,
      campaign_id: ad.campaign.id,
      oldname: ad.campaign.name,
      newname: newName,
      link: ad.link || null,
      creative: ad.creative || null,
    };

    return ad_config;
  };
  integrateAndResolve = async (ads, isIntegrate) => {
    let self = this;
    const adConfigs = ads.map((ad) => this.integrateAndMap(ad, isIntegrate));
    self.setState({ adLoading: true });

    try {
      const allResponse = await Promise.all(
        adConfigs.map((ad_config) =>
          fetch(
            `${window._MDOMAIN}api/v0/url-generator?rtype=sourceedit&assignment_id=${this.props.assignment_id}`,
            {
              method: "post",
              credentials: "include",
              body: JSON.stringify(ad_config),
            }
          )
        )
      );
      const allResponseJson = await Promise.all(
        allResponse.map((response) => response.json())
      );
      if (allResponseJson) self.fetchCampaigns();
    } catch (e) {
      console.error(e);
      self.setState({ adLoading: false });
    }
  };
  filterCampaigns = (campaignData, hasKey, isIntegrate) =>
    campaignData.filter((item) =>
      isIntegrate
        ? !item.campaign.includes(hasKey)
        : item.campaign.includes(hasKey)
    );
  render() {
    let { mappingData } = this.props;
    let { rowData, loading, adData, adLoading } = this.state;
    return (
      <div>
        {this.filterCampaigns(rowData, this.props.mappingData.hashkey, false)
          .length != 0 && (
          <MatchingCampaigns
            adLoading={adLoading}
            adData={adData}
            loading={loading}
            integrateAd={this.integrateAndResolve}
            fetchCampaigns={this.fetchCampaigns}
            campaignData={rowData}
            isIntegrate={false}
            source={mappingData.source}
            subsource={mappingData.subsource}
            mappingData={mappingData}
          />
        )}
        {this.filterCampaigns(rowData, mappingData.hashkey, true).length !=
          0 && (
          <MatchingCampaigns
            adLoading={adLoading}
            adData={adData}
            loading={loading}
            integrateAd={this.integrateAndResolve}
            fetchCampaigns={this.fetchCampaigns}
            campaignData={rowData}
            isIntegrate={true}
            source={mappingData.source}
            subsource={mappingData.subsource}
            mappingData={mappingData}
          />
        )}
      </div>
    );
  }
}
export class MatchingCampaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      showMapping: false,
      gridApi: undefined,
      columnApi: undefined,
      syncTime: new Date(),
      currentTime: new Date(),
      selectedRows: [],
      selectionMode: "no_selection",
      columnDefs: [
        {
          field: "selectItem",
          headerName: "",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          suppressSizeToFit: true,
          width: 50,
          cellStyle: { paddingRight: "10px" },
        },
        {
          headerName: "Campaign",
          field: "campaign",
        },
        {
          headerName: "Campaign type",
          field: "campaign_type",
        },
        {
          headerName: "Mode",
          field: "mode",
        },
        {
          headerName: "Ads",
          field: "ads",
        },
        {
          headerName: "Source",
          field: "source",
          cellRenderer: "mappingCellComponent",
          cellRendererParams: {
            objectGetter: () => this.props.source,
          },
        },
        {
          headerName: "Sub source",
          field: "subsource",
          cellRenderer: "mappingCellComponent",
          cellRendererParams: {
            objectGetter: () => this.props.subsource,
          },
        },
      ],
      frameworkComponents: {
        mappingCellComponent: MappingCellComponent,
      },
    };
  }
  setTime = () => {
    this.setState({ currentTime: new Date() });
  };
  componentWillMount() {
    this.setTime();
  }
  componentDidMount() {
    window.setInterval(
      function () {
        this.setTime();
      }.bind(this),
      1000
    );
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ syncTime: new Date() });
  }
  filterCampaigns = (campaignData, hasKey, isIntegrate) =>
    campaignData.filter((item) =>
      isIntegrate
        ? !item.campaign.includes(hasKey)
        : item.campaign.includes(hasKey)
    );

  render() {
    const {
      searchQuery,
      columnDefs,
      selectedRows,
      showMapping,
      syncTime,
      currentTime,
      selectionMode,
    } = this.state;
    const {
      isIntegrate,
      source,
      subsource,
      campaignData,
      mappingData,
      fetchCampaigns,
      adData,
      loading,
      adLoading,
    } = this.props;
    const getSelectedRowData = () => {
      const selectedData = this.gridApi.getSelectedRows();
      this.setState({
        selectedRows: selectedData.map((item) => ({
          campaign: item.campaign,
          ads: item.ads,
        })),
      });
    };
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      getSelectedRowData();
    };
    if (this.gridApi) this.gridApi.sizeColumnsToFit();
    if (this.columnApi) this.columnApi.autoSizeColumns();
    const isExternalFilterPresent = () => {
      return true;
    };

    const filteredCampaigns = this.filterCampaigns(
      campaignData,
      mappingData.hashkey,
      isIntegrate
    );
    const changesPublished = _.sumBy(
      filteredCampaigns,
      (item) =>
        item.adList.filter(
          (ad) =>
            ad.subsource &&
            (ad.subsource === subsource || ad.subsource.includes(subsource))
        ).length
    );
    const changesFailed =
      _.sumBy(filteredCampaigns, (item) => item.adList.length) -
      changesPublished;
    const onFilterChange = (mode) =>
      this.setState({
        selectedRows: filteredCampaigns,
        selectionMode: mode ? "Success" : "Failed",
        showMapping: true,
      });
    const doesExternalFilterPass = (node) =>
      searchQuery
        ? node.data.campaign
            .toLowerCase()
            .includes(searchQuery.trim().toLowerCase())
        : true;
    return (
      <div
        className={css({
          marginTop: "20px",
          padding: "24px",
          backgroundColor: colors.white.base,
        })}
      >
        <div className={css({ ...mixins.flexSpaceBetween })}>
          <div className={css({ ...mixins.flexSpaceBetween })}>
            <div
              className={css({ ...mixins.flexMiddleAlign, marginRight: 20 })}
            >
              {isIntegrate ? "Matching campaigns" : "Integrated campaigns"}
            </div>
            <div
              onClick={fetchCampaigns}
              className={css({
                "&:hover": { cursor: "pointer" },
                borderRadius: 3,
                padding: "0px 10px",
                marginLeft: "10px",
                fontSize: "12px",
                backgroundColor: colors.gray.lightest,
                ...mixins.flexMiddleAlign,
                color: colors.gray.base,
              })}
            >
              <div className={css({ marginRight: "10px" })}>
                Updated {differenceInMinutes(currentTime, syncTime) || "< 1"}{" "}
                mins ago
              </div>
              <i className={cx("pi pi-reset", css({ fontSize: "13px" }))} />
            </div>
          </div>
          <div>
            <Button
              disabled={
                this.state.selectedRows.length === 0 || this.state.loading
              }
              onClick={() => this.setState({ showMapping: true })}
            >
              {isIntegrate
                ? "Integrate HasKey and Resolve errors"
                : "Resolve errors"}
            </Button>
          </div>
        </div>
        <Divider />
        <div>
          {loading ? (
            <div
              className={css({ height: "100px", ...mixins.flexMiddleAlign })}
            >
              <Loader color="grey" className={css({ margin: "auto" })} />
            </div>
          ) : (
            <div>
              {!isIntegrate && (
                <div
                  className={css({
                    margin: "15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  })}
                >
                  <div
                    className={css({
                      ...mixins.flexSpaceBetween,
                      fontSize: "13px",
                      width: "25%",
                    })}
                  >
                    {!!changesPublished && (
                      <div
                        className={cx(
                          css({
                            color: colors.green.base,
                            fontWeight: "bold",
                            cursor: "pointer",
                            ...mixins.flexMiddleAlign,
                          })
                        )}
                        onClick={() => onFilterChange(true)}
                      >
                        <i
                          className={cx(
                            css({ fontSize: 15, marginRight: 5 }),
                            "pi",
                            "pi-check"
                          )}
                        />
                        {changesPublished} changes published
                      </div>
                    )}
                    {!!changesFailed && (
                      <div
                        className={cx(
                          css({
                            color: colors.red.base,
                            fontWeight: "bold",
                            cursor: "pointer",
                            ...mixins.flexMiddleAlign,
                          })
                        )}
                        onClick={() => onFilterChange(false)}
                      >
                        <i
                          className={cx(
                            css({
                              fontSize: 15,
                              fontWeight: "bold",
                              marginRight: 5,
                            }),
                            "pi",
                            "pi-warning-line"
                          )}
                        />
                        {changesFailed} changes failed
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div
                className={css({
                  marginTop: "10px",
                  ...mixins.flexSpaceBetween,
                })}
              >
                <div
                  className={css({ fontSize: "15px", color: colors.gray.base })}
                >
                  Selected {(selectedRows && selectedRows.length) || 0}/
                  {filteredCampaigns.length} results
                </div>
                <div>
                  <Search
                    placeholder={`Search Campaign`}
                    type="small"
                    onChange={(query) => {
                      this.setState({ searchQuery: query }, () => {
                        this.gridApi.onFilterChanged();
                        getSelectedRowData();
                      });
                      this.gridApi.onFilterChanged();
                      getSelectedRowData();
                    }}
                    value={searchQuery}
                    showSearchIcon={true}
                    className={searchBox}
                  />
                </div>
              </div>

              <div
                className={cx(
                  "ag-theme-alpine",
                  "custom-theme",
                  css({
                    width: "100%",
                    margin: "10px 0px 15px",
                    fontSize: "13px",
                  })
                )}
              >
                <AgGridReact
                  suppressRowTransform={true}
                  onGridReady={onGridReady}
                  defaultColDef={{
                    cellStyle: {
                      border: `0.5px solid ${colors.gray.lighter}`,
                      fontWeight: "lighter",
                      backgroundColor: colors.white.base,
                      fontSize: "13px",
                      margin: "auto 0px",
                    },
                  }}
                  domLayout="autoHeight"
                  headerHeight={50}
                  rowHeight={50}
                  animateRows={false}
                  rowSelection="multiple"
                  columnDefs={columnDefs}
                  onSelectionChanged={(event) => {
                    getSelectedRowData();
                  }}
                  rowData={filteredCampaigns}
                  frameworkComponents={this.state.frameworkComponents}
                  isExternalFilterPresent={isExternalFilterPresent}
                  doesExternalFilterPass={doesExternalFilterPass}
                  pagination={true}
                  paginationPageSize={4}
                />
              </div>
            </div>
          )}
        </div>
        <Modal
          backDropClassName={modalBackDropStyle}
          modalClassName={cx(modalContainerBody, css({ width: "85%" }))}
          visible={showMapping}
        >
          <IntegrateCampaign
            adLoading={adLoading}
            selectionMode={selectionMode}
            fetchCampaigns={fetchCampaigns}
            source={source}
            {...this.props}
            subsource={subsource}
            onClose={() => {
              this.gridApi.deselectAll();
              this.setState({
                showMapping: false,
                selectionMode: "no_selection",
              });
            }}
            campaignList={selectedRows}
            data={filteredCampaigns}
            adData={adData.facebook_data}
          />
        </Modal>
      </div>
    );
  }
}
class IntegrateCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      gridApi: undefined,
      campaignList: this.props.campaignList,
      selectedAds: [],
      campaignData: this.props.data,
      totalAdGroups: [],
      isChangesPublished: false,
    };
  }

  render() {
    const {
      source,
      subsource,
      isIntegrate,
      selectionMode,
      adData,
      adLoading,
      integrateAd,
    } = this.props;
    const {
      searchQuery,
      selectedAds,
      campaignList,
      campaignData,
      totalAdGroups,
      isChangesPublished,
    } = this.state;

    const adLength = totalAdGroups && totalAdGroups.length;
    const onRowSelection = (newRow, deleteRow, totalRows) => {
      if (!totalAdGroups.find((elem) => elem.mode === totalRows[0].mode)) {
        this.setState({ totalAdGroups: [...totalAdGroups, ...totalRows] });
      }
      let filteredRows = selectedAds;
      if (deleteRow)
        filteredRows = filteredRows.filter((ad) => !deleteRow.includes(ad));
      if (newRow) newRow.forEach((row) => filteredRows.push(row));
      this.setState({ selectedAds: filteredRows });
    };
    return (
      <div className={mappingContainer}>
        <div className={mappingBody}>
          <div
            className={css({ ...mixins.flexSpaceBetween, padding: "10px 0px" })}
          >
            <div className={css({ fontSize: "18px" })}>Review and Publish</div>
            <div onClick={this.props.onClose} className={iconStyle}>
              <i className={`pi pi-close`} />
            </div>
          </div>
          <Divider />
          <Toast />
        </div>
        <div
          className={css({
            overflow: "auto",
            padding: "10px 24px",
            height: "100%",
          })}
        >
          <div>
            <div
              className={css({
                margin: "10px 0px 20px",
                ...mixins.flexSpaceBetween,
              })}
            >
              <div className={css({ fontSize: "13px" })}>
                Selecting {(selectedAds && selectedAds.length) || 0}/{adLength}{" "}
                Results
              </div>
              <div>
                <Search
                  placeholder={`Search Campaign`}
                  type="small"
                  onChange={(query) => {
                    this.setState({ searchQuery: query });
                  }}
                  value={searchQuery}
                  showSearchIcon={true}
                  className={searchBox}
                />
              </div>
            </div>
            {campaignList && (
              <CampaignTableList
                adData={adData}
                selectionMode={selectionMode}
                source={source}
                subsource={subsource}
                selectedRows={campaignList.filter((item) =>
                  item.campaign
                    .toLowerCase()
                    .includes(searchQuery.trim().toLowerCase())
                )}
                data={campaignData}
                onSelect={onRowSelection}
              />
            )}
          </div>
        </div>
        <div className={bottomBox}>
          <Button
            type="primary"
            onClick={async () => {
              await integrateAd(
                selectedAds.map((item) => item.sampleAd),
                isIntegrate
              );
              this.setState({ isChangesPublished: true });
              Toast.show("Changes Published", "success");
            }}
            loading={adLoading}
            disabled={isChangesPublished || selectedAds.length === 0}
          >
            Publish Changes
          </Button>
        </div>
      </div>
    );
  }
}
export class IntegrateCellComponent extends Component {
  render() {
    const { source, value } = this.props;
    return (
      <div>
        {source != value ? (
          <div
            className={css({
              alignItems: "center",
              lineHeight: "24px",
              maxWidth: "180px",
              height: "48px",
              whiteSpace: "initial",
            })}
          >
            <span className={css({ color: colors.red.base })}>{value}</span>
            {" > "}
            <span>{source}</span>
          </div>
        ) : (
          <div>{value}</div>
        )}
      </div>
    );
  }
}
export class StatusCellComponent extends Component {
  render() {
    const { value } = this.props;
    return (
      <div
        className={cx(
          value === "Pending"
            ? css({ backgroundColor: colors.red.base })
            : css({ backgroundColor: colors.green.light })
        )}
      >
        {value}
      </div>
    );
  }
}
export class CampaignTableList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { source, subsource, data, selectionMode, adData } = this.props;
    const adStatus = (ad, subsource) =>
      ad.subsource &&
      (ad.subsource === subsource)
        ? "Success"
        : "Pending";
    return (
      <div>
        {this.props.selectedRows.map((item) => {
          const currentCampaign = data.find(
            (ad) => ad.campaign === item.campaign
          );
          if (currentCampaign) {
            const ads = currentCampaign.adList
              .map((adItem) => {
                const ad = adData.find((item) => item.id === adItem.id);
                return {
                  ads: ad.name,
                  id: ad.id,
                  mode: ad.mode,
                  campaign: ad.campaign,
                  virtual_num: ad.virtual_num,
                  lead_gen_form_id: ad.lead_gen_form_id,
                  source: ad.source,
                  subsource: ad.subsource,
                  link: ad.link,
                  creative: ad.creative,
                  status: adStatus(ad, subsource),
                };
              })
              .filter((ad) => {
                if (selectionMode === "no_selection") return true;
                else {
                  if (selectionMode === "Success")
                    return ad.status === "Success";
                  else return ad.status === "Pending";
                }
              });
            if (ads.length != 0)
              return (
                <CampaignTable
                  adData={adData}
                  key={item.campaign}
                  source={source}
                  subsource={subsource}
                  ads={ads}
                  campaignName={item.campaign}
                  onSelect={this.props.onSelect}
                />
              );
          }
        })}
      </div>
    );
  }
}

export class CampaignTable extends Component {
  render() {
    const adModes = [LEADGEN_FORM, URL, VIRTUAL_NUM];
    const { ads, adData, campaignName, source, subsource, onSelect } =
      this.props;
    return (
      <div className={css({ boxSizing: "border-box", marginTop: "10px" })}>
        <div
          className={css({
            backgroundColor: colors.gray.lighter,
            borderRadius: "3px 3px 0px 0px",
            padding: "0px 10px",
            height: "50px",
            display: "flex",
            alignItems: "center",
          })}
        >
          <div className={css({ fontSize: "13px" })}>{campaignName}</div>
        </div>
        <div>
          {adModes.map((mode) => {
            const filteredAds = ads.filter((ad) => ad.mode === mode);
            if (filteredAds.length > 0)
              return (
                <AdGroup
                  adData={adData}
                  mode={mode}
                  ads={filteredAds}
                  source={source}
                  subsource={subsource}
                  onSelect={onSelect}
                />
              );
          })}
        </div>
      </div>
    );
  }
}
class AdGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
    };
  }
  generateAdGroups() {
    let { ads, mode, adData } = this.props;
    const modeIds = _.uniq(
      ads.map((ad) =>
        mode === LEADGEN_FORM ? ad.lead_gen_form_id : ad.virtual_num
      )
    );
    const rowData = modeIds.map((modeId) => {
      const filteredAds = ads.filter((ad) =>
        mode === LEADGEN_FORM
          ? ad.lead_gen_form_id === modeId
          : ad.virtual_num === modeId
      );
      return {
        mode: modeId,
        ads: ads
          .filter((ad) =>
            mode === LEADGEN_FORM
              ? ad.lead_gen_form_id === modeId
              : ad.virtual_num === modeId
          )
          .map((ad) => ad.ads),
        source: filteredAds[0].source,
        subsource: filteredAds[0].subsource,
        status: filteredAds[0].status,
        sampleAd: filteredAds[0],
      };
    });
    return rowData;
  }

  render() {
    const { mode, source, subsource, onSelect, adData, ads } = this.props;
    const rowData = this.generateAdGroups();
    return (
      <AdGroupTable
        adData={adData}
        mode={mode}
        rowData={rowData}
        source={source}
        subsource={subsource}
        onSelect={onSelect}
      />
    );
  }
}
export class AdGroupTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode,
      searchQuery: "",
      gridApi: undefined,
      selectedRows: [],
      columnDefs: [
        {
          field: "selectItem",
          headerName: "",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          suppressSizeToFit: true,
          width: 45,
        },
        {
          headerName: `Mode > ${this.props.mode}`,
          field: "mode",
        },
        {
          headerName: "Ads",
          field: "ads",
          cellRenderer: "adsCellComponent",
        },
        {
          headerName: "Source",
          field: "source",
          cellRenderer: "integrateCellComponent",
          cellRendererParams: {
            source: this.props.source,
          },
        },
        {
          headerName: "Sub source",
          field: "subsource",
          cellRenderer: "integrateCellComponent",
          cellRendererParams: {
            source: this.props.subsource,
          },
        },
        {
          headerName: "Status",
          field: "status",
          cellClass: (params) => {
            return (
              params.value === "Success" &&
              css({
                backgroundColor: `${colors.green.light} !important`,
                color: colors.green.base,
              })
            );
          },
        },
      ],
      frameworkComponents: {
        integrateCellComponent: IntegrateCellComponent,
        statusCellComponent: StatusCellComponent,
        adsCellComponent: AdsCellComponent,
      },
    };
  }
  arrayDifference = (arr1, arr2) => arr1.filter((x) => !arr2.includes(x));
  render() {
    const { columnDefs, selectedRows } = this.state;
    const { rowData, mode } = this.props;
    const getSelectedRowData = (event) => {
      const selectedData = this.gridApi.getSelectedRows();
      this.setState({ selectedRows: selectedData }, () =>
        this.props.onSelect(
          this.arrayDifference(selectedData, selectedRows),
          this.arrayDifference(selectedRows, selectedData),
          rowData
        )
      );
    };
    const adLength = _.sumBy(rowData, (item) => item.ads.length);
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
      getSelectedRowData();
      params.api.sizeColumnsToFit();
      params.columnApi.autoSizeColumns();
      params.api.forEachNode((node) => node.setSelected(true));
    };
    return (
      <div className={css({ marginBottom: "15px" })}>
        <div
          className={cx(
            "ag-theme-alpine",
            css({
              width: "100%",
            })
          )}
        >
          <AgGridReact
            suppressRowTransform={true}
            onGridReady={onGridReady}
            defaultColDef={{
              cellClass: "overflow-visible",
              cellStyle: {
                border: `0.5px solid ${colors.gray.lighter}`,
                display: "flex",
                backgroundColor: colors.white.base,
                alignItems: "center",
              },
            }}
            domLayout="autoHeight"
            headerHeight={50}
            rowHeight={60}
            animateRows={false}
            rowSelection="multiple"
            columnDefs={columnDefs}
            onSelectionChanged={(event) => {
              getSelectedRowData(event);
            }}
            rowData={rowData}
            frameworkComponents={this.state.frameworkComponents}
          />
        </div>
        {mode === URL && (
          <div
            className={css({
              backgroundColor: colors.yellow.light,
              borderRadius: "0px 0px 3px 3px",
              padding: "0px 10px",
              height: "40px",
              display: "flex",
              alignItems: "center",
            })}
          >
            <div
              className={css({
                fontSize: "12px",
                color: colors.yellow.base,
                ...mixins.flexMiddleAlign,
              })}
            >
              <i
                className={cx(
                  css({ fontSize: 12, marginRight: 10 }),
                  "pi",
                  "pi-warning-line"
                )}
              />
              Sub source of {adLength} Ads Under this Campaign can’t be changed
              from here. Please visit respective Platform
            </div>
          </div>
        )}
      </div>
    );
  }
}

export class MappingCellComponent extends Component {
  render() {
    const { objectGetter, value } = this.props;
    return (
      <div className={listBody}>
        {value.map((src) => (
          <div
            className={cx(
              "list-item",
              src != objectGetter() && css({ color: colors.red.base })
            )}
          >
            {src}
          </div>
        ))}
      </div>
    );
  }
}
export class AdsCellComponent extends Component {
  render() {
    const { value } = this.props;
    return (
      <div className={css({ ...mixins.flexRow })}>
        <div className={adListBody}>{value.join(",")}</div>
        {value.length > 1 && (
          <div className={css({ color: colors.gray.base })}>
            + {value.length - 1} more{" "}
          </div>
        )}
      </div>
    );
  }
}

class Placeholder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      show: false 
    };
  }
  mapValues(values) {
    return values
      ? Object.keys(values).map((val) => ({ type: val, value: values[val] }))
      : [];
  }
  savePlaceHolders(fieldValues) {
    let { handleChange } = this.props;
    let mapping = {};
    fieldValues.forEach((item) => (mapping[item.type] = item.value));
    handleChange({ type: "keyval", key: "params", value: mapping });
    this.setState({ isOpen: false });
  }
  render() {
    let { isOpen, show } = this.state;
    let { getValue, name } = this.props;
    let fieldNames = getValue(name);
    const tooltip =
    "Use ‘[NAME], [EMAIL] or [PHONE]’ to dynamically use user’s details in placeholders";

    return (
      <div className={css({ ...mixins.flexRow })}>
        <Button
          type="primary"
          disabled={fieldNames.length == 0}
          onClick={() => this.setState({ isOpen: true })}
        >
          Define Variables
        </Button>
        <Tooltip
          classes={{ popper: css({ zIndex: " 10000012 !important" }) }}
          title={
            <span
              className={css`
                font-size: 12px;
              `}
            >
              {tooltip}
            </span>
          }
          onOpen={() => {
            this.setState({ show: true });
          }}
          onClose={() => {
            this.setState({ show: false });
          }}
          open={show}
          arrow
        >
          <div className={css(`margin-left:5px;`)}>
            <Ionicon
              fontSize="16px"
              icon="md-information-circle"
              color={show ? "#000" : "#ccc"}
            />
          </div>
        </Tooltip>
        <Modal visible={isOpen} modalClassName={flexCenter}>
          <PlaceholderComponent
           fieldNames={fieldNames}
           fieldValues={this.mapValues(this.props.getValue("params"))}
           savePlaceHolders={(values) => this.savePlaceHolders(values)}
           onClose={() => this.setState({ isOpen: false })}
          />
        </Modal>
      </div>
    );
  }
}
const DYNAMIC_FIELDS = ["name", "phone", "email"];

class PlaceholderElement extends Component {
  constructor(props) {
    super(props);
  }
  placeholderValue(field) {
    return (field.value) ? field.value : "";
  }
  render() {
    let { fieldValue, onChange } = this.props;
    return (
      <div
        className={css({
          ...mixins.flexSpaceBetween,
          lineHeight: "30px",
          padding: "10px 0px",
        })}
      >
        <div>{fieldValue.type.toUpperCase()}</div>
        <input
          type={"text"}
          className={css`
            padding: 5px;
            border: 1px solid #ededed;
            outline: none;
            width: 200px;
          `}
          value={this.placeholderValue(fieldValue)}
          checked={this.placeholderValue(fieldValue)}
          name={fieldValue.type}
          onChange={(e) =>
            onChange({ type: fieldValue.type, value: e.target.value })
          }
        />
      </div>
    );
  }
}

class PlaceholderComponent extends Component {
  state = {
    fieldValues: this.props.fieldValues,
  };
  updatePlaceHolders(placeholder) {
    let { fieldValues } = this.state;
    let newFieldValues = [
      ...fieldValues.filter((ele) => ele.type != placeholder.type),
      placeholder,
    ];
    this.setState({ fieldValues: newFieldValues });
  }
  render() {
    const { fieldNames, savePlaceHolders, onClose } = this.props;
    const { fieldValues } = this.state;
    return (
      <div className={modalContainer}>
        <div
          className={css({
            fontSize: 15,
            padding: "20px",
            boxShadow: `0 4px 10px 0 rgba(0,0,0,0.05)`,
          })}
        >
          Placeholder selection
          <i
            className={cx("pi", "pi-close", iconCloseClassName)}
            onClick={onClose}
          />
        </div>
        <div className={css({ padding: "20px" })}>
          {fieldNames &&
            fieldNames.map((ele) => {
              const fieldValue = fieldValues.find(
                (item) => item.type === ele
              ) || { type: ele, value: "" };
              return (
                <PlaceholderElement
                  onChange={(val) => this.updatePlaceHolders(val)}
                  fieldValue={fieldValue}
                />
              );
            })}
        </div>
        <div
          className={css({
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          })}
        >
          <Button
            type="primary"
            onClick={() => savePlaceHolders(fieldValues)}
            disabled={fieldValues.length === 0 || !_.every(fieldValues.map((fValue) => fValue.value))}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}