import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import {EM} from './events'
import {css} from 'react-emotion';
import {Calendar,OptionGroupCheckBox,Option,DropDown, OptionGroupRadio} from '@anarock/pebble';

import Ionicon from 'react-ionicons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Ink from 'react-ink'
import { DashboardReportsLink } from '../panel/src/components/pivots-components/dashboard-reports-link';
import { ReportsWarning } from '../panel/src/components/pivots-components/reports-warning';

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


export class Button extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }
  
  render() {
    let {closed=new Date(),label,popper,html,icon} = this.props
    let {show} = this.state
    return (
      <div 
        className={css`
user-select: none;
display:flex;  
align-items: center;
height:28px;
.pi {
	display: block;
}
.cnt {
	height:28px;
	padding-left: 10px;
	padding-right: 10px;
	display:flex;
	align-items: center;
}
position:relative;
border: 1px solid #e0e0e0;
border-radius: 3px;
color: #6b7785;
font-size:12px;
cursor: pointer;
.text {
	margin:5px;
}
:hover {
	background: #e0e0e0;
}
.popper {
	position:absolute;
	right:0px;
	top:30px;
}
`}>
        {html}
        {!html &&
          <div 
            onClick={(e) => {
              if(new Date() - closed > 100){
              	this.setState({show:true})  
              }
            }}
            className="cnt">
            <i className={`pi pi-${icon}`} />
            {
              label &&
              <div className="text">{label}</div>
            }
            {
              label &&
              <i className={`pi pi-arrow-drop-${show?"up":"down"}`} />
            }
          </div>
        }
        <div className="popper">
          {show && 
            <Popper 
              allowClicks={true}
              close={() => this.setState({show:false,closed: new Date()})}
              html={popper}/>
          }
        </div>
      </div>
    )
  }
}

export class Time extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.interval = setInterval(() => this.setState({cb: new Date()}),3000)
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  render() {
    let {time,refresh} = this.props
    if(!time){
      time = new Date()/1000
    }
    let totalsec = parseInt(new Date()/1000-time);
    let min = parseInt(totalsec/60);
    let sec = totalsec%60;
    return (
      <div onClick={() => refresh()} style={{color:totalsec>600?'#d32f02':'#6b7785',cursor:'pointer',fontFamily:'Lato',fontSize:12,position:'absolute',top:3,right:3}}>last updated: {min}m {sec}s ago</div>
    )
  }
}

export class Filters extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filters: [
        {name:"Channel",value:"online",more:2,type:"text"},
        {name:"Source",value:"contains: facebook",more:0,type:"text"},
        {name:"CPL",value:">: 9000",type:"number"},
        {name:"Lead Date",value:"1 Nov - 20 Nov",type:"date"}
      ]
    }
  }
  date = (d) => {
    let pts = new Date(d).toString().split(' ');
    return `${pts[2]}${pts[1]}${pts[3]}`
  }
  render() {
    let {conf,id,plan, report_name,assignment_id} = this.props
    let {filteroptions={},filters,last_updated=''} = conf
    let {last='',closed=new Date(),editfilter} = this.state
    let dashboardReports = ["source-wise",'facebook','google','keywords'];
    return (
      <div className={css`
position: relative;
user-select: none;
margin-top:5px;
border: 1px solid #ededed;
background: #fbfbfb;
display: flex;
padding:6px;
font-weight:bold;
.pi-arrow-drop-down, .pi-arrow-drop-up {
	font-size:8px;
}
.item.active {
	.chip {
		background:#c0c0ff;
	}
	.dot-border {
		border: 1px dashed #e0e0e0;
	}
}
.item {
	position: relative;
	color: #6b7785;
	padding:6px;
	padding-right:0px;
  font-size: 10px;
	.dot-border {
		position:relative;
		padding: 3px;
		border: 1px dashed transparent;
		:hover {
			border: 1px dashed #e0e0e0;
		}
		:hover .close {
			background: #fbfbfb;
			z-index: 1000;
			color: #efbbad !important;
			svg {
				fill: #efbbad !important;
			}
		}
		.close {
			div {
				margin-bottom: 2px;
			}
			padding-left:5px;
			display: flex;
			align-items: center;
			position:absolute;
			color: transparent;
			right:3px;
			top:3px;
			cursor: pointer;
			:hover{
				border-bottom: 1px dashed #d32f02;
				svg {
					fill: #d32f02 !important;
				}
				color: #d32f02 !important;
			}
		}
	}
  .name {
    padding-bottom:10px;
		text-overflow: ellipsis;
		overflow:hidden;
		max-width:80px;
  }
  .chip {
		cursor: pointer;
    color: #6161ff;
    display:flex;
    align-items: center;
    background: #e8e8ff;
    font-size: 10px;
    height: 28px;
    padding:10px;
    border-radius: 3px;
		border: 1px solid #e8e8ff;
		&.empty {
			background: transparent;
			border: 1px solid #6161ff;
			color: #6b7785;
			border: 1px solid #6b7785;
		}
		:hover {
			background:#c0c0ff;
		}
    .text {
      overflow:hidden;
      text-overflow: ellipsis;
      max-width:100px;
      margin-right:5px;
      white-space: nowrap;
			display: block;
    }
    .border {
      border: 1px solid #6161ff;
      border-radius: 3px;
      padding:0px 2px;
      margin-left:-2px;
			margin-right:5px;
    }
  }
}
.buttons {
	margin-top:20px;
	display: flex;
	> div {
		margin-right: 5px;
	}
}

.tab-item {
	padding: 5px;
	cursor: pointer;
	:hover {
		color: #000 !important;
		text-decoration: underline;
	}
}
`}>
        <div style={{flex:1,display:'flex',flexWrap:'wrap'}}>
          {Object.keys(filters).map(item => {
              let o = filters[item]
              let value = '';
              let more = 0;
            	if(o['type']=='inlist'){
              	let options = Object.keys(o['options']).filter(k => o['options'][k])
                if(options.length>0){
                	value = options[0]==''?'blank':options[0].split(":#")[0]
                	more = options.length - 1  
                }
              } else if(o['type']=='daterange'){
                let {start,end} = o
                let [s,s1,s2,s_] = new Date(start).toString().split(' ')
                let [e,e1,e2,e_] = new Date(end).toString().split(' ')
                if(start && end){
                	value = `${s1} ${s2} - ${e1} ${e2}`
                }
              }
            	
            	
            	let icon = editfilter==item?"up":"down"
              return (
                <div className={`item ${editfilter==item?"active":""}`}>
                  <div className="dot-border">
                    {value &&
                      <div
                      className="close"
                      onClick={(e) => {
                          e.stopPropagation()
                          let {conf} = this.props
                          if(conf['filters'][item]['type']=='daterange' && conf['filters'][item]['start']){
                            conf["filters"][item]={
                              type: 'daterange',
                              start: '',
                              end: ''
                            }
                          } else if(conf['filters'][item]['type']=='inlist' && Object.keys(conf['filters'][item]['options']).length>0){
                            conf["filters"][item]={
                              type: "inlist",
                              options:{}
                            }
                          } else {
                            delete conf["filters"][item]
                          }
                          this.props.setState({cb:new Date()})
                        }}
                      >
                      <div>{value!=''?'clear':'remove'}</div>
                      <Ionicon icon={`md-${value!=''?'close':'trash'}`}
                        color="transparent" 
                        fontSize="12px"/>
                    </div>}
                    <div className="name">{item}</div>
                    <div onClick={() => this.setState({editfilter:new Date()-closed < 100 && last==item?"":item})}>
                      {
                        value!=''?
                        <div className="chip">
                          <div className="text">{value}</div>
                          {
                            more > 0 &&
                            <div className="border">+{more}</div>
                          }
                          <i className={`pi pi-arrow-drop-${icon}`}></i>
                        </div>:
                        <div className="chip empty">
                          <div className="text">{o['type']=='daterange'?'From - To':'Choose'}</div>
                          <i className={`pi pi-arrow-drop-${icon}`}></i>
                        </div>
                      }
                    </div>
                  </div>
                  {editfilter==item &&
                    <div style={{position:'absolute',}}>
                      {filters[item]["type"]=="inlist" &&
                        <Popper 
                          allowClicks={true}
                          close={() => this.setState({editfilter:'',closed: new Date(),last:item})}
                          html={
                            <ListFilter 
                              filters={filters[item]} 
                              options={filteroptions[item]}
                              close={() => this.setState({editfilter:''})}
                              apply={(f) => {
                                filters[item] = f
                                this.setState({editfilter:''})
                                this.setState({filters:JSON.parse(JSON.stringify(filters))})
                                EM.emit('runreport',id)
                              }}
                            />
                          }
                        />
                      }
                      {filters[item]["type"]=="daterange" &&
                        <Popper 
                          close={() => this.setState({editfilter:'',closed: new Date(),last:item})}
                          allowClicks={true}
                          html={
                            <div style={{zIndex:10000,border:'1px solid #ededed',position:'absolute',boxShadow:'0px 8px 15px 0px rgba(0,0,0,.15)',marginBottom:30}}>
                              <Calendar
                                //onChange={action('change')}
                                selected={[filters[item]["start"]?new Date(filters[item]["start"]):null,filters[item]["end"]?new Date(filters[item]["end"]):null]}
                                range={true}
                                onApply={(f) => {
                                  console.log("apply",f)
                                  let [start_date,end_date] = f
                                  filters[item]["start"] = this.date(start_date)
                                  filters[item]["end"] = this.date(end_date)
                                  console.log(filters)
                                  this.setState({editfilter:''})
                                  this.setState({filters:JSON.parse(JSON.stringify(filters))})
                                  EM.emit('runreport',id)
                                }}
                                //onClear={action('clear')}
                              />
                            </div>
                          }
                        />
                      }
                    </div>
                  }
                </div>
              )
            })
          }
          {false &&
           	<div className="item" style={{paddingTop:10}}>
              <div className="name">&nbsp;</div>
              <div className="chip">+6 more</div>
            </div> 
          }
        </div>
        {window._TDOMAIN === 'anarock.com' && dashboardReports.includes(report_name) && <DashboardReportsLink report_name={report_name} filter={filters} assignment_id={assignment_id}/>}  
        <div className="buttons">
          {
          Object.keys(conf['filters']).length < 4 &&
          <Button icon="filter" label={"Filters"} popper={
              <div style={{boxShadow:'0px 8px 15px 0px rgba(0,0,0,.15)',border:"1px solid #d0d0d0",right:0,position:'absolute',zIndex:100,background:'white',width:150}}>
                {
                  conf['options']
                    .filter(item => conf['filters'][item]==undefined)
                  	.filter(item => ["date","source","subsource","channel"].indexOf(item.toLowerCase())!=-1)
                    .map(item => (
                      <div 
                        onClick={e => {
                          let {conf} = this.props
                          if(item=='date'){
                            conf['filters'][item] = {
                              type: 'daterange',
                              start: '',
                              end: ''
                            }
                          } else {
                            conf['filters'][item] = {options:{},type:"inlist"}
                          }
                          this.props.setState({cb:new Date()})
                        }}
                        class="hover-background" 
                        style={{padding:"5px 10px",cursor:'pointer',borderBottom:'1px solid #e0e0e0'}}>
                        {item}
                      </div>
                  ))
                }
              </div>
            }/>
          }
          <Time time={last_updated} refresh={conf.refresh}/>
          <Button icon="edit" label={"Columns"} popper={<Columns {...this.props}/>}/>
          <Button icon="more" popper={
              <div className={css`
padding-top:10px;
padding-bottom:10px;
width:200px;
background:white;
border-radius:3px;
> div {
	padding:0px 20px;
	cursor: pointer;
	height:30px;
	display: flex;
	align-items: center;
	:hover {
		background: #ededed;
		
	}
	i {
		margin-right:10px;
	}
}
`}>
                <div onClick={() => {
                    // window.location.href=conf.downloadLink+'&plan='+plan
                    let [url,filters] = conf.downloadLink.split(':::')
                    fetch(url+'&download=true&plan='+plan,
                      {method: 'post',credentials:"include",body: JSON.stringify({filters:JSON.parse(filters)})})
                    	.then((res) => res.text())
                    	.then((response) => {
                      download('pivot_'+(new Date())+'.csv',response)
                    })
                  }}><i className="pi pi-download"></i>Download</div>
                <div style={{flexDirection:'column',height:50,alignItems:'flex-start',justifyContent:'center'}}>
                  <div>Mediaplan</div>
                  <div style={{display:'flex'}}>
                    {['none','totals','all'].map(o => (
                      <div 
                        onClick={() => this.props.setMediaMode(o)}
                        className="tab-item">{o}</div>
                    ))}
                  </div>
                </div>
              </div>
            }/>
        </div>
      </div>
    )
  }
}

export class Popper extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    setTimeout(() => document.addEventListener('click', this.handleClick, true),100)
    document.addEventListener('keydown',this.handleKeyPress,true)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true);
    document.removeEventListener('keydown',this.handleKeyPress,true)
  }
                              
	handleKeyPress = (e) => {
    try {
      if(e.repeat){
        return
      }
      let valid = false
      if(e.keyCode==27){
        this.props.close()
        valid = true
      }
      if(valid){
        e.preventDefault()
        e.stopPropagation()  
      }
    } catch(e) {  
    }
  }
  handleClick = (e) => {
    try{
    	if(!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.props.close()
        if(!this.props.allowClicks){
          e.preventDefault()
          e.stopPropagation()
        }
      }
    } catch(e) {
      // console.log(e,e.toString())
      // if(e.toString().indexOf('Unable to find node on an unmounted component')!=-1){
      //   console.log("removing unmounted node")
      //   document.removeEventListener('click', this.handleClick, true);
      // }
    }
  }
  render() {
    let {html} = this.props
    return (
      <div className={css`
z-index:1000000;
position: absolute;
top:0px;
right:0px;
box-shadow: 0px 8px 15px 0px rgba(0,0,0,.15);
`}>
        {html}
      </div>
    )
  }
}

export class ListFilter extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filters: JSON.parse(JSON.stringify(this.props.filters || {"options":{}}))
    }
  }
  render() {
    let options = this.props.options || []
    let {filters,filtertext=''} = this.state
    return (
      <div style={{width:300,background:'#fff',zIndex:1000,border:'1px solid #ededed',position:'absolute',boxShadow:'0px 8px 15px 0px rgba(0,0,0,.15)'}}>
        <OptionGroupCheckBox
          searchBox
          searchBoxProps={{
            clearable: true,
            placeholder: 'Search',
            onChange: value => this.setState({filtertext:value})
          }}
          onChange={values => {
            filters["options"] = {}
            values.map(o => {
              filters["options"][o] = 1
            })
            this.setState({cb:new Date(),filters:JSON.parse(JSON.stringify(filters))})
          }}
          selected={Object.keys(filters["options"])}
          onApply={() => {
            this.props.apply(filters)
          }}
          onClear={() => {
            filters['options'] = {}
            this.setState({filters:JSON.parse(JSON.stringify(filters))})
          }}
        >
          <div style={{display:'flex',marginLeft:20,marginTop:0,marginBottom:10}} className={css`.link{margin-right:10px;cursor:pointer; :hover{text-decoration:underline}}`}>
            <div className="link" onClick={() => {
                filters["options"] = filters["options"] || {}
                options
            		.filter(o => (o||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1)
                .map(o => {
                  filters["options"][o] = 1
                })
            		this.setState({cb:new Date(),filters:JSON.parse(JSON.stringify(filters))})
              }}>Select Visible</div>
            <div className="link" onClick={() => {
                filters["options"] = filters["options"] || {}
                options
            		.filter(o => (o||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1)
                .map(o => {
                  if(filters["options"][o]) delete filters["options"][o];
                })
            		this.setState({cb:new Date(),filters:JSON.parse(JSON.stringify(filters))})
              }}>Clear Visible</div>
          </div>
          {
            options
            	.filter(o => (o||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1)
            	.slice(0,100)
              .map(o => (
              <Option value={o} label={(o || 'blank').split(":#")[0]}/>
            )) 
          }
        </OptionGroupCheckBox>
      </div>
    )
  }
}

export class Columns extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dropdisabled: {},
      filters: {},
      filteroptions: {}
    }
  }
  onDragEnd = (result) => {
    this.setState({dropdisabled:{}})
    this.enableSc()

    if (!result.destination)return;
    
    const {source, destination} = result
    const startIndex = source.index
    const endIndex = destination.index

    const [removed] = this.props.conf[source.droppableId].splice(startIndex,1)

    this.props.conf[destination.droppableId].splice(endIndex,0,removed)

    const {conf} = this.props
    this.props.setState({cb:new Date()})
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  disableSc = () => {
    return
    this.refs.block.style['position'] = 'absolute'
    this.refs.block.style['top'] = window.scrollY+'px'
    this.refs.block.style['left'] = window.scrollX+'px'
  }

  enableSc = () => {
    return
    this.refs.block.style['position'] = 'fixed'
    this.refs.block.style['top'] = 0
    this.refs.block.style['left'] = 0
  }

  onBeforeDragStart = () => {
    this.disableSc()
  }
  
  onDragStart = (e) => {
    let source = e.source['droppableId']
    let item = this.props.conf[source][e.source['index']]
    if(item=='values'){
      this.setState({dropdisabled:{values:true,metrics:true,'options':true}})
    } else if(['rows','columns','options'].indexOf(source)!=-1){
      this.setState({dropdisabled:{values:true,metrics:true}})  
    } else if(['metrics','values'].indexOf(source)!=-1){
      this.setState({dropdisabled:{rows:true,columns:true,'options':true}})
    }
  };
  onDragUpdate = (e) => {
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    padding: 5,
    margin:3,
    background: isDragging ? 'lightgreen' : '#fff',
    border:'1px solid #e0e0e0',
    whiteSpace:'nowrap',
    display:'flex',
    alignItems:'center',
    borderRadius:3,
    ...draggableStyle,
  });

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#fff',
    padding: 10,
    minHeight:100,
    maxHeight:400,
    overflow: "auto",
    border: '1px solid #ededed'
  });

	getBlock = o => (
    <div onClick={e => e.stopPropagation()}>
      <Droppable droppableId={o} direction="vertical" isDropDisabled={this.state.dropdisabled[o]}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={this.getListStyle(snapshot.isDraggingOver)}
          >
            <input  
              onChange={e => {
                  let {filters} = this.state
                  filters[o] = e.currentTarget.value
                  this.setState({filters:JSON.parse(JSON.stringify(filters))})
                }
              }
              placeHolder="add column"
              onClick={(e) => {
                e.stopPropagation()
                this.setState({focus:o})
              }}
              onFocus={() => this.setState({focus:o})}
              //onBlur={() => setTimeout(() => this.state.focus==o && this.setState({focus:''}),500)}
              value={this.state.filters[o] || ''}
              style={{height:25,margin:3,width:'-webkit-fill-available',border:'1px solid #e0e0e0',borderRadius:3,fontSize:14,paddingLeft:5}}
            />
            {this.state.focus==o &&
              <Popper
                html={
              		<div style={{boxShadow:'0px 8px 15px 0px rgba(0,0,0,.15)',border:"1px solid #ededed",position:'absolute',marginTop:40,marginRight:220,zIndex:100,background:'white',width:150,right:0}}>
                    {
                      this.props.conf['options']
                        .filter(item => this.props.conf[o].indexOf(item)==-1 && item.indexOf(this.state.filters[o] || '')!=-1)
                        .map(item => (
                        <div 
                          onClick={e => {
                            this.setState({focus:''})
                            let {conf} = this.props
                            if(["date","channel","source","subsource","week","month","agent","agent_name","project_name","campaign","adset","adgroup"].indexOf(item)!=-1){
                            	conf[o] = [item,...conf[o]]
                            } else {
                              conf[o] = [...conf[o],item]
                            }
                            this.props.setState({cb:new Date()})
                          }}
                          class="hover-background" 
                          style={{padding:"5px 10px",cursor:'pointer',borderBottom:'1px solid #e0e0e0'}}>
                          {item}
                        </div>
                      ))
                    }
                  </div>    
                }
                close={() => {
                  this.setState({focus:''})
                }}
              />
              
            }
            {this.props.conf[o]
              .filter(item => ['options'].indexOf(o)==-1 
                  || (!this.state.filters[o] || item.toLowerCase().indexOf(this.state.filters[o])!=-1))
              .map((item, index) => (
              <Draggable key={index} draggableId={o+index} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={this.getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div style={{flex:1}}>{item}</div>
                    {item!="values" &&
                      <Ionicon icon="md-close" 
                        onClick={() => {
                          let {conf} = this.props
                          conf[o] = conf[o].filter(o => o!=item)
                          this.props.setState({cb:new Date()})
                        }}
                        fontSize={16}/>
                    }
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
	
	render() {
    return (
      <DragDropContext 
        onDragEnd={this.onDragEnd} 
        onBeforeDragStart={this.onBeforeDragStart}
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate} >
        <div style={{display:'flex'}}>
          <div style={{height:'100%',display:'flex'}}>
            <div style={{width:200,maxHeight:500,overflow:"auto"}}>
              { this.getBlock("columns") }
            </div>
          </div>
        </div>
      </DragDropContext>
    )
  }
}

export class FilterBar extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      filters: {}
    }
  }
  handleChange = (e) => {
    let {filtertext} = this.state
    filtertext[e.currentTarget.name] = e.currentTarget.value
    this.setState(JSON.parse(JSON.stringify({filtertext})))
  }

  getValue = (key) => {
    return this.state.filtertext[key] || ''
  }

  closeFilter = (item) => {
    let {open,filters} = this.state
    delete filters[item]
    delete open[item]
    this.props.setState({open:JSON.parse(JSON.stringify(open)),filters:JSON.parse(JSON.stringify(filters))})
  }
  openFilter = (item) => {
    let {open} = this.state
    open[item] = 1
    this.props.setState({open:JSON.parse(JSON.stringify(open))})
  }

	getFilterBlock = () => {
    let o = 'filters'
    let {conf,id} = this.props
    let {filteroptions={},filters} = conf
    let {filtertext,editfilter} = this.state
    return (
    <div style={{maxWidth:'calc(100vw-160px)',display:'flex',alignItems:'center',background:'#fff',padding:5,borderRadius:5}}>
      { this.state.focus==o &&
        <div style={{border:"1px solid #d0d0d0",position:'absolute',zIndex:100,background:'white',width:200}}>
          {
            conf['options']
              .filter(item => conf[o][item]==undefined)
              .map(item => (
                <div 
                  onClick={e => {
                    this.setState({focus:''})
                    let {conf} = this.props
                    if(item=='date'){
                      conf[o][item] = {
                        type: 'daterange',
                        start: '',
                        end: ''
                      }
                    } else {
                      conf[o][item] = {options:{},type:"inlist"}
                    }  
                    this.props.setState({cb:new Date()})
                  }}
                  class="hover-background" 
                  style={{padding:"5px 10px",cursor:'pointer',borderBottom:'1px solid #e0e0e0'}}>
                  {item}
                </div>
            ))
          }
        </div>
      }
      <div style={{flex:1,display:'flex',flexWrap:'wrap'}}>
      {Object.keys(conf[o])
        .map((item, index) => (
          <div 
            onClick={() => this.setState({editfilter:item})}
            style={{height:60,position:'relative',cursor:'pointer',margin:3,padding:5,background:'#fff',border:'1px solid #e0e0e0',borderRadius:3}}>
            <div style={{display:'flex'}}>
              <div style={{flex:1}}>{item}</div>
              <Ionicon icon="md-close" 
                style={{cursor:'pointer'}}
                onClick={(e) => {
                  e.stopPropagation()
                  let {conf} = this.props
                  delete conf[o][item]
                  this.props.setState({cb:new Date()})
                }}
                fontSize={16}/>
            </div>
            {item!='date'?
            <div 
              style={{display:'flex',fontSize:'80%'}}>
                <Ionicon icon="md-list" />
                <div style={{display:'flex',alignItems:'center',width:100,flex:1,overflow:'hidden',whiteSpace:'nowrap'}}>
                  {
                    Object.keys(conf[o][item]['options']).length?
                    Object.keys(conf[o][item]['options']).map(o => (
                      <div style={{borderRadius:3,border:'1px solid #e0e0e0',padding:3,margin:1,background:'#fff'}}>
                        {o}
                      </div>
                    )):
                    <div style={{color:'#ccc'}}>
                      all values
                    </div>
                  }
                </div>
              {editfilter==item &&
                <ListFilter 
                  filters={filters[item]} 
                  options={filteroptions[item]}
                  close={() => this.setState({editfilter:''})}
                  apply={(f) => {
                    filters[item] = f
                    this.setState({editfilter:''})
                    this.setState({filters:JSON.parse(JSON.stringify(filters))})
                    EM.emit('runreport',id)
                  }}
                />
              }
            </div>:
            <div style={{display:'flex',zIndex:1000}}>
              <Ionicon icon="md-calendar" />
              <div style={{zIndex:10,position:'relative',transform:'scale(.75)',transformOrigin:'left top',height:36,width:120,margin:2}}>
                <DateRange key1="start" key2="end"
                  getValue={(k) => filters[item]?filters[item][k]:''}
                  handleDayClick={(k,v) => {
                    let pts = v.toLocaleDateString("en-GB").split(",")[0].split("/")
                    
                    filters[item][k] = `${pts[2]}-${pts[1]}-${pts[0]}`
                    this.setState({filters:JSON.parse(JSON.stringify(filters))})
                  }}
                />
              </div>
            </div>
            }
          </div>
        ))
      }
      </div>
      <div style={{display:'flex',flexDirection:'column'}}>
        <button
          onChange={e => {
              let {filters} = this.state
              filters[o] = e.currentTarget.value
              this.setState({filters:JSON.parse(JSON.stringify(filters))})
            }
          }
          onFocus={() => this.setState({focus:o})}
          onBlur={() => setTimeout(() => this.state.focus==o && this.setState({focus:''}),500)}
          value={this.state.filters[o] || ''}
          >filters</button>
        <button>columns</button>
      </div>
    </div>
    )
  }
  
  render() {
    return (
      <div>
      	{this.getFilterBlock()}
      </div>
    )
  }
}

export class PivotController extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      dropdisabled: {},
      filters: {},
      filtertext: {}
    }
  }
  componentDidMount() {
    if(window.location.href.indexOf('local.anarockdigital')!=-1){
      this.setState({
        editfilter: "channel"
      })
    }
  }

  onDragEnd = (result) => {
    this.setState({dropdisabled:{}})
    this.enableSc()

    if (!result.destination)return;
    
    const {source, destination} = result
    const startIndex = source.index
    const endIndex = destination.index

    const [removed] = this.props.conf[source.droppableId].splice(startIndex,1)

    this.props.conf[destination.droppableId].splice(endIndex,0,removed)

    const {conf} = this.props
    this.props.setState({cb:new Date()})
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  disableSc = () => {
    return
    this.refs.block.style['position'] = 'absolute'
    this.refs.block.style['top'] = window.scrollY+'px'
    this.refs.block.style['left'] = window.scrollX+'px'
  }

  enableSc = () => {
    return
    this.refs.block.style['position'] = 'fixed'
    this.refs.block.style['top'] = 0
    this.refs.block.style['left'] = 0
  }

  onBeforeDragStart = () => {
    this.disableSc()
  }
  
  onDragStart = (e) => {
    let source = e.source['droppableId']
    let item = this.props.conf[source][e.source['index']]
    if(item=='values'){
      this.setState({dropdisabled:{values:true,metrics:true,'options':true}})
    } else if(['rows','columns','options'].indexOf(source)!=-1){
      this.setState({dropdisabled:{values:true,metrics:true}})  
    } else if(['metrics','values'].indexOf(source)!=-1){
      this.setState({dropdisabled:{rows:true,columns:true,'options':true}})
    }
  };
  onDragUpdate = (e) => {
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    padding: 5,
    margin:3,
    background: isDragging ? 'lightgreen' : '#fff',
    border:'1px solid #e0e0e0',
    whiteSpace:'nowrap',
    display:'flex',
    alignItems:'center',
    borderRadius:3,
    ...draggableStyle,
  });

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#fff',
    padding: 3,
    borderRadius:5,
    minHeight:100,
  });

  
  handleLookChange = (e) => {
    this.props.setState({look: e.currentTarget.value})
  }

  getBlock = o => (
    <div>
      <div style={{marginTop:10,marginBottom:5}}>{o}</div>
      <Droppable droppableId={o} direction="vertical" isDropDisabled={this.state.dropdisabled[o]}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={this.getListStyle(snapshot.isDraggingOver)}
          >
            <input  
              onChange={e => {
                  let {filters} = this.state
                  filters[o] = e.currentTarget.value
                  this.setState({filters:JSON.parse(JSON.stringify(filters))})
                }
              }
              onFocus={() => this.setState({focus:o})}
              onBlur={() => setTimeout(() => this.state.focus==o && this.setState({focus:''}),500)}
              value={this.state.filters[o] || ''}
              style={{height:25,margin:3,width:'-webkit-fill-available',border:'1px solid #e0e0e0',borderRadius:3,fontSize:14,paddingLeft:5}}
            />
            {['rows','values','columns'].indexOf(o)!=-1 && this.state.focus==o &&
              <div style={{border:"1px solid #d0d0d0",position:'absolute',marginLeft:150,zIndex:100,background:'white',width:200}}>
                {
                  this.props.conf[['rows','columns'].indexOf(o)!=-1?'options':'metrics']
                    .filter(item => this.props.conf[o].indexOf(item)==-1 && item.indexOf(this.state.filters[o] || '')!=-1)
                    .map(item => (
                    <div 
                      onClick={e => {
                        this.setState({focus:''})
                        let {conf} = this.props
                        conf[o].push(item)
                        this.props.setState({cb:new Date()})
                      }}
                      class="hover-background" 
                      style={{padding:"5px 10px",cursor:'pointer',borderBottom:'1px solid #e0e0e0'}}>
                      {item}
                    </div>
                  ))
                }
              </div>
            }
            {this.props.conf[o]
              .filter(item => ['options','metrics'].indexOf(o)==-1 
                  || (!this.state.filters[o] || item.toLowerCase().indexOf(this.state.filters[o])!=-1))
              .map((item, index) => (
              <Draggable key={index} draggableId={o+index} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={this.getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div style={{flex:1}}>{item}</div>
                    {item!="values" &&
                      <Ionicon icon="md-close" 
                        onClick={() => {
                          let {conf} = this.props
                          conf[o] = conf[o].filter(o => o!=item)
                          this.props.setState({cb:new Date()})
                        }}
                        fontSize={16}/>
                    }
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )

  render() {
    let {conf,id} = this.props
    let {options=[],values=[],columns=[],rows=[],filteroptions={},metrics=[],setState,display,filters,look} = conf
    let {filtertext} = this.state
    return (
      <div style={{display:'flex',position:'static'}} className="pivot customscroll">
        <div ref="block" style={{padding:5,zIndex:100000000,boxShadow:'0 0 10px rgba(0,0,0,0.3)',paddingTop:10,paddingBottom:50,position:'absolute',left:0,width:350,background:'#e0e0e0',minHeight:window.innerHeight,top:0}}>
          <div style={{position:'absolute',right:5,top:5,zIndex:10}}>
            <Ionicon 
              style={{cursor:'pointer'}}
              icon={"md-close"}
              color="#ccc"
              onClick={e => {
                this.props.close()
              }}
            />
          </div>  
          <div style={{display:'flex',alignItems:'center'}}>
            <input 
              style={{width:'70%',padding:5,border:'none',fontSize:14}}
              value={conf.name}
              onChange={e => {
                conf.name = e.currentTarget.value
                this.props.setState({cb:new Date()})
              }} />
            <div 
              onClick={() => EM.emit('runreport',id)}
              style={{cursor:'pointer',alignItems:'center',display:'flex',color:'white',background:'black',padding:5,marginLeft:5,borderRadius:5,fontSize:10}}>
              <Ionicon 
                icon="md-refresh"
                fontSize="18px"
                color="#fff"
              />  
              <div>&nbsp;run</div>
            </div>
          </div>

          <div style={{display:'flex',marginTop:10}}>
            <div style={{marginRight:10}}>Report Name:</div>
            <select
              style={{width:'70%',padding:5,border:'none',fontSize:14}}
              value={conf.report}
              onChange={e => {
                conf.report = e.currentTarget.value
                this.props.setState({cb:new Date()})
              }}>
              {
                ['assignmentdailysummary','assignment_lead_report'].map(o => (
                  <option value={o}>{o}</option>
                ))
              }
            </select>
          </div>
          
          <DragDropContext 
            onDragEnd={this.onDragEnd} 
            onBeforeDragStart={this.onBeforeDragStart}
            onDragStart={this.onDragStart}
            onDragUpdate={this.onDragUpdate} >
            <div style={{display:'flex'}}>
              <div style={{paddingTop:10,height:'100%',display:'flex'}}>
                <div style={{width:150}}>
                  { this.getBlock("rows") }
                  { this.getBlock("columns") }
                  { this.getBlock("values") }
                </div>
                <div style={{width:180,paddingLeft:10}}>
                  <div>
                    <div style={{marginTop:10,marginBottom:5}}>filters</div>
                  </div>
                </div>
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>    
    )
  }
}
