import React, { PureComponent } from 'react'
import {css} from 'react-emotion';
import { BrowserRouter as Router, browserHistory, Route, Link } from "react-router-dom";
import {BarChart, ZoomContainer} from './components/graph'
import Ionicons from 'react-ionicons'
import {ChatView} from './components/chat'


class IssueCard extends PureComponent {
  render() {
    return (
      <div className={css`
background: #fff;
border-radius: 3px;
padding:10px;
margin-right:10px;
width: fit-content;
flex: 1 0 auto;
.item {
	white-space: nowrap;
	text-align: right;
	padding:5px;
	&.imp {
	}
}
`}>
        <div className="item">Mahindra Antheia</div>
        <div className="item imp">3</div>
        <div className="item">25,000</div>
        <div className="item">22,000</div>
        <div className="item">400,000</div>
      </div>
    )
  }
}

class Issuev2 extends PureComponent {
  render() {
    return (
      <div className={css`
width: calc(100vw - 20px);
.heading {
	font-size: 18px;
	padding-bottom: 10px;
}
.table {
	display: flex;
  background: #fbfbfb;
  padding: 10px;
  border-radius: 3px;
  .table-header {
    padding:10px;
    margin-right: 10px;
    > div {
			white-space: nowrap;
			font-size:12px;
      padding:5px;
      color: #6b7785;
    }
  }
  .items {
    display: flex;
    overflow: auto;
  }
}
`}>
        <div className="heading">This is Heading</div>
        <div className="table">
          <div className="table-header">
            <div>Project Name</div>
            <div className="imp">Days Left</div>
            <div>PO Hold</div>
            <div>GST Hold</div>
            <div>GST Pending</div>
          </div>
          <div className="items">
            <IssueCard />
            <IssueCard />
            <IssueCard />
            <IssueCard />
            <IssueCard />
          </div>
        </div>
      </div>
    )
  }
}

class Table extends PureComponent {
  constructor(props){
    super(props)
    this.getValue = this.getValue.bind(this)
    this.state = {}
  }
  formatNum = (num,name='') => {
    // if((name || '').toLowerCase().indexOf('rate')!=-1){
    //   if(!num) return '';
    //   return num+' %'
    // }
    if(num=='Total') return 'Total';
    if((name||'').toLowerCase().indexOf('month')!=-1){
      if(!num) return '';
      let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      return months[parseInt(num)%100-1]
    }
    if((name||'').toLowerCase().indexOf('date')!=-1 || (name||'').toLowerCase().indexOf('week')!=-1){
      if(!num) return '';
      var d = new Date(0);
      d.setUTCSeconds(num/1000);
      let [w,m,d,y,_] = d.toString().split(' ');
      return `${m} ${d}`
    }
    if(['number'].indexOf(typeof(num))!=-1) {
      return (parseFloat(num) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\..*/,'');
    } else {
      return num
    }
  }
  requestApproval = (deadline,row) => {
    let self = this
    self.setState({loading:true})
    fetch(`${window._MDOMAIN}/api/v0/alerts?rtype=request_deadline&deadline=${deadline}&id=${row['id']}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        for(let k in data.data){
          row[k] = data.data[k]
        }
        self.setState({cb:new Date(),deadline:-1})
      }
      self.setState({loading:false})
    }).catch((e) => {
      self.setState({loading:false})
      console.log(e)
    })
  }
  approveRequest = (row) => {
    let self = this
    self.setState({loading:true})
    fetch(`${window._MDOMAIN}/api/v0/alerts?rtype=approval_request&id=${row['id']}&deadline=${row['deadline']}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        for(let k in data.data){
          row[k] = data.data[k]
        }
        self.setState({cb:new Date(),deadline:-1})
      }
      self.setState({loading:false})
    }).catch((e) => {
      self.setState({loading:false})
    })
  }
  getValue(val,key){
    let {report_name} = this.props
    let text = this.formatNum(val,key)
    
    if(typeof(val)=='object'){
      if(!val) {
        return null
      }
      return (
        <ZoomContainer>
          <div style={{background:'white',padding:'5px 10px',borderRadius:3}}>
        		<BarChart scale={2} data={val} fill={'#6b7785'}/>
          </div>
        </ZoomContainer>
      )
    }
    let [name,url] = (text||'').split(':#')
    if(url){
      return (
        <Link to={`/panel/${url}`}>
          {name}
        </Link>
      )
    }
    return text
  }
  render() {
    let {data,keys,tab} = this.props
    let {show,deadline,deadline_days} = this.state
    return (
      <div className={css`
table {
	margin: 10px;
}
table,td,tr,th {
	border: 1px solid #ededed;
	background: #fff;
	border-collapse: collapse;
	white-space: prewrap;
}
td {
	padding: 5px;
	max-width: 220px;
	font-size:12px;
	overflow: hidden;
	:last-child {
		overflow: visible;
	}
}
th {
	white-space: normal;
	max-width: 220px;
	color: #6b7785;
  background: #fbfbfb;
	font-size:12px;
	padding:5px;
}
`}>
  			<table>
          <tr>
            {
              keys.map(k => (
                <th style={{textAlign:!isNaN(parseInt(data[0][k]))?'right':'left'}}>{k}</th>
              ))
            }
            <th>Actions</th>
          </tr>  
        {
    			data.map((row,_i) => (
            <tr>
              {
                keys.map(k => (
                  <td style={{textAlign:!isNaN(parseInt(row[k]))?'right':'left'}}>{this.getValue(row[k],k)}</td>
                ))
              }
              {row['meta'] &&
                <td style={{cursor:'pointer'}}>
                <div style={{position:'relative',display:'flex'}}>
                  <div style={{position:'relative'}}>
                    <Ionicons 
                      onClick={() => {
                        this.setState({show:show==_i?-1:_i})
                      }}
                      icon="md-mail" 
                      color={show==_i?"#1f69ff":"#ccc"}/>
                    <div style={{background:'#d32f02',width:6,height:6,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:30,position:'absolute',top:0,right:-1}}></div>
                    {show==_i &&
                      <div>
                        <div onClick={() => this.setState({show:-1})} style={{position:'fixed',width:'100vw',height:'100vh',left:0,top:0,background:'rgba(0,0,0,.5)',zIndex:10000}}></div>
                        <div style={{position:'absolute',transform:'translateY(-50%) translateX(-100%)',zIndex:1000,display:'flex',alignItems:'center',zIndex:100001}}>
                          <ChatView channel={`issues-${row['meta']['id']}`} header={false} style={{zIndex:10}}/>
                        </div>
                      </div>
                    }
                  </div>
                  
                  <div 
                    onClick={() => this.setState({deadline:_i,deadline_days:row['meta']&&row['meta']['deadline']?(new Date(row['meta']['deadline']) - new Date())/86400000:0})}>
                    {
                      (!row['meta'] || !row['meta']['deadline'])?
                        <Ionicons icon={'md-stopwatch'} color={deadline==_i?"#1f69ff":"#ccc"}/>:
                      	<div style={{display:'flex',alignItems:'center',paddingRight:10,whiteSpace:'nowrap'}}>
                      		<Ionicons icon={'md-stopwatch'} color={deadline==_i?"#1f69ff":"#ccc"}/>
                        	{parseInt((new Date(row['meta']['deadline']) - new Date())/86400000)} days
                      	</div>
                    }
                  </div>
                    {row['meta'] && row['meta']['deadline'] && !row['meta']['deadline_approved'] &&
                      <div
                        onClick={(e) => {
                          e.stopPropagation()
                          if(['Amrit Raj','Sravan Bonthala','Arpit Awasthi'].indexOf(window.user_details['name'])!=-1){
                            this.approveRequest(row['meta'])
                          }
                        }}
                        style={{whiteSpace:'nowrap',background:'#1f69ff',color:'#fff',cursor:'pointer',padding:'5px 10px',borderRadius:3}}
                        >{['Amrit Raj','Sravan Bonthala','Arpit Awasthi'].indexOf(window.user_details['name'])!=-1?'Approve':'Approval Pending'}</div>
                    }
                  {deadline==_i &&
                    <div>
                      <div onClick={() => this.setState({deadline:-1})} style={{position:'fixed',width:'100vw',height:'100vh',left:0,top:0,background:'rgba(0,0,0,.5)',zIndex:10000}}></div>
                      <div style={{position:'absolute',zIndex:100001,background:'#fff',padding:20,borderRadius:3}}>
                        <div>{parseInt(deadline_days)} days</div>
                        <input type="range" min="1" max="30" value={deadline_days} onChange={(e) => this.setState({deadline_days:e.target.value})} class="slider" id="myRange" />
                        <div 
                          onClick={() => {
                            let d = new Date()
                            d = new Date(d.setDate(d.getDate()+parseInt(deadline_days)))
                            this.requestApproval(d.toUTCString(),row['meta'])
                          }}
                          style={{whiteSpace:'nowrap',background:'#1f69ff',color:'#fff',cursor:'pointer',padding:'5px 10px',borderRadius:3}}>
                          {['Amrit Raj','Sravan Bonthala','Arpit Awasthi'].indexOf(window.user_details['name'])!=-1?'Approve':'Request Approval'}
                        </div>
                        <div style={{fontSize:8}}>Approval is needed from HOD</div>
                      </div>
                    </div>
                  }
                </div>
              </td>
              }
            </tr>
          ))
        }
        </table>
      </div>
    )
  }
}

export default class Issues extends PureComponent {
  state = {}
  getIssues(refresh='') {
    let self = this
    self.setState({alerts:[]})
    self.setState({loading:true})
    fetch(`${window._MDOMAIN}/api/v0/alerts?rtype=get&refresh=${refresh}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({alerts:data.data})
      }
      self.setState({loading:false})
    }).catch((e) => {
      self.setState({loading:false})
    })
  }

	componentDidMount() {
    this.getIssues()
  }
  
  render() {
    let {alerts=[],loading,tab='pending'} = this.state
    return (
      <div className={css`
.tabs {
	margin-bottom: 10px;
	display: flex;
	border-radius: 3px;
	overflow: hidden;
	width: fit-content;
	cursor: pointer;
	& > div {
		padding:10px;
		background: #fbfbfb;
		border-right: 1px solid #e0e0e0;
		:last-child {
			border: none;
		}
		:hover,&.active {
			background: #e8e8ff;
			color: #6161ff;
		}
	}
}
`}>
        <div style={{display:'flex',justifyContent:'flex-end',padding:10,color:'#6b7785',fontSize:12,height:20}}>
          {
						!loading &&
        		<div onClick={() => this.getIssues("true")} style={{cursor:'pointer'}}>refresh</div>
          }
        </div>
        <div style={{display:'none'}}>
          <Issuev2 />
        </div>
        <div className="tabs">
          {["pending","wip","expired","22 - a day","20 - 2 days","3 days","3+ days"].map(o => (
            <div onClick={() => this.setState({tab:o})} className={o==tab?'active':''}>{o}</div>
          ))
          }
        </div>
        {
          alerts
          	.filter(a => a.data.filter(o => !o.meta || o.meta.tab==tab).length > 0)
            .map(a => (
            <div style={{marginBottom:30}}>
              {a.name}
              <Table data={a.data.filter(o => !o.meta || o.meta.tab==tab)} keys={a.keys} tab={tab}/>
            </div>
          ))
        }
      </div>
    )
  }
}