import React, { PureComponent } from 'react'
import {css} from 'react-emotion'

import {Mosaic,Card} from './components/containers'
import {Graph, MiniInfo, BarChart, ZoomContainer} from './components/graph'
import {Filters} from '../../pivots/pivotcontroller'

import dummydata from './data'

const SummaryCard = ({heading,column,data={metrics:[],summary:{}},scale=1}) => {
  const formatNum = (num) => {
    if(['lv','vb','lq'].indexOf(column)!=-1){
      return (num||0)+'%'
    }
    return (parseFloat(num) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\..*/,'');
  }
  return (
    <ZoomContainer>
      <Card>
        <div style={{minWidth:150}}>
          <div style={{fontSize:12}}>{heading}</div> 
          <div style={{marginTop:5,fontSize:24}}>{formatNum(data['summary'][column])}</div>
          <div style={{height:14,marginTop:5,textAlign:'right',fontSize:12}}> <span style={{color:'#69c022'}}></span></div>
          <BarChart scale={2} column={column} fill={'#6b7785'} data={data['metrics'].slice(-30).map(o => {return {color:'black',x:o['date'],y:Math.round(o[column]*100||0.0)/100}})}/>
        </div>
      </Card>
    </ZoomContainer>
  )
}

const Summary = ({summary,data}) => {
  return (
    <div className={css`
.summary {
	display:flex;
	overflow:auto;
	width: calc(100vw - 156px);
	padding:10px;
	margin-left:-10px;
	margin-right:-5px;
	@media only screen and (max-width: 1200px) {
		width: calc(100vw - 6px);
	}
}
`}>
      <div>
        	<div style={{marginTop:20,marginBottom:10}}>Summary</div>
        	<div className="summary">
            <SummaryCard data={data} heading="Spends" column="spends" />
            <div style={{minWidth:10}}></div>
            <div>
              <SummaryCard data={data} heading="Leads" column="leads" />
              <div style={{minHeight:10}}></div>
              <SummaryCard data={data} heading="CPL" column="cpl" />
            </div>
            <div style={{minWidth:10}}></div>
            <div>
              <SummaryCard data={data} heading="Qualified Leads" column="qleads" />
              <div style={{minHeight:10}}></div>
              <SummaryCard data={data} heading="CPQL" column="cpql" />
              <div style={{minHeight:10}}></div>
              <SummaryCard data={data} heading="Lead to Qualification" column="lq" />
            </div>
            <div style={{minWidth:10}}></div>
            <div>
              <SummaryCard data={data} heading="Visits Done" column="svdone" />
              <div style={{minHeight:10}}></div>
              <SummaryCard data={data} heading="CPSV" column="cpsv" />
              <div style={{minHeight:10}}></div>
              <SummaryCard data={data} heading="Lead to Visit Done" column="lv" />
            </div>
            <div style={{minWidth:10}}></div>
            <div>
              <SummaryCard data={data} heading="Bookings" column="booked" />
              <div style={{minHeight:10}}></div>
              <SummaryCard data={data} heading="CPB" column="cpb" />
              <div style={{minHeight:10}}></div>
              <SummaryCard data={data} heading="Visits to Bookings" column="vb" />
            </div>
            <div style={{minWidth:10}}></div>
        	</div>
        </div>
    </div>
  )
}
export default class Overview extends PureComponent {
  state = {}
	getData = () => {
    let self = this
    let {assignment_id} = this.props.assignment
    fetch(`${window._MDOMAIN}api/v0/overview?assignment_id=${assignment_id}&rtype=get`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200){
        self.setState({data:data.data})
      }
    })
  }
  componentWillMount() {
    this.getData()
  }
  render() {
    let {data={metrics:[],summary:{},statuses:{}},minizoom=0} = this.state
    const summary = data['summary']
    return (
      <div className={css`
font-size:14px;
color: #6b7785;
.summary {
	display:flex;
	overflow:auto;
	width: calc(100vw - 156px);
	padding:10px;
	margin-left:-10px;
	margin-right:-5px;
	@media only screen and (max-width: 1200px) {
		width: calc(100vw - 6px);
	}
}

*::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
  overflow: visible;
  background-color: white;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: red;
  cursor: pointer;
  width:10px;
}
`}>
        {false &&
          <div style={{border:'1px solid #6b7785',padding:5,borderRadius:3,marginRight:20,display:'flex'}}>
            <div style={{cursor:'pointer',color:'#fff',background:'#6161ff',border:'1px solid #6161ff',padding:5,borderRadius:3}}>online</div>
            <div style={{marginLeft:5,cursor:'pointer',color:'#6b7785',border:'1px solid #6b7785',padding:5,borderRadius:3}}>01 Feb - 25 Mar</div>
            <div style={{marginLeft:5,cursor:'pointer',color:'#6b7785',border:'1px solid #6b7785',padding:5,borderRadius:3}}>facebook</div>
            <div style={{marginLeft:5,cursor:'pointer',color:'#6b7785',border:'1px solid #6b7785',padding:5,borderRadius:3}}>leadgen_ri</div>
          </div>
        }
        <Summary summary={summary} data={data} />
        <div>
          <div style={{marginTop:20,marginBottom:10}}>Lead Distribution</div>
          <div className="summary" style={{display:'flex'}}>
            <Card>
              <div style={{width:100}}>
                <div style={{fontSize:10}}>Fresh</div>
                <div style={{fontSize:24,marginTop:5}}>{data['statuses'][5] || 0}</div>
              </div>
            </Card>
            <div style={{minWidth:10}}></div>
            <Card>
              <div style={{width:100}}>
                <div style={{fontSize:10}}>Claimed</div>
                <div style={{fontSize:24,marginTop:5}}>{data['statuses'][6] || 0}</div>
              </div>
            </Card>
            <div style={{minWidth:10}}></div>
            <Card>
              <div style={{width:100}}>
                <div style={{fontSize:10}}>Interested</div>
                <div style={{fontSize:24,marginTop:5}}>{data['statuses'][7] || 0}</div>
              </div>
            </Card>
            <div style={{minWidth:10}}></div>
            <Card>
              <div style={{width:100}}>
                <div style={{fontSize:10}}>Visit Done</div>
                <div style={{fontSize:24,marginTop:5}}>{data['statuses'][9] || 0}</div>
              </div>
            </Card>
            <div style={{minWidth:10}}></div>
            <Card>
              <div style={{width:100}}>
                <div style={{fontSize:10}}>Final Negotiation</div>
                <div style={{fontSize:24,marginTop:5}}>{data['statuses'][10] || 0}</div>
              </div>
            </Card>
            <div style={{minWidth:10}}></div>
            <Card>
              <div style={{width:100}}>
                <div style={{fontSize:10}}>Failed</div>
                <div style={{fontSize:24,marginTop:5}}>{data['statuses'][12] || 0}</div>
              </div>
            </Card>
            <div style={{minWidth:10}}></div>
            <Card>
              <div style={{width:100}}>
                <div style={{fontSize:10}}>Junk</div>
                <div style={{fontSize:24,marginTop:5}}>{data['statuses'][2] || 0}</div>
              </div>
            </Card>
            <div style={{minWidth:10}}></div>
            <Card>
              <div style={{width:100}}>
                <div style={{fontSize:10}}>Call Center</div>
                <div style={{fontSize:24,marginTop:5}}>{data['statuses'][4] || 0}</div>
              </div>
            </Card>
            <div style={{minWidth:10}}></div>
          </div>
        </div>
        <div style={{}}>
          <div style={{marginTop:20,marginBottom:10}}>Daily Summary</div>
          <Mosaic>
            {
              ['dqleads','dsvdone','dbookings'].map(o => (
                <Card><MiniInfo tooltip={true} zoom={() => this.setState({minizoom:o})} data={data['metrics']} columns={[o]} /></Card>    
              ))
            }
          </Mosaic>
          <div style={{marginTop:20,marginBottom:10}}>Cumulative Metrics</div>
          <Mosaic>
            {
              ['cumm. cpl','cumm. cpql','cumm. cpsv','cumm. 7 cpql','cumm. 7 cpsv','cumm. 30 cpql','cumm. 30 cpsv','avg. 7 spends'].map(o => (
                <Card><MiniInfo tooltip={true} zoom={() => this.setState({minizoom:o})} data={data['metrics']} columns={[o]} /></Card>
              ))
            }
          </Mosaic>
        </div>
        {
          minizoom?
          <div onClick={() => this.setState({minizoom:0})}
            onTouchMove={e => e.stopPropagation()}
            style={{position:'fixed',top:0,left:0,width:'100vw',height:'100vh',background:'rgba(0,0,0,.5)',display:'flex',justifyContent:'center',alignItems:'center',zIndex:10005}}>
            <div onClick={(e) => e.stopPropagation()} style={{transform:'scale(2)'}}>
              <Card><MiniInfo scale={2} tooltip={true} data={data['metrics']} columns={[minizoom]} /></Card>
            </div>
          </div>:null
        }
        
      </div>
    )
  }
}