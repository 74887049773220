/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 */
enum PebbleIcon {
  AccountCircle = "account_circle",
  Actions = "actions",
  AddFloors = "add-floors",
  Add = "add",
  AddedDetails = "added-details",
  Admin = "admin",
  AgreementLetter = "agreement-letter",
  AlertFilled = "alert-filled",
  Alert = "alert",
  AllotmentLetter = "allotment-letter",
  Apps = "apps",
  ArrowBack = "arrow-back",
  ArrowDown = "arrow-down",
  ArrowDropDown = "arrow-drop-down",
  ArrowDropUp = "arrow-drop-up",
  ArrowRight = "arrow-right",
  ArrowUp = "arrow-up",
  Article = "article",
  Back = "back",
  Balcony = "balcony",
  BarGraph = "bar-graph",
  BasePrice = "base-price",
  Bathroom = "bathroom",
  Bedroom = "bedroom",
  Bolt = "bolt",
  BookmarkAdd = "bookmark-add",
  Building = "building",
  Business = "business",
  Calendar = "calendar",
  CallCenter = "call-center",
  Call = "call",
  Camera = "camera",
  CarFront = "car-front",
  Car = "car",
  ChangeNaming = "change-naming",
  ChannelPartners = "channel-partners",
  CheckBold = "check-bold",
  CheckCircleFilled = "check-circle-filled",
  Check = "check",
  CheckboxIndeterminate = "checkbox-indeterminate",
  CheckboxSelected = "checkbox-selected",
  CheckboxUnfilled = "checkbox-unfilled",
  CheckboxUnselected = "checkbox-unselected",
  CheckedServer = "checked-server",
  ChevronDown = "chevron-down",
  ChevronLeft = "chevron-left",
  ClockFilled = "clock-filled",
  Clock = "clock",
  CloseCircleFilled = "close-circle-filled",
  CloseCircle = "close-circle",
  Close = "close",
  CloudDownload = "cloud-download",
  CloudUpload = "cloud-upload",
  ConfigureFloors = "configure-floors",
  ConfigureUnits = "configure-units",
  Contact = "contact",
  ControlUnit = "control-unit",
  CopyFilled = "copy-filled",
  Copy = "copy",
  CpIcon = "cp-icon",
  Cross = "cross",
  Delete2 = "delete-2",
  DeleteFilled = "delete-filled",
  Delete = "delete",
  Description = "description",
  Dialer = "dialer",
  DigitalLeads = "digital-leads",
  DirectWalkins = "direct-walkins",
  DirectionCompass = "direction-compass",
  Direction = "direction",
  DocumentUploaded = "document-uploaded",
  Document = "document",
  DoorFront = "door-front",
  Dot = "dot",
  Dots = "dots",
  Download = "download",
  Edit2 = "edit-2",
  EditAlt = "edit-alt",
  EditNote = "edit-note",
  EditThin = "edit-thin",
  Edit = "edit",
  ExtraArea = "extra-area",
  EyeLine = "eye-line",
  Eye = "eye",
  FaceToFace2 = "face-to-face-2",
  FaceToFace3 = "face-to-face-3",
  FaceToFace = "face-to-face",
  Facing = "facing",
  File = "file",
  Filter2 = "filter-2",
  FilterAlt = "filter-alt",
  Filter = "filter",
  Fire = "fire",
  Firm = "firm",
  FollowUp2Filled = "follow-up-2-filled",
  FollowUp2 = "follow-up-2",
  FollowUp = "follow-up",
  Funnel = "funnel",
  GradeLine = "grade-line",
  Grade = "grade",
  HamburgerBold = "hamburger-bold",
  Hamburger = "hamburger",
  HelpAndSupport = "help-and-support",
  Help = "help",
  Hidden = "hidden",
  History = "history",
  HomeFilled2 = "home-filled-2",
  HomeFilled = "home-filled",
  Home = "home",
  HouseStatus = "house-status",
  HowToReg = "how-to-reg",
  Image = "image",
  IncomingCall2 = "incoming-call-2",
  IncomingCall = "incoming-call",
  IncomingCalls = "incoming-calls",
  InfoFilled = "info-filled",
  Info = "info",
  Inventory = "inventory",
  Invoice = "invoice",
  Jodi = "jodi",
  Junk = "junk",
  Landscape = "landscape",
  LeadsTab = "leads-tab",
  LineChart = "line-chart",
  Link = "link",
  List = "list",
  Location = "location",
  LockClock = "lock-clock",
  LockFilled = "lock-filled",
  LockOpen = "lock-open",
  Lock = "lock",
  LogoutDoor = "logout-door",
  Logout = "logout",
  MailSend = "mail-send",
  Mail = "mail",
  Mandates = "mandates",
  MeetingRoom = "meeting-room",
  Mic = "mic",
  Minus = "minus",
  MissedCall = "missed-call",
  Money = "money",
  More = "more",
  NotAvailable = "not-available",
  NotCalled = "not-called",
  Note1 = "note-1",
  NoteAlt = "note-alt",
  Note = "note",
  Notification = "notification",
  Offline = "offline",
  OpenExternal = "open-external",
  OpeningQuote = "opening-quote",
  Orientation = "orientation",
  OtherLeads = "other-leads",
  Others = "others",
  OutgoingCall2 = "outgoing-call-2",
  OutgoingCall = "outgoing-call",
  Patchout = "patchout",
  Pause = "pause",
  Pdf = "pdf",
  Percentage = "percentage",
  PersonAddDisabled = "person-add-disabled",
  PersonSearch = "person-search",
  Person = "person",
  Phone2 = "phone-2",
  PhoneAlt = "phone-alt",
  PhoneFilled = "phone-filled",
  PhoneInTalk = "phone-in-talk",
  PhoneIncoming2 = "phone-incoming-2",
  PhoneIncoming = "phone-incoming",
  PhoneMissed = "phone-missed",
  PhoneOutgoing2 = "phone-outgoing-2",
  PhoneOutgoing = "phone-outgoing",
  Phone = "phone",
  Play = "play",
  Plus = "plus",
  PossessionLetter = "possession-letter",
  Premium = "premium",
  Print = "print",
  PriorityChange = "priority-change",
  Profile1 = "profile-1",
  Profile = "profile",
  Quotations = "quotations",
  RadioCheckFilled = "radio-check-filled",
  RadioCheck = "radio-check",
  RadioSelected = "radio-selected",
  Radio = "radio",
  Redo = "redo",
  Reimbursements2 = "reimbursements-2",
  Reimbursements = "reimbursements",
  Reschedule = "reschedule",
  Reset = "reset",
  Revisit = "revisit",
  Rules = "rules",
  ScheduleMail = "schedule-mail",
  Search = "search",
  SelfAdded = "self-added",
  SettingsLine = "settings-line",
  Settings = "settings",
  ShareWithPeople = "share-with-people",
  Share = "share",
  SiteVisit2 = "site-visit-2",
  SiteVisit = "site-visit",
  Sms = "sms",
  Spinner = "spinner",
  Stats = "stats",
  Store2 = "store-2",
  Store = "store",
  System = "system",
  Table = "table",
  Team = "team",
  Training = "training",
  Transfer2 = "transfer-2",
  TransferAlt = "transfer-alt",
  Transfer = "transfer",
  Transferred = "transferred",
  Travel = "travel",
  Trophy = "trophy",
  UncheckedRadio = "unchecked-radio",
  Undo = "undo",
  Unlock = "unlock",
  Update = "update",
  Updating = "updating",
  Upload = "upload",
  UserAlt = "user-alt",
  User = "user",
  Videocam = "videocam",
  ViewDetails = "view-details",
  VpnKey = "vpn-key",
  WarningLine = "warning-line",
  Warning = "warning",
  WelcomeLetter = "welcome-letter",
  WhatsappFilled = "whatsapp-filled",
  Whatsapp = "whatsapp",
}
export default PebbleIcon;
