import React, { PureComponent } from 'react';
import { Button, OptionGroupCheckBox, Option, DropDown, DateInput } from "@anarock/pebble";
import { colors, Tooltip } from "@material-ui/core";
import Notify from "./components/notify";
import { css } from 'react-emotion';
import {LaunchPopup} from './components/launchPopup'
import Ionicon from 'react-ionicons'
import { isEmpty } from 'lodash'
import PopupImage from './static/images/vendorAccess.svg';


const DEFAULT_START_DATE = "1970-01-01";
const DEFAULT_END_DATE = "2099-12-31";
const PLUS_OTHERS_LIMIT = 3;

const plusOthersText = (values)=> {
  if (!values) return "";
  return values.length>PLUS_OTHERS_LIMIT
  ? values.slice(0,PLUS_OTHERS_LIMIT).join(", ")+` +${values.length-PLUS_OTHERS_LIMIT}`
  :values.join(", ")
}
class VendorAccess extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      vendorAccessToEdit: undefined,
      vendorAccesses: [],
      options: {},
      showPopup: false,
    };
    this.getAllVendorAccess();
    this.getSegments();
  }

  componentDidMount() {
    let x = window.localStorage.getItem('showPopUp');
    if (x === null) {
      this.setState({showPopup: true})
      window.localStorage.setItem('showPopUp', false);
    }
  }

  getAllVendorAccess() {
    this.setState({loading:true})
    let self = this
    fetch(`${window._MDOMAIN}/api/v0/getallvendor`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        self.setState({vendorAccesses:data.data});
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }
  getSegments() {
    this.setState({loading:true})
    let self = this
    let {assignment} = this.props;
    let {assignment_id} = assignment;
    fetch(`${window._MDOMAIN}api/v0/segments?rtype=options&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        const {project_data,channel,source,subsource} = data.data;
        self.setState({options:{project_data,channel,source,subsource}})
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }
  deleteVendorAccess(vendorAccess) {
    const { vendorAccesses } = this.state;
    let agree = (prompt(`Are you sure, you want to remove vendor access: ${vendorAccess.name}?\n Type 'y' to confirm`, '').toLowerCase() == 'y');
    if(agree){
      this.setState({loading:true})
      let self = this
      fetch(`${window._MDOMAIN}/api/v0/delete_vendor`, {
        method: 'post',
        body: JSON.stringify({id:vendorAccess.id,vendor_id:vendorAccess.vendor_id}),
        credentials:"include"
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        self.setState({loading:false})
        if(data.status==200) {
          self.setState({vendorAccesses:vendorAccesses.filter(access=>access.id!==vendorAccess.id)});
        } else {
          self.setState({message:"Unable to Load",message_id:new Date()})
        }
      }).catch(function(e){
        self.setState({loading:false})
        self.setState({message:"Unable to Load",message_id:new Date()})
      })
    }
  }
  
  render() {
    const { vendorAccessToEdit, vendorAccesses, options, message, message_id, showPopup } = this.state;
    const { assignment } = this.props;
    const dateStyle = css`min-width:110px;text-align:center;`;
    const launchPopupProps = {
      header: 'Vendor Access',
      subheader: <div>Unite marketing & sales by <span style={{color:'#5656ED'}}>allowing vendors to access leads</span> through our marketing panel. With this latest release, you will able to:</div>,
      lines: [
        'Share the access of leads with 3rd party vendors for real-time tracking.',
        'Measure & optimize campaign performance effectively.',
        'Track key performance indicators with real time acess to lead gen funnel.',
        'One-click access to all the leads from multiple assignments in one place.'
      ],
      img: PopupImage,
      externalLinkText: 'Learn how to create Vendor-Access',
      externalLink: 'https://support.anarock.com/support/solutions/articles/35000202677-vendor-access'
    }
    return <div>
      <div className={css`display:flex;`}>
      <Button
        className={css`margin-bottom:20px;`}
        onClick={() => {
          this.setState({vendorAccessToEdit:{
            assignment_id: assignment.assignment_id,
            project:[],
            name: "",
            channel: [],
            source: [],
            subsource: [],
            startdate: "",
            enddate: "",
            expirydate: "",
            link: ""
          }});
        }}
      >
        Create New Vendor Access
        </Button>
        <div className={css`padding-top:12px; margin-left:5px;`}>
          <Ionicon fontSize="16px" icon="md-information-circle" className={css(`:hover {
            cursor: pointer
          }`)} color={showPopup ? "#000" : "#ccc"} onClick={() => this.setState({ showPopup: true })} />
        </div>
      </div>
      {showPopup && (<LaunchPopup {...launchPopupProps} onClick={() => this.setState({ showPopup: undefined })}></LaunchPopup>)}
      <Notify message={message} message_id={message_id} />
      {vendorAccessToEdit && (<div
        onClick={() => this.setState({ vendorAccessToEdit: undefined })}
        style={{
          overflow: "auto",
          zIndex: 1000000,
          paddingTop: 20,
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          position: "fixed",
          background: "rgba(0,0,0,.5)",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          style={{ height: "fit-content" }}
        >
          <EditVendorAccess
          vendorAccess={vendorAccessToEdit} 
          assignment_id={this.props.assignment.assignment_id} 
          options={options}
          onSave={vendorAccess=>{
            const index = vendorAccesses.findIndex(obj=>obj.id===vendorAccess.id);
            let newVendorAccesses = [...vendorAccesses];
            if(index>-1) newVendorAccesses[index] = vendorAccess;
            else newVendorAccesses.push(vendorAccess)
            this.setState({vendorAccesses:newVendorAccesses,vendorAccessToEdit:undefined,message:'Saved Successfully',message_id:new Date()});
          }}
          />
        </div>
      </div>)}
      
      <div className={css`
        table,tr,td,th {
          border-collapse: collapse;
          border: 1px solid #ededed;
          background: #fff;
          .right {
            text-align: right;
          }
        }
        td {
          padding: 10px 15px;
          max-width:200px;
        }
        th {
          padding: 10px 15px;
          background: #fbfbfb;
          color: #6b7785;
        }
        .link {
          cursor: pointer;
          :hover {
            text-decoration: underline;
          }
        }
      `}>
        <table>
          <tr>
          {["Vendor Name","Project","Channel","Source","Subsource","Start Date","End Date","Link Expiry Date","Status","Link",""]
          .map(key =><th key={key}>{key}</th>)
          }
          </tr>
          {vendorAccesses.filter(row=>row.assignment_id===assignment.assignment_id).map(row => (
            <tr key={row.id}>
              <td>{row.name}</td>
              <td title={row.project&&row.project.length>PLUS_OTHERS_LIMIT&&row.project.join()}>{row.project ? plusOthersText(row.project) : 'All Projects'}</td>
              <td title={row.channel&&row.channel.length>PLUS_OTHERS_LIMIT&&row.channel.join()}>{row.channel ? plusOthersText(row.channel) : 'All Channels'}</td>
              <td title={row.source&&row.source.length>PLUS_OTHERS_LIMIT&&row.source.join()}>{row.source ? plusOthersText(row.source) : 'All Sources'}</td>
              <td title={row.subsource&&row.subsource.length>PLUS_OTHERS_LIMIT&&row.subsource.join()}>{row.subsource ? plusOthersText(row.subsource) : 'All Subsources'}</td>
              <td className={dateStyle}>{row.startdate === DEFAULT_START_DATE ? '-' : row.startdate}</td>
              <td className={dateStyle}>{row.enddate === DEFAULT_END_DATE ? '-' : row.enddate}</td>
              <td className={dateStyle}>{row.expirydate === DEFAULT_END_DATE ? '-' : row.expirydate}</td>
              <td>{new Date(row.expirydate)>new Date()?"Active":"Inactive"}</td>
              <td>
                <a target="_blank" href={`/vendor/${row.name + "-" + row.vendor_id}`}>View</a>
              </td>
              <td>
                <div style={{display:"flex" }}>
                  <div style={{width:"20px", cursor:"pointer"}} onClick={() => this.setState({vendorAccessToEdit:row})} className="btn-icon">
                    <Ionicon icon="md-create" color="#6b7785"/>
                  </div>
                  <div style={{width:"20px", marginLeft:"10px", cursor:"pointer"}} onClick={() => this.deleteVendorAccess(row)} className="btn-icon">
                    <Ionicon icon="md-trash" color="#6b7785"/>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  }
}

export default VendorAccess;

class EditVendorAccess extends React.PureComponent{
  constructor (props) {
    super(props)
    this.state = {
      vendorAccess: props.vendorAccess,
      loading: false,
      isDisabled: true,
      isVendorNameTooltipOpen: false,
      isLinkExpiryTooltipOpen: false,
      isStartDateTooltipOpen: false,
      isEndDateTooltipOpen: false,
      vendorNameError: false
    };
  }

  validateForm() {
    let { channel, name, project, source, subsource } = this.state.vendorAccess;
    const rgx = /^[a-zA-Z0-9]+$/
    if (name!==""&&(channel==null||channel.length)&&(project==null||project.length)&&(source==null||source.length)&&(subsource==null||subsource.length)) {
      this.setState({isDisabled:false})
    } else {
      this.setState({isDisabled:true})
    }
    if (name === '' || rgx.test(name)) {
      this.setState({isTooltipOpen:false,vendorNameError:false})
    } else {
      this.setState({isTooltipOpen:true,vendorNameError:true,isDisabled:true})
    }
  }

  componentDidMount() {
    this.validateForm()
  }
  
  saveVendorAccess(vendorAccess) {
    this.setState({loading:true})
    let self = this
    
    fetch(`${window._MDOMAIN}/api/v0/${vendorAccess.id?"update_vendor_details":"new_vendor"}`, {
      method: "post",
      body: JSON.stringify(vendorAccess),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        self.props.onSave(data.data);
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }
  render(){
    const { loading, vendorAccess, isDisabled, isVendorNameTooltipOpen, isEndDateTooltipOpen, isLinkExpiryTooltipOpen, isStartDateTooltipOpen, vendorNameError } = this.state;
    const { options } = this.props;
    const label = css`min-width:100px;color:#6b7785;margin-right:20px;`
    const column = css`display:flex;align-items:center;margin-bottom:25px;`
    const dateColumn = css`display:flex;align-items:baseline;`

    return (
      <div className={css`background:#fff;padding:20px 30px;min-width:500px;min-height:400px;color:#6b7785;`}>
        <div className={column}>
          <div className={label}>Vendor Name <span style={{color:'red'}}>*</span></div>
          <input
            placeholder="Enter name"
            className={css`padding:5px; border: 1px solid #ededed;outline: none;width:200px;`}
            value={vendorAccess.name}
            required
            onChange={e => this.setState({ vendorAccess: { ...vendorAccess, "name": e.target.value } }, () => { this.validateForm() })}
          />
          <div style={{paddingLeft:'5px'}}>
            <Tooltip
            classes={{ tooltip: css({ backgroundColor: vendorNameError ? colors.red[800] : colors.grey[600], }),
            popper: css({ zIndex: " 10000012 !important", }) }} 
              title={<div className={css({fontSize: "12px", margin: "8px"})}>Please enter alphanumeric character only</div>}
              open={vendorNameError || isVendorNameTooltipOpen}
              onOpen={()=>{this.setState({isVendorNameTooltipOpen:true})}}
              onClose={()=>{this.setState({isVendorNameTooltipOpen:false})}}
            arrow>
               <div className={css(`margin-left:5px`)}>
              <Ionicon fontSize="16px" icon="md-information-circle" color={ isVendorNameTooltipOpen ? "#000" : "#ccc"}></Ionicon>
            </div>
            </Tooltip>
          </div>
        </div>
        <div className={column}>
          <div className={label}>Project <span style={{color:'red'}}>*</span></div>
          <MultiDropdown
            label="Project"
            value={vendorAccess.project}
            onChange={project => this.setState({ vendorAccess: { ...vendorAccess, project } }, () => { this.validateForm() })}
            options={options.project_data}
          />
        </div>
        <div className={column}>
          <div className={label}>Channel <span style={{color:'red'}}>*</span></div>
          <MultiDropdown
            label="Channel"
            value={vendorAccess.channel}
            onChange={channel => this.setState({ vendorAccess: { ...vendorAccess, channel } }, () => { this.validateForm() })}
            options={options.channel}
          />
        </div>
        <div className={column}>
          <div className={label}>Source <span style={{color:'red'}}>*</span></div>
          <MultiDropdown
            label="Source"
            value={vendorAccess.source}
            onChange={source => this.setState({ vendorAccess: { ...vendorAccess, source } }, () => { this.validateForm() })}
            options={options.source}
          />
        </div>
        <div className={column}>
          <div className={label}>Subsource <span style={{color:'red'}}>*</span></div>
          <MultiDropdown
            label="Subsource"
            value={vendorAccess.subsource}
            onChange={subsource => this.setState({ vendorAccess: { ...vendorAccess, subsource } }, () => { this.validateForm() })}
            options={options.subsource}
          />
        </div>
        <div className={dateColumn}>
          <div className={label}>Start Date</div>
          <DateInput
            placeholder=""
            value={vendorAccess.startdate&&vendorAccess.startdate!==DEFAULT_START_DATE?new Date(vendorAccess.startdate):null}
            calendarProps={{
              onChange: ()=>{},
              tileDots: []
            }}
            inputProps={{
              placeholder: ""
            }}
            onChange={val => {
              const date = new Date(val)
              const startdate = date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0'+date.getDate()).slice(-2);
              this.setState({vendorAccess:{...vendorAccess,startdate}});
            }}
          />
           <div style={{paddingLeft:'5px'}}>
            <Tooltip
            classes={{ tooltip: css({ backgroundColor: colors.grey[600]}),
            popper: css({ zIndex: " 10000012 !important", }) }} 
              title={<div className={css({fontSize: "12px", margin: "8px"})}>Leads that are created in our system in the selected date range</div>}
              open={isStartDateTooltipOpen}
              onOpen={()=>{this.setState({isStartDateTooltipOpen:true})}}
              onClose={()=>{this.setState({isStartDateTooltipOpen:false})}}
            arrow>
              <div className={css(`margin-left:5px`)}>
              <Ionicon fontSize="16px" icon="md-information-circle" color={ isStartDateTooltipOpen ? "#000" : "#ccc"}></Ionicon>
            </div>
            </Tooltip>
          </div>
        </div>
        <div className={dateColumn}>
          <div className={label}>End Date</div>
          <DateInput
            placeholder=""
            value={vendorAccess.enddate && vendorAccess.enddate !== DEFAULT_END_DATE ? new Date(vendorAccess.enddate) : null}
            calendarProps={{
              onChange: () => { },
              tileDots: []
            }}
            inputProps={{
              placeholder: ""
            }}
            onChange={val => {
                const date = new Date(val);
                const enddate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
                this.setState({ vendorAccess: { ...vendorAccess, enddate } });
            }
            }
          />
          <div style={{paddingLeft:'5px'}}>
            <Tooltip
            classes={{ tooltip: css({ backgroundColor: colors.grey[600]}),
            popper: css({ zIndex: " 10000012 !important", }) }} 
              title={<div className={css({fontSize: "12px", margin: "8px"})}>Leads that are created in our system in the selected date range</div>}
              open={isEndDateTooltipOpen}
              onOpen={()=>{this.setState({isEndDateTooltipOpen:true})}}
              onClose={()=>{this.setState({isEndDateTooltipOpen:false})}}
            arrow>
                 <div className={css(`margin-left:5px`)}>
              <Ionicon fontSize="16px" icon="md-information-circle" color={ isEndDateTooltipOpen ? "#000" : "#ccc"}></Ionicon>
            </div>
            </Tooltip>
          </div>
        </div>
        <div className={dateColumn}>
          <div className={label}>Link Expiry Date</div>
          <DateInput
            placeholder=""
            value={vendorAccess.expirydate&&vendorAccess.expirydate!==DEFAULT_END_DATE?new Date(vendorAccess.expirydate):null}
            calendarProps={{
              onChange: ()=>{},
              tileDots: []
            }}
            inputProps={{
              placeholder: ""
            }}
            onChange={val =>{
              const date = new Date(val);
              const expirydate = date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0'+date.getDate()).slice(-2);
              this.setState({vendorAccess:{...vendorAccess,expirydate}});
            }}
          />
           <div style={{paddingLeft:'5px'}}>
            <Tooltip
            classes={{ tooltip: css({ backgroundColor: colors.grey[600]}),
            popper: css({ zIndex: " 10000012 !important", }) }} 
              title={<div className={css({fontSize: "12px", margin: "8px"})}>The vendor access link will be disabled after this date</div>}
              open={isLinkExpiryTooltipOpen}
              onOpen={()=>{this.setState({isLinkExpiryTooltipOpen:true})}}
              onClose={()=>{this.setState({isLinkExpiryTooltipOpen:false})}}
            arrow>
                 <div className={css(`margin-left:5px`)}>
              <Ionicon fontSize="16px" icon="md-information-circle" color={ isLinkExpiryTooltipOpen ? "#000" : "#ccc"}></Ionicon>
            </div>
            </Tooltip>
          </div>
        </div>
        <Button
          onClick={() => {
            let vendorAccesstoSave = {
              ...vendorAccess,
              startdate: vendorAccess.startdate || DEFAULT_START_DATE,
              enddate: vendorAccess.enddate || DEFAULT_END_DATE,
              expirydate: vendorAccess.expirydate || DEFAULT_END_DATE
            }
            this.saveVendorAccess(vendorAccesstoSave); 
          }}
          loading={loading}
          disabled={isDisabled}
        >
          Save
        </Button>
      </div>
    )
  }
}

class MultiDropdown extends React.PureComponent{
  constructor (props) {
    super(props)
    this.state = {
      filtertext: ""
    };
  }
  render(){
    const { label, onChange, options, value } = this.props;
    const {filtertext} = this.state;
    return(
      <DropDown
        buttonLabel={value? (plusOthersText(value) || `Select ${label}`): `All ${label}s`} 
        labelClassName={css({ minWidth: "180px", alignSelf: "start" })}  
        onClick={() => {}}>
          {({ toggle }) => (<div>
            <div className={css`display:flex;align-items:center;padding:10px 20px 0 20px;justify-content:space-between;`}>
              <Button
                onClick={() => {
                  onChange(null);
                  toggle();
                }}
                type="link"
              >
                Select All
              </Button>
              <Button
                onClick={() => {
                  onChange([]);
                }}
                type="link"
              >
                Clear All
              </Button>
            </div>
            
            <OptionGroupCheckBox 
              searchBox
              searchBoxProps={{
                clearable: true,
                placeholder: 'Search',
                onChange: value => this.setState({filtertext:value})
              }}
              selected={value||options.map(o=>o.name)}
              onChange={value => {
                if(value) value.length===options.length? onChange(null): onChange(value);
              }}>
              {options
                .filter(o => ((o.name||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1))
                .map((o) => (
                  <Option value={o.name} label={o.name} />
                ))
              }
            </OptionGroupCheckBox>
          </div>)}
      </DropDown>
    )
  }
}