import React, { Component } from 'react'
import Ionicon from 'react-ionicons'

export default class Tooltip extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false
    }
  }
  render() {
    let {show} = this.state
    let {text,width,size} = this.props
    return (
      <div style={this.props.style}>
      <div style={{position:"relative",textAlign:"left",alignItems:"center",display:"flex"}}>
        <div
          onMouseEnter={() => this.setState({show:true})} onMouseLeave={() => this.setState({show:false})} 
          style={{lineHeight:(size?size:15)+'px',cursor:"pointer",borderRadius:15,width:size?size:14,height:size?size:14,display:"flex",justifyContent:"center",alignItems:"center"}}
          >
          <Ionicon icon="md-information-circle" fontSize={size?size:15} color={show?"#000":"#ccc"} />
        </div>
        <div style={{wordBreak:'break-word',boxShadow:"0px 0px 3px 0px rgba(0,0,0,0.75)",fontSize:12,display:show?"block":"none",width:width?width:200,borderRadius:3,padding:10,zIndex:1000,position:"absolute",transform:"translateY(100%)",bottom:20,left:20,background:"#000",color:"#fff"}}>
          {
            text.split("\n").map((o) => (
              <p>{o.trim()}</p>
            ))
          }
        </div>
      </div>
      </div>
    )
  }
}
