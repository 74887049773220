import React, { PureComponent } from 'react'
import Table from './table'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import styles from 'react-day-picker/lib/style.css';
import Ionicon from 'react-ionicons'


class Dashboard extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      assignment: this.props.assignment || {},
      table: [],
      filters: {startdate:this.getLastWeek(),enddate:new Date()}
    }
  }
  
  getLastWeek = () => {
    var d = new Date();
    var ts = d.getTime();
    var days = ts - (7 * 24 * 60 * 60 * 1000);
    d.setTime(days);
    return d
  }

  handleDayClick = (key,day) => {
    let {filters} = this.state
    let pts = day.toLocaleDateString("en-GB").split(",")[0].split("/")
    filters[key] = `${pts[2]}-${pts[1]}-${pts[0]}`
    this.setState({filters:JSON.parse(JSON.stringify(filters))});
  }

  componentDidMount() {
    if(window.location.href.indexOf('local.anarockdigital')!=-1){
      this.setState({
        tab: "graphs"
      })
    }
  }

  render() {
    let {table,assignment,filters} = this.state
    let {assignment_id} = assignment
    let {tab} = this.props
    return (
      <div>
        <style dangerouslySetInnerHTML={{ __html: styles }} />
        <div className="filters" style={{display:"flex",alignItems:"center",background:'#fff'}}>
          <div style={{width:"40px"}}><Ionicon icon="md-funnel" fontSize="32px" color="#999"/></div>
          <div>
            <div className="label">startdate</div>
            <DayPickerInput 
              formatDate = {(o) => {
                  let [m,d,y] = o.toString().split(" ").slice(1,4)
                  return `${d} ${m}`
                }}
              placeholder="start"
              value={filters.startdate?new Date(filters.startdate):''} onDayChange={(day) => this.handleDayClick('startdate',day)} />
          </div>
          <div>
            <div className="label">enddate</div>
            <DayPickerInput 
              formatDate = {(o) => {
                  let [m,d,y] = o.toString().split(" ").slice(1,4)
                  return `${d} ${m}`
                }}
                placeholder="end"
              value={filters.enddate?new Date(filters.enddate):''} onDayChange={(day) => this.handleDayClick('enddate',day)} />
          </div>
          {Object.keys(filters).length?
          <div style={{cursor:'pointer',textDecoration:'underline'}} onClick={() => {this.setState({filters:{}});this.getReportData()}}>
            reset
          </div>:null
          }
          <Ionicon style={{cursor:'pointer'}} onClick={() => {
            filters['reload'] = new Date()
            this.setState({cb: new Date()})
          }} icon="md-refresh" fontSize="32px" color="#999"/>
        </div>
        <div>
          {tab=="summary" && 
            <div>
              <Table params={{assignment_id,rtype:'summary',filters}}/>
              {false &&
                <div>
                  <div style={{marginTop:"30px"}}>Site Attributed Summary</div>
                  <Table params={{assignment_id,rtype:'siteattr_summary',filters}}/>
                </div>
              }
              <div style={{marginTop:"30px"}}>Call Center Summary</div>
              <Table params={{assignment_id,rtype:'ccreport',filters}}/>
            </div>
          }
          {tab=="site data" && 
            <Table params={{assignment_id,rtype:'sitedata',filters}}/>
          }
          {tab=="lead dump" && 
            <Table params={{assignment_id,rtype:'leaddump',filters}}/>
          }
          {tab=="calls" && 
            <Table params={{assignment_id,rtype:'calls',filters}}/>
          }
          {tab=="inquiries testing" && 
            <Table params={{assignment_id,rtype:'inquiries_summary',filters}}/>
          }
          {tab=="lead testing" && 
            <div>
              Latest 30 Leads without delay for integration testing purpose. it also includes duplicate leads
              <Table params={{assignment_id,rtype:'leadtest',filters}}/>
            </div>
          }
          {tab=="graphs" && 
            <div style={{display:'flex',flexWrap:'wrap'}}>
              <div style={{height:300,width:500}}>
                <Graph style={{height:500}} params={{assignment_id,rtype:'calls',filters}}/>
              </div>
              <div style={{height:300,width:500}}>
                <Graph style={{height:500}} params={{assignment_id,rtype:'calls',filters}}/>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default Dashboard