import React, { PureComponent } from 'react'
import {css} from 'react-emotion'

// import { ResponsiveBarCanvas, Bar } from '@nivo/bar'
// import {ResponsiveLine,Line} from '@nivo/line'

import dummydata from '../data'

export class ZoomContainer extends PureComponent {
  state = {}
  render() {
    let {zoom} = this.state
    return (
      <div style={{width:'fit-content',height:'fit-content',userSelect:'none'}} onClick={() => this.setState({zoom:true})}>
        {this.props.children}
      	{zoom &&
          <div onClick={(e) => {
              e.stopPropagation()
              this.setState({zoom:false})
            }} style={{touchAction:'none',transform:'scale(2)',justifyContent:"center",alignItems:'center',display:'flex',position:'fixed',top:0,left:0,width:'100vw',height:'100vh',background:'rgba(0,0,0,0.5)',zIndex:100000}}>
            {this.props.children}
          </div>
      	}
      </div>
    )
  }
}


export class BarChart extends PureComponent {
  state = {}
	
	formatNum = (num) => {
    if(['lv','vb','lq'].indexOf(this.props.column)!=-1){
      return (num||0)+'%'
    }
    return (parseFloat(num) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\..*/,'');
  }
  
  render() {
    let {fill='#1f69ff',size=1,scale=1} = this.props
    let {hovered=-1} = this.state
    let {data} = this.props
    if(!data){
      data=[]
    }
    let max = Math.max(...data.map(o => o.y)) || 10
    data = [...Array(30).fill(0),...data].slice(-30)
    return (
      <div style={{position:'relative'}}>
        <div style={{display:hovered>=0?'block':'none',left:Math.min(hovered*6,30*6*size-60),top:-40,position:'absolute',background:'white',padding:'3px 10px',borderRadius:3,zIndex:10,boxShadow:'0 2px 15px 0 rgba(0,0,0,0.1)'}}>
          <div style={{fontSize:9,whiteSpace:'nowrap'}}>{hovered>=0?data[hovered].x:0}</div>
          <div style={{fontSize:12,whiteSpace:'nowrap'}}>{hovered>=0?this.formatNum(data[hovered].y):0}</div>
        </div>
        <svg style={{width:6*30*size,height:30*size+10,transform:`rotateX(180deg)`,touchAction: scale>1?'none':null}}>
          {
            data.map((o,i) => (
              <g
                onTouchStart = {e => {
                  e.stopPropagation();
                }}
                onTouchMove={e => {
                  if(scale<2) return;
                  e.preventDefault();
                  e.stopPropagation();
                  let x = e.target.getBoundingClientRect().x - scale*parseInt(e.target.getAttribute("x"))
                  let clientX = e.changedTouches[0].clientX
                  this.setState({hovered:Math.max(0,Math.min(data.length-1,parseInt((clientX-x)/(scale*6))))})
                }}
                onTouchEnd={e => {
                  this.setState({hovered:-1})
                }}
                >
                <rect 
                  title={o} x={i*6*size} y={0} width={4*size} height={1+(o.y||0)*30*size/max} style={{fill:hovered==i?'red':fill}}
                />
                <rect 
                  onMouseEnter={(e) => {this.setState({hovered:i})}}
                  onMouseLeave={() => {this.setState({hovered:-1})}}
                  x={i*6*size}
                  y={0}
                  width={6*size}
                  height={30*size}
                  style={{fill:'transparent'}}
                />
              </g>
            ))
          }
        </svg>
      </div>
    )
  }
}

export class MiniInfo extends PureComponent {
  render() {
    let {tooltip=false,columns,data=[],scale=1} = this.props
    return (
      <div className={css`
font-size: 11px;
`}>
        {
          
          data.length && 
        	<div onClick={() => this.props.zoom && this.props.zoom()} style={{position:'relative'}}>
            {!tooltip &&
              <div style={{position:'absolute',width:'100%',height:'100%',left:0,top:0,zIndex:10}}></div> 
            }
            <div style={{marginTop:-10,color:'#6b7785'}}>{columns[0]} - {parseFloat(data.slice(-1)[0][columns[0]] || 0).toFixed(2)}</div>
            <BarChart scale={scale} size={1} fill={'#6b7785'} data={data.slice(-30).map(o => {return {color:'black',x:o['date'],y:Math.round(o[columns[0]]*100||0.0)/100}})}/>
          </div>
        }
      </div>
    )
  }
}

export class Graph extends PureComponent {
  render() {
    let {columns} = this.props
    return (
      <div style={{width:window.innerWidth-300,height:200,position:'relative'}}>
        <ResponsiveLine
        	data={[{id:columns[0],data:dummydata.dailymetrics.map(o => {return {color:'black',x:o['date'],y:o[columns[0]]||0}})}]}
          maxValue="auto"
          minValue="auto"
          pixelRatio={2}
        	padding={0.3}
        	innerPadding={0}
          colors={['#6060ff']}
          axisBottom={{
            "tickSize": 5,
            "tickPadding": 5,
            "tickRotation": 0,
            "legend": "date",
            "legendPosition": "middle",
            "legendOffset": 36,
            "tickRotation": -30
          }}
          yScale={{type: 'linear',stacked: true}}
          axisLeft={{
            "tickSize": 5,
            "tickPadding": 5,
            "tickRotation": 0,
            "legendPosition": "middle",
            "legendOffset": -40,
          }}
          labelSkipWidth={12}
        	labelSkipHeight={12}
          enableLabel={false}
          axisTop={null}
        	axisRight={null}
          margin={{left:60,bottom:10}}
        />
      </div>
    )
  }
}
