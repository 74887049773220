import React, { PureComponent } from 'react'


class ChannelMapping extends PureComponent {
  render() {
    return (
      <div>
        This is ChannelMapping
      </div>
    )
  }
}

export default ChannelMapping