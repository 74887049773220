import { css } from "emotion";
import React, { PureComponent } from "react";
import Announcement from '../static/images/announcement.svg';
import { Button } from "@anarock/pebble";
import Ionicon from 'react-ionicons'

export class LaunchPopup extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    
    render() {
        const {header, subheader, img, lines, onClick, externalLink, externalLinkText } = this.props;
        const style = css`
        width:600px;
        height:500px;
        display:flex;
        flex-direction:column;
        justify-content:space-around;
        align-items:center;
        align-content:center;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 0px 45px;
        position: relative`

        const wrapper = css`
        position: relative;
        overflow: auto;
        z-index: 10000011;
        padding-top: 70px;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        position: fixed;
        background: rgba(0,0,0,.5);
        width: 100vw;
        height: 100vh;`

        return (
            <div className={wrapper} onClick={onClick}>
                <div onClick={(e) => e.stopPropagation()} style={{ height: "fit-content" }}>
                    <div className={`popup-window ${style}`}>
                        <div className={css`position:absolute;top:10px;left:570px;cursor:pointer;`} onClick={onClick}><Ionicon icon="md-close" color="#000"/></div>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <div style={{paddingTop:'28px'}}><img src={Announcement}/></div>
                            <h1 style={{marginLeft:'5px'}}>{header}</h1>
                        </div>
                        {subheader}
                        <img src={img}/>
                        <div style={{alignItems:'flex-start'}}>
                            {lines.map((e) => {
                                return (<div style={{display:'flex'}}><Ionicon icon="md-checkmark" color="#5656ED" fontSize="18px"/><div style={{marginLeft:'10px'}}>{e}</div></div>)
                            })}
                        </div>
                        <Button type="link" onClick={() => window.open( externalLink, "_blank")}>{externalLinkText}</Button>
                        <Button onClick={onClick}>Let's Go!</Button>
                    </div>
                </div>
            </div>
            
        )
    }
}