import React, { PureComponent } from 'react'
import Moment from 'react-moment';
import {css} from 'react-emotion';

import moment from 'moment';
import Ionicon from 'react-ionicons'

import DayPickerInput from 'react-day-picker/DayPickerInput';
import styles from 'react-day-picker/lib/style.css';

let box = {
  background:"#efefef",width:"80px",
  height:"20px",border:"1px solid white",
  display:"flex",justifyContent:"center",
  alignItems:"center",whiteSpace:"nowrap",
  overflow:"hidden",fontSize:"12px"
}


class Mediaplan extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      assignment: this.props.assignment || {},
      plan: {
        overall:{budget:{},leads:{},qleads:{},svisits:{},bookings:{}},
        online:{parent:'overall',budget:{},leads:{},qleads:{},svisits:{},bookings:{}},
        offline:{parent:'overall',budget:{},leads:{},qleads:{},svisits:{},bookings:{}},
        googlesearch:{parent:'online'},
        gdn:{parent:'online'},
        print:{parent:'offline'},
        radio:{parent:'offline'},
      },
      renderplan: {},
      tabs:{},
    }    
  }

  // handleBlur = (e) => {
  //   let value = e.currentTarget.value
  //   this.setState({overall:{budget:10}})
  //   console.log(value)
  // }

  // handleChange = (e) => {
  //   let value = e.currentTarget.value
  //   this.setState({overall:{[e.currentTarget.name]:value}})
  // }

  fill = (rplan,obj,type) => {
    if(!obj[type]) return {price:0,rate:0,count:0}
    let {price,rate,count} = obj[type]
    
    let leads  = rplan["leads"]["count"]
    let budget = rplan["budget"]["count"]
    let qleads = rplan["qleads"]["count"]
    let svisits = rplan["svisits"]["count"]

    if(type=='leads') {
      if(price!=undefined){
        count = (budget/price)
      } else if(count!=undefined) {
        price = (budget/count)
      }
    } else if(type=='qleads') {
      if(price!=undefined){
        count = (budget/price)
        rate = (count*100/leads)
      } else if(count!=undefined){
        price = (budget/count)
        rate = (count*100/leads)
      } else if(rate!=undefined){
        count = (leads*rate/100)
        price = (budget/count)
      }
    } else if(type=='svisits') {
      if(price!=undefined){
        count = (budget/price)
        rate = (count*100/qleads)
      } else if(count!=undefined){
        price = (budget/count)
        rate = (count*100/qleads)
      } else if(rate!=undefined){
        count = (qleads*rate/100)
        price = (budget/count)
      }
    } else if(type=='bookings') {
      if(price!=undefined){
        count = (budget/price)
        rate = count*100/svisits
      } else if(count!=undefined){
        price = budget/count
        rate = count*100/svisits
      } else if(rate!=undefined){
        count = svisits*rate/100
        price = budget/count
      }
    }
    return {price,rate,count}
  }

  componentDidMount() {
    
  }

  
  getRenderplan() {
    let {plan} = this.state
    let renderplan = {}
    for(let name in plan){
      let channel = plan[name]
      renderplan[name] = {budget:{},leads:{},qleads:{},svisits:{},bookings:{}}
      renderplan[name]["budget"] = this.fill(renderplan[name],channel,"budget")
      renderplan[name]["leads"] = this.fill(renderplan[name],channel,"leads")
      renderplan[name]["qleads"] = this.fill(renderplan[name],channel,"qleads")
      renderplan[name]["svisits"] = this.fill(renderplan[name],channel,"svisits")
      renderplan[name]["bookings"] = this.fill(renderplan[name],channel,"bookings")
    }
    console.log(renderplan)
    return renderplan
  }

  getStructure() {
    let {plan} = this.state
    let struct = {}
    for(let k in plan){
      let {parent} = plan[k]
      if(parent && struct[parent]){
        struct[parent].push(k)
      } else if (parent){
        struct[parent] = [k]
      }
    }
    return struct
  }

  handleChange = (e,channel,key1,key2) => {
    let value = parseInt(e.currentTarget.value)
    let {plan} = this.state
    plan[channel][key1] = {[key2]: value}
    this.setState({plan,cb:new Date()})
  }

  
  render() {
    let {tabs,plan} = this.state
    let {budget} = this.state.plan.overall
    let struct = this.getStructure()
    let renderplan = this.getRenderplan()
    return (
      <div>
        <div style={{maxWidth:800}}>
          <RenderChildren  
            {...this.state}
            handleChange = {this.handleChange}
            struct={struct}
            toggleTab = {(name) => {
              let {tabs} = this.state
              tabs[name] = !tabs[name]
              tabs = JSON.parse(JSON.stringify(tabs))
              this.setState({tabs})
            }}
            renderplan = {renderplan}
            name="overall" />
        </div>
      </div>
    )
  }
}

const RenderChildren = (props) => {
  let {struct, name, tabs} = props
  return (
    <div>
      <Detail {...props} />
      {
        tabs[name] && struct[name]?
        <div style={{display:'flex'}}>
          <div style={{marginLeft:2,width:10,background:"#999",borderRadius:3}}></div>
          <div style={{flex:1}}>
            {
              struct[name].map((o) => (
                <RenderChildren {...props} name={o}/>
              ))
            }
          </div>
        </div>
        :null
      }
    </div>
  )
}

const Block = (props) => {
  let {name,type,read,write,handleChange} = props
  let {rate,price,count} = (read[type] || {})

  let block = {
    width:"80%",height:"100%",background:"transparent",border:"none"
  }

  const stopClick = (e) => {
    e.stopPropagation()
  }
  
  return (
    <div onClick={stopClick} style={{margin:3,padding:"5px 15px",background:'#efefef',borderRadius:3}}>
      <div style={{fontSize:10,color:'#999'}}>{type}</div>
      <div style={{fontSize:16,width:80}}>
        <input style={block} value={count?count.toFixed(0):''} onChange={(e) => handleChange(e,name,type,"count")} />
      </div>
      <div style={{display:'flex',fontSize:10,justifyContent:'space-between',height:12}}>
        <input style={block} value={rate?rate.toFixed(0):''} onChange={(e) => handleChange(e,name,type,"rate")} />
        {false &&
          <div>
            <div>{rate!=undefined?`${rate}%`:''}</div>
            <div>{price!=undefined?`Rs.${price}`:''}</div>
          </div>
        }
        <input style={block} value={price?price.toFixed(0):''} onChange={(e) => handleChange(e,name,type,"price")}/>
      </div>
    </div>
  )
}

const Detail = (props) => {
  // {budget, pbudget, leads, cpl, qleads,cpql,qrate}
  let {name,toggleTab,renderplan,plan} = props
  return(
    <div style={{cursor:'pointer',display:'flex',alignItems:'center',padding:4,borderRadius:3,margin:2,background:'#ccc'}}
      onClick={() => toggleTab(name)}
      >
      <div style={{flex:1,textAlign:'center',fontSize:18,textTransform:'uppercase'}}>
        {name}
      </div>
      <Block {...props} write={plan[name]} read={renderplan[name]} type="budget"/>
      <Block {...props} write={plan[name]} read={renderplan[name]} type="leads"/>
      <Block {...props} write={plan[name]} read={renderplan[name]} type="qleads"/>
      <Block {...props} write={plan[name]} read={renderplan[name]} type="svisits"/>
      <Block {...props} write={plan[name]} read={renderplan[name]} type="bookings"/>
    </div>
  )
}


const DateRange = ({key1,key2,handleDayClick,getValue,single,readonly}) => {
  const getDuration = () => {
    let v1 = getValue(key1)
    let v2 = getValue(key2)
    if (v1 && v2){
      let days = parseInt((new Date(v2) - new Date(v1))/86400000)
      return `${parseInt(days/30)} mons ${parseInt(days%30)} days`
    }
    return 'select dates'
  }
  
  const closestyle = css`cursor:pointer;right:0px;position:absolute; :hover{fill:#eb7785;}`;

  return (
    <div style={{width:'fit-content'}} className="range">
      <style dangerouslySetInnerHTML={{ __html: styles }} />
      <div style={{display:'flex',pointerEvents:readonly?'none':'default'}}>
        <div style={{position:'relative'}}>
          <DayPickerInput
            value={getValue(key1)?new Date(getValue(key1)):''}
            placeholder="dd mmm"
            formatDate = {(o) => {
                let [m,d,y] = o.toString().split(" ").slice(1,4)
                return `${d} ${m}`
              }}
            format="dd mmm"
            onDayChange={(day) => handleDayClick(key1,day)}
            />
          {!readonly && getValue(key1) && <Ionicon onClick={() => handleDayClick(key1,null)} className={closestyle} icon="md-close" fontSize="12px" color="#ccc"/>}
        </div>
        <span>&nbsp;</span>
        <div style={{position:'relative'}}>
          <DayPickerInput 
            value={getValue(key2)?new Date(getValue(key2)):''}
            placeholder="dd mmm"
            formatDate = {(o) => {
                let [m,d,y] = o.toString().split(" ").slice(1,4)
                return `${d} ${m}`
              }}
            format="dd mmm"
            onDayChange={(day) => handleDayClick(key2,day)}
            />
          {!readonly && getValue(key2) && <Ionicon onClick={() => handleDayClick(key2,null)} className={closestyle} icon="md-close" fontSize="12px" color="#ccc"/>}
        </div>
      </div>
      {!single &&
        <div className="duration" style={{minWidth:100}}>
          {getDuration()}
        </div>
      }
    </div>
  )
}

class Tooltip extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      show: false
    }
  }
  render() {
    let {show} = this.state
    let {text,width,size} = this.props
    return (
      <div style={{position:"relative",textAlign:"left",alignItems:"center",display:"flex"}}>
        <div
          onMouseEnter={() => this.setState({show:true})} onMouseLeave={() => this.setState({show:false})} 
          style={{lineHeight:(size?size:15)+'px',cursor:"pointer",borderRadius:15,width:size?size:14,height:size?size:14,display:"flex",justifyContent:"center",alignItems:"center"}}
          >
          <Ionicon icon="md-information-circle" fontSize={size?size:15} color={show?"#000":"#ccc"} />
        </div>
        <div style={{wordBreak:'break-word',boxShadow:"0px 0px 3px 0px rgba(0,0,0,0.75)",fontSize:12,display:show?"block":"none",width:width?width:200,borderRadius:3,padding:10,zIndex:1000,position:"absolute",transform:"translateY(50%)",bottom:20,left:20,background:"#000",color:"#fff"}}>
          {
            text.split("\n").map((o) => (
              <p>{o.trim()}</p>
            ))
          }
        </div>
      </div>
    )
  }
}

const Budgetlog = (props) => {
  let {value,handleChange,edit} = props
  let blog = value || []
  
  let item = {width:100,display:"flex",justifyContent:"center"}
  return (
    <div style={{border:'1px solid #efefef',padding:10,marginTop:10}}>
      <div style={{display:"flex",alignItems:"center",border:"1px solid #efefef",padding:5,marginTop:5,position:'relative',fontWeight:"bold"}}>
        <div style={item}>Date</div>
        <div style={item}>Type</div>
        <div style={{...item,width:250}}>Date Range</div>
        <div style={item}>Amount</div>
        <div style={{width:200}}>Comment</div>
      </div>
      {
        blog.length==0?
        <div style={{padding:10,fontWeight:100,color:'#666'}}>Please add all the planned and released budgets for a duration</div>
        :null
      }
      {
        blog.map((o,index) => (
          <div style={{display:"flex",alignItems:"center",border:"1px solid #efefef",padding:5,marginTop:5,position:'relative'}}>
            <div style={{...item,fontSize:12,alignItems:"center",flexDirection:"column"}}>
              <div><Moment format="YYYY/MM/DD" date={new Date(o.created_at)} /></div>
              {
                (o.updated_at && o.created_at && (new Date(o.updated_at))-(new Date(o.created_at)))>3600000?
                <div style={{fontSize:10,color:"#666"}}>
                  edited:
                  <Moment format="YYYY/MM/DD" date={new Date(o.updated_at)} />
                </div>
                :null
              }
            </div>
            <div style={item}>
              <select value={o.type} onChange={(e) => {
                  o.type = e.currentTarget.value
                  o.updated_at = (new Date()).toISOString()
                  handleChange(blog)
                }}>
                {
                  ["","planned","released"].map((o) => <option value={o}>{o}</option>)
                }
              </select>
            </div>
            <div style={{...item,width:250}}>
              <DateRange key1="start_date" key2="end_date" 
              handleDayClick={(k,v) => 
                    {
                      o[k] = v.toISOString().split("T")[0]
                      o.updated_at = (new Date()).toISOString()
                      handleChange(blog)
                    }}
              getValue={(k) => o[k]}  />
            </div>
            <div style={item}>
              <input value={o.amount} onChange={(e) => {
                  o.amount = parseInt(e.currentTarget.value) || null
                  o.updated_at = (new Date()).toISOString()
                  handleChange(blog)
                }}
                type="number" 
                style={{width:"80%",padding:5}}
                placeholder="amount"
                />
            </div>
            <div style={{width:200}}>
              <textarea style={{minWidth:200,minHeight:50,background:"none",amount:0}} value={o.comment} onChange={(e) => {
                  o.comment = e.currentTarget.value
                  o.updated_at = (new Date()).toISOString()
                  handleChange(blog)
                }} />
            </div>
            <div>
              <Ionicon 
                style={{cursor:"pointer",position:"absolute",top:5,right:5}}
                onClick={() => {
                  handleChange(blog.filter((_,i) => i!=index))
                }}
                icon="md-close" fontSize="16px" color="#ccc"/>
            </div>
          </div>
        ))
      }
      {edit?
        <div style={{padding:10}}>
          <div className="btn" onClick={() => {blog.push({created_at:(new Date()).toISOString(),updated_at:new Date(),start_date:'',end_date:'',comment:''});handleChange(blog)}}>Add</div>
        </div>
        :null
      }
    </div>
  )
}

class Budgetplan extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {

    }
  }
  
  formatDate = (o) => {
    let [m,d,y] = o.toString().split(" ").slice(1,4)
    return `${d} ${m}`
  }

  getSpends = (date) => {
    return 1000
  }

  getDays = (plan,date,index) => {
    const {end_date,start_date} = this.props.assignment
    return parseInt((new Date(date) - new Date(index==0?start_date:plan[index-1].date))/86400000)
  }

  getTable = (plan) => {
    let {assignment} = this.props
    const total_budget = (assignment.meta || {}).digital_budget || 0
    const total_spent  = 220000
    
    let remaining = {budget_planned:total_budget,budget_spent:total_spent,days:this.getDays(plan,this.props.assignment.end_date,0)}
    plan = plan.map((o,_i) => {
      let {date,amount} = o
      let budget_spent = this.getSpends(date)
      let days = this.getDays(plan,date,_i)
      remaining['budget_planned'] -= amount || 0
      remaining['budget_spent'] -= budget_spent || 0
      remaining['days'] -= days || 0
      return {
        date,budget_planned:amount,budget_spent,days,
        daily_budget_spent:parseInt(budget_spent/days) || '',daily_budget_planned:parseInt(amount/days) || ''
      }
    })
    remaining['daily_budget_planned'] = parseInt(remaining['budget_planned']/remaining['days']) || ''
    remaining['daily_budget_spent']   = parseInt(remaining['budget_spent']/remaining['days']) || ''
    return {remaining,plan}
  }

  handleDayChange = (day,index) => {
    if(this.props.readOnly)return
    let {meta} = this.props.assignment
    let plan = meta.budgetplan || []
    let pts = day.toLocaleDateString("en-GB").split(",")[0].split("/")  
    plan[index]["date"] = `${pts[2]}-${pts[1]}-${pts[0]}`
    this.props.setPlan(JSON.parse(JSON.stringify(plan)))
  }

  insertBefore = (index) => {
    if(this.props.readOnly)return
    let {end_date,meta} = this.props.assignment
    let plan = meta.budgetplan || []
    let toinsert = {date:(plan[index]||{date:end_date})['date'],amount:0}
    
    plan = [...plan.slice(0,index),toinsert,...plan.slice(index,1000)]

    this.props.setPlan(JSON.parse(JSON.stringify(plan)))
  }
  removeBefore = (index) => {
    if(this.props.readOnly)return
    let {meta} = this.props.assignment
    let plan = meta.budgetplan || []
    
    plan = JSON.parse(JSON.stringify(plan.filter((_,i) => i!=index)))
    this.props.setPlan(plan)
  }

  handleBudgetChange = (e,index) => {
    if(this.props.readOnly)return
    let {meta} = this.props.assignment
    let plan = meta.budgetplan || []
    plan[index]['amount'] = parseInt(e.currentTarget.value) || 0
    this.props.setPlan(JSON.parse(JSON.stringify(plan)))
  }

  formatNumber = (num) => {
    if(num >= 10000000) {
      return `${(num/10000000).toFixed(2)} Cr`.replace('.00','')
    } else if(num >= 100000){
      return `${(num/100000).toFixed(2)} L`.replace('.00','')
    } else {
      return parseFloat(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00','')
    }
  }
  
  render() {
    const label = {width:150,overflow:'hidden',fontSize:12,height:30,display:'flex',alignItems:'center'}
    const metric = {opacity:1,border:'none',padding:5,width:60,height:30,fontSize:12,alignItems:'center',display:'flex',transition:'all ease .3s'}

    let {add,remove} = this.state
    let {assignment,readOnly} = this.props
    
    let {start_date,end_date} = assignment

    let {budgetplan=[]} = assignment.meta || {}

    let {remaining,plan} = this.getTable(budgetplan)

    let styleTransform = {}
    if(add!=-1){
      styleTransform[add] = {borderLeft:"60px solid #efefef",width:120}
    }
    if(remove!=-1){
      styleTransform[remove] = {opacity:.3}
    }

    return (
      <div className="budgetplan">
        {
          !readOnly &&
          <div style={{paddingLeft:150,display:'flex',paddingBottom:20}}>
            {
              plan.map((o,_i) => (
                <div style={{display:'flex',width:60}}>
                  <div style={{cursor:'pointer'}} onMouseEnter={() => this.setState({add:_i})} onMouseLeave={() => this.setState({add:-1})}>
                    <Ionicon  icon="md-add-circle" color='#000' onClick={() => this.insertBefore(_i)}/>
                  </div>
                  <div style={{cursor:'pointer'}} onMouseEnter={() => this.setState({remove:_i})} onMouseLeave={() => this.setState({remove:-1})}>
                    <Ionicon icon="md-remove-circle" color='#000' onClick={() => this.removeBefore(_i)}/>
                  </div>  
                </div>
              ))
            }
            <div style={{display:'flex'}}>
              <div style={{cursor:'pointer'}} onMouseEnter={() => this.setState({add:"last"})} onMouseLeave={() => this.setState({add:-1})}>
                <Ionicon  icon="md-add-circle" color='#000' onClick={() => this.insertBefore(1000)}/>
              </div>
            </div>
          </div>
        }
        <div style={{display:'flex',alignItems:'center'}}>
          <div style={label}>Date ({this.formatDate(new Date(start_date))})</div>
          {
            plan.map((o,_i) => (
              <div style={{...metric,position:'relative',padding:0,...(styleTransform[_i]||{}),overflow:readOnly?'hidden':null}} key={_i}>
                <DayPickerInput 
                  formatDate = {this.formatDate}
                  placeholder="dd mm"
                  value={new Date(o.date)}
                  dayPickerProps={{
                    modifiers: {sel:new Date(o.date)},
                    disabledDays:{before:_i==0?new Date(start_date):new Date(plan[_i-1].date),after:_i==plan.length-1?new Date(end_date):new Date(plan[_i+1].date)}
                  }}
                  onDayChange={(date) => this.handleDayChange(date,_i)}
                  />
              </div>
            ))
          }
          <div style={{...metric,...(styleTransform["last"]||{})}}>
            {this.formatDate(new Date(end_date))}
          </div>
        </div>
        <div style={{display:'flex'}}>
          <div style={label}>Budget Planned</div>
          {
            plan.map((o,_i) => (
              <div style={{...metric,padding:0,...(styleTransform[_i]||{})}}>
                <input class="numinput" readOnly={readOnly} value={o.budget_planned} onChange={(e) => this.handleBudgetChange(e,_i)}/>
                <div>{this.formatNumber(o.budget_planned)}</div>
              </div>
            ))  
          }
          <div style={{...metric,...(styleTransform["last"]||{})}}>{this.formatNumber(remaining.budget_planned)}</div>
        </div>
        {false &&
          <div style={{display:'flex'}}>
            <div style={label}>Budget Spent</div>
            {
              plan.map((o,_i) => (
                <div style={{...metric,...(styleTransform[_i]||{})}}>{this.formatNumber(o.budget_spent)}</div>
              ))  
            }
            <div style={{...metric,...(styleTransform["last"]||{})}}>{this.formatNumber(remaining.budget_spent)}</div>
          </div>
        }
        <div style={{display:'flex'}}>
          <div style={label}>Days</div>
          {
            plan.map((o,_i) => (
              <div style={{...metric,...(styleTransform[_i]||{})}}>{o.days}</div>
            ))  
          }
          <div style={{...metric,...(styleTransform["last"]||{})}}>{remaining.days}</div>
        </div>
        <div style={{display:'flex'}}>
          <div style={label}>Daily Budget Planned</div>
          {
            plan.map((o,_i) => (
              <div style={{...metric,...(styleTransform[_i]||{})}}>{o.daily_budget_planned}</div>    
            ))  
          }
          <div style={{...metric,...(styleTransform["last"]||{})}}>{remaining.daily_budget_planned}</div>
        </div>
        {false &&
          <div style={{display:'flex'}}>
            <div style={label}>Daily Budget Spent</div>
            {
              plan.map((o,_i) => (
                <div style={{...metric,...(styleTransform[_i]||{})}}>{o.daily_budget_spent}</div>
              ))
            }
            <div style={{...metric,...(styleTransform["last"]||{})}}>{remaining.daily_budget_spent}</div>
          </div>
        }
      </div>
    )
  }
}

class SpendsConfig extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {

    }
  }
  render() {
    return (
      <div style={{display:'flex'}}>
        <select>
          <option>googlesearch</option>
          <option>googlegsp</option>
          <option>googlegdn</option>
          <option>facebook</option>
        </select>
        <select>
          <option>adwords</option>
          <option>facebook</option>
          <option>cpql</option>
          <option>cpsv</option>
        </select>
        {true?
        <div style={{display:'flex'}}>
          <div style={{display:'flex'}}>
            <div>contains</div>
            <div><input /></div>
          </div>
          <div style={{display:'flex'}}>
            <div>dosn't</div>
            <div><input /></div>
          </div>
        </div>:
        <div>
          <div>cost</div>
          <div><input /></div>
        </div>
        }
      </div>
    )
  }
}

class Loading extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {

    }
  }
  render() {
    let {loading} = this.props
    return (
      <div>
      {
        loading?
        <div style={{zIndex:100,color:'#fff',fontSize:32,display:'flex',justifyContent:'center',alignItems:'center',minHeight:100,minWidth:100,position:"absolute",width:"100%",height:"100%",left:"0px",top:"0px",background:"rgba(0,0,0,0.6)"}}>
          Loading...
        </div>:
        null
      }
      </div>
    )
  }
}


export {Mediaplan,DateRange,Budgetlog,Tooltip,Budgetplan,Loading,SpendsConfig}