import { mixins, Toast} from "@anarock/pebble";
import FacebookIcon from "@material-ui/icons/Facebook";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import { css, cx } from "emotion";
import debounce from "just-debounce-it";
import React, { Component, PureComponent } from "react";
import "semantic-ui-css/semantic.min.css";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  Modal,
  Radio,
  Table,
} from "semantic-ui-react";
import { TrendingUp } from "@material-ui/icons";
import { text } from "d3-fetch";
import AddFbPageModal from "./components/fb-form-utils";
import { Tooltip } from "pebble-web";
import { FbLeadFormsTable } from "./components/fb-lead-forms/fb-lead-forms-table";
const DEFAULT_MAPPINGS = {
  email: "email",
  full_name: "name",
  phone_number: "phone",
  gender: "gender",
  job_title: "occupation",
  work_phone_number: "alternate_number",
  company_name: "industry",
};

class FieldHeading extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { title, spellIcon, description, expected, wrapStyle } = this.props;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          ...wrapStyle,
        }}
      >
        {spellIcon ? (
          <SpellcheckIcon
            style={{ fontSize: "14px", padding: "0px", margin: "0px" }}
          />
        ) : (
          <FacebookIcon style={{ margin: "8px 0px" }} />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ marginLeft: "4px", fontWeight: "bold" }}>{title}</div>
          {expected && (
            <div style={{ color: "gray", fontSize: "12px" }} title={expected}>
              Expected values - {expected}
            </div>
          )}
        </div>
        {description && (
          <div
            style={{
              color: "gray",
              fontSize: "12px",
              marginLeft: "30px",
              minWidth: "200px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "200px",
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </div>
        )}
      </div>
    );
  }
}

export default class Leadgen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showfbPageFormModal: false,
      fbPageLoading: false,
      pages: [],
      forms: [],
      campaigns: [],
      items: [],
      item: {},
      leads: [],
      sources: [],
      pageloading: false,
      formloading: false,
      addloading: false,
      leadloading: false,
      itemsloading: false,
      showleads: false,
      formQuestions: [],
      leadSupportedQuestions: [],
      mappings: {},
      developerProjects: [],
      integrationType: "integration_key",
      extraDetails: [],
      actualSources: [],
      actualSourcesLoading: false,
      formQuestionsError: "",
      formQuestionsBlank: false,
      mappingErrors: false,
      formQuestionsValue: "",
      pageValidation: false,
      leadValidation: false,
      fQValidation: false,
      fQPhoneValidation: false,
      integrationKValidation: false,
      sourceValidation: false,
      subSouceValidation: false,
      projectValidation: false,
      projectFQValidation: false,
      extraDetailsValidation: false,
      blankFormValid: false,
      formValid: false,
      formError: false,
      errorKey: false,
      projectMapping: false,
      isChanged: false,
      projectMappingValidation: false,
      phoneNumberValidation: true,
    };
  }
  addFBPage = (pageId) => {
    let self = this;
    const { item, items, fbPageLoading } = this.state;
    self.setState({ fbPageLoading: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=fb_pages_assign&assignment_id=${this.props.assignment.assignment_id}&page_id=${pageId}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          Toast.show("Added Facebook page successfully", "success");
        } else {
          Toast.show(data.message, "error");
        }
        self.setState({ fbPageLoading: false, showfbPageFormModal: false });
      })
      .catch((e) => {
        self.setState({ fbPageLoading: false, showfbPageFormModal: false });
        Toast.show("Unable to Add Facebook page", "error");
      });
  };
  getFormQuestions = (selectedItem) => {
    let self = this;
    const { item, items } = this.state;
    this.setState({ formloading: true });
    fetch(
      `${
        window._DIGIXDOMAIN
      }/api/v0/integrate_lead_form?rtype=form_questions&page_id=${
        selectedItem ? selectedItem.page_id : item.page_id
      }&form_id=${selectedItem ? selectedItem.form_id : item.form_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          const formQuestions = data.data;
          const mappings = {
            ...((item && item.mappings) || {}),
          };
          if (formQuestions) {
            formQuestions.map((question) => {
              mappings[question.key] =
                (item && item.mappings && item.mappings[question.key]) ||
                DEFAULT_MAPPINGS[question.key.toLowerCase()] ||
                undefined;
            });
          }
          self.setState({
            formQuestions: data.data,
            mappings: mappings,
            formloading: false,
            integrationType:
              Object.keys(mappings).length != 0 && item["campaign_key"] === null
                ? "form_project"
                : "integration_key",
            // mappings.project_mappings
            //   ? "form_project"
            //   : "integration_key",
            formQuestionsError: "",
          });
        }
      })
      .catch(() => {
        this.setState({ formloading: false });
      });
  };

  getSupportedLeadColumns = () => {
    let self = this;
    const { item } = this.state;
    this.setState({ formloading: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=supported_lead_columns&page_id=${item.page_id}&form_id=${item.form_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          self.setState({
            leadSupportedQuestions: data.data,
            formloading: false,
          });
        }
      })
      .catch(() => {
        this.setState({ formloading: false });
      });
  };

  getPages = () => {
    let self = this;
    this.setState({ pageloading: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=pages&assignment_id=${this.props.assignment.assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          let pages = data.data.map(({ page_id, name }) => {
            return { key: page_id, text: name, value: page_id };
          });
          self.setState({ pages });
        }
        this.setState({ pageloading: false });
      })
      .catch((e) => {
        this.setState({ pageloading: false });
      });
  };

  getCampaigns = () => {
    let self = this;
    this.setState({ campaignloading: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=campaigns&assignment_id=${this.props.assignment.assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          let campaigns = data.data.map(({ campaign_id }) => {
            return { key: campaign_id, text: campaign_id, value: campaign_id };
          });
          self.setState({ campaigns });
        }
        this.setState({ campaignloading: false });
      })
      .catch(() => {
        this.setState({ campaignloading: false });
      });
  };

  getItems = () => {
    let self = this;
    this.setState({ itemsloading: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=items&assignment_id=${this.props.assignment.assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          self.setState({ items: data.data });
        }
        this.setState({ itemsloading: false });
      })
      .catch(() => {
        this.setState({ itemsloading: false });
      });
  };

  getLeads = () => {
    let self = this;
    let { item } = this.state;
    this.setState({ leads: [], leadloading: true, showLeads: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=leads&assignment_id=${this.props.assignment.assignment_id}&page_id=${item.page_id}&form_id=${item.form_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          let leads = data.data;
          self.setState({ leads });
        }
        this.setState({ leadloading: false });
      })
      .catch(() => {
        this.setState({ leadloading: false });
      });
  };

  getForms = (rtype = "forms") => {
    let self = this;
    let { item } = this.state;
    this.setState({ formloading: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=${rtype}&page_id=${item.page_id}&assignment_id=${this.props.assignment.assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          let forms = data.data.map(({ form_id, form_name }) => {
            return { key: form_id, text: form_name, value: form_id };
          });
          self.setState({ forms: forms });
        }
        this.setState({ formloading: false });
      })
      .catch(() => {
        this.setState({ formloading: false });
      });
  };

  getSources = (rtype = "sources") => {
    let self = this;
    let { item } = this.state;
    this.setState({ sourcesloading: true });
    fetch(`${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=${rtype}`, {
      method: "get",
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          self.setState({ sources: data.data });
        }
        this.setState({ sourcesloading: false });
      })
      .catch(() => {
        this.setState({ sourcesloading: false });
      });
  };
  getActualSource = () => {
    let self = this;
    this.setState({ actualSourcesLoading: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=actual_sources`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          let actualSources = data.data;
          self.setState({ actualSources });
        }
        this.setState({ actualSourcesLoading: false });
      })
      .catch(() => {
        this.setState({ actualSourcesLoading: false });
      });
  };

  handlePageValidation = () => {
    let { item } = this.state;
    if (!item.page_id || !item.page_name) {
      return true;
    } else {
      return false;
    }
  };

  handleFormValidation = () => {
    let { item } = this.state;
    if (item && (!item.form_id || !item.form_name)) {
      return true;
    } else {
      return false;
    }
  };

  handleFormQuestionValidation = () => {
    let { formQuestionsBlank, formQuestionsError } = this.state;
    if (formQuestionsError) {
      return true;
    } else {
      return false;
    }
  };

  handleFQPhoneValidation = () => {
    let { errorKey } = this.state;
    if (errorKey) {
      return true;
    } else {
      return false;
    }
  };

  handleIntegrationKeyValidation = () => {
    let { integrationType, item } = this.state;
    this.setState({
      projectValidation: false,
    });
    if ((integrationType = "integration_key" && !item.campaign_key)) {
      return true;
    } else {
      return false;
    }
  };

  handleSourceValidation = () => {
    let { item } = this.state;
    if (!item.actual_source) {
      return true;
    } else {
      return false;
    }
  };

  handleSubSourceValidation = () => {
    let { item } = this.state;
    if (!item.source) {
      return true;
    } else {
      return false;
    }
  };

  handleProjectValidation = () => {
    let { mappings, integrationType } = this.state;
    this.setState({
      projectFQValidation: false,
      integrationKValidation: false,
      projectMappingValidation: false,
    });
    if (
      integrationType == "form_project" &&
      ((!mappings &&
        !mappings.project_mapping_question &
          !mappings.project_mapping_question.key) ||
        (mappings &&
          mappings.project_mapping_question &&
          mappings.project_mapping_question.key) == undefined)
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleProjectFQValidation = () => {
    let { mappingErrors } = this.state;
    this.setState({
      projectValidation: false,
    });
    if (mappingErrors) {
      return true;
    } else {
      return false;
    }
  };

  handleExtraDetailsValidation = () => {
    let { extraDetails } = this.state;
    var keys = extraDetails.map((e) => e.key);
    var values = extraDetails.map((e) => e.value);
    if (keys.includes("") || values.includes("")) {
      return true;
    } else {
      return false;
    }
  };

  handleProjectMappingValidation = () => {
    let { mappings, developerProjects, integrationType } = this.state;
    if (integrationType === "form_project") {
      const options = Object.values(mappings && mappings.project_mappings);
      const projectKeys = developerProjects.map((proj) => proj.id);
      return options.every((option) => projectKeys.includes(option));
    }
    return true;
  };

  handlePhoneNumberValidation = () => {
    let { mappings, formQuestions } = this.state;
    return (
      mappings &&
      Object.values(mappings).includes("phone") &&
      formQuestions &&
      formQuestions.length > 0
    );
  };
  saveForm = () => {
    let self = this;
    let { item, mappings, items, integrationType } = this.state;
    if (integrationType === "form_project") {
      item["campaign_key"] = null;
    }
    item["extra_details"] = {};
    this.state.extraDetails.map((i) => {
      if (i.key.length > 0 && i.value.length > 0) {
        item["extra_details"][i.key] = i.value;
      }
    });
    this.setState({ addloading: true });
    console.log("save form");
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=save_item&assignment_id=${this.props.assignment.assignment_id}`,
      {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          console.log("200 status");
          self.getItems();
          Toast.show("Form updated successfully");
        } else {
          self.setState({ formError: true });
          Toast.show("Form couldn't be updated", "error");
        }
        self.setState({ addloading: false });
      })
      .catch((e) => {
        self.setState({ addloading: false });
        console.log("er", e);
        Toast.show("Form couldn't be updated", "error");
      });
  };

  getRemainingForms = (form_id) => {
    let existing = {};
    this.state.items.map((o) => (existing[o.form_id] = 1));
    return this.state.forms.filter(
      (o) => form_id == o.value || !existing[o.value]
    );
  };

  syncForms = () => {
    this.getForms("sync_forms");
    this.getFormQuestions();
    this.getSupportedLeadColumns();
    this.getProjectDevelopers();
  };

  handlePageChange = (e, { value, options }) => {
    let { item } = this.state;
    item["page_id"] = value;
    item["page_name"] = options.filter((o) => o.value == value)[0]["text"];
    item["form_id"] = "";
    item["form_name"] = "";
    this.getForms();
    this.setState({
      item: JSON.parse(JSON.stringify(item)),
      mappings: item.mappings,
    });
  };

  handleFormChange = (e, { value, options }) => {
    let { item } = this.state;
    var prevPageName = item.page_name;
    var prevFormName = item.form_name;
    item["form_id"] = value;
    item["form_name"] = options.filter((o) => o.value == value)[0]["text"];
    var pageName = item.page_name;
    var formName = item.form_name;
    this.setState(
      {
        item: JSON.parse(JSON.stringify(item)),
        mappings: item.mappings,
        isChanged: prevPageName !== pageName || prevFormName !== formName,
        fQValidation: false,
        fQPhoneValidation: false,
        integrationKValidation: false,
        sourceValidation: false,
        subSouceValidation: false,
        projectValidation: false,
        projectFQValidation: false,
        extraDetailsValidation: false,
        blankFormValid: false,
        projectMappingValidation: false,
        extraDetails: [],
      },
      () => {
        this.getFormQuestions(item);
      }
    );
  };

  handleCampaignChange = (e, { value }) => {
    let { item } = this.state;
    item["campaign_key"] = value;
    this.setState({ item: JSON.parse(JSON.stringify(item)) });
  };

  handleSourceChange = (e, { value }) => {
    let { item } = this.state;
    item["source"] = value;
    this.setState({ item: JSON.parse(JSON.stringify(item)) });
  };

  // handleSourceChange = (e, {value}) => {
  //   let {item} = this.state
  //   item["source"] = value
  //   this.setState({item:JSON.parse(JSON.stringify(item))})
  // }
  handleActualSourceChange = (e, { value }) => {
    let { item } = this.state;
    item["actual_source"] = value;
    this.setState({ item: JSON.parse(JSON.stringify(item)) });
  };

  handleStatusChange = (item) => {
    item["status"] = item.status == "on" ? "off" : "on";
    this.setState({ item: JSON.parse(JSON.stringify(item)) });
    setTimeout(this.saveForm);
  };

  handleFormEdit = (o) => {
    let temp = [];
    o &&
      o.extra_details &&
      Object.keys(o.extra_details).map((i) => {
        temp.push({ key: i, value: o.extra_details[i] });
      });
    this.setState({
      item: {
        ...JSON.parse(JSON.stringify(o)),
        actual_source: "facebook",
      },
      phoneNumberValidation: true,
      modalOpen: true,
      formError: false,
      pageValidation: false,
      isChanged: false,
      leadValidation: false,
      fQValidation: false,
      fQPhoneValidation: false,
      integrationKValidation: false,
      sourceValidation: false,
      subSouceValidation: false,
      projectValidation: false,
      projectFQValidation: false,
      extraDetailsValidation: false,
      blankFormValid: false,
      formValid: false,
      projectMappingValidation: false,
      extraDetails: temp,
      forms: [
        {
          key: o.form_id,
          value: o.form_id,
          text: o.form_name,
        },
      ],
      pages: [
        {
          key: o.page_id,
          value: o.page_id,
          text: o.page_name,
        },
      ],
    });
    setTimeout(() => {
      this.getCampaigns();
      this.getPages();
      this.getForms();
      this.getSources();
      this.getActualSource();
      this.getFormQuestions();
      this.getSupportedLeadColumns();
      this.getProjectDevelopers();
    });
  };

  handleViewLeads = (o) => {
    this.setState({ item: JSON.parse(JSON.stringify(o)) });
    setTimeout(() => {
      this.getLeads();
    });
  }
  componentDidMount() {
    setTimeout(this.getItems);
  }
  getError = () => {
    const { mappings, leadSupportedQuestions } = this.state;
    const questionMappings = {
      ...mappings,
      project_mapping_question: undefined,
      project_mappings: undefined,
    };
    const valuetypes = {};
    let erroredKey = undefined;
    Object.values(questionMappings || {})
      .filter((value) => !!value && value !== "not_used" && value !== "others")
      .map((value) => {
        if (valuetypes[value] && this.state.formQuestionsError) {
          erroredKey = leadSupportedQuestions.find(
            (question) => question.key === this.state.formQuestionsError
          ).display_name;
        } else {
          valuetypes[value] = 1;
        }
      });
    var error = [undefined, undefined];
    error[0] = erroredKey;
    error[1] =
      mappings && mappings["phone_number"] && !valuetypes["phone"] && "Phone";
    this.setState({
      errorKey: error[1],
    });
    return error;
  };

  getProjectDevelopers = () => {
    let self = this;
    this.setState({ developerProjectsLoading: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=developer_projects&assignment_id=${this.props.assignment.assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          let developerProjects = data.data;
          self.setState({ developerProjects });
        }
        this.setState({ developerProjectsLoading: false });
      })
      .catch(() => {
        this.setState({ developerProjectsLoading: false });
      });
  };
  errorDetailsFunc = () => {
    let flag = false;
    if (!this.state.extraDetails) return false;
    this.state.extraDetails.map((i) => {
      if (
        !flag &&
        ((i.key.length != 0 && i.value.length == 0) ||
          (i.key.length == 0 && i.value.length != 0))
      ) {
        flag = true;
      }
    });
    return flag;
  };
  checkBlank = () => {
    const {
      item,
      mappings,
      integrationType,
      mappingErrors,
      formQuestionsBlank,
      projectFQValidation,
      projectValidation,
    } = this.state;
    if (
      !item.page_name ||
      !item.page_id ||
      !item.form_name ||
      !item.form_id ||
      !item.actual_source ||
      !item.source
    ) {
      return true;
    }
    if (integrationType === "form_project") {
      var pValidation =
        !mappings ||
        !mappings.project_mapping_question ||
        !mappings.project_mapping_question.key;
      var pFValidation = projectValidation == false && mappingErrors;
      if (pValidation || pFValidation) {
        return true;
      } else {
        return false;
      }
    }
    //  let flag = false;
    // if (!this.state.mappings || !this.state.mappings.project_mappings) {
    //   return false;
    // }
    // let project_mappings = Object.keys(this.state.mappings.project_mappings);
    // return !(
    //   project_mappings.length ===
    //   this.state.mappings.project_mapping_question.options.length
    // );
  };

  checkIntegrationKey = () => {
    if (!this.state.item.campaign_key) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    let {
      sources,
      pages,
      forms,
      campaigns,
      pageloading,
      sourcesloading,
      formloading,
      addloading,
      leadloading,
      campaignloading,
      projectMappingValidation,
      itemsloading,
      item,
      items,
      leads,
      modalOpen,
      formError,
      showLeads,
      developerProjectsLoading,
      developerProjects,
      mappings,
      integrationType,
      formQuestions,
      leadSupportedQuestions,
      extraDetails,
      actualSources,
      actualSourcesLoading,
      showfbPageFormModal,
      fbPageLoading,
    } = this.state;
    const { assignment } = this.props;
    sources = sources.map((o) => {
      return { key: o, text: o, value: o };
    });
    actualSources = actualSources.map((o) => {
      return { key: o, text: o, value: o };
    });
    // let sources = []
    // 'ri_county,ri_abhiman,ri_courtyard,ri_reana,ri_town,ri,gcc,ger,sin,dub,uk,us,cct'.split(',').map(c=>{
    //   'leadgen'.split(',').map(t=>{
    //     'video,'.split(',').map(k=>{
    //       let o = k+t+'_'+c
    //       sources.push({key:o,text:o,value:o})
    //     })
    //   })
    // })
    const errorKey = this.getError();
    const errorDetails = this.errorDetailsFunc();
    const errorBlank = this.checkBlank();

    console.log(
      this.state.blankFormValid,
      errorBlank,
      projectMappingValidation
    );
    const isMultipleProjects =
      assignment &&
      assignment.extra_details &&
      assignment.extra_details.array_agg &&
      assignment.extra_details.array_agg.length > 1;
    const integrationError = this.checkIntegrationKey();
    return (
      <div style={{ minWidth: 800 }}>
        <Modal
          onOpen={() => {
            this.getPages();
            this.getCampaigns();
            this.getSources();
            this.getActualSource();
            this.getSupportedLeadColumns();
            this.getProjectDevelopers();
          }}
          open={modalOpen}
          onClose={() =>
            this.setState({
              modalOpen: false,
              formError: false,
              mappings: {},
              integrationType: "integration_key",
              formQuestions: [],
              phoneNumberValidation: true,
            })
          }
          trigger={
            <div className={css({ ...mixins.flexRow })}>
              <Button
                onClick={() =>
                  this.setState({
                    item: { actual_source: "facebook" },
                    extraDetails: [],
                    phoneNumberValidation: true,
                    modalOpen: true,
                    mappings: {},
                    integrationType: "integration_key",
                    formQuestions: [],
                    pageValidation: false,
                    leadValidation: false,
                    formQuestionsBlank: false,
                    fQValidation: false,
                    fQPhoneValidation: false,
                    integrationKValidation: false,
                    sourceValidation: false,
                    subSouceValidation: false,
                    projectValidation: false,
                    projectFQValidation: false,
                    extraDetailsValidation: false,
                    blankFormValid: false,
                    projectMappingValidation: false,
                  })
                }
                className={css({ marginRight: "15px" })}
              >
                Add FB Lead Form
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showfbPageFormModal: true });
                }}
              >
                Add FB Page
              </Button>
            </div>
          }
        >
          <Modal.Header>Add / Edit FB Lead Form for Sync</Modal.Header>
          <Modal.Content>
            <FieldHeading title="Page Name" />
            <Dropdown
              style={{
                border:
                  this.state.pageValidation &&
                  (!item.page_name || !item.page_id)
                    ? "1px solid #e97451"
                    : "",
              }}
              placeholder=""
              value={item.page_id}
              loading={pageloading}
              onChange={this.handlePageChange}
              fluid
              search
              selection
              options={pages}
            />
            <Divider />
            <FieldHeading title="Lead Form" />
            <div style={{ display: "flex" }}>
              <Dropdown
                style={{
                  border:
                    this.state.leadValidation &&
                    (!item.form_name || !item.form_id)
                      ? "1px solid #e97451"
                      : "",
                }}
                placeholder=""
                value={item.form_id}
                loading={formloading}
                onChange={this.handleFormChange}
                search
                fluid
                selection
                options={this.getRemainingForms(item.form_id)}
              />
              <Button
                style={{ width: 200, marginLeft: 20 }}
                onClick={this.syncForms}
                disabled={formloading || !item.page_id}
              >
                refresh forms
              </Button>
            </div>
            <Divider />
            {formQuestions.map((question, i) => {
              const val = mappings && mappings[question.key];
              var mappingKey =
                formQuestions &&
                formQuestions.map((option) => {
                  return mappings && mappings[option.key];
                });
              var blankerr = mappingKey.includes(undefined);
              var error = mappingKey.filter(
                (item, index) =>
                  mappingKey.indexOf(item) !== index &&
                  item != "not_used" &&
                  item != "others"
              )[0];
              this.setState({
                formQuestionsError: error,
              });
              this.setState({
                formQuestionsValue: val,
                formQuestionsBlank: blankerr,
              });
              const options = [
                ...leadSupportedQuestions.filter(
                  (question) => question.key === "not_used"
                ),
                ...leadSupportedQuestions
                  .filter((question) => question.key !== "not_used")
                  .sort((a, b) => (a.display_name > b.display_name ? 1 : -1)),
              ].map((question) => {
                return {
                  text: `${question.display_name}${
                    question.description ? ` - ${question.description}` : ""
                  }`,
                  value: question.key,
                  key: question.key,
                  name: question.display_name,
                };
              });
              var mappingValues = [];

              return (
                <div key={question.id} style={{ margin: "4px 0px" }}>
                  <FieldHeading
                    title={question.label}
                    expected={
                      (question.options &&
                        question.options
                          .map((option) => option.value)
                          .join(", ")) ||
                      ""
                    }
                  />
                  {}
                  <Dropdown
                    style={{
                      border:
                        this.state.fQValidation &&
                        this.state.fQValidation &&
                        (!val || val == this.state.formQuestionsError)
                          ? "1px solid #e97451"
                          : "",
                    }}
                    placeholder=""
                    value={val}
                    onChange={(e, { value, options }) => {
                      const mappings = {
                        ...this.state.mappings,
                        [question.key]: value,
                      };
                      this.setState(
                        {
                          mappings: mappings,
                          item: {
                            ...item,
                            mappings: mappings,
                          },
                        },
                        () => {
                          //   var d = formQuestions.map((question, i) => {
                          //    this.state.mappings && this.state.mappings[question.key];
                          //   });
                          //   console.log(d);
                          //   mappingValues = Object.values(this.state.mappings);
                          //   var error = mappingValues.filter((item,index) => mappingValues.indexOf(item) !== index)[0];
                          // this.setState({
                          //   formQuestionsError: error
                          // })
                        }
                      );
                    }}
                    loading={formloading}
                    search
                    fluid
                    selection
                    options={options}
                  />
                </div>
              );
            })}
            {isMultipleProjects && (
              <div>
                <Divider />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    toggle
                    onChange={(e, { checked }) => {
                      var questionMapping = {
                        ...this.state.mappings,
                        project_mapping_question: undefined,
                        project_mappings: undefined,
                      };
                      var mappings = this.state.isChanged
                        ? questionMapping
                        : this.state.mappings;
                      this.setState({
                        integrationType: checked
                          ? "form_project"
                          : "integration_key",
                        integrationKValidation:
                          integrationType === "integration_key" ? true : false,
                        mappings: mappings,
                      });
                    }}
                    checked={integrationType === "form_project"}
                    disabled={
                      formQuestions.filter((question) => question.options)
                        .length === 0
                    }
                  />
                  <div style={{ margin: "0px 0px 0px 8px" }}>
                    Project From Lead Form
                  </div>
                </div>
              </div>
            )}
            {integrationType === "form_project" && (
              <div>
                <FieldHeading
                  title="Project From Lead Form"
                  spellIcon
                  wrapStyle={{ margin: "8px 0px" }}
                />

                <Dropdown
                  style={{
                    border:
                      this.state.projectValidation &&
                      this.state.projectValidation &&
                      !(
                        mappings &&
                        mappings.project_mapping_question &&
                        mappings.project_mapping_question.key
                      )
                        ? "1px solid #e97451"
                        : "",
                  }}
                  placeholder=""
                  value={
                    mappings &&
                    mappings.project_mapping_question &&
                    mappings.project_mapping_question.key
                  }
                  onChange={(e, { value, options }) => {
                    const selected = formQuestions.find(
                      (question) => question.key === value
                    );
                    const { project_mappings, project_mapping_question } =
                      this.state.mappings;
                    const projectMappings = project_mappings || {};
                    if (
                      selected &&
                      project_mapping_question &&
                      selected !== project_mapping_question
                    ) {
                      selected.options.map((option) => {
                        projectMappings[option.key] = undefined;
                      });
                    }
                    const newMappings = {
                      ...this.state.mappings,
                      project_mappings: projectMappings,
                      project_mapping_question: selected,
                    };
                    this.setState({
                      mappings: newMappings,
                      item: {
                        ...item,
                        mappings: newMappings,
                      },
                    });
                  }}
                  loading={formloading}
                  search
                  fluid
                  selection
                  options={
                    formQuestions &&
                    formQuestions
                      .filter(
                        (question) =>
                          question.options && question.options.length
                      )
                      .map((question) => {
                        return {
                          text: question.label,
                          value: question.key,
                          key: question.key,
                        };
                      })
                  }
                />

                {this.state.mappings.project_mapping_question &&
                  this.state.mappings.project_mapping_question.options.map(
                    (option) => {
                      var mappingValues = Object.values(
                        this.state.mappings.project_mappings
                      );
                      var mappingError =
                        mappingValues.includes(undefined) ||
                        (mappings.project_mappings &&
                          Object.keys(mappings.project_mappings).length ===
                            0) ||
                        mappings.project_mapping_question.options.length !=
                          mappingValues.length;
                      this.setState({
                        mappingErrors: mappingError,
                        projectMapping:
                          mappings.project_mapping_question.options.length <
                          mappingValues.length,
                      });
                      return (
                        <div style={{ display: "flex" }} key={option.key}>
                          <div style={{ width: "100%", marginRight: "20px" }}>
                            <FieldHeading
                              title="Project Name on Facebook Form"
                              spellIcon
                              wrapStyle={{ margin: "8px 0px" }}
                            />
                            <Dropdown
                              placeholder=""
                              value={option.key}
                              onChange={() => {}}
                              loading={formloading}
                              search
                              fluid
                              selection
                              options={[
                                {
                                  value: option.key,
                                  key: option.key,
                                  text: option.value,
                                },
                              ]}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <FieldHeading
                              title="Project Name on Dashboard"
                              spellIcon
                              wrapStyle={{ margin: "8px 0px" }}
                            />
                            <Dropdown
                              style={{
                                border:
                                  projectMappingValidation ||
                                  (this.state.projectFQValidation &&
                                    this.state.projectFQValidation &&
                                    !mappings.project_mappings[option.key])
                                    ? "1px solid #e97451"
                                    : "",
                              }}
                              placeholder=""
                              value={
                                mappings &&
                                mappings.project_mappings &&
                                mappings.project_mappings[option.key]
                              }
                              onChange={(e, { value }) => {
                                const newMappings = {
                                  ...mappings,
                                  project_mappings: {
                                    ...mappings.project_mappings,
                                    [option.key]: value,
                                  },
                                };
                                this.setState({
                                  mappings: newMappings,
                                  item: {
                                    ...item,
                                    mappings: newMappings,
                                  },
                                });
                              }}
                              loading={developerProjectsLoading}
                              search
                              fluid
                              selection
                              options={developerProjects.map((project) => {
                                return {
                                  text: project.name,
                                  value: project.id,
                                  key: project.id,
                                };
                              })}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            )}
            {integrationType === "integration_key" && (
              <div>
                <FieldHeading
                  title="Integration Key"
                  spellIcon
                  wrapStyle={{ margin: "8px 0px" }}
                />
                <Dropdown
                  style={{
                    border:
                      this.state.integrationKValidation &&
                      (campaigns.length == 0 || item.campaign_key == "")
                        ? "1px solid #e97451"
                        : "",
                  }}
                  placeholder=""
                  value={item.campaign_key}
                  onChange={this.handleCampaignChange}
                  loading={formloading}
                  search
                  fluid
                  selection
                  options={campaigns}
                />
              </div>
            )}
            <Divider />
            <FieldHeading
              title="Source"
              spellIcon
              wrapStyle={{ margin: "8px 0px" }}
            />
            <Dropdown
              style={{
                border:
                  this.state.sourceValidation && !item.actual_source
                    ? "1px solid #e97451"
                    : "",
              }}
              placeholder=""
              loading={actualSourcesLoading}
              value={item.actual_source}
              onChange={this.handleActualSourceChange}
              search
              fluid
              selection
              options={actualSources}
            />
            <FieldHeading
              title="Sub Source"
              spellIcon
              wrapStyle={{ margin: "8px 0px" }}
            />
            <Dropdown
              style={{
                border:
                  this.state.subSouceValidation && !item.source
                    ? "1px solid #e97451"
                    : "",
              }}
              placeholder=""
              value={item.source}
              onChange={this.handleSourceChange}
              loading={sourcesloading}
              search
              fluid
              selection
              options={sources}
            />
            <Divider />
            <div>
              <div
                className={css(mixins.flexSpaceBetween, {
                  alignItems: "center",
                  marginBottom: 10,
                })}
              >
                <div>Extra Details</div>
                <Button
                  onClick={() => {
                    let temp = extraDetails.slice();
                    temp.push({ key: "", value: "" });
                    this.setState({
                      extraDetails: temp,
                    });
                  }}
                >
                  Add Key Value Pair{" "}
                </Button>
              </div>
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                })}
              >
                {this.state.extraDetails.map((detail, id) => {
                  return (
                    <React.Fragment>
                      <div
                        className={css({
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        })}
                      >
                        <ExtraDetails
                          id={id}
                          stateChange={(k, v) => {
                            let temp = extraDetails.slice();
                            temp[id].key = k;
                            temp[id].value = v;
                            this.setState({
                              extraDetails: temp,
                            });
                          }}
                          k={detail.key}
                          v={detail.value}
                          isError={this.state.extraDetailsValidation}
                        />
                        <Button
                          onClick={() => {
                            let temp = extraDetails.slice();
                            temp.splice(id, 1);
                            this.setState({
                              extraDetails: temp,
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            {(this.state.fQValidation || this.state.fQPhoneValidation) && (
              <React.Fragment>
                <Divider />
                <div style={{ color: "red" }}>
                  {this.state.fQValidation &&
                    this.state.formQuestionsError &&
                    "Error!! Two Facebook columns cannot be mapped to same column - " +
                      errorKey[0]}
                </div>
              </React.Fragment>
            )}
            {((this.state.blankFormValid && errorBlank) ||
              this.state.projectMappingValidation) && (
              <React.Fragment>
                <Divider />
                <div style={{ color: "red" }}>
                  Project Mapping cannot be blank
                </div>
              </React.Fragment>
            )}
            {this.state.formError && (
              <React.Fragment>
                <Divider />
                <div style={{ color: "red" }}>
                  Form (id) is already integrated with another assignment.
                </div>
              </React.Fragment>
            )}
            {!this.state.phoneNumberValidation && (
              <React.Fragment>
                <Divider />
                <div style={{ color: "red" }}>
                  Please map Phone number field to atleast one question from FB
                  form
                </div>
              </React.Fragment>
            )}
            {integrationType === "integration_key" &&
              this.state.integrationKValidation &&
              integrationError && (
                <React.Fragment>
                  <Divider />
                  <div style={{ color: "red" }}>
                    Integration Key cannot be blank
                  </div>
                </React.Fragment>
              )}
            {/* { integrationType === "form_project" && (projectMappingValidation) && (
              <React.Fragment>
                <Divider />
                <div style={{ color: "red" }}>
                  Project mapping fields cannot be blank
                </div>
              </React.Fragment>
            )} */}
            <Divider />
            <div style={{ float: "right", fontSize: 10 }}>
              add missing sources{" "}
              <a
                href={`${process.env.SOURCE_URL}/channel_sub_sources`}
                target="_blank"
              >
                here
              </a>
            </div>
            <Button
              // disabled={
              //   integrationType == "integration_key" ?!(!errorKey[0] && !errorKey[1] && !errorDetails && !errorBlank && !integrationError):
              //   !(!errorKey[0] && !errorKey[1] && !errorDetails && !errorBlank )
              // }
              loading={addloading}
              onClick={() => {
                var mappingValidation = !this.handleProjectMappingValidation();
                var pagevalidation = this.handlePageValidation();
                var leadvalidation = this.handleFormValidation();
                var fQvalidation = this.handleFormQuestionValidation();
                var fQphonevalidation = this.handleFQPhoneValidation();
                var integrationkvalidation =
                  this.handleIntegrationKeyValidation();
                var sourcevalidation = this.handleSourceValidation();
                var subsourcevalidation = this.handleSubSourceValidation();
                var projectvalidation = this.handleProjectValidation();
                var projectfqvalidation = this.handleProjectFQValidation();
                var extradetailsvalidation =
                  this.handleExtraDetailsValidation();
                var phoneNumberValidation = this.handlePhoneNumberValidation();
                var blankformvalid =
                  pagevalidation ||
                  leadvalidation ||
                  sourcevalidation ||
                  subsourcevalidation ||
                  projectvalidation ||
                  projectfqvalidation;
                var formvalid =
                  !pagevalidation &&
                  !leadvalidation &&
                  !fQvalidation &&
                  !sourcevalidation &&
                  !subsourcevalidation &&
                  !projectvalidation &&
                  !projectfqvalidation;
                this.setState({
                  pageValidation: pagevalidation,
                  leadValidation: leadvalidation,
                  fQValidation: fQvalidation,
                  fQPhoneValidation: fQphonevalidation,
                  integrationKValidation: integrationkvalidation,
                  sourceValidation: sourcevalidation,
                  subSouceValidation: subsourcevalidation,
                  projectValidation: projectvalidation,
                  projectFQValidation: projectfqvalidation,
                  extraDetailsValidation: extradetailsvalidation,
                  formValid: formvalid,
                  blankFormValid: blankformvalid,
                  projectMappingValidation: mappingValidation,
                  phoneNumberValidation: phoneNumberValidation,
                });
                // var blankValid = this.state.pageValidation || this.state.leadValidation || this.state.fQValidation || this.state.fQPhoneValidation || this.state.sourceValidation || this.state.subSouceValidation || this.state.projectValidation || this.state.projectFQValidation;
                // this.setState({
                //   blankFormValid:blankValid
                // })
                if (this.state.integrationType == "integration_key") {
                  !pagevalidation &&
                    phoneNumberValidation &&
                    !leadvalidation &&
                    !fQvalidation &&
                    !fQphonevalidation &&
                    !errorKey[1] &&
                    !integrationkvalidation &&
                    !sourcevalidation &&
                    !subsourcevalidation &&
                    (this.state.extraDetails
                      ? !extradetailsvalidation
                      : false) &&
                    this.saveForm();
                } else {
                  !mappingValidation &&
                    phoneNumberValidation &&
                    !pagevalidation &&
                    !leadvalidation &&
                    !fQvalidation &&
                    !fQphonevalidation &&
                    !errorKey[1] &&
                    !sourcevalidation &&
                    !subsourcevalidation &&
                    !projectvalidation &&
                    !projectfqvalidation &&
                    (this.state.extraDetails
                      ? !extradetailsvalidation
                      : false) &&
                    this.saveForm();
                }
              }}
            >
              Add / Update{" "}
            </Button>
          </Modal.Content>
        </Modal>
        <Toast />
        {showfbPageFormModal && (
          <AddFbPageModal
            loading={fbPageLoading}
            onClose={() => this.setState({ showfbPageFormModal: false })}
            onSubmit={this.addFBPage}
          />
        )}
        <FbLeadFormsTable
          data={items}
          loading={itemsloading}
          onStatusChange={this.handleStatusChange}
          onEdit={this.handleFormEdit}
          onViewLeads={this.handleViewLeads}
        />
        <Modal
          open={showLeads}
          onClose={() => this.setState({ showLeads: false })}
          className="ui modal large"
        >
          <Modal.Header>Leads for {item.form_name}</Modal.Header>
          <Modal.Content scrolling>
            <Table
              celled
              unstackable
              collapsing
              tableData={leads}
              sortable
              compact="very"
              headerRow={
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Phone</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Email</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Lead created at</Table.HeaderCell>
                  <Table.HeaderCell>Lead synced at</Table.HeaderCell>
                  <Table.HeaderCell>Source</Table.HeaderCell>
                  <Table.HeaderCell>Subsource</Table.HeaderCell>
                </Table.Row>
              }
              renderBodyRow={(o) => (
                <Table.Row>
                  <Table.Cell>{o.name}</Table.Cell>
                  <Table.Cell>{o.phone}</Table.Cell>
                  <Table.Cell>{o.email}</Table.Cell>
                  <Table.Cell>{o.status}</Table.Cell>
                  <Table.Cell>
                    {o.created_at &&
                      new Date(o.created_at).toLocaleDateString()}
                  </Table.Cell>
                  <Table.Cell>
                    {o.updated_at &&
                      new Date(o.updated_at).toLocaleDateString()}
                  </Table.Cell>
                  <Table.Cell>{o.actual_source}</Table.Cell>
                  <Table.Cell>{o.source}</Table.Cell>
                </Table.Row>
              )}
            ></Table>
            <div style={{ marginTop: 10 }}>
              <Button
                onClick={this.getLeads}
                loading={leadloading}
                disabled={leadloading}
              >
                Refresh
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class ExtraDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.k,
      value: this.props.v,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.k != prevProps.k) {
      ``;
      this.setState({
        key: this.props.k,
        value: this.props.v,
      });
    }
  }
  render() {
    const { stateChange, isError, id } = this.props;
    const debFunc = debounce(
      () => {
        stateChange(this.state.key, this.state.value);
      },
      300,
      false
    );
    const error =
      (this.state.key.length != 0 && this.state.value.length == 0) ||
      (this.state.key.length == 0 && this.state.value.length != 0) ||
      (this.state.key.length == 0 && this.state.value.length == 0);
    return (
      <div
        className={css({
          width: "100%",
          display: "flex",
          flexDirection: "column",
        })}
      >
        <div key={id}
          className={css({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
            width: "100%",
          })}
        >
          <div className={css({ width: "100%", margin: 5 })}>
            <Input
              style={{border: isError &&(isError && !this.state.key) ?"1px solid #e97451": ""}}
              fluid
              placeholder={"Enter Key"}
              onChange={(e) => {
                this.setState({
                  key: e.target.value,
                });
                debFunc();
              }}
              value={this.state.key}
            />
          </div>
          <div className={css({ width: "100%", margin: 5 })}>
            <Input
              style={{border: isError &&(isError && !this.state.value) ?"1px solid #e97451": ""}}
              fluid
              placeholder={"Enter Value"}
              onChange={(e) => {
                this.setState({
                  value: e.target.value,
                });
                debFunc();
              }}
              value={this.state.value}
            />
          </div>
        </div>
        <div className={css({ margin: 5, color: "red" })}>
          { isError && error && <div>Both key and value should be present</div>}
        </div>
      </div>
    );
  }
}
