import React, { Component } from "react";
import { css, cx } from "react-emotion";
import Ionicon from "react-ionicons";
import { Table, DetailCard } from "./schema/ui-utils";
import Notify from "../components/notify";
import { mixins } from "@anarock/pebble";
import { colors, Tooltip } from "@material-ui/core";
import _ from "lodash";

const scheme = {
  campaign_name: { label: "Name", name: "name", type: "string" },
  status: { label: "Status", name: "status", type: "status" },
  portal: { label: "Portal", name: "gateway", type: "string" },
  send_as: { label: "Send as", name: "json.send_as", type: "string" },
  segments: {
    label: "Segments",
    name: "segments",
    type: "dropdown",
    options: [],
    tooltip:
      "Create a segment of leads in the 'SEGMENTS' section on the main tab ( Tabs on the left-hand side)",
  },
  display_name: {
    label: "Sender ID",
    name: "json.display_name",
    type: "senderID",
    single: true,
  },
  template: {
    label: "Template name",
    name: "template_name",
    type: "dropdown",
    single: true,
  },
  placeholders: {
    label: "Place Holders",
    name: "json.placeholder",
    type: "placeholders",
  },
  sms_text: {
    label: "Whatsapp Template",
    name: "json.template_text",
    type: "sms",
  },
  broadcast_name: {
    label: "Broadcast Name",
    name: "json.broadcast_name",
    type: "string",
  },
  api_token: { label: "API token", name: "json.api_token", type: "string" },
  url: { label: "URL", name: "json.url", type: "string" },
  schedule: { label: "Schedule", name: "json.schedule", type: "schedule" },
};

const tooltipTemplate = (
  <div className={css({ fontSize: "12px" })}>
    <div className={css(`display:flex;`)}>
      a.{" "}
      <div className={css(`margin-left:4px;`)}>
        Create a Pro/Business Account on Wati.io
      </div>
    </div>
    <div className={css(`display:flex;margin-top:10px;`)}>
      b.{" "}
      <div className={css(`margin-left:4px;`)}>
        Ensure that enough credit is available
      </div>
    </div>
    <div className={css(`display:flex;margin-top:10px;`)}>
      c.{" "}
      <div className={css(`margin-left:4px;`)}>
        Get approval for your Remarketing templates on Wati.io
      </div>
    </div>
    <div className={css(`display:flex;margin-top:10px;`)}>
      d.{" "}
      <div className={css(`margin-left:4px;`)}>
        Create segments from the Segment section in left sidebar
      </div>
    </div>
    <div className={css(`display:flex;margin-top:10px;`)}>
      e.{" "}
      <div className={css(`margin-left:4px;`)}>
        'Create New Whatsapp Campaign' & schedule your campaigns to retarget
        your leads on WhatsApp
      </div>
    </div>
    <div className={css(`display:flex;margin-top:10px;`)}>
      f.{" "}
      <div className={css(`margin-left:4px;`)}>
        Don't forget to use "send test message" before scheduling
      </div>
    </div>
  </div>
);
export default class Whatsapp extends Component {
  state = {};
  getSMS() {
    this.setState({ loading: true });
    let self = this;
    let { assignment } = this.props;
    let { assignment_id } = assignment;
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/whatsapp?rtype=list&assignment_id=${assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.setState({ loading: false });
        if (data.success == true) {
          self.setState({ smslist: data.data.data, options: data.data.options });
        } else {
          self.setState({ message: "Unable to Load", message_id: new Date() });
        }
      })
      .catch(function (e) {
        self.setState({ loading: false });
        self.setState({ message: "Unable to Load", message_id: new Date() });
      });

    this.setState({ loading: true });
  }
  saveSMS(sms) {
    if (
      sms.json.placeholder &&
      sms.json.placeholder.length > 0 &&
      !_.every(
        sms.json.placeholder.map((item) => sms.params && sms.params[item])
      )
    ) {
      this.setState({
        message: "Missing placeholder values, please add valid values",
        message_id: new Date(),
      });
    } else {
      this.setState({ loading: true });
      let self = this;
      if (sms.segments === undefined) {
        sms.segments = [];
      }
      let { assignment_id } = this.props.assignment;
      fetch(
        `${window._DIGIXDOMAIN}/api/v0/whatsapp?rtype=save&assignment_id=${assignment_id}`,
        {
          method: "post",
          credentials: "include",
          body: JSON.stringify(sms),
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          self.setState({ loading: false });
          if (data.success == true) {
            sms["id"] = data.data["id"];
            self.setState({ cb: new Date() });
            self.getSMS();
            self.setState({
              message: "Saved Successfully",
              message_id: new Date(),
              selitem: null,
            });
          } else {
            self.setState({
              message: "Unable to Save",
              message_id: new Date(),
            });
          }
        })
        .catch(function (e) {
          self.setState({ loading: false });
          self.setState({ message: "Unable to Save", message_id: new Date() });
        });
    }
  }
  componentDidMount() {
    this.getSMS();
  }
  render() {
    let {
      selitem,
      smslist = [],
      options = {},
      message,
      message_id,
      show,
    } = this.state;
    return (
      <div
        className={css`
          .create-btn {
            padding: 10px 20px;
            border-radius: 3px;
            background: #1f69ff;
            margin-bottom: 20px;
            color: #fff;
            width: fit-content;
            cursor: pointer;
            :hover {
              filter: brightness(1.2);
            }
          }
        `}
      >
        <div
          className={css({
            alignItems: "center",
            ...mixins.flexRow,
          })}
        >
          <div
            className="create-btn"
            onClick={() => this.setState({ selitem: {} })}
          >
            Create New Whatsapp Campaign
          </div>
          <Tooltip
            classes={{
              tooltip: css({ backgroundColor: colors.grey["800"] }),
              popper: css(`z-index:10000012 !important;`),
            }}
            title={
              <div className={css({ padding: "8px" })}>{tooltipTemplate}</div>
            }
            onOpen={() => this.setState({ show: true })}
            onClose={() => this.setState({ show: false })}
            placement="bottom-start"
            arrow
          >
            <div className={css(`margin-left:5px;margin-bottom:15px`)}>
              <Ionicon
                fontSize="16px"
                icon="md-information-circle"
                color={show ? "#000" : "#ccc"}
              ></Ionicon>
            </div>
          </Tooltip>
        </div>
        <Table
          select={(selitem) => this.setState({ selitem })}
          data={smslist}
          keys={["name"]}
          actions="true"
        />
        <Notify message={message} message_id={message_id} />
        {selitem && (
          <div
            onClick={() => this.setState({ selitem: null })}
            style={{
              overflow: "auto",
              zIndex: 90000,
              paddingTop: 20,
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              position: "fixed",
              background: "rgba(0,0,0,.5)",
              width: "100vw",
              height: "100vh",
            }}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              style={{ height: "fit-content" }}
            >
              <SMSCard
                options={options}
                save={(sms) => this.saveSMS(sms)}
                data={selitem}
                scheme={scheme}
                ui={"campaign_name,status,portal,segments,api_token,url,template,placeholders,sms_text,broadcast_name,schedule".split(
                  ","
                )}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
class SMSCard extends Component {
  state = {
    options: this.props.options,
    templateLoading: false,
  };
  flatten = (obj) => {
    return Object.keys(obj).map((k) => ({ id: k, name: obj[k] }));
  };
  getWATItemplates(api_token, url) {
    let self = this;
    self.setState({ templateLoading: true });
    fetch(`${window._DIGIXDOMAIN}/api/v0/get-wati-templates`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      credentials: "include",
      body: JSON.stringify({ api_token, url }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        const template = data.data.map((ele) => ele.name);
        let sms_mapping = {};
        data.data.forEach((ele) => {
          if (ele.name && ele.name != "") {
            return (sms_mapping[ele.name] = ele);
          }
        });
        self.setState({
          options: { ...this.state.options, template },
          sms_mapping,
          templateLoading: false,
        });
      })
      .catch(function (e) {
        self.setState({ templateLoading: false });
        self.setState({
          message: "Unable to Load templates",
          message_id: new Date(),
        });
      });
  }
  componentDidMount() {
    const { data } = this.props;
    if (data && data.json && data.json.url && data.json.api_token)
      this.getWATItemplates(data.json.api_token, data.json.url);
  }
  saveSMS(sms) {
    this.props.save(sms);
  }
  updateSMS() {
    const { data } = this.props;
    const { options } = this.state;
    if (!(data && data.json && data.json.display_name))
      this.setState({ sms_mapping: {}, options: { ...options, template: [] } });
  }
  render() {
    const { options, templateLoading, sms_mapping } = this.state;
    const { ui, scheme, data } = this.props;
    Object.keys(options).map((k) => {
      if (options[k] instanceof Array) {
        scheme[k].options = options[k];
      } else {
        scheme[k].options = this.flatten(options[k]);
      }
    });
    return (
      <DetailCard
        ui={ui}
        scheme={scheme}
        data={data}
        options={options}
        save={(sms) => this.saveSMS(sms)}
        updateSMS={() => this.updateSMS()}
        mapping={sms_mapping}
        loading={templateLoading}
        fetchTemplates={(api_token, url) => {
          if (api_token && url) {
            this.getWATItemplates(api_token, url);
          }
        }}
      />
    );
  }
}
