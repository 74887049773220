import React, {Component} from 'react'
import {css, cx} from 'react-emotion';
import Notify from './notify'
import {CampaignForm} from './schema/ui-utils';
import { Modal, colors, Loader, mixins, Button, Tooltip} from "pebble-web";
import _ from "lodash";
import { ProjectTable } from './schema/campaignComponents';

const campaginPage = css({
  margin: "40vh 0px", 
  height: "30px", 
  flexDirection:"column" ,
  ...mixins.flexMiddleAlign
});
const modalBackDropStyle = css({
  bottom: 0,
  left: "310px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});
const modalContainerBody = css({
  background: colors.gray.lightest,
  position: "fixed",
  top: 0,
  bottom: 0
});
const createCampaignBody = css({
  marginRight: 150,
  fontSize: "18px"
})

const scheme = {
  channel: {section:"Project Details", label:'Channel Type',name:'channel',type:'select-open',options:['online','offline']},
  asem_id: {section:"Project Details", label:'Project Name',name:'asem_id',type:'custom-input',options:[]},
  source: {section:"Project Details", label:'Source',name:'source',type:'custom-input',options:[]},
  subsource: {section:"Project Details", label:'Sub Source',name:'subsource',type:'custom-input',options:[]},
  spends_integration: {section:"Spends", label:'Spends',type:'spends_integration',column:true,sep:true},
  leads_integration: {section:"Leads", label:'Leads',type:'leads_integration',column:true,sep:true},
  empty:false
}

const ui = [
  {section:"Project Details", label:'asem_id', required: true},
  {section:"Project Details", label:'channel', required: true},
  {section:"Project Details", label:'source', required: true},
  {section:"Project Details", label:'subsource', required: true},
  {section: "Spends", label:'spends_integration', required: true}
]

let readscheme = JSON.parse(JSON.stringify(scheme));
['channel','source','subsource'].map(k => {
  readscheme[k].readonly = true
});

export default class UrlGeneratorV2 extends Component {
  state = {
    error:false,
    mode:'create'
  }
	urlData = ({spends_config,projects,virtual_numbers,channels,campaign_data}) => {
    if(projects && projects[0] && !projects[0].key){
    	projects = projects.map(o => ({key:o.asem_id,value:o.name}))  
    }
    let urls = {}
    let vnumbers = {}
    virtual_numbers.map(o => {
      let {channel,source,subsource,numbers,asem_id} = o
      let key = channel+':'+source+':'+subsource
      if(!vnumbers[key]){
        vnumbers[key] = {}
      }
      vnumbers[key][asem_id] = {numbers}
    })
    let facebook_map = {};

    const addValueToKey = (key,value) => {
      facebook_map[key] = facebook_map[key] || [];
      facebook_map[key].push(value);
    }
    let spendsMappping = spends_config.map( item => ({id:item.id, source:item.source, subsource:item.subsource}));

    if( campaign_data && campaign_data.facebook_data )campaign_data.facebook_data.forEach(campaign => addValueToKey(campaign.campaign.name, campaign));

    let facebook_data = Object.values(facebook_map).map( ads => { 
      const source = _.uniq( ads.map( item => item.source));
      const subsource =   _.uniq( ads.map( item => item.subsource));
      const mode =   _.uniq( ads.map( item => item.mode));
      return ({campaign: ads[0].campaign.name, mode: mode, ads: ads.length, source: source, subsource: subsource, adList: ads, isPending: true })
    }
    )
    let cmappings = {}
    channels.map(o => {
      let {cname,channel,asem_id} = o
      if(!cmappings[asem_id]){
        cmappings[asem_id] = {values:[],keys:{}}
      }
      cmappings[asem_id].values.push(o)
      cmappings[asem_id].keys[cname] = 1
    })
    spends_config.map(o => {
      let {id,	assignment_id,	channel,	source,	subsource,spends,	json,	adata,status, asem_id, created_at} = o
      spends = (parseFloat(spends) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\..*/,'')
      let key = asem_id+':'+channel+':'+source+':'+subsource
      if(!urls[key]){
        urls[key] = {created_at,channel,source,asem_id,subsource,adata,spends,spends_integration:{mode:'mandate'},leads_integration:{projects,virtual_numbers:vnumbers[key],channels:cmappings}}
      }
      let obj = urls[key]['spends_integration'];
      if(asem_id && status=='active'){
        obj['mode'] = 'project'
      }
      let okey = null
      if(assignment_id){
        okey = 'm_'+assignment_id
      } else {
        okey = 'p_'+asem_id
      }
      obj[okey] = o
    })
    const filteredSubsources = _.uniq(spends_config.filter( spends => spends.source === "facebook").map( spends => spends.subsource));
    urls = Object.keys(urls).map(o => urls[o]).sort((a,b) => new Date(a.created_at)<new Date(b.created_at)?1:-1)
    this.setState({urls,projects,loading:false,facebook_data,spendsMappping, campaign_data, filteredSubsources})
  }
  loadSuggestions = () => {
    let self = this
    let {assignment_id} = self.props.assignment
    if(scheme.source.options.length!=0) return;
    fetch(`${window._MDOMAIN}api/v0/segments?rtype=options`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        scheme['source'].options = data.data['source'].map(o => o.name.trim())
        scheme['subsource'].options = data.data['subsource'].map(o => o.name.trim())
        self.setState({cb: new Date()})
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }
	getData = () => {
    let {assignment_id} = this.props.assignment
    let self = this
    self.setState({loading:true});
    fetch(`${window._MDOMAIN}/api/v0/url-generator?rtype=get-v2&assignment_id=${assignment_id}`,{
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        scheme['asem_id'].options = data.data.projects.map(o => o.name.trim());
        self.setState({developerProjects: data.data.projects});
        
      }
    }).catch(() => {
      this.setState({loading:false})
    })

		fetch(`${window._MDOMAIN}/api/v0/url-generator?rtype=get-v2&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({...data.data})
        self.urlData(data.data)
      }
      this.setState({loading:false})
    }).catch(() => {
      this.setState({loading:false})
    })
  }
  saveData = (conf) => {
    let {channel,source,subsource,asem_id,spends_integration={mode:'mandate'}} = conf
    var project_id = asem_id && this.state.developerProjects && this.state.developerProjects.filter(e => e.name == asem_id)[0];
    let changes = [];
    Object.keys(spends_integration).map(k => {
      if(spends_integration[k] && spends_integration[k].dirty){
        changes.push(spends_integration[k]);
        changes[0].asem_id = changes[0].asem_id !== project_id && project_id ? project_id && project_id.asem_id : changes[0].asem_id;
      }
    })
    if(!source){
      scheme['empty'] = true
    }
    if(!subsource){
      scheme['empty'] = true    
    }
    if(!asem_id){
      scheme['empty'] = true    
    }
    let body = {mode:spends_integration.mode || 'mandate',channel,source,subsource,asem_id: project_id && project_id.asem_id,changes}
    if(!(channel && source && subsource && asem_id)){
      this.setState({message:"Provide Required Fields",message_id:new Date()})
      return
    }
    if(changes.length==0){
      this.setState({message:"No Changes to Save",message_id:new Date()})
      return
    }
    let {assignment_id} = this.props.assignment
    let self = this
    self.setState({updateLoading:true});
    fetch(`${window._MDOMAIN}api/v0/url-generator?rtype=update&assignment_id=${assignment_id}`, {
      method: 'post',
      credentials:"include",
      body: JSON.stringify(body)
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        let {spends_config, editindex=-1} = self.state
        let sconf = {}
        spends_config.map(o => sconf[o.id] = o)
        data.data.map(o => sconf[o.id] = o)
        spends_config = Object.keys(sconf).map(k => sconf[k]).sort((a,b) => new Date(a.created_at)>new Date(b.created_at)?1:-1)
        self.state.spends_config = spends_config;
        self.urlData(self.state)
        if(editindex==-1){
          self.setState({editindex:0, create:false})
        }
        self.setState({message:"Saved Successfully",message_id:new Date()})
        self.getData();
        self.setState({create: false, editindex: -1,updateLoading: false});
      } else {
        self.setState({message:"Unable to Save Changes",message_id:new Date(),updateLoading: false})
      }
    }).catch(function(e){
      self.setState({message:"Unable to Save Changes",message_id:new Date(), updateLoading: false})
    })
  }
  componentDidMount() {
    this.getData()
  }

  updateScheme = (empty) => {
    return scheme['empty'] = empty;
  }
  
  render() {
    let {
      campaign_data,
      create = false,
      editindex = -1,
      facebook_data,
      spends_config = [],
      urls = [],
      projects = [],
      loading = true,
      sources = [],
      message,
      message_id,
      developerProjects,
      asem_id,
      error = false,
      mode,
      filteredSubsources,
      updateLoading,
    } = this.state;
    let { assignment_id } = this.props.assignment;
    let data = urls;
    const returnToCampaigns = () => {
      this.setState({
        create: false,
        editindex: -1
      })
    };
    let allprops = {projects,assignment_id,sources,developerProjects,asem_id,error};
    if(loading) {
      return (
        <div className={campaginPage}>
          <Notify message={message} message_id={message_id}/> 
          <Loader scale={1.5} color={colors.violet.base}/>
          <div className={css({marginTop: "15px", color: colors.gray.base})}>Fetching Campaigns</div>
        </div>
      )
    }
    
    if(projects.length == 0) {
      return (
        <div className={campaginPage}>
          <div className={css({ fontSize: "40px"})}><i className='pi pi-warning'/></div>	
          <div className={css({marginTop: "15px", color: colors.gray.darker})}>No Projects to show</div>
      	</div>
      )
    }
    return (
      <div>
        <Notify message={message} message_id={message_id} />
        <div className={css({ marginBottom: "20px", ...mixins.flexRow })}>
          <Button
            size="small"
            className={css({ marginRight: "15px" })}
            onClick={() => {
              this.loadSuggestions();
              this.setState({ create: true, mode: "create" });
              scheme["empty"] = false;
            }}
          >
            Create Campaign
          </Button>
          <div className={css({ ...mixins.flexRow })}>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                this.loadSuggestions();
                scheme["empty"] = false;
                this.setState({ create: true, mode: "resolve error" });
              }}
            >
              Resolve Campaigns
            </Button>
            <Tooltip
              placement="auto-start"
              label={(labelProps) => (
                <div
                  {...labelProps}
                  className={css({
                    display: "flex",
                    opacity: 0.5,
                    alignItems: "baseline",
                  })}
                >
                  <i className={cx("pi pi-info", css({ marginLeft: "5px" }))} />
                </div>
              )}
              text={`Click this Button to Integrate Spends’ key and map correct source/subsources for the respective campaigns. Available only for facebook campaigns`}
            />
          </div>
        </div>
        <ProjectTable
          data={data}
          developerProjects={developerProjects}
          onClick={(index) =>
            this.setState({ editindex: index, create: true, mode: "create" })
          }
        />
        <Modal
          backDropClassName={modalBackDropStyle}
          modalClassName={cx(modalContainerBody, css({ width: "100%" }))}
          visible={create}
        >
          <div className={createCampaignBody}>
            {editindex != -1 ? (
              <CampaignForm
                mode={mode}
                onClick={returnToCampaigns}
                adData={campaign_data}
                facebook_data={facebook_data}
                save={this.saveData}
                updateLoading={updateLoading}
                filteredSubsources={filteredSubsources}
                data={{ ...allprops, ...data[editindex] }}
                spendData={spends_config}
                scheme={{ ...scheme, readscheme }}
                ui={ui}
              />
            ) : (
              <CampaignForm
                mode={mode}
                onClick={returnToCampaigns}
                adData={campaign_data}
                facebook_data={facebook_data}
                save={this.saveData}
                updateLoading={updateLoading}
                filteredSubsources={filteredSubsources}
                data={allprops}
                spendData={spends_config}
                scheme={scheme}
                updateScheme={this.updateScheme}
                ui={ui}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}