import React, { Component } from "react";
import { css } from "react-emotion";
import _ from "lodash";
import { mixins, colors } from "pebble-web";
import "./custom-rule-table.css";
import CampaignDropdown from "./campaign-dropdown";

class CustomRuleTable extends React.Component {
  handleTableChanges = (rowIndex, key, value) => {
    const { data, handleChange } = this.props;
    const newData = [...data];
    newData[rowIndex][key] = value;
    handleChange("conditions", newData);
  };
  render() {
    const { data, columns, campaigns, source, isRenderExample } = this.props;
    if (isRenderExample) {
      return (
        <table
          className={css({
            tableLayout: "fixed",
            width: "100%",
            fontSize: "13px",
            pointerEvents: "none",
          })}
        >
          <colgroup>
            <col className={css({ width: "20%" })} />
            <col className={css({ width: "20%" })} />
            <col className={css({ width: "15%" })} />
            <col className={css({ width: "22.5%" })} />
            <col className={css({ width: "22.5%" })} />
          </colgroup>
          <thead className={css({ height: "50px" })}>
            <tr>
              {columns.map((column) => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className={css({ height: "45px" })} key="example table">
              <td key="subsource">Example Subsource</td>
              <td key="campaign_ids">All Campaigns Enabled</td>
              <td key="mediaplan_param">
                <MediaPlanCellComponent
                  value={[
                    {
                      mediaplan_param: 4000,
                      cutoff_param: 3000,
                      notify_param: 2000,
                    },
                  ]}
                />
              </td>
              <td key="notify_param">
                <NotifyCellComponent
                  value={[
                    {
                      mediaplan_param: 4000,
                      cutoff_param: 3000,
                      notify_param: 2000,
                    },
                  ]}
                />
              </td>
              <td key="cutoff_param">
                <CutoffCellComponent
                  value={[
                    {
                      mediaplan_param: 4000,
                      cutoff_param: 3000,
                      notify_param: 2000,
                    },
                  ]}
                />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <table
        className={css({
          tableLayout: "fixed",
          width: "100%",
          fontSize: "13px",
        })}
      >
        <colgroup>
          <col className={css({ width: "20%" })} />
          <col className={css({ width: "20%" })} />
          <col className={css({ width: "15%" })} />
          <col className={css({ width: "22.5%" })} />
          <col className={css({ width: "22.5%" })} />
        </colgroup>
        <thead className={css({ height: "50px" })}>
          <tr>
            {columns.map((column) => (
              <th key={column.field}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr className={css({ height: "45px" })} key={rowIndex}>
              <td key="subsource">{row["subsource"]}</td>
              <td key="campaign_ids">
                <CampaignDropdown
                  campaigns={campaigns}
                  rowIndex={rowIndex}
                  source={source}
                  value={row["campaign_ids"]}
                  handleChange={this.handleTableChanges}
                />
              </td>
              <td key="mediaplan_param">
                <MediaPlanCellComponent value={row["rule_params"]} />
              </td>
              <td key="notify_param">
                <NotifyCellComponent
                  value={row["rule_params"]}
                  rowIndex={rowIndex}
                  handleChange={this.handleTableChanges}
                />
              </td>
              <td key="cutoff_param">
                <CutoffCellComponent
                  value={row["rule_params"]}
                  rowIndex={rowIndex}
                  handleChange={this.handleTableChanges}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default CustomRuleTable;

class MediaPlanCellComponent extends Component {
  render() {
    const { value } = this.props;
    return <div>&#8377; {value[0].mediaplan_param.toFixed(2)}</div>;
  }
}
class NotifyCellComponent extends Component {
  render() {
    const { rowIndex, handleChange, value } = this.props;
    const paramValue = value[0];
    return (
      <div className={css({ ...mixins.flexRow })}>
        <CustomInput
          value={paramValue.notify_param}
          onChange={(value) => {
            handleChange(rowIndex, "rule_params", [
              {
                ...paramValue,
                notify_param: parseFloat(value),
              },
            ]);
          }}
        />
        {paramValue.notify_param > 0 && (
          <ParamComponent
            mediaplan_param={paramValue.mediaplan_param}
            param={paramValue.notify_param}
          />
        )}
      </div>
    );
  }
}
class CutoffCellComponent extends Component {
  render() {
    const { value, rowIndex, handleChange } = this.props;
    const paramValue = value[0];
    return (
      <div className={css({ ...mixins.flexRow })}>
        <CustomInput
          value={paramValue.cutoff_param}
          onChange={(value) => {
            handleChange(rowIndex, "rule_params", [
              {
                ...paramValue,
                cutoff_param: parseFloat(value),
              },
            ]);
          }}
        />
        {paramValue.cutoff_param > 0 && (
          <ParamComponent
            mediaplan_param={paramValue.mediaplan_param}
            param={paramValue.cutoff_param}
          />
        )}
      </div>
    );
  }
}
class ParamComponent extends Component {
  render() {
    const { mediaplan_param, param } = this.props;
    const description =
      mediaplan_param === param
        ? "(at mediaplan)"
        : `(${((param / mediaplan_param) * 100).toFixed(1)}% of mediaplan)`;
    return (
      <div
        className={css({
          color: colors.gray.base,
          marginLeft: "5px",
          marginTop: "6px",
          fontSize: "11px",
        })}
      >
        {description}
      </div>
    );
  }
}
class CustomInput extends Component {
  render() {
    const { value, onChange } = this.props;
    return (
      <div className={css({ ...mixins.flexMiddleAlign })}>
        &#8377;
        <input
          className={css({
            marginLeft: "5px",
            border: "none",
            outline: "none",
            width: "100px",
            height: "30px",
            "&:focus": {
              backgroundColor: colors.gray.lighter,
              fontSize: "14px",
            },
          })}
          type="number"
          step={0.01}
          placeholder="Enter Amount"
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
        {
          value < 0 && 
          <div className={css({ fontSize: 11, color: colors.red.light, marginLeft: "10px"})}>
            Enter Valid Values
          </div>
        }
      </div>
    );
  }
}
