
Array.prototype.toObj = function(){
  let list = this
  let headers = list[0]
  return  list.slice(1).map(row => {let obj = {}; row.map((cell,i) => obj[headers[i]]=cell) ;return obj})
}

Array.prototype.cumulativeRates = function() {
	let list = this
  let qleads = 0
  let leads = 0
  let svdone = 0
  return list.map(o => {
    qleads += parseInt(o['qleads']) || 0
    leads += parseInt(o['leads']) || 0
    svdone += parseInt(o['svdone']) || 0
   	o['_qleads']=qleads
   	o['_leads']=leads
   	o['_svdone']=svdone
    o['_qrate'] = leads?qleads*100/leads:0
    o['_svrate'] = leads?svdone*100/leads:0
    return o
  })
}

let dummydata = {}
dummydata.statuses = `Status	Count
In Call Center	33315
Failed	888
Junk	663
Visit done	198
Interested	127
Claimed	38
Booking done	25
New	7
Meeting done	3
Final negotiation	2
Fresh	1
`.split('\n').filter(o => o.trim()).map(o => o.split('	'))

dummydata.pvsa = `

`.split('\n').filter(o => o.trim()).map(o => o.split('	'))

dummydata.alldata = `
name	yspends	spends	leads	qleads	svdone	bookings	junk	failed	claimed	claimed>3	fresh	available_budget
Mahalaxmi Sales Center Launch	133534	2989019	662	96	16	2	140	131	42			
Kohinoor (Tinsel town/ Abhimaan / Reana / Courtyard / County Phase 2)	118134	5208646	5460	937	256	16	1063	235	427			
Vaarivana (Pharande Vaarivana)	84460	830504	662	136	4		198	31	232			
Tridhaatu Morya	70667	647331	454	74	21		77	64	144			
Aranya phase 2 	65641	1780684	628									
Piramal Revanta Phase 2	53767	3268793	1629	186	41	3	378	160	79			
Piramal Vaikunth	49828	9133961	4161	641	192	17	541	676	33			
PS Group Nexterra 	48253	634117	507	98	58		193	77	114			
Mahindra Happinest	46557	1690866	2426	626	120	9	1046	225	102			
Purva Palm Beach	43596	2957918	2507	332	124	1	1508	200	191			
Mahindra Antheia	43278	6726348	6434	1442	315	25	1080	388	7			
JKG PALM COURT (palmcourt)	39514	2013170	2514	1014	244	11	989	422	522			
Agasan - AND Forever	35278	646823	1265	277	68	8	129	90	459			
Piramal Aranya	30093	4403948	5308	314	77	6	366	439	18			
M3M New Launch	28110	162616	158	11	2		52	10	78			
Mahindra Luminare	25903	103296	35									
TATA Housing Gurgaon Gateway	25787	3181325	2114	241	73		1270	114	9			
Ajmera i-Land (iland)	22942	3047293	2768	220	30		278	236	28			
Tata Promont	20447	2045676	1264	231	98	4	791	83	47			
Kukreja Classic	15172	514958	466	119	21		125	32	42			
`.split('\n').filter(o => o.trim()).map(o => o.split('	'))

dummydata.users = `
Name	Email	Permission	Last Login
Sravan Bonthala	sravan.bonthala@anarock.com	Admin	10 mins ago
Amrit Raj	sravan.bonthala@anarock.com	Admin	10 mins ago
Shubham	sravan.bonthala@anarock.com	Admin	10 mins ago
Pratiksha	sravan.bonthala@anarock.com	Admin	10 mins ago
`.split('\n').filter(o => o.trim()).map(o => o.split('	'))

dummydata.dailymetrics = `
date	leads	qleads	svdone
2018-11-26	24	1	
2018-11-27	13		
2018-11-28	5		
2018-11-29	8	7	
2018-11-30	13	20	
2018-12-01	4	1	
2018-12-02	23	6	4
2018-12-03	14	16	
2018-12-04	19	6	1
2018-12-05	3	2	
2018-12-06	15	3	
2018-12-07	14	7	
2018-12-08	36	12	
2018-12-09	38	14	2
2018-12-10	20	4	1
2018-12-11	25	6	
2018-12-12	13	6	
2018-12-14	18	2	
2018-12-15	27	12	2
2018-12-16	22	8	4
2018-12-17	23	12	
2018-12-18	17	5	
2018-12-19	35	5	
2018-12-20	31	14	
2018-12-21	15	13	2
2018-12-22	34	16	1
2018-12-23	51	17	6
2018-12-24	38	12	1
2018-12-25	44	14	1
2018-12-26	35	30	2
2018-12-27	16	13	
2018-12-28	13	12	3
2018-12-29	17	13	1
2018-12-30	13	7	1
2018-12-31	13	6	3
2019-01-01	17	1	1
2019-01-02	13	9	
2019-01-03	10	3	
2019-01-04	11	24	
2019-01-05	17	25	1
2019-01-06	18	8	3
2019-01-07	19	9	2
2019-01-08	20	4	
2019-01-09	19	9	
2019-01-10	9	11	1
2019-01-11	24	12	
2019-01-12	20	13	4
2019-01-13	24	10	4
2019-01-14	19	12	
2019-01-15	18	10	6
2019-01-16	18	4	
2019-01-17	11	3	1
2019-01-18	16	8	1
2019-01-19	19	17	1
2019-01-20	22	11	2
2019-01-21	10	6	1
2019-01-22	7		
2019-01-23	6	4	3
2019-01-24	6	10	1
2019-01-25	8	7	
2019-01-26	2	4	2
2019-01-27	5	4	1
2019-01-28	2	1	
2019-01-31	4	6	2
2019-02-01	21	7	3
2019-02-02	16	8	2
2019-02-03	14	7	1
2019-02-04	15	8	2
2019-02-05	14	1	
2019-02-06	15	7	2
2019-02-07	25	12	1
2019-02-08	22	18	3
2019-02-09	18	11	3
2019-02-10	15	6	1
2019-02-11	28	9	
2019-02-12	25	1	
2019-02-13	22	21	5
2019-02-14	21	7	
2019-02-15	20	8	
2019-02-16	17	6	1
2019-02-17	25	7	3
2019-02-18	34	9	
2019-02-19	32	8	1
2019-02-20	42	9	
2019-02-21	40	47	
2019-02-22	3		
`.split('\n').filter(o => o.trim()).map(o => o.split('	')).toObj().cumulativeRates()

console.log(dummydata)

export default dummydata

