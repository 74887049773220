import reactGA from "react-ga4";

let initialised = false;

const PROD_ANALYTICS_MEASUREMENT_ID = "G-EMBWV775GR";
const STAGING_ANALYTICS_MEASUREMENT_ID = "G-74QEHJ15DK";

export function initializeTracker() {
  let ANALYTICS_MEASUREMENT_ID =
    window._MDOMAIN.indexOf("staging") != -1
      ? STAGING_ANALYTICS_MEASUREMENT_ID
      : PROD_ANALYTICS_MEASUREMENT_ID;

  reactGA.initialize(ANALYTICS_MEASUREMENT_ID, {
    gaOptions: { siteSpeedSampleRate: 100 },
  });
  initialised = true;
}

export function trackPage(path) {
  if (!initialised) return;
  reactGA.send({ hitType: "pageview", page: path });
}

export const trackEvent = (args) => {
  if (!initialised) return;
  reactGA.event(args);
};

export function setProperty(property, params) {
  if (!initialised) return;
  reactGA.gtag("set", property, params);
}
