const {EventEmitter} = require('events')

const EM = new EventEmitter()

EM.addListener('send:message',(o) => {
  ws.send(o)
})

let wait_time = 1000;

const Connect = () => {
  if(typeof window!=='undefined' && (!window.ws || window.ws.readyState!=1)) {
    window.ws =new window.WebSocket(`wss://${window._MDOMAIN.split("://")[1]}ws`)
    ws.onopen = function() {
      console.log("websocket connected");
      wait_time = 1000
    };
    ws.onmessage = function(e) {
      console.log(e.data)
      let data = JSON.parse(e.data)
      if(data.type=='code_version' && window.time && new Date(window.time) < new Date(data.time)) {
        EM.emit('receive:panel',{type:"newversion"})
      } else if(data.type=='alerts_count') {
        EM.emit('receive:panel',data)
      } else {
      	EM.emit('receive:message',e.data)  
      }
    };
    ws.onerror = function(e) {
      console.error("WebSocket Error:",e);
      wait_time = Math.min(2*wait_time,60000);
    };
    ws.onclose = function(e) {
      console.log(e)
      console.log("waiting for",wait_time)
      setTimeout(function() {
        Connect();
      }, wait_time);
      console.log("connection closed");
    }
  }
}



export {EM,Connect}
