import React, { PureComponent } from 'react'
import {css} from 'react-emotion'

export class Mosaic extends PureComponent {
  render() {
    return (
      <div className={css`
display: flex;
flex-wrap:wrap;
margin-left:-5px;
> div {
	margin:5px;
}
`}>
        {this.props.children}
      </div>
    )
  }
}

export class Card extends PureComponent {
  render() {
    return (
      <div className={css`
height: fit-content;
width: fit-content;
border-radius:3px;
background: #fff;
//overflow: auto;
padding:10px;
box-shadow:0 2px 15px 0 rgba(0,0,0,0.1);
`}>
        {this.props.children}
      </div>
    )
  }
}

export class Modal extends PureComponent {
  render() {
    return (
      <div className={css`
`}>
        {this.props.children}
      </div>
    )
  }
}