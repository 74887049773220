import React, { Component } from "react";
import { css, cx } from "react-emotion";
import _ from "lodash";
import {
  mixins,
  colors,
  DropDown,
  Option,
  OptionGroupCheckBox,
} from "pebble-web";

const buttonStyle = css({
  background: colors.white.base,
  color: colors.gray.base,
  cursor: "pointer",
  fontSize: "12px",
  borderRadius: "3px",
  border: `1px solid ${colors.gray.light}`,
  height: "40px",
  lineHeight: "40px",
  width: "175px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:not([disabled]):hover": {
    border: `1px solid ${colors.gray.light}`,
  },
  "&[disabled]": {
    border: `1px solid ${colors.gray.light}`,
    background: "#F3F3F3",
  },
});
export const flexInBetween = css({
  display: "flex",
  justifyContent: "space-between",
});
export const multiEditModalWrapper = css({
  minWidth: "264px",
  height: "50px",
});
const filterButton = css({
  maxWidth: "250px",
});
export default class FilterDropdown extends Component {
  state = {
    tempValues: this.props.value,
  };
  render() {
    const { tempValues } = this.state;
    const { onChange } = this.props;
    const rulesFilter = ["cpl", "cpql"];
    return (
      <DropDown
        closeOnOutsideClick
        dropDownClassName={css({
          zIndex: 10,
          height: "50px",
        })}
        labelComponent={({ toggleDropdown, isOpen }) => {
          return (
            <div
              onClick={() => {
                toggleDropdown();
              }}
              className={cx(buttonStyle)}
            >
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  color: "rgba(0, 0, 0, 0.54)",
                })}
              >
                <div
                  className={cx(
                    filterButton,
                    css({
                      fontSize: "13px",
                      fontWeight: isOpen ? 600 : 500,
                    })
                  )}
                >
                  Filter by Parameter
                </div>
              </div>
              <div
                className={css({
                  ...mixins.flexMiddleAlign,
                })}
              >
                <i
                  className={cx(
                    "pi pi-filter",
                    css({
                      fontSize: "12px",
                      color: colors.gray.light,
                      marginLeft: "10px",
                    })
                  )}
                />
              </div>
            </div>
          );
        }}
      >
        {({ toggle }) => {
          return (
            <OptionGroupCheckBox
              wrapClassName={multiEditModalWrapper}
              onChange={(value) => this.setState({ tempValues: value })}
              selected={tempValues}
              onApply={(tempValues) => {
                onChange(tempValues);
                toggle();
              }}
              onClear={() => this.setState({ tempValues: [] })}
            >
              {rulesFilter.map((param) => (
                <Option key={param} value={param} label={param} />
              ))}
            </OptionGroupCheckBox>
          );
        }}
      </DropDown>
    );
  }
}
