import Airtable from "airtable";

const base = new Airtable({
    apiKey: "patYySUIYqDFWdfmu.6d68679e265d573bdfc04ce4e24e82962de5834be2f624f24e1f5ff19e2bbef1"
  }).base("appHpDmenOcaRYzdx");

  const getTenantReportUrl = (records, report_name, assignment_id
  ) => {
    const env = window._MDOMAIN.indexOf('staging') === -1 ? "production" : "staging";
    const TENANT_DOMAIN = env === "staging"? 'anarock.com' : window._TDOMAIN;  
    const report = records && records.find(
      record =>
        record.fields.env === env &&
        record.fields.tenant === TENANT_DOMAIN &&
        record.fields.page === report_name
    );
    const DOMAIN = env === "staging" ? 'beta.staging.anarock.com' : TENANT_DOMAIN; 
    const reportName = report.fields.report;
    const category = report.fields.category;
    return `https://dashboard.${DOMAIN}/marketing-reports?report=${reportName}&assignmentId=${assignment_id}&category=${category}`;
    
  };  
  export async function getReportUrl(report_name, assignment_id) {
    try {
      const records = await base("marketing-reports")
        .select({ view: "Grid view" })
        .firstPage();
      return getTenantReportUrl(records, report_name, assignment_id);
    } catch (e) {
        console.error(e);
    }
  }  
