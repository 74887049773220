import React, { PureComponent, Component } from 'react'
import ReactDOM from 'react-dom';
import {css, cx} from 'react-emotion'
import Ionicon from 'react-ionicons';
import Notify from './components/notify'
import {
  Modal,
  colors,
  Button,
  Input,
  OutsideClick,
  PopUp,
  typography,
  Loader
} from "pebble-web";

const flexCenter = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});
const modalContainer = css({
  background: colors.white.base,
  width: "440px",
  alignSelf: "center",
  position: "relative",
});
const iconCloseClassName = css({
  marginLeft: "210px",
  cursor: "pointer",
  position: "absolute",
  right: modalPadding,
  top: 25,
  fontSize: "14px",
  color: colors.gray.base,
  "&:hover": {
    color: colors.gray.darker,
  },
});
const modalPadding = 30;

const headingText = css({
  ...typography.xl.bold,
  margin: "20px 0px"
});


export default class FileTree extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      tree: null,
      open_file: {},
      addedto: {},
      cm_item:{},
      newfile: null,
      duplicateModal: false,
      currentItem: {},
      duplicateLoading: false,
      deletePopUp: false,
      deleteLoading: false
    }
    this.icons = {
      "folder" : "md-folder",
      "png": "md-image",
      "jpg": "md-image",
      "gif": "md-image",
      "js": "logo-nodejs"
    }
  }

  loadFileContents = (file) => {
    let self = this
    let extension = file.name.split('.').slice(-1)[0];
    console.log("extension is",extension)
    if(['png','jpg','pdf','gif','jpeg','ico'].indexOf(extension)!=-1){
      let link = `${window._MICROSITEDOMAIN}/service/microsites?rtype=open_file&path=${encodeURIComponent(file.name)}&ftype=${file.type}&as=link`
      self.props.changeFile({type:'link',link},file.name.split('.').slice(-1),file)
      return 
    }
    else if(file.type=="folder") {
      fetch(`${window._MICROSITEDOMAIN}/service/microsites?rtype=list_files&path=${encodeURIComponent(file.name)}`, {
        method: 'get',
        credentials:"include"
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        if(data.success) {
            let {tree} = self.state
            file['children'] = data.data.children
            file.loaded = true
            self.setState({tree:JSON.parse(JSON.stringify(tree))})
        } else {
          throw new Error()
        }
      }).catch(function(e){
        console.error(e)
        self.setState({message:"Unable to Load Files",message_id:new Date()})
      })
      return;
    }
    fetch(`${window._MICROSITEDOMAIN}/service/microsites?rtype=open_file&path=${encodeURIComponent(file.name)}&ftype=${file.type}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        if(file.type=="folder") {
          let {tree} = self.state
          file['children'] = data.data
          file.loaded = true
          self.setState({tree:JSON.parse(JSON.stringify(tree))})
        } else {
          self.props.changeFile(data.data,file.name.split('.').slice(-1),file)
        }
      } else {
        throw new Error()
      }
    }).catch(function(e){
      console.log(e)
      self.setState({message:"Unable to Load Files",message_id:new Date()})
    })
  }
  
  updatePaths = (tree) => {
    if(tree && tree[0]){
    	let paths = tree[0]['children'].map(o => o.name.split('/').slice(-1)[0]).filter(o => o.indexOf('.html')!=-1)
    	this.props.updatePaths(paths)
    }
  }
  
  getFiles = () => {
    let self = this
    let prefix = this.props.prefix 
    this.setState({fileLoader: true});
    fetch(`${window._MICROSITEDOMAIN}/service/microsites?rtype=list_files&path=${prefix}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        self.setState({tree:[data.data], fileLoader: false})
    		self.updatePaths([data.data])
      } else {
        throw new Error()
      }
    }).catch(function(e){
      console.error(e)
      self.setState({message:"Unable to Load Files",message_id:new Date()})
    })
  }

  toggleFile = (file) => {
    if(file.type=="folder"){
      if(file.opened){
        file.opened = false
      } else {
        file.opened = true
        if(!file.loaded){
          this.loadFileContents(file)  
        }
      }  
    } else {
      this.setState({open_file:file})
      this.loadFileContents(file)
    }
    
    this.setState({cb:new Date()})
  }

  handleRefresh = () => {
    let {cm_item} = this.state
    this.setState({open_file:cm_item})
    this.loadFileContents(cm_item)
    this.closeMenu()
  }

  uploadFile = (file,addedto) => {
    let self = this
    let prefix = addedto.name+'/'
    this.setState({fileLoader: true});
    let url = `${window._MICROSITEDOMAIN}/service/upload?rtype=upload_file&path=${prefix}`
    let formData = new FormData()
    formData.append('file', file)
    let fname = prefix+file.name
    fetch(url, {
      method: 'POST',
      body: formData,
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        addedto.children.map((o) => {
          if(o.name==fname){
            o.upload = data.success?"done":"error"
          }
        })
        self.setState({cb:new Date()});
        self.getFiles();
        } else {
        throw new Error()
      }
    }).catch(function(e){
      self.setState({message:"Unable to upload Files",message_id:new Date(), fileLoader: false})
      addedto.children.map((o) => {
        if(o.name==fname){
          o.upload = "error"
        }
      })
      self.setState({cb:new Date()})
    })
  }

  getFile = (path) => {
    let {tree} = this.state
    let finalfile = null
    let check = (tree) => {
      tree.map(o => {
        if(finalfile) return
        if(o.name==path){
          finalfile = o
        }
        if(o.type=="folder" && o.children) {
            check(o.children)
        }
      })
    }
    check(tree)
    return finalfile
  }

  handleContextMenu = (e) => {
    if(ReactDOM.findDOMNode(this).contains(e.target)) {
      let file = e.composedPath()[1].dataset
      if(!file || !file.name){
        return
      }
      
      let finalfile = this.getFile(file.name)      

      if(finalfile){
        this.setState({contextmenutop:e.composedPath()[0].offsetTop,cm_item:finalfile})
        e.preventDefault()  
      }
    }
  }

  createNewFile = (file, callback) => {
    let self = this
    let url = `${window._MICROSITEDOMAIN}/service/microsites?rtype=new_file`
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify({ftype:file.type, path: file.name}),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        self.setState({message:"Successfully Created",message_id:new Date()})
        callback()
      } else {
        throw new Error()
      }
    }).catch(function(){
      self.setState({message:"Unable to Create",message_id:new Date()})
    })
  }
  renameFile = (path,new_path,ftype,callback) => {
    let self = this
    let url = `${window._MICROSITEDOMAIN}/service/microsites?rtype=rename_file`
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify({path,new_path,ftype: ftype == "folder" ? "folder" : "file" }),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        self.setState({message:"Successfully Renamed",message_id:new Date()})
        callback()
        self.props.getProjects()
      } else {
        throw new Error()
      }
    }).catch(function(){
      self.setState({message:"Unable to Rename",message_id:new Date()})
    })
  }

  closeMenu = () => {
    this.setState({contextmenutop:-1,cm_item:{}})
  }

  handleNewFile = () => {
    let {cm_item} = this.state
    this.setState({newfile:{type:"file"},newfiledir:cm_item})
    this.closeMenu()
  }

  handleNewFolder = () => {
    let {cm_item} = this.state
    this.setState({newfile:{type:"folder"},newfiledir:cm_item})
    this.closeMenu()
  }

  handleRename = () => {
    let {cm_item} = this.state
    cm_item.rename = true
    this.setState({cb: new Date(),renametext:cm_item.name.split('/').slice(-1)[0]})
    this.closeMenu()
  }
  
  handleDelete = () => {
    let self = this
    let {currentItem} = this.state
    this.setState({ deleteLoading: true})
    let url = `${window._MICROSITEDOMAIN}/service/microsites?rtype=delete_file`
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify({path:currentItem.name, isProject: this.isProject(currentItem.name), ftype: currentItem.type == "folder"? "folder": "file"}),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.success) {
        const oldname = currentItem.name
        currentItem.name += '.deleted'
        if(self.isProject(oldname)){
          self.props.getProjects();
          self.props.onClose();
        }
        self.setState({message:"Successfully Deleted",message_id:new Date(),deleteLoading: false, deletePopUp: false})
      } else {
        throw new Error()
      }
    }).catch(function(){
      self.setState({message:"Unable to Delete",message_id:new Date(), deleteLoading: false, deletePopUp: false})
    })
    this.closeMenu()
  }
  
  handleDuplicate = (name) => {
    let self = this
    let {currentItem} = this.state
    this.setState({ duplicateLoading: true});
    let new_path = currentItem.name.split('/');
    new_path.pop();
    new_path.push(name);
    let duplicatedPath = new_path.join('/');
    if(currentItem.type != 'folder') {
      duplicatedPath += `.${currentItem.type}`;
    }
      let url = `${window._MICROSITEDOMAIN}/service/microsites?rtype=duplicate`
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
        method: 'POST',
        body: JSON.stringify({path:currentItem.name, new_path: duplicatedPath, isProject: this.isProject(currentItem.name), ftype: currentItem.type == "folder" ? "folder" : "file" }),
        credentials:"include"
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        if(data.success) {
          self.getFiles()
          self.props.getProjects();
          self.setState({message:"Request Successful, Folder will be created soon",message_id:new Date(),duplicateModal: false, duplicateLoading: false})
          self.closeMenu()
        } else {
          throw new Error()
        }
      }).catch(function(e){
        console.log(e)
        self.setState({message:"Unable to Duplicate",message_id:new Date(),duplicateModal: false, duplicateLoading: false})        
    		self.closeMenu()
      }) 
  }
  
  handleUnzip = () => {
    let self = this
    let {cm_item} = this.state
    let ask = confirm(`are you sure, you want to unzip ${cm_item.name.split('/').slice(-1)} ?`)
    if(ask){
      let url = `${window._MICROSITEDOMAIN}/service/microsites?rtype=unzip`
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
        method: 'POST',
        body: JSON.stringify({name:cm_item.name}),
        credentials:"include"
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        if(data.success) {
          self.getFiles()
          self.setState({message:"Successfully Unziped",message_id:new Date()})
          self.closeMenu()
        } else {
          throw new Error()
        }
      }).catch(function(e){
        console.log(e)
        self.setState({message:"Unable to Unzip",message_id:new Date()})        
    		self.closeMenu()
      })
    }
  }


  componentDidMount(){
    if(this.props.prefix!="") setTimeout(this.getFiles)
    document.addEventListener("contextmenu",this.handleContextMenu)
  }
  componentWillUnmount(){
    document.removeEventListener("contextmenu",this.handleContextMenu)
  }

  loadTree = (files,depth=0) => {
    let {search='',addedto,open_file,cm_item,renametext} = this.state
    let {prefix} = this.props;
    return files!=null ?
    (files.length > 0?
    files
    .filter(f => !f.name.endsWith(".deleted"))
    .filter(f => f.type=='folder'||f.name.toLowerCase().replace(/.*\//,'').indexOf(search)!=-1)
    .map((f) => (
      <div
        onDragOver={(e) => {
          if(f.type=='folder'){
            this.setState({addedto:f})
            e.preventDefault()
            e.stopPropagation()
          }
        }}
        onDrop={e => {
          if(f.type=='folder'){
            let {addedto} = this.state
            e.preventDefault()
            e.stopPropagation()
            var files = Object.values(e.dataTransfer.files).map((o) => {console.log(o); return {retryUpload:() => this.uploadFile(o,addedto),name:addedto.name+'/'+o.name,type:o.type==""?"folder":"file",upload:"pending"}})
            Object.values(e.dataTransfer.files).forEach((file) => this.uploadFile(file,addedto))
            addedto.children = [...files,...(addedto.children || [])]
            this.setState({cb:new Date(),addedto:{}})
          }
        }}
        >
        <div className="item"
          draggable
          data-name={f.name}
          data-type={f.type}
          onClick = {() => this.toggleFile(f)}
          style={{marginBottom:1,background:(open_file.name==f.name || cm_item.name==f.name)?'#e0e0e0':f.type!='folder'&&search!=''?'#e8e8ff':null,opacity:f.upload=="pending"?.3:1,paddingLeft:depth*12+2,color:addedto.name==f.name?'red':(f.upload=="error"?"red":"inherit"),alignItems:"center"}}
          >

          { f.upload=="error" &&
            <Ionicon onClick={(e) => {
                e.stopPropagation(); 
                f.upload="pending";
                f.retryUpload();
                this.setState({cb: new Date()})
              }} icon={'md-refresh'} fontSize="14px" color={"#6B7785"} style={{}}/>
          }
          {f.type=="folder"
            &&  
            (f.opened?
              <Ionicon icon={'md-arrow-dropdown'} fontSize="14px" color={"#6B7785"} style={{marginLeft:-2}}/>:
              <Ionicon icon={'md-arrow-dropright'} fontSize="14px" color={"#6B7785"} style={{marginLeft:-2}}/>
            )
          }
          { f.type=="folder"?
            <Ionicon icon={f.opened?"md-folder-open":"md-folder"} fontSize="12px" color={"#6B7785"} style={{marginRight:3}}/>:
            <Ionicon icon={this.icons[f.type] || 'md-document'} fontSize="12px" color={"#6B7785"} style={{marginRight:3}}/>
          }
          {f.rename?
            <input 
              ref={c => {
                if(c){c.focus()}
              }}
              onBlur = {() => {
                f.rename = false
                this.setState({message:"No Change in Name",message_id:new Date(),renametext:''})
                return
              }}
              onClick={(e) => {
                e.stopPropagation()
              }} value={renametext} 
              onKeyDown={(e) => {
                if(e.key=="Enter"){
                  e.preventDefault()
                  e.stopPropagation()
                  let prefix = f.name.split('/')
                  let newname = prefix.slice(0,prefix.length-1).join('/')+'/'+renametext.replace(/[^0-9a-zA-Z \-_.]/g,'')

                  if(newname==f.name){
                    f.rename = false
                    this.setState({message:"No Change in Name",message_id:new Date(),renametext:''})
                    return
                  }

                  if(this.getFile(newname)){
                    this.setState({message:"File name exists",message_id:new Date()})
                    return
                  }

                  this.renameFile(f.name,newname,f.type,() => {
                    f.name = newname
                    f.rename = false
                    this.setState({renametext:'',cb:new Date()})  
                  })
                } else if(e.key=="Escape"){
                  e.preventDefault()
                  e.stopPropagation()
                  f.rename = false
                  this.setState({renametext:'',cb:new Date()})
                }
              }}

              onChange={(e) => this.setState({renametext:e.currentTarget.value})}
            />
            :
            <div style={{flex:1}}>{f.name.split('/').slice(-1)}</div>
          }
        </div>
        {
          f.type=='folder' &&
          f.opened &&
          ( f.loaded?
            this.loadTree(f.children || [],depth+1):
            <div className="item" style={{paddingLeft:(depth+1)*12+2}}>loading...</div> 
          )
        }
      </div>
    )):
    <div className="item" style={{paddingLeft:depth*12+2}}>-- no files --</div>)
    :<div className="item" style={{paddingLeft:depth*12+2}}>{prefix!=""? 'loading...':'Select Project'}</div>
  }

  isProject = (path) => {
      const {name} = this.props;
      const currentFolder = path.split('/').pop();
      return name == currentFolder;
  }
  render() {
    let {search='',tree,addedto,message,message_id,contextmenutop,cm_item,newfile,newfiledir, duplicateModal, duplicateLoading, deletePopUp, deleteLoading, currentItem, fileLoader=false} = this.state
    if(tree && tree[0] && tree[0].name!=this.props.prefix){
      return (
        <div style={{color:'red',padding:10}}>
          Change Template
        </div>
      )
    }
    return (
      <div className={css`
position: relative;
overflow: auto;
> div {
  width: min-content;
}

.contextmenu {
  .item {
    font-size: 12px;
    color:#6B7785;
    cursor: pointer;
    padding:2px;
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
    min-width:100px;
    padding-left:3px;
  }
  .item:hover {
    background: #e0e0e0;
  }
}
.item {
  font-size: 12px;
  color:#6B7785;
  cursor: pointer;
  padding:2px;
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  min-width: 150px;
}
.item:hover {
  background: #e0e0e0;
}
`} style={{width:150}}>
        <Notify message={message} message_id={message_id}/>
        {contextmenutop > 0 &&
          <div style={{position:"absolute",right:5,top:contextmenutop,background:'white',whiteSpace:'nowrap',fontSize:12,zIndex:100,border:"1px solid #e0e0e0"}}>
            <ContextMenu
              isProject={this.isProject(cm_item.name)}  
              name={cm_item.name}
              type={cm_item.type}
              del={() => this.setState({deletePopUp: true, currentItem: cm_item})}
              rename={this.handleRename}
              refresh={this.handleRefresh}
              duplicate={() => this.setState({ duplicateModal: true, currentItem: cm_item })}
              unzip={this.handleUnzip}
              download={() => window.location.href=`${window._MICROSITEDOMAIN}/service/microsites?rtype=download&ftype=${cm_item.type}&path=${cm_item.name}`}
              newfolder={this.handleNewFolder}
              newfile={this.handleNewFile}
              close={this.closeMenu}/>
          </div>
        }
        <Modal visible={duplicateModal} modalClassName={flexCenter}>
          <DuplicateComponent
            onSave={(text) => this.handleDuplicate(text)}
            onClose={() => this.setState({ duplicateModal: false })}
            loading={duplicateLoading}
          />
        </Modal>
        {
          deletePopUp &&
            <PopUp
            visible
            onClose={() => {this.setState({deletePopUp: false})}}
            onApprove={() => this.handleDelete()}
            onReject={() => {this.setState({deletePopUp: false})}}
            approveButtonText="Yes"
            approveButtonProps={{
              loading: deleteLoading,
              disabled: deleteLoading
            }}
          >
            <div
              className={cx(
                headingText,
                css({ marginRight: "30px" })
              )}
            >
              Are you sure, you want to delete {currentItem.name.split('/').slice(-1)} ?
            </div>
          </PopUp> 
        }
        {
          fileLoader ? <LoadingState/> :
        <div>
          <div>
            <input placeholder="search file" value={search} onChange={e => this.setState({search:e.target.value.toLowerCase()})}/>
          </div>
          {this.loadTree(tree,0)}
          <div>
            {(addedto.name || '').split('/').slice(-1)}
          </div>
          {newfile &&
            <div style={{display:"flex",alignItems:"center"}}>
              <Ionicon icon={newfile.type=="folder"?'md-folder':'md-document'} fontSize="12px" color={"#6B7785"} style={{marginRight:3}}/>
              <input placeholder="enter name" 
                ref={c => {if(c)c.focus()}} 
                onBlur={() => this.setState({newfile:null,newfiledir:null})}
                value = {newfile.name||''}
                onKeyDown={(e) => {
                  let {newfiledir} = this.state
                  if(e.key=="Enter"){
                    e.preventDefault()
                    e.stopPropagation()
                    if(!newfile.name) {
                      this.setState({newfile:null,newfiledir:null})
                      return
                    }

                    let newname = newfiledir.name+'/'+newfile.name.replace(/[^0-9a-zA-Z \-_.]/g,'')

                    newfile["name"] = newname

                    if(!newname){
                      this.setState({newfile:null,newfiledir:null})
                      return
                    } 
                    if(this.getFile(newname)) {
                      this.setState({message:"File name exists",message_id:new Date(),newfile:null,newfiledir:null})
                      return
                    }

                    this.createNewFile(newfile,() => {
                      newfiledir.children = [newfile,...(newfiledir.children||[])]
                      this.setState({newfile:null,cb:new Date(),newfiledir:null})
                    })
                  } else if(e.key=="Escape"){
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({newfile:null,newfiledir:null})
                  }
                }}
                onChange={ e => {
                  console.log(e.currentTarget.value)
                  newfile.name = e.currentTarget.value
                  this.setState({cb: new Date()})
                }}
              />
            </div>
          }
        </div>
        }
      </div>
    )
  }
}

class ContextMenu extends PureComponent {
  render() {
    const {
      name,
      newfile,
      newfolder,
      rename,
      del,
      type,
      duplicate,
      refresh,
      download,
      unzip,
      isProject
    } = this.props;
    return (
      <OutsideClick onOutsideClick={() => this.props.close()}>
        <div className="contextmenu">
          {(name || "").indexOf(".zip") != -1 && (
            <div>
              <div onClick={unzip} className="item">
                unzip
              </div>
            </div>
          )}
          {type == "folder" && (
            <div>
              <div onClick={newfolder} className="item">
                New Folder
              </div>
              <div onClick={newfile} className="item">
                New File
              </div>
              <div onClick={refresh} className="item">
                Refresh
              </div>
            </div>
          )}
          {
            !isProject &&
            <div onClick={rename} className="item">
            Rename
            </div>
          }
          <div onClick={download} className="item">
            Download
          </div>
          <div onClick={del} className="item">
            Delete
          </div>
          {
            type == "folder" && (
              <div onClick={duplicate} className="item">
              Duplicate
              </div>
            )
          }
        </div>
      </OutsideClick>
    );
  }
}

class DuplicateComponent extends Component {
  state = {
    duplicateName: undefined,
  };
  render() {
    const { onSave, onClose, loading } = this.props;
    const { duplicateName } = this.state;
    return (
      <div className={modalContainer} data-id="modal">
        <div
          className={css({
            fontSize: 15,
            padding: "20px",
            boxShadow: `0 4px 10px 0 rgba(0,0,0,0.05)`
          })}
        >
          Name Duplicate Folder
          <i
            className={cx("pi", "pi-close", iconCloseClassName)}
            onClick={onClose}
          />
        </div>
        <div className={css({ padding: "20px" })}>
          <Input
            value={duplicateName}
            onChange={(e) => this.setState({ duplicateName: e })}
            placeholder="Enter New name"
          />
        </div>
        <div
          className={css({
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          })}
        >
          <Button
            type="primary"
            onClick={() => onSave(duplicateName)}
            loading={loading}
          >
            Create
          </Button>
        </div>
      </div>
    );
  }
}

class LoadingState extends Component {
  render(){
    return(
      <div className={css({marginLeft: "30px", width: "100%", height: "100%", background: colors.gray.lightest})}>
        <div className={css({ margin: "10px 0px"})}>Files being fetched</div>
        <Loader scale={1} color="grey"/>
      </div>
    )
  }
}