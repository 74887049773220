import React, { Component } from "react";
import { css, cx } from "react-emotion";
import { Loader, Tooltip, colors } from "pebble-web";
import { getReportUrl } from "./airtable";

const item = css({
  width: "100%",
  height: "100%",
  marginTop: "3px",
  padding: "10px 20px",
  cursor: "pointer",
  textTransform: "uppercase",
  fontSize: "11px",
  color: "#101721",
  position: "relative",
  "&:hover": {
    background: "#e8e8ff",
    color: "#6161ff",
  },
  "&.select": {
    background: "#e8e8ff",
    color: "#6161ff",
  },
});
const loaderClass = css({
  width: "100%",
  height: "100%",
  marginTop: "3px",
  padding: "10px"

})
const disabled = css({
  pointerEvents: "none",
});
export class DashboardNavigation extends Component {
  state = {
    reportUrl: undefined,
    loading: false,
  };
  componentDidMount() {
    const { report_name, assignment_id } = this.props;
    if (assignment_id !== 0) this.fetchRepotUrl(report_name, assignment_id);
  }
  componentDidUpdate(prevProps) {
    if (this.props.assignment_id != prevProps.assignment_id) {
      const { report_name, assignment_id } = this.props;
      this.fetchRepotUrl(report_name, assignment_id);
    }
  }
  fetchRepotUrl = async (report_name, assignment_id) => {
    this.setState({ loading: true });
    const reportUrl = await getReportUrl(report_name, assignment_id);
    this.setState({ reportUrl: reportUrl, loading: false });
  };
  render() {
    const { reportUrl, loading } = this.state;
    const { report_name, assignment_id } = this.props;
    return (
      <Tooltip
        placement="top"
        label={(labelProps) => (
          <div className={css({ width: "146px", height: "40px" })}>
            {loading ? (
              <div className={loaderClass} >
                <Loader scale={0.5} color={colors.violet.base} />
              </div>
            ) : (
              <div
                {...labelProps}
                className={item}
                onClick={() => {
                  if (assignment_id !== 0) window.open(`${reportUrl}`, "_blank");
                }}
              >
                {report_name}
              </div>
            )}
          </div>
        )}
        text={`Select Assignment`}
        disabled={assignment_id !== 0}
      />
    );
  }
}
