import React, { Component } from "react";
import { css, cx } from "react-emotion";
import _ from "lodash";
import {
  mixins,
  colors,
  Button,
  DateInput,
  Select,
  Option,
  Tag,
  Input,
  Toast,
} from "pebble-web";
import { Divider } from "semantic-ui-react";
import { RuleFormInput } from "./rules-form-input";
import { RulesFormTable } from "./rules-form-table";
import { format } from "date-fns";
import { deepEqual } from "../../utils/rules";
import { isValid } from "../../utils/rules";

const ruleFormBody = css({
  margin: "15px 0px",
  padding: "24px 24px 10px",
  background: colors.white.base,
});
const formComponent = css({
  fontSize: "15px",
  color: colors.gray.dark,
});
const itemHeader = css({
  fontSize: "13px",
  fontWeight: 100,
  color: colors.gray.dark,
});
const bottomBox = css({
  display: "flex",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
  borderRadius: "0px 0px 10px 10px",
  flexDirection: "column",
  alignItems: "flex-end",
  backgroundColor: colors.white.base,
  padding: "10px 24px",
  zIndex: 8000,
});
const mainPage = css({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden",
});

const genericBody = css({
  overflow: "auto",
  padding: "10px 20px",
  height: "100vh",
});
const required = css`
  &:after {
    content: " *";
    color: red;
  }
`;

const ui = ["source", "subsources", "parameter"];
const days = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};
const mediaplanDetails = ["source", "subsource_ids", "parameter"];
export default class RulesForm extends Component {
  state = {
    loading: true,
    inputEmail: "",
    schema: {
      source: {
        label: "Source",
        name: "source",
        options: Object.keys(this.props.campaigns),
      },
      subsources: {
        label: "Sub Sources",
        name: "subsource_ids",
        options: [],
      },
      parameter: {
        label: "Parameter",
        name: "parameter",
        options: ["cpl", "cpql"],
        multiSelect: false,
      },
    },
    data: JSON.parse(JSON.stringify(this.props.ruleData)),
  };
  setValue(key, value) {
    let { data } = this.state;
    data[key] = value;
  }
  getValue = (key) => {
    let { data } = this.state;
    if (data === undefined) return undefined;
    let output = data[key];
    return output;
  };

  fetchMediaplan = () => {
    const { assignment_id, campaigns } = this.props;
    const { data } = this.state;
    fetch(
      `${window._MDOMAIN}/api/v0/get_mediaplan_param?assignment_id=${assignment_id}`,
      {
        method: "post",
        credentials: "include",
        body: JSON.stringify({
          source: data.source,
          subsources: this.subsourceValue(data.subsource_ids),
          param: data.parameter,
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then((mediaplan) => {
        if (mediaplan.status === 200) {
          let conditions = [];
          mediaplan.data.forEach((mediaplanValue) => {
            const rule_params = [
              {
                mediaplan_param: mediaplanValue.mediaplan_param,
                notify_param: (mediaplanValue.mediaplan_param * 80) / 100,
                current_param: mediaplanValue.mediaplan_param,
                cutoff_param: mediaplanValue.mediaplan_param,
              },
            ];
            const campaign_ids = (
              data.source === "facebook" ? campaigns.facebook : campaigns.google
            ).map((item) => item.id);

            const currentCondition = {
              subsource: mediaplanValue.subsource,
              rule_params: rule_params,
              campaign_ids,
            };
            conditions.push(currentCondition);
          });
          this.handleChange({ key: "conditions", value: conditions });
        }
      })
      .catch(function (e) {
        Toast.show("Enable to fetch data", "error");
      });
  };
  handleSubsourceChange = (value) => {
    const { subsourceMapping } = this.props;
    const subsourceValue = subsourceMapping.find(
      (item) => item.name === value.subsource
    );
    const inputValue = [subsourceValue.id];
    const { data } = this.state;
    this.setValue("subsource_ids", inputValue);
    this.setValue("subsource_data", {
      start_date: value.startdate,
      end_date: value.enddate,
    });
  };
  handleSourceChange = (value) => {
    this.setValue("source", value);
    this.getSubsourceOptions();
  };
  handleChange = (event) => {
    let { data } = this.state;
    if (event) {
      if (mediaplanDetails.includes(event.key)) {
        if (event.key === "source") {
          this.handleSourceChange(event.value);
        } else if (event.key === "subsource_ids") {
          this.handleSubsourceChange(event.value);
        } else {
          this.setValue(event.key, event.value);
        }
        if (
          data.source &&
          data.subsource_ids &&
          data.subsource_ids.length > 0 &&
          data.parameter
        ) {
          this.fetchMediaplan();
        }
      } else if (event.key === "start_date" || event.key === "end_date") {
        this.handleDayClick(event.key, event.value);
      } else {
        this.setValue(event.key, event.value);
      }
    }
    this.setState({ cb: new Date(), data: JSON.parse(JSON.stringify(data)) });
  };
  handleDayClick = (key, day) => {
    const dateFormated = format(day, "YYYY-MM-DD");
    this.setValue(key, dateFormated);
  };
  subsourceValue = (value) => {
    const { subsourceMapping } = this.props;
    return value.map(
      (subsource) => subsourceMapping.find((item) => item.id === subsource).name
    );
  };
  getSubsourceOptions = () => {
    let self = this;
    const { assignment_id, ruleData } = this.props;
    const { data, schema } = this.state;
    const currentRuleData =
      (data && data.source) || (ruleData && ruleData.source);
    if (currentRuleData) {
      fetch(
        `${window._MDOMAIN}/api/v0/get_unused_subsources?assignment_id=${assignment_id}&source=${currentRuleData}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then((response) => {
          if (response.status === 200) {
            const subsourceOptions = response.data;
            self.setState({
              schema: {
                ...schema,
                subsources: {
                  ...schema.subsources,
                  options: subsourceOptions,
                },
              },
              loading: false,
            });
          }
        })
        .catch(function (e) {
          console.error(e);
          Toast.show("Unable to fetch data", "error");
        });
    }
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      data: JSON.parse(JSON.stringify(nextProps.ruleData)),
    });
  }

  componentDidMount() {
    this.getSubsourceOptions();
  }
  render() {
    const { schema, data, loading, inputEmail } = this.state;
    const { onClose, campaigns, mode, subsourceMapping } = this.props;
    const frequency = this.getValue("frequency") || [];
    const isRenderExample = !(
      data &&
      data.source &&
      data.subsource_ids &&
      data.parameter &&
      data.conditions &&
      data.conditions.length > 0
    );
    return (
      <div className={mainPage}>
        <div className={genericBody}>
          <div
            className={css({
              fontWeight: "bold",
            })}
          >
            {mode === "edit" ? "Edit Rule" : "Create A New Rule"}
          </div>

          <div className={ruleFormBody}>
            <div>Create automated rule to</div>
            <Divider />
            <div className={css({ marginTop: "10px", ...mixins.flexRow })}>
              {ui.map((field, index) => {
                return (
                  <RuleFormInput
                    data={data}
                    subsourceMapping={subsourceMapping}
                    getValue={this.getValue}
                    handleChange={this.handleChange}
                    key={index}
                    {...schema[field]}
                  />
                );
              })}
            </div>
          </div>
          <div className={ruleFormBody}>
            <div>Conditions and Actions</div>
            <Divider />
            <RulesFormTable
              isRenderExample={isRenderExample}
              data={data}
              handleChange={this.handleChange}
              getValue={this.getValue}
              campaigns={campaigns}
            />
          </div>
          <div className={ruleFormBody}>
            <div>Schedule</div>
            <Divider />
            <div
              className={css({
                display: "flex",
                justifyContent: "flex-start",
              })}
            >
              <div
                className={cx(
                  css({ ...mixins.flexSpaceBetween, width: "50%" }),
                  formComponent
                )}
              >
                <div className={css({ width: "50%" })}>
                  <div className={cx(itemHeader, required)}>Start date</div>
                  <DateInput
                    disabled={false}
                    placeholder="Start Date"
                    value={
                      this.getValue("start_date")
                        ? new Date(this.getValue("start_date"))
                        : ""
                    }
                    calendarProps={{
                      onChange: () => {},
                      tileDots: [],
                    }}
                    inputProps={{
                      placeholder: "",
                    }}
                    onChange={(value) => {
                      if (value)
                        this.handleChange({
                          key: "start_date",
                          value: new Date(value),
                        });
                    }}
                  />
                  {data && data.subsource_data && (
                    <div
                      className={css({
                        fontSize: 10,
                        color: colors.red.light,
                      })}
                    >
                      Start Date should be within{" "}
                      {data.subsource_data.start_date}
                    </div>
                  )}
                </div>
                <div className={css({ marginLeft: "10px", width: "50%" })}>
                  <div className={cx(itemHeader, required)}>End date</div>
                  <DateInput
                    disabled={false}
                    placeholder="End Date"
                    value={
                      this.getValue("end_date")
                        ? new Date(this.getValue("end_date"))
                        : ""
                    }
                    calendarProps={{
                      onChange: () => {},
                      tileDots: [],
                    }}
                    inputProps={{
                      placeholder: "",
                    }}
                    onChange={(value) => {
                      if (value)
                        this.handleChange({
                          key: "end_date",
                          value: new Date(value),
                        });
                    }}
                  />
                  {data && data.subsource_data && (
                    <div
                      className={css({
                        fontSize: 10,
                        color: colors.red.light,
                      })}
                    >
                      End Date should be within {data.subsource_data.end_date}
                    </div>
                  )}
                </div>
              </div>
              <div className={css({ marginLeft: "10px", width: "25%" })}>
                <div className={cx(itemHeader)}>
                  Frequency
                  <span className={css({ fontSize: 11 })}>
                    (Checks @12PM at selected time period)
                  </span>
                </div>
                <Select
                  onChange={(value) => {
                    this.handleChange({ key: "frequency", value: value });
                  }}
                  placeholder=""
                  multiSelect
                  value={frequency.map((item) => days[item]).join(",")}
                  onClear={() =>
                    this.handleChange({ key: "frequency", value: [] })
                  }
                  required
                  className={css({ height: "20px" })}
                  fullWidthDropdown
                  selected={frequency}
                >
                  {Object.keys(days).map((day) => (
                    <Option
                      key={parseInt(day)}
                      value={parseInt(day)}
                      label={days[day]}
                    />
                  ))}
                </Select>
              </div>

              <div className={css({ marginLeft: "10px", width: "25%" })}>
                <div className={cx(itemHeader)}>Send Email Notification to</div>
                <div className={css({ ...mixins.flexRow })}>
                  <Input
                    type="text"
                    onChange={(value) => {
                      this.setState({ inputEmail: value });
                    }}
                    value={inputEmail}
                    placeholder=""
                    className={css({ height: "50px" })}
                  />
                  {inputEmail && (
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        this.handleChange({
                          key: "email_ids",
                          value: [
                            ...(this.getValue("email_ids") || []),
                            inputEmail,
                          ],
                        });
                        this.setState({ inputEmail: "" });
                      }}
                      className={css({
                        marginLeft: "5px",
                        color: colors.purple.base,
                      })}
                    >
                      Add
                    </Button>
                  )}
                </div>
                <div
                  className={css({
                    ...mixins.flexSpaceBetween,
                    flexDirection: "column",
                  })}
                >
                  {this.getValue("email_ids") &&
                    this.getValue("email_ids").map((email) => (
                      <div>
                        <Tag
                          label={email}
                          color="violet"
                          className={css({ margin: "0px 5px 5px" })}
                          onClose={() => {
                            let newEmails = [
                              ...this.getValue("email_ids").filter(
                                (emailId) => emailId != email
                              ),
                            ];
                            this.handleChange({
                              key: "email_ids",
                              value: newEmails,
                            });
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={bottomBox}>
          <div className={css({ ...mixins.flexRow })}>
            <Button
              type="secondary"
              className={css({ marginRight: "10px" })}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => this.props.saveChanges(data)}
              disabled={deepEqual(data, this.props.ruleData) || !isValid(data)}
            >
              {mode === "create" ? "Create Rule" : "Save Changes"}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
