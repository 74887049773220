import React, { Component } from "react";
import { css, cx } from "react-emotion";
import _ from "lodash";
import { colors, mixins, Option, Select, Tooltip } from "pebble-web";

const ruleFormComponent = css({
  margin: "10px 0px 0px",
  paddingBottom: "20px",
  width: "25%",
  fontSize: "10px",
  color: colors.gray.dark,
  marginRight: "15px",
  "&:last-child": {
    marginRight: "0px",
  },
});
const itemHeader = css({
  fontSize: "13px",
  fontWeight: 100,
  color: colors.gray.dark,
});
const required = css`
  &:after {
    content: " *";
    color: red;
  }
`;
export class RuleFormInput extends Component {
  state = {
    isDisabled: false,
    filter: "",
  };
  subsourceValue = (value) => {
    const { subsourceMapping } = this.props;
    const currentValue = subsourceMapping.find((item) => item.id === value[0]);
    return currentValue ? currentValue.name : "";
  };
  render() {
    let { options, label, name, getValue, handleChange } = this.props;
    const { filter } = this.state;
    const value = getValue(name) || (name === "subsource_ids" ? [] : "");
    return (
      <div className={ruleFormComponent}>
        <div>
          <div className={css({ ...mixins.flexRow })}>
            <div className={cx(itemHeader, required)}>{label}</div>
            {name === "subsource_ids" && (
              <Tooltip
                placement="top-start"
                label={(labelProps) => (
                  <div
                    {...labelProps}
                    className={css({
                      display: "flex",
                      opacity: 0.5,
                      alignItems: "baseline",
                    })}
                  >
                    <i
                      className={cx("pi pi-info", css({ marginLeft: "5px" }))}
                    />
                  </div>
                )}
                text="Duplicate Subsources represent different mediaplan values"
              />
            )}
          </div>
          <div>
            <Select
              disabled={options.length == 0}
              onChange={(value) => {
                handleChange({ key: name, value: value });
              }}
              placeholder=""
              value={
                name === "subsource_ids" ? this.subsourceValue(value) : value
              }
              className={css({
                height: "45px",
                fontSize: "13px",
                fontWeight: "bold",
              })}
              fullWidthDropdown
              selected={
                name === "subsource_ids" ? this.subsourceValue(value) : value
              }
              searchBox
              searchBoxProps={{
                placeholder: "Search",
                value: filter,
                onChange: (value) => this.setState({ filter: value }),
              }}
            >
              {options
                .filter((option) => {
                  let optionValue =
                    name === "subsource_ids" ? option.subsource : option;

                  return optionValue
                    .toLowerCase()
                    .includes(filter.toLowerCase());
                })
                .map((option) => (
                  <Option
                    key={name === "subsource_ids" ? option.subsource : option}
                    value={option}
                    label={name === "subsource_ids" ? option.subsource : option}
                  />
                ))}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}
