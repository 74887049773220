import React, { Component } from "react";
import { css, cx } from "react-emotion";
import { colors, Button, mixins, Option, Tooltip } from "pebble-web";

export class ReportsWarning extends Component {
  render() {
    return (
      <div
        className={css({
          position: "relatibe",
          marginLeft: "20px",
          ...mixins.flexRow,
          color: colors.red.light,
          border: `1px solid ${colors.red.lighter}`,
          borderRadius: 3,
          width: "225px",
          lineHeight: "14px",
          fontSize: "11px",
          padding: "5px"
        })}
      >
        This report will be discontinued on the marketing panel starting 15th
        Aug 12 AM. Please start accessing this report using “View on Dashboard”
        button
      </div>
    );
  }
}
