import React, {Component, Fragment} from 'react'
import {css} from 'react-emotion'

export default class GSearch extends Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  componentDidMount() {
    document.addEventListener('keydown',this.handleKeyPress,true)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown',this.handleKeyPress,true)
  }
                              
	handleKeyPress = (e) => {
    return
    // console.log(e)
    try {
      if(e.repeat){
        return
      }
      let valid = false
      if(e.keyCode==27 && this.state.show){
        this.setState({show:false})
        valid = true
      } else if(e.keyCode==80 && (e.metaKey || e.ctrlKey)){
        this.setState({show:true})
        valid = true
      }
      if(valid){
        e.preventDefault()
        e.stopPropagation()  
      }
    } catch(e) {  
    }
  }
  
  render() {
    let {show} = this.state
    return (
      <div 
        onClick={e=>this.setState({show:false})}
        className={css`
display:${show?'flex':'none'};
z-index:1000000;
position:fixed;
left:0px;
top:0px;
width:100vw;
height:100vh;
background:rgba(0,0,0,0.15);
justify-content: center;
padding-top:100px;
> div {
	border-radius:3px;
	width:500px;
	height:fit-content;
	background: white;
}
input {
	width:100%;
	padding:20px 30px;
	outline: none;
	border-radius: 3px;
	border: 1px solid #e0e0e0;
	font-size:16px;
}
`}>
        {show&&
          <div onClick={e => e.stopPropagation()}>
            <input ref={e=> {
                e && e.focus()
              }} focus={true} placeholder="Search"/>
          </div>
        }
      </div>
    )
  }
}