import React, { Component } from "react";
import { css, cx } from "react-emotion";
import { colors, Button, mixins, Option, Tooltip} from "pebble-web";
import { getReportUrl } from "./airtable";


export class DashboardReportsLink extends Component {
  state = {
    reportUrl: undefined,
    report_name: this.props.report_name,
    assignment_id: this.props.assignment_id,
    loading: false
  };
  componentDidMount() {
    this.fetchRepotUrl();
  }
  componentWillReceiveProps(nextProps) {
    const { report_name, assignment_id } = nextProps;
    this.setState(
      {
        report_name: report_name,
        assignment_id: assignment_id,
        loading: true
      },
      this.fetchRepotUrl
    );
  }
  fetchRepotUrl = async () => {
    const { report_name, assignment_id } = this.state;
    const reportUrl = await getReportUrl(report_name, assignment_id);
    this.setState({ reportUrl: reportUrl, loading: false });
  };
  render() {
    const { reportUrl, loading } = this.state;
    return (
      <div
        className={css({
          position: "relative",
          marginRight: "10px",
          marginTop: "10px",
          ...mixins.flexRow,
        })}
      >
        <Button
          type="secondary"
          className={css({ color: colors.gray.darker })}
          size="small"
          disabled={reportUrl === undefined}
          onClick={() => {
            window.open(`${reportUrl}`, "_blank");
          }}
          loading={loading}
        >
          View on Dashboard
        </Button>
        <Tooltip
          placement="auto-end"
          label={(labelProps) => (
            <div
              {...labelProps}
              className={css({
                display: "flex",
                opacity: 0.5,
                alignItems: "baseline",
              })}
            >
              <i className={cx("pi pi-info", css({ marginLeft: "5px" }))} />
            </div>
          )}
          text={`Experience the new reports here with most up-to-date data and faster results`}
        />
      </div>
    );
  }
}