import React, { PureComponent } from 'react'
import {css} from 'react-emotion'
import dummydata from './data'

import {Graph, MiniInfo} from './components/graph'
import {Mosaic, Card} from './components/containers'


export default class Home extends PureComponent {
  state = {}
  getData = () => {
    let self = this
    fetch(`${window._MDOMAIN}api/v0/home?rtype=get`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200){
        self.setState({data:data.data})
      }
    })
  }
  componentDidMount() {
    this.getData()
  }
  
  render() {
    let {data={table:[]}} = this.state
    let keys = ["name","poc","yspends","spends","leads","cpl","qleads","cpql","svdone","cpsv"]
    return (
      <div className={css`
font-size:11px;
color: #101721;
table,td,tr {
	text-align:right;
	border: 1px solid #e0e0e0;
}
.header {
	font-size:14px;
}
tr:first-child {
	background: #ededed;
	td {
		color: #000;
		font-weight:700;
	}
}
td {
	padding: 5px;
	font-family: Lato;
	font-weight:500;
}
td:first-child {
	color: #1f69ff;
	text-align: left;
	max-width:150px;
	white-space: nowrap;
	overflow: hidden;
}
.filter-bar {
	color: #6b7785;
	padding-bottom:10px;
}
`}>
        <Mosaic>
          <Card>
            <div style={{display:'flex'}}>
              <div style={{flex:1}}>
                <div className="header" style={{paddingBottom:5,fontWeight:'bold'}}>Assignment Performance</div>
                <div className="filter-bar">Filters: channel: online source: facebook subsource: leadgen_ri</div>
              </div>
              <div style={{display:'flex'}}>
                <div><input /></div>
            		<div>filter</div>
              </div>
            </div>
            <table>
              <tr>{keys.map(k => (<td>{k}</td>))}</tr>
              {
                data.table.slice(0,200).map(row => (
                  <tr>{keys.map(k => (<td>{row[k]}</td>))}</tr>
                ))
              }
            </table>
            <div>
              <a>refresh</a>
            </div>
          </Card>
        </Mosaic>
      </div>
    )
  }
}