import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom';
import Ionicon from 'react-ionicons'
import {Loading} from './components'
import {css} from 'react-emotion';

import {Calendar,OptionGroupCheckBox,Option,DropDown, OptionGroupRadio} from '@anarock/pebble';

export class Popper extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    setTimeout(() => document.addEventListener('click', this.handleClick, true),100)
    document.addEventListener('keydown',this.handleKeyPress,true)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true);
    document.removeEventListener('keydown',this.handleKeyPress,true)
  }
                              
	handleKeyPress = (e) => {
    try {
      if(e.repeat){
        return
      }
      let valid = false
      if(e.keyCode==27){
        this.props.close()
        valid = true
      }
      if(valid){
        e.preventDefault()
        e.stopPropagation()  
      }
    } catch(e) {  
    }
  }
  handleClick = (e) => {
    try{
    	if(!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.props.close()
        if(!this.props.allowClicks){
          e.preventDefault()
          e.stopPropagation()
        }
      }
    } catch(e) {
      console.log(e)
      // console.log(e,e.toString())
      // if(e.toString().indexOf('Unable to find node on an unmounted component')!=-1){
      //   console.log("removing unmounted node")
      //   document.removeEventListener('click', this.handleClick, true);
      // }
    }
  }
  render() {
    let {html} = this.props
    return (
      <div className={css`
z-index:1000000;
position: absolute;
top:0px;
left:0px;
box-shadow: 0px 8px 15px 0px rgba(0,0,0,.15);
`}>
        {html || this.props.children}
      </div>
    )
  }
}

export class ListFilter extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filters: JSON.parse(JSON.stringify(this.props.filters || {"options":{}}))
    }
  }
  render() {
    let options = this.props.options || []
    let {filters,filtertext=''} = this.state
    return (
      <div style={{width:300,background:'#fff',zIndex:1000,border:'1px solid #ededed',position:'absolute',boxShadow:'0px 8px 15px 0px rgba(0,0,0,.15)'}}>
        <OptionGroupCheckBox
          searchBox
          searchBoxProps={{
            clearable: true,
            placeholder: 'Search',
            onChange: value => this.setState({filtertext:value})
          }}
          onChange={values => {
            filters["options"] = {}
            values.map(o => {
              filters["options"][o] = 1
            })
            console.log(filters)
            this.setState({cb:new Date(),filters:JSON.parse(JSON.stringify(filters))})
          }}
          selected={Object.keys(filters["options"])}
          onApply={() => {
            this.props.apply(filters)
          }}
          onClear={() => {
            filters['options'] = {}
            this.setState({filters:JSON.parse(JSON.stringify(filters))})
          }}
        >
          <div style={{display:'flex',marginLeft:20,marginTop:0,marginBottom:10}} className={css`.link{margin-right:10px;cursor:pointer; :hover{text-decoration:underline}}`}>
            <div className="link" onClick={() => {
                filters["options"] = filters["options"] || {}
                options
            		.filter(o => (o["name"]||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1)
                .map(o => {
                  filters["options"][o["id"]] = 1
                })
            		this.setState({cb:new Date(),filters:JSON.parse(JSON.stringify(filters))})
              }}>Select Visible</div>
            <div className="link" onClick={() => {
                filters["options"] = filters["options"] || {}
                options
            		.filter(o => (o["name"]||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1)
                .map(o => {
                  if(filters["options"][o["id"]]) delete filters["options"][o["id"]];
                })
            		this.setState({cb:new Date(),filters:JSON.parse(JSON.stringify(filters))})
              }}>Clear Visible</div>
          </div>
          {
            options
            	.filter(o => (o["name"]||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1)
            	.slice(0,100)
              .map(o => (
              <Option value={o["id"]+''} label={(o["name"] || 'blank').split(":#")[0]}/>
            )) 
          }
        </OptionGroupCheckBox>
      </div>
    )
  }
}

export class Filters extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  date = (d) => {
    let pts = new Date(d).toString().split(' ');
    return `${pts[2]}${pts[1]}${pts[3]}`
  }
  render() {
    let {filteroptions={},filters={},alloptions=[],update,getFilterOptions,filterTable,downloadlink} = this.props
    let {last='',closed=new Date(),editfilter,showmore} = this.state
    return (
      <div className={css`
.add-btn{
	cursor: pointer;
	width:fit-content;
	margin-top:33px;
	border:1px solid #e0e0e0;
	color:#6b7785;
	height:28px;
	display:flex;
	align-items:center;
	padding: 0px 10px;
	border-radius:3px;
	background:#fff;
	:hover {
		background: #ededed;
	}
}

width:100%;
position: relative;
user-select: none;
margin-top:5px;
border: 1px solid #ededed;
background: #fbfbfb;
display: flex;
padding:6px;
font-weight:bold;
.remove {
	opacity: 0;
	color: #d32f02;
	position: absolute;
	font-size:12px;
	right:0px;
	top:0px;
	padding:3px;
	cursor:pointer;
	text-decoration: underline;
	:hover {
		opacity:1;
	}
}
.pi-arrow-drop-down, .pi-arrow-drop-up {
	font-size:8px;
}
.item.active {
	.chip {
		background:#c0c0ff;
	}
	.dot-border {
		border: 1px dashed #e0e0e0;
	}
}
.item {
	position: relative;
	color: #6b7785;
	padding:6px;
	padding-right:0px;
  font-size: 10px;
	.dot-border {
		position:relative;
		padding: 3px;
		border: 1px dashed transparent;
		:hover {
			border: 1px dashed #e0e0e0;
		}
		:hover .close {
			background: #fbfbfb;
			z-index: 1000;
			color: #efbbad !important;
			svg {
				fill: #efbbad !important;
			}
		}
		.close {
			div {
				margin-bottom: 2px;
			}
			padding-left:5px;
			display: flex;
			align-items: center;
			position:absolute;
			color: transparent;
			right:3px;
			top:3px;
			cursor: pointer;
			:hover{
				border-bottom: 1px dashed #d32f02;
				svg {
					fill: #d32f02 !important;
				}
				color: #d32f02 !important;
			}
		}
	}
  .name {
    padding-bottom:6px;
		text-overflow: ellipsis;
		overflow:hidden;
		max-width:80px;
    height: 30px;
    line-height: 1;
    display: flex;
    align-items: center;
  }
  .chip {
		cursor: pointer;
    color: #6161ff;
    display:flex;
    align-items: center;
    background: #e8e8ff;
    font-size: 10px;
    height: 28px;
    padding:10px;
    border-radius: 3px;
		border: 1px solid #e8e8ff;
		&.empty {
			background: transparent;
			border: 1px solid #6161ff;
			color: #6b7785;
			border: 1px solid #6b7785;
		}
		:hover {
			background:#c0c0ff;
		}
    .text {
      overflow:hidden;
      text-overflow: ellipsis;
      max-width:100px;
      margin-right:5px;
      white-space: nowrap;
			display: block;
    }
    .border {
      border: 1px solid #6161ff;
      border-radius: 3px;
      padding:0px 2px;
      margin-left:-2px;
			margin-right:5px;
    }
  }
}
.buttons {
	margin-top:20px;
	display: flex;
	> div {
		margin-right: 5px;
	}
}

.tab-item {
	padding: 5px;
	cursor: pointer;
	:hover {
		color: #000 !important;
		text-decoration: underline;
	}
}

.list-items {
	min-width: 150px;
	max-width: 300px;
	background: #fff;
	max-height: 300px;
	overflow: auto;
	.options {
		white-space: nowrap;
		overflow: hidden;	
		cursor:pointer;
		padding: 10px 20px;
		font-size:14px;
		:hover {
			background: #ededed;
		}
	}
}
`}>
        <div style={{flex:1,display:'flex',flexWrap:'wrap'}}>
          {Object.keys(filters).map(item => {
              let o = filters[item]
              let value = '';
              let more = 0;
            	if(o['type']=='inlist'){
              	let options = Object.keys(o['options']).filter(k => o['options'][k])
                if(options.length>0){
                	value = options[0]==''?'blank':options[0].split(":#")[0]
                	more = options.length - 1  
                }
              } else if(o['type']=='daterange'){
                let {start,end} = o
                let [s,s1,s2,s_] = new Date(start).toString().split(' ')
                let [e,e1,e2,e_] = new Date(end).toString().split(' ')
                if(start && end){
                	value = `${s1} ${s2} - ${e1} ${e2}`
                }
              }
            	
            	
            	let icon = editfilter==item?"up":"down"
              return (
                <div className={`item ${editfilter==item?"active":""}`}>
                  <div className="dot-border">
                    {(true || value) &&
                      <div
                      className="close"
                      onClick={(e) => {
                          e.stopPropagation()
                          let {filters} = this.props
                          if(filters[item]['type']=='daterange' && filters[item]['start']){
                            filters[item]={
                              type: 'daterange',
                              start: '',
                              end: ''
                            }
                          } else if(filters[item]['type']=='inlist' && Object.keys(filters[item]['options']).length>0){
                            filters[item]={
                              type: "inlist",
                              options:{}
                            }
                          } else {
                            delete filters[item]
                          }
                          update(filters)
                          filterTable()
                        }}
                      >
                      <div>{value!=''?'clear':'remove'}</div>
                      <Ionicon icon={`md-${value!=''?'close':'trash'}`}
                        color="transparent" 
                        fontSize="12px"/>
                    </div>}
                    <div className="name">{item}</div>
                    <div onClick={() => this.setState({editfilter:new Date()-closed < 100 && last==item?"":item})}>
                      {
                        value!=''?
                        <div className="chip">
                          <div className="text">{value}</div>
                          {
                            more > 0 &&
                            <div className="border">+{more}</div>
                          }
                          <i className={`pi pi-arrow-drop-${icon}`}></i>
                        </div>:
                        <div className="chip empty">
                          <div className="text">{o['type']=='daterange'?'From - To':'Choose'}</div>
                          <i className={`pi pi-arrow-drop-${icon}`}></i>
                        </div>
                      }
                    </div>
                  </div>
                  {editfilter==item &&
                    <div style={{position:'absolute',}}>
                      {filters[item]["type"]=="inlist" &&
                        <Popper 
                          allowClicks={true}
                          close={() => this.setState({editfilter:'',closed: new Date(),last:item})}
                          html={
                            <ListFilter 
                              filters={filters[item]} 
                              options={getFilterOptions(item)}
                              close={() => this.setState({editfilter:''})}
                              apply={(f) => {
                                filters[item] = f
                                this.setState({editfilter:''})
                                update(filters)
                                filterTable()
                              }}
                            />
                          }
                        />
                      }
                      {filters[item]["type"]=="daterange" &&
                        <Popper 
                          close={() => this.setState({editfilter:'',closed: new Date(),last:item})}
                          allowClicks={true}
                          html={
                            <div style={{zIndex:10000,border:'1px solid #ededed',position:'absolute',boxShadow:'0px 8px 15px 0px rgba(0,0,0,.15)',marginBottom:30}}>
                              <Calendar
                                //onChange={action('change')}
                                selected={[filters[item]["start"]?new Date(filters[item]["start"]):null,filters[item]["end"]?new Date(filters[item]["end"]):null]}
                                range={true}
                                onApply={(f) => {
                                  console.log("apply",f)
                                  let [start_date,end_date] = f
                                  filters[item]["start"] = this.date(start_date)
                                  filters[item]["end"] = this.date(end_date)
                                  console.log(filters)
                                  this.setState({editfilter:''})
                                  update(filters)
                                  filterTable()
                                }}
                                //onClear={action('clear')}
                              />
                            </div>
                          }
                        />
                      }
                    </div>
                  }
                </div>
              )
            })
          }
          <div className="item" style={{position:'relative'}}>
            <div onClick={() => this.setState({showmore:true})} className="add-btn">filters</div>
            {showmore &&
              <div style={{position:'absolute'}}>
                <Popper allowClicks={true} close={() => this.setState({showmore:false})}>
                  <div className="list-items">
                    {alloptions
                    	.filter(o => !filters[o.name])
                      .map(o => (
                      <div 
                        onClick={() => {
                          filters[o.name] = o
                          update(filters)
                          this.setState({editfilter:o.name,showmore:false})
                        }}
                        className="options">{o.name}</div>
                    ))}
                  </div>
                </Popper>
              </div>
            }
          </div>
        </div>
        <a href={downloadlink}>
        	<div className="item" style={{cursor:'pointer'}}>download</div>
        </a>
      </div>
    )
  }
}

class Table extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      table: this.props.table || [],
      params: {},
      downloadlink: '',
      options: {},
      filterOptions:{options:[]},
      audiourl:null,
      loading: false
    }
    this.url = ''
  }  

  getReportData = (params) => {
    
    let {assignment_id,rtype,filters} = params
    let { customURL } = this.props;
    if(!assignment_id)return;
    let newUrl = customURL || `${window._MDOMAIN}/api/v0/reports?rtype=${rtype}&assignment_id=${assignment_id}&filters=${JSON.stringify(filters)}`
    if (this.url!=newUrl) {
      this.setState({downloadlink:newUrl+'&download=true'})
      let self = this
      this.url = newUrl
      this.setState({loading:true})
      fetch(newUrl, {
        method: 'get',
        credentials:"include"
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        if(data.status==200) {
          self.setState({table:data.data})
          setTimeout(() => self.filterTable(),100)
        }
        this.setState({loading:false})
      }).catch(() => {
        this.setState({loading:false})
      })  
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps, () => this.getReportData(this.props.params))
  }

  componentDidMount(){
    this.getReportData(this.props.params)
  }

  formatPrice(val) {
    return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00','')
  }

  updateOptions(e) {
    let value = e.currentTarget.value
  }

  playAudio(audiourl) {
    this.setState({audiourl:null})
    setTimeout(() => {
      this.setState({audiourl})
    },100)
  }

	getFilterOptions = (key) => {
    let {table} = this.state
    if(table.length>0){
      let k = table[0].indexOf(key)
      let options = {}
      table.slice(1).map(o => {
        options[o[k]] = 1
      })
      return Object.keys(options).map(o => ({name:o,id:o}))
    }
    return []
  }
  
  filterTable = () => {
    let {table=[],filters={}} = this.state
    if(table.length==0) return;
    let f = []
    Object.keys(filters).map(o => {
      if(filters[o].type=='inlist' && Object.keys(filters[o].options).length>0){
        f.push({index:table[0].indexOf(o),options:JSON.parse(JSON.stringify(filters[o].options))})
      }
    })
    if(f.length==0){
      this.setState({filtertable:table.slice(1)})
      return
    }
    let filtertable = table.slice(1).filter(row => {
      let cond = true;
      for(let c of f){
        cond = cond && (c.options[row[c.index]])
        if(!cond) return false;
      }
      return cond;
    })
    this.setState({filtertable})
  }


  render() {
    let {table,downloadlink,filterOptions={"Lead Id":[{name:"hello",id:"hello"}]},audiourl,loading,filters={},filtertable=[]} = this.state
    let headers = {}
    let alloptions = []
    if(table.length){
      alloptions = table[0].map(o => ({name:o,type:'inlist',options:{}}))
    }
    return (
      <div className={css`
min-height:200px;
paddingbottom:20px;
.table-wrap {
  max-height: calc(100vh - 60px);
  width: calc(100vw - 200px);
  overflow: auto;
	position: relative;
	border-left: 1px solid #ededed;
}
table,tr,td,th {
	border-spacing: 0px;
	border-collapse: separate;
	font-size:10px;
}
table {
	overflow: auto;
	line-height: 1.1;
}
th {
	text-align: left;
	color: #6b7785;
	position: sticky;
	top: 0px;
	border-top: 1px solid #ededed;
	border-right: 1px solid #ededed;
	border-bottom: 1px solid #ededed;
	font-weight: normal;
	background: #fbfbfb;
}
td {
	color: #101721;
	max-width: 120px;
	background: #fff;
	padding: 6px;
	white-space: nowrap;
	overflow: hidden;
	border-right: 1px solid #ededed;
	border-bottom: 1px solid #ededed;
}
`}>
      <div style={{position:"relative"}}>
        {loading && <div>Loading</div>}
        {!loading &&
        	<div style={{display:"flex",flex:1,alignItems:"center",height:"40px",display:'none'}}>
            {
              audiourl?
              <div style={{position:"fixed",right:"30px",bottom:"30px",zIndex:10000000}}>
                <audio autoPlay controls>
                  <source src={audiourl} />
                </audio>
              </div>
              :null
            }
          </div>
        }
        <Filters downloadlink={downloadlink}
          filters={filters}
          alloptions={alloptions}
          filteroptions={{"Lead Id":[{name:"hello",id:"hello"}]}}
          getFilterOptions={this.getFilterOptions}
          filterTable={this.filterTable}
          update={(filters) => {
            this.setState({filters:JSON.parse(JSON.stringify(filters))})
          }}
          />
        <div className="table-wrap">
        <table>
          {table[0] && 
            <tr>
              <th style={{padding:6,position:'sticky',left:0,top:0,zIndex:100}}>sno</th>
              {
                table[0].map(o => (
                  <th><div style={{maxHeight:40,overflow:'hidden',padding:6,minWidth:50}}>{o}</div></th>
                ))
              }
            </tr>
          }
          {
            filtertable.slice(0,500).sort((a,b) => new Date(b[9]) - new Date(a[9])).map((row,r) => {
              return (
                <tr>
                  <td style={{padding:6,position:'sticky',left:0,zIndex:99}}>{r+1}</td>
                  {
                    row.map((cell,c) => {
                      if(r==0){
                        headers[c] = cell
                        return (
                          <td>
                            <div style={{display:"flex",alignItems:"center"}}>
                              <div style={{flex:1}}>{cell}</div>
                              <div style={{position:"relative"}}>
                                <Ionicon 
                                  style={{cursor:"pointer",display:"none"}}
                                  onClick = {(e) => this.showFilters(e,cell)}
                                  icon="md-arrow-dropdown" fontSize="18px" color="rgb(0, 0, 0)"/>
                              </div>
                            </div>
                          </td>
                        )
                      } else {
                        if(headers[c]=="calls") {
                          let urls = cell.split(",")
                          return (
                            <td className="calls">
                              <div style={{display:"flex"}}>
                                {
                                  urls.map((url) => (
                                    <div>
                                      <Ionicon 
                                        style={{cursor:"pointer"}}
                                        onClick = {(e) => this.playAudio(url)}
                                        icon="md-play" fontSize="18px" color={audiourl==url?'red':'black'}/>
                                    </div>
                                  ))
                                }
                              </div>
                            </td>
                          )  
                        } else {
                          return (
                            <td>{headers[c]=='spends' && parseFloat(cell)?this.formatPrice(parseFloat(cell)):cell}</td>
                            )  
                        }
                      }
                    })
                  }
                </tr>
              )
            })
          }
        </table>
        </div>
        <div>
          {filtertable.slice(500).length > 0 && 
            <div>{filtertable.slice(500).length} Results are hidden, Please Apply Filters or Download</div>
          }
        </div>
      </div>
      </div>
    )
  }
}

export default Table