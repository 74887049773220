import React, { PureComponent } from 'react'
import {css} from 'react-emotion'
import Ionicon from 'react-ionicons'
import {Button,OptionGroupCheckBox,Option,DropDown, OptionGroupRadio} from '@anarock/pebble';

function copy(text) {
  let elem = document.createElement('input')
  document.body.appendChild(elem)
  elem.value = text
  elem.select()
  elem.setSelectionRange(0, 99999);
	document.execCommand("copy");
  document.body.removeChild(elem);
  alert('copied url')
}

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const _fu = `
facebook,leadgen_ri
google,search_ri
google,gdn_ri
facebook,ctw_ri
native,ak_taboola
portals,99acres_ri
portals,housing_ri
linkedin,leadgen_ri
native,ak_yahoo
facebook,leadgen_nri
fixedbuys,toi_perf_ri
facebook,videoleadgen_ri
fixedbuys,moneycontrol_perf_ri
facebook,leadgen_gcc
google,gsp_ri
portals,cf_ri
`.split('\n').filter(o => o.trim()).map(o => o.split(','))

export default class UrlGenerator extends PureComponent {
  state = {}
  getSources = () => {
    let {assignment_id} = this.props.assignment
    let self = this
		fetch(`${window._MDOMAIN}/api/v0/url-generator?rtype=get&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({...data.data})
      }
      this.setState({loading:false})
    }).catch(() => {
      this.setState({loading:false})
    })
  }
  
  getUrls = () => {
    let {assignment_id} = this.props.assignment
    let self = this
		fetch(`${window._MDOMAIN}/api/v0/assignments?rtype=get&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({urls:(data.data[0].meta || {}).urls || []})
      }
      self.setState({loading:false})
    }).catch(() => {
      self.setState({loading:false})
    })
  }
  
  componentDidMount() {
    this.getSources()
    this.getUrls()
  }
	getUrl = (url) => {
    let {source='',subsource='',campaign=''} = url
    let {assignment} = this.props
		return `${assignment.meta.microsite_url}?utm_source=${encodeURIComponent(source)}&utm_medium=${encodeURIComponent(subsource)}${campaign?`&utm_campaign=${encodeURIComponent(campaign)}`:''}`;
  }
  saveCopy = () => {
    let {source='',subsource='',campaign='',urls=[]} = this.state
    
    let {assignment} = this.props
    let {assignment_id} = assignment
    
    urls = [{source,subsource,campaign,time:new Date()},...urls]
    assignment['meta']['urls'] = urls
    assignment.meta['urls'] = urls
    
    let self = this
		fetch(`${window._MDOMAIN}api/v0/assignments?rtype=post`, {
      method: 'post',
      body: JSON.stringify(assignment),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        self.setState({urls:(data.data.meta || {}).urls || [],source:'',subsource:'',campaign:''})
        copy(self.getUrl({source,subsource,campaign}))
      } else {
        self.setState({message:"Unable to Save",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Save",message_id:new Date()})
    })
  }
  deleteURL = (index) => {
    let {urls=[]} = this.state
    
    let {assignment} = this.props
    let {assignment_id} = assignment
    
    urls.splice(index,1)
    assignment['meta']['urls'] = urls || []
    assignment.meta['urls'] = urls || []
    
    let self = this
		fetch(`${window._MDOMAIN}api/v0/assignments?rtype=post`, {
      method: 'post',
      body: JSON.stringify(assignment),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        self.setState({urls:(data.data.meta || {}).urls || []})
      } else {
        self.setState({message:"Unable to Save",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Save",message_id:new Date()})
    })
  }
  getCsvData = () => {
    let {urls=[]} = this.state
    return 'URLS\n'+urls.map(o => this.getUrl(o)).join('\n')
  }
  render() {
    let {campaign='',sources=[],subsources=[],urls=[],source='',subsource='',filtersource,filtersubsource,fu=_fu} = this.state
    let {assignment} = this.props
    let current_url = '';
    try {
    	current_url = this.getUrl({source,subsource,campaign})
    } catch(e) {
    }
    return (
      <div className={css`
table,td,tr {
	font-size: 12px;
	border: 1px solid #e0e0e0;
	background: #fff;
}
td {
	min-width:40px;
	max-width:600px;
	padding:5px;
	white-space: nowrap;
	overflow:hidden;
}
`}>
    {assignment.meta && assignment.meta.microsite_url?
      <div>
        <div style={{border:'1px solid #e0e0e0',background:'#fff',marginBottom:20,padding:20,borderRadius:3,width:'fit-content'}}>
          <div style={{color:'#6b7785'}}>microsite url</div> 
          <div style={{display:'flex',alignItems:'center'}}>{assignment.meta.microsite_url}
            <a style={{marginLeft:10}} href={assignment.meta.microsite_url} target="_blank"><Ionicon color="#6b7785" icon="md-open"/></a>
          </div>
        </div>
        
        <div style={{border:'1px solid #e0e0e0',background:'#fbfbfb',marginBottom:20,padding:20,borderRadius:3,width:'fit-content'}}>
          <div style={{color:'#6b7785'}}>Create New Url</div>
          <div style={{display:'flex',marginBottom:20,marginTop:10}}>
            <DropDown 
              ref={(r) => {
                this.dropdown1 = r
              }}
              buttonLabel={source || 'Select Source'}>
              {() => (
                <OptionGroupRadio 
                  searchBox
                  searchBoxProps={{
                    placeholder: 'Search',
                    onChange: value => this.setState({filtersource:value})
                  }}
                  onChange = {(source) => {
                    this.setState({source})
                    this.dropdown1.toggleDropdown()
                  }}
                  >
                  {
                    sources
                    .filter(o => o.name.toLowerCase().indexOf((filtersource||'').toLowerCase())!=-1)
                    .map((o) => (
                      <Option value={o.name} label={o.name} />
                    ))
                  }
                </OptionGroupRadio>
              )}
            </DropDown>
            <div style={{width:20}}></div>
            <DropDown 
              ref={(r) => {
                this.dropdown2 = r
              }}
              buttonLabel={subsource || 'Select Medium / Sub Source'}>
              {() => (
                <OptionGroupRadio 
                  searchBox
                  searchBoxProps={{
                    placeholder: 'Search',
                    onChange: value => this.setState({filtersubsource:value})
                  }}
                  onChange = {(subsource) => {
                    this.setState({subsource})
                    this.dropdown2.toggleDropdown()
                  }}
                  >
                  {
                    subsources
                    .filter(o => o.name.toLowerCase().indexOf((filtersubsource||'').toLowerCase())!=-1)
                    .map((o) => (
                      <Option value={o.name} label={o.name} />
                    ))
                  }
                </OptionGroupRadio>
              )}
            </DropDown>
            <input spellCheck={false} placeholder="placement/campaign" style={{padding:'5px 20px',marginLeft:20,border:'1px solid #ededed',borderRadius:3}} value={campaign} onChange={(e) => this.setState({campaign:e.target.value.replace(/#/g,'')})}/>
          </div>
          <div>
            {
              source && subsource && assignment.meta.microsite_url &&
                <div style={{display:'flex',marginBottom:10,borderRadius:3,border:'1px solid #ededed'}}>
                	<div style={{flex:1,background:'#fff',padding:10,fontSize:11}}>{current_url}</div>
                  <div className={css`cursor:pointer;:hover{background:#ededed;}`}style={{padding:10,display:'flex',alignItems:'center'}} onClick={() => this.saveCopy()}>save and copy</div>
                </div>
            }
          </div>
          {urls.length>0 &&
            <div style={{}}>
              <div style={{marginBottom:10,display:'flex'}}>
                <div>list of urls</div>
                <div style={{marginLeft:20,cursor:'pointer'}} onClick={() => download(`${assignment.extra_details.assignment_name.toLowerCase().replace(' ','_')}_urls.csv`,this.getCsvData())}><Ionicon icon="md-download" color="#6b7785"/></div>
              </div>
              <table>
                <tr><td>URL</td><td>Source</td><td>Medium/Subsource</td><td>Actions</td></tr>
                {
                  urls
                  .map((o,_i) => (
                    <tr>
                      <td>{this.getUrl(o)}</td>
                      <td>{o['source'] || ''}</td>
                      <td>{o['subsource'] || ''}</td>
                      <td>
                        <div onClick={() => {
                            let confirm = prompt("Type 'yes' to delete the url")
                            if(confirm=='yes'){
                              this.deleteURL(_i)
                            }
                          }}
                          style={{textDecoration:'underline',color:'#d32f02',textAlign:'center',cursor:'pointer'}}>remove</div>
                      </td>
                    </tr>
                  ))
                }
              </table>
            </div>
          }
        </div>
        <div>
          Fix missing sources <a href={`https://lead.${window._TDOMAIN}/channel_sources`} target="_blank">here</a>
        </div>
        <div style={{marginTop:20,fontSize:11}}>
          <div>*Please note the selecting source google and facebook compulsory if you are generating these URLs for any google or facebook campaign. </div>
        </div>
      </div>:
      <div>
        Add Microsite Url in Assignment Details
      </div>
    }
    </div>
    )
  }
}