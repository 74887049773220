import React, {PureComponent, Fragment} from 'react'
import {css} from 'react-emotion'
import {DateRange} from '../components'
import Ionicon from 'react-ionicons'

class SimpleTable extends PureComponent {
  formatNum = (num,name='') => {
    if(name=='start' || name=='end'){
    	let [m,d,y] = new Date(num).toString().split(" ").slice(1,4)
    	return `${d} ${m}`
    }
    if((name || '').toLowerCase().indexOf('rate')!=-1){
      if(!num) return '';
      return num+' %'
    }
    if(num=='Total') return 'Total';
    if((name||'').toLowerCase().indexOf('month')!=-1){
      if(!num) return '';
      let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      return months[parseInt(num)%100-1]
    }
    if((name||'').toLowerCase().indexOf('date')!=-1 || (name||'').toLowerCase().indexOf('week')!=-1){
      if(!num) return '';
      var d = new Date(0);
      d.setUTCSeconds(num/1000);
      let [w,m,d,y,_] = d.toString().split(' ');
      return `${m} ${d}`
    }
    if(['number'].indexOf(typeof(num))!=-1) {
      return Math.round((parseFloat(num) || 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\..*/,'');
    } else {
      return num
    }
  }
  render() {
    let {table,columns} = this.props
    let totals = table.slice(-1)[0]
    return (
      <div className={css`
tr:last-child {
	background: #6b7785;
	color: #fff;
}
td:first-child {
	text-align: left;
}
`}>
        <table>
          <tr>
            {
              columns.map(k => <th>{k}</th>)
            }
          </tr>
          {table.map((row,_r) => (
            <tr>
            	{columns.map(k => 
                <td>
                  {this.formatNum(row[k],k)}
                  {k=='budget' && <div style={{display:'inline-block',width:30,fontSize:10,textAlign:'right',color:'#6b7785'}}>{Math.round(row[k]*100/totals[k])}%</div>}
                </td>)
          		}
            </tr>
          ))}
        </table>
      </div>
    )
  }
}

class Table extends PureComponent {
  state = {groups:{},history:[JSON.parse(JSON.stringify(this.props.table))],table:JSON.parse(JSON.stringify(this.props.table))}//{leads:{search_ri:'search_ri',gdn_ri:'search_ri'}}}
	formatNum = (num,name='') => {
    if(name=='start' || name=='end'){
    	let [m,d,y] = new Date(num).toString().split(" ").slice(1,4)
    	return `${d} ${m}`
    }
    if((name || '').toLowerCase().indexOf('rate')!=-1){
      if(!num) return '';
      return num+' %'
    }
    if(num=='Total') return 'Total';
    if((name||'').toLowerCase().indexOf('month')!=-1){
      if(!num) return '';
      let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      return months[parseInt(num)%100-1]
    }
    if((name||'').toLowerCase().indexOf('date')!=-1 || (name||'').toLowerCase().indexOf('week')!=-1){
      if(!num) return '';
      var d = new Date(0);
      d.setUTCSeconds(num/1000);
      let [w,m,d,y,_] = d.toString().split(' ');
      return `${m} ${d}`
    }
    if(['number'].indexOf(typeof(num))!=-1) {
      return Math.round((parseFloat(num) || 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\..*/,'');
    } else {
      return num
    }
  }
  scaleValues(scale,c){
    let {columns} = this.props
    let {table} = this.state
    table = table.map((row,r) => {
      this.setValue([r,c,Math.round(row[columns[c]]*scale),true])
      this.completeRow(row)
      return row
    })
    this.setState({edit:''})
    this.update(table)
  }

	saveTable = (table,id,assignment_id) => {
    this.setState({loading:true})
    let self = this
    fetch(`${window._MDOMAIN}api/v0/mediaplan?rtype=save-table&id=${id}&assignment_id=${assignment_id}`, {
      method: 'post',
      body: JSON.stringify(table),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        self.setState({history:[table],table:table})
        self.props.update(table)
      } else {
        self.setState({message:"Unable to Load Mediaplan",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load Mediaplan",message_id:new Date()})
    })
  }
  
  setValue = (input) => {
    let sets = [['budget'],['leads','cpl'],['qleads','qrate','cpql'],['svdone','svrate','cpsv'],['bookings','cpb']]
    let {columns} = this.props
    let {table} = this.state
    let r,c,value,auto;
    if(!input){
    	let {edit,value:v} = this.state
      value = v;
      [r,c] = edit.split(":").map(o => parseInt(o))  
    } else {
      [r,c,value,auto] = input
    }
    
  
    if(table.length==r){
      value = (value || '').replace(/[^0-9]/g,'')
      if(c<2) return;
      let totals = this.getTotals(table)
      if(parseFloat(value)/totals[columns[c]]==1) return;
      this.scaleValues(parseFloat(value)/totals[columns[c]],c)
      return
    }
    
    if(this.getMetric(table[r],columns[c])==value)return;
    
    let obj = table[r];
    let col = columns[c]
    
    obj[col] = c<2?(value||'').trim():parseInt((value || '').toString().replace(/[^0-9]/g,''))
    for(let set of sets){
      if(set.indexOf(col)!=-1){
        for(let k of set){
          if(k!=col){
            if(obj.hasOwnProperty(k)){
              delete obj[k]
            }
          }
        }
      }
    }
    this.completeRow(obj)
    this.select=true
    if(!auto){
    	this.update(table)  
    }
  }
  handleKeyDown = (e) => {
    let {edit,value} = this.state
    let {columns} = this.props
    let {table} = this.state
    if(e.key=='ArrowRight' || (e.key=='Tab' && !e.shiftKey)){
      e.preventDefault()
      this.setValue()
      let [r,c] = edit.split(":").map(o => parseInt(o))
      if(c+1 > 10) return;
      this.setState({edit:(r)+':'+(c+1),value:this.getMetric(table[r],columns[c+1])})
      this.select = true
    } else if(e.key=='ArrowLeft' || (e.key=='Tab' && e.shiftKey)){
      e.preventDefault()
      this.setValue()
      let [r,c] = edit.split(":").map(o => parseInt(o))
      if(c-1 < 0) return;
      this.setState({edit:(r)+':'+(c-1),value:this.getMetric(table[r],columns[c-1])})
      this.select = true
    } else if(e.key=='ArrowDown'){
      this.setValue()
      let [r,c] = edit.split(":").map(o => parseInt(o))
      if(r+1 >= table.length) return;
      this.setState({edit:(r+1)+':'+(c),value:this.getMetric(table[r+1],columns[c])})
      this.select = true
    } else if(e.key=='ArrowUp'){
      this.setValue()
      let [r,c] = edit.split(":").map(o => parseInt(o))
      if(r-1 < 0) return;
      this.setState({edit:(r-1)+':'+(c),value:this.getMetric(table[r-1],columns[c])})
      this.select = true
    } else if(e.key=='Enter'){
      this.setValue()
    } else if(e.key=='Escape') {
      let [r,c] = edit.split(":").map(o => parseInt(o))
      this.setState({edit:(r)+':'+(c),value:this.getMetric(table[r],columns[c])})
      this.select = true
    } else if(e.key=='z' && (e.ctrlKey || e.metaKey)) {
      let {history,table} = this.state
      if(history.length>1){
        let current = history.pop()
        let past = history.pop()
        this.update(past)
        let {edit} = this.state
        let {columns} = this.props
        if(edit && edit.indexOf(":")!=-1){
          let [r,c] = edit.split(":").map(o => parseInt(o))
          this.setState({edit:(r)+':'+(c),value:this.getMetric(past[r],columns[c])})
          this.select = true
        }
      }
    }
  }
	
  getMetric = (row,metric) => {
    if(row && row.hasOwnProperty(metric)){return this.formatNum(row[metric])}
    console.log("error in getMetrics")
    return 0
  }
  completeRow = (row) => {
    // console.log("incomplete row",row)
    if(!row.hasOwnProperty('budget')){
      console.log("no budget")
      return
    }
    
    if(row.hasOwnProperty('cpl')) {
      row['leads'] = row['budget']/row['cpl']
    } else if(row.hasOwnProperty('leads')) {
      row['cpl'] = row['budget']/row['leads']
    }
    if(!row.hasOwnProperty('leads')) {
      console.log("no leads")
      return
    }
    
    if(row.hasOwnProperty('qrate')) {
      row['qleads'] = row['leads']*row['qrate']/100
      row['cpql'] = row['budget']/row['qleads']
    } else if(row.hasOwnProperty('qleads')) {
      row['qrate'] = row['qleads']*100/row['leads']
      row['cpql'] = row['budget']/row['qleads']
    } else if(row.hasOwnProperty('cpql')) {
      row['qleads'] = row['budget']/row['cpql']
      row['qleads'] = row['leads']*100/row['qrate']
    }
    if(!row.hasOwnProperty('qleads')) {
      console.log("no qleads")
      return
    }
    
    
    if(row.hasOwnProperty('svrate')) {
      row['svdone'] = row['qleads']*row['svrate']/100
      row['cpsv'] = row['budget']/row['svdone']
    } else if(row.hasOwnProperty('svdone')) {
      row['svrate'] = row['svdone']*100/row['qleads']
      row['cpsv'] = row['budget']/row['svdone']
    } else if(row.hasOwnProperty('cpsv')) {
      row['svdone'] = row['budget']/row['cpsv']
      row['svdone'] = row['qleads']*row['svrate']/100
    }
    if(!row.hasOwnProperty('svdone')) {
      console.log("no svdone")
      return
    }
    
    if(row.hasOwnProperty('bookings')) {
      row['cpb'] = row['budget']/row['bookings']
    } else if(row.hasOwnProperty('cpb')) {
      row['bookings'] = row['budget']/row['cpb']
    }
  }
  getTotals = (table) => {
    let totals = {totals:true,source:'Totals',subsource:' '}
    table.map(row => {
      ['budget','leads','qleads','svdone','bookings'].map(k => {
        totals[k] = (totals[k]||0)+(row[k] || 0)
      })
    })
    totals['cpl'] = totals['budget']/totals['leads']
    totals['cpql'] = totals['budget']/totals['qleads']
    totals['qrate'] = totals['qleads']*100/totals['leads']
    totals['cpsv'] = totals['budget']/totals['svdone']
    totals['svrate'] = totals['svdone']*100/totals['qleads']
    totals['cpb'] = totals['budget']/totals['bookings']
    
    return totals
  }
  
  runFormula = () => {
    let {table} = this.state
    for(let row of table){
      this.completeRow(row)
    }
    // update(JSON.parse(JSON.stringify(table)))
  }
  
  handlePaste = (event) => {
    let {columns} = this.props
    let {table} = this.state
    let {edit,value} = this.state
    let [rOffset,cOffset] = edit.split(":").map(o => parseInt(o))
    this.setState({value:''})
    
    var text = event.clipboardData.getData('text')
    var rows = text.split("\n")
    rows.map((row,r) => {
      var cols = row.split("\t")
      cols.map((cell,c) => {
        try{
          let nr = rOffset+r
          let nc = cOffset+c
          console.log(nr,table.length-1)
          if(nr>=table.length){
            table.push({})
          }
          this.setValue([nr,nc,nc<2?cell:parseInt(cell.replace(/[^0-9.]/g,'').trim()) || 0,true])
        } catch(e){
          console.error("handlePaste",e)
        }
      })
    })
    setTimeout(() => {
      this.update(this.state.table)
    	this.setState({edit:(rOffset)+':'+(cOffset),value:this.getMetric(table[rOffset],columns[cOffset])})
    	this.select = true
    },100)
    
    // document.querySelector(".entry").blur()
    // this.setState({data,entry:"0_0",dirty})
  }
  
  update = (t) => {
    let {table,history} = this.state
    history.push(t)
    this.setState({table:JSON.parse(JSON.stringify(t))})
    console.log(history)
  }
  
  getOptions() {
    this.setState({loading:true})
    let self = this
    fetch(`${window._MDOMAIN}api/v0/mediaplan?rtype=options`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        let sources = {}
        let subsources = {}
        data.data.subsource.map(o => {
          subsources[o['name']] = 1
        })
        data.data.source.map(o => {
          sources[o['name']] = 1
        })
        self.setState({sources,subsources})
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }

  
  componentDidMount(){
    this.getOptions()
  }
  
  
  render() {
    let {sugg=false,edit='',value='',groups,table,history,sources={},subsources={}} = this.state
    let {columns} = this.props
    let totals = this.getTotals(table)
    let renderdata = [...table,totals];
    let groupsused = {}
    let savedplan = history[0]
    
    for(let k in groups){
      groupsused[k] = {}
      let toset = groupsused[k]
      let vals = groups[k]
      for(let k in vals){
        toset[vals[k]] = (toset[vals[k]] || 0) + 1
      }
    }
    
    
    renderdata.map((row,_r) => {
      columns.map((o,_c) => {
        let col = columns[_c]
        let groupkey = (groups[col] || {})[row['subsource']]
        if(groupkey){
          for(let i=_c;i<13;i++){
            groupsused[_r+':'+i] = groupsused[col][groupkey]
          }
          groupsused[col][groupkey]=0
        }
      })
    })
    
    let issues = 0
    renderdata.slice(0,-1).map(r => {
      if(!sources[r['source']]) issues += 1
      if(!subsources[r['subsource']]) issues += 1
    })
    
    const enableEditMap = {
      leads: 'budget',cpl: 'budget',
      qrate:'leads',qleads:'leads',cpql:'leads',
      svrate:'qleads',svdone:'qleads',cpsv:'qleads',
      bookings:'svdone',cpb:'svdone'
    }
    
    return (
      <div className={css`
-webkit-print-color-adjust:exact;
.sug {
	padding: 5px 10px;
	border:1px solid #6b7785;
	cursor:pointer;
	text-align:left;
	:hover {
		background: #ededed;
	}
}
.totals {
	td {
		color: #fff !important;
	}
	background: #6b7785;
}
.btn{
	all:unset;
	font-size: 16px;
	margin-right:10px;
	min-width: 20px;
	height: 20px;
	padding:5px 10px;
	border-radius: 3px;
	background: #ededed;
	color: #6b7785;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: #ededed;
	:hover {
		filter: brightness(.6);
		background: #ededed;
	}
}
`}>
        <div style={{display:'flex',margin:5}}>
          <div className="btn" onClick={() => {
              let {edit="0:0"} = this.state
              let r = parseInt(edit.split(":")[0])
              table = [...table.slice(0,r),{source:'',subsource:'',budget:0},...table.slice(r)]
              this.setState({edit:r+':0',value:this.getMetric(table[r],'source')})
              this.select = true
              this.update(table)
            }}>+</div>
          <div className="btn" onClick={() => {
              if(!edit || edit.indexOf(":")==-1) return;
              let [r,c] = edit.split(':').map(o => parseInt(o))
              table.splice(r,1)
              r = Math.min(r,table.length-1)
              this.setState({edit:r+':0',value:this.getMetric(table[r],'source')})
              this.select = true
              this.update(table)
            }}>-</div>
          <div style={{flex:1,display:'flex',justifyContent:'flex-end'}}>
            {history.length>1?
              <div style={{display:'flex'}}>
                {issues>0 &&<div style={{padding:6,background:'#fff',color:'#d32f02',marginRight:30,borderRadius:3}}>{issues} sources/subsources are incorrect. Please fix to save changes</div>}
                {issues==0 && 
                  <div className="btn" onClick={() => {
                    if(edit && parseInt(edit.split(':')[0])!=table.length){
                      this.setValue()
                    }
                    this.saveTable(table,this.props.id,this.props.assignment_id)
                  	}}>save</div>
                }
                <div className="btn" onClick={() => {
										this.setState({history:[JSON.parse(JSON.stringify(this.props.table))],table:JSON.parse(JSON.stringify(this.props.table))})
                  }}>discard</div>
            	</div>:
              <div onClick={this.props.close} className="btn">
                close
              </div>
            }
          </div>
        </div>
        <table>
          <tr>{columns.map(o => (<th>{o}</th>))}</tr>
          {renderdata.map((row,_r) => (
            <tr className={`${row['totals']?'totals':''}`} >
            {columns.map((o,_c) => (
              groupsused[_r+':'+_c]!=0?
              <td 
                style={{color:(() => {
                    let color = '#000';
                    if(savedplan[_r] && row[o]!=savedplan[_r][o]) color = '#f3aa07';
                    if(_c==0 && !sources[row[o]]){
                      color = '#d32f02';
                    }
                    if(_c==1 && !subsources[row[o]]){
                      color = '#d32f02';
                    }
                    return color
                  })(),position:'relative'}}
                rowSpan={groupsused[_r+':'+_c] || 1}
                onClick={() => {
                    if(_c < 2 && table.length==_r) return;
                    try{
                      if(edit && parseInt(edit.split(':')[0])!=table.length){
                      	this.setValue()  
                      }
                    } catch(e){console.error(e)}
                    this.setState({edit:_r+':'+_c,value:this.getMetric(row,o)})
                    this.select = true
                  }}>
                  	{enableEditMap[o] && !row[enableEditMap[o]] > 0 &&
                  		<div style={{background:'rgba(0,0,0,.5)',width:'100%',height:'100%',position:'absolute',top:0,left:0,zIndex:1000}}></div>
                    }
                        {_r+":"+_c==edit?
                          <div>
                            <div onKeyDown={this.handleKeyDown} className="input">{value}</div>
                            <input 
                              onClick={e => {
                          			this.keydown = true
                        			  e.target.style['opacity']=1
                          			e.stopPropagation()
                        			}}
                              onPaste={this.handlePaste}
                              style={{opacity:_r==table.length?1:0}} spellCheck={false} 
                              onKeyDown={(e) => {
                          			if(_r!=table.length && !(this.keydown && e.key.indexOf('Arrow')!=-1)){
                                  this.handleKeyDown(e)
                                } else if(e.key=='Escape'){
                                  this.keydown = false
                        			  	e.target.style['opacity']=0
                                }
                        			}}
                              ref={o => {
                          					if(o && this.select){
                                      this.keydown = false
                                      o.focus()
                                      if(this.blur){
                                        clearTimeout(this.blur)
                                      }
                                      setTimeout(() => o.select(),100)
                                      this.select = false
                                    }
                                  }}
                              //onBlur = {() => this.blur = setTimeout(() => this.setState({value:'',edit:''}),10)}
                              value={value} 
                              onChange={(e) => this.setState({value:e.target.value})} />
                          </div>
                          :null
                        }
                        {true && _r+":"+_c==edit && ['source','subsource'].indexOf(o)!=-1 &&
                    			<div>
                      			<div style={{position:'absolute',top:5,background:'#fff',cursor:'pointer'}}><Ionicon onClick={e => this.setState({sugg:!sugg})} fontSize="14" icon={sugg?"md-arrow-dropup":"md-arrow-dropdown"} color="#6b7785"/></div>
                            {sugg && <div style={{position:'absolute',background:'#fff',zIndex:10000,minWidth:100,top:30,color:'#6b7785',maxHeight:300,overflow:'auto',boxShadow: '0px 8px 15px 0px rgba(0,0,0,.15)'}}>
                              {(() => {
                        				let options = Object.keys((o=='source'?sources:subsources))
                                  .filter(o => (o||'').indexOf(value||'')!=-1);
                        				console.log(Object.keys((o=='source'?sources:subsources)),value)
                                return options.length?options
                                  .map(o => (
                                  <div className="sug" onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      table[_r][columns[_c]] = o
                                      this.setState({edit:'',value:''})
                                      this.update(table)
                                    }}>{o}</div>
                                )):
                                <div>no suggestions</div>
                              })()}
                            </div>}
                    			</div>
                        }
              					{this.formatNum(this.getMetric(row,o),o)}
                  {o=='budget' && <div style={{color:'#6b7785',display:'inline-block',textAlign:'right',fontSize:10,width:30}}>{Math.round(row[o]*100/totals[o])}%</div>}
              </td>
              :null
              ))}
            </tr>
          ))}
        </table>
      </div>
    )
  }
}

export default class MediaPlan extends PureComponent {
  state = {
    plans : [],
    planning:[
      {name:'Phase 1',start:'2019/04/01',end:'2019/04/30',budget:200000,leads:200,qleads:100,svdone:10},
      {name:'Phase 2',start:'2019/04/30',end:'2019/05/30',budget:200000,leads:200,qleads:100,svdone:10},
      {name:'Phase 3',start:'2019/04/30',end:'2019/05/30',budget:200000,leads:200,qleads:100,svdone:10},
      {name:'Phase 4',start:'2019/04/30',end:'2019/05/30',budget:200000,leads:200,qleads:100,svdone:10},
      {name:'Phase 5',start:'2019/04/30',end:'2019/05/30',budget:200000,leads:200,qleads:100,svdone:10}
    ],
    observations: [
      {observations:"Here Previous email observations will appear"}
    ],
    pcolumns: ['name','start','end','budget','leads','cpl','qleads','qrate','cpql','svdone','svrate','cpsv'],
    columns:['source','subsource','budget','leads','cpl','qleads','qrate','cpql','svdone','svrate','cpsv']
  }

	getPlans = (assignment_id) => {
    this.setState({loading:true})
    let self = this
    fetch(`${window._MDOMAIN}api/v0/mediaplan?rtype=get&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        if(data.data){
        	let plans = data.data
          self.oplans = JSON.parse(JSON.stringify(plans))
        	self.setState({plans})
        }
      } else {
        self.setState({message:"Unable to Load Mediaplan",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load Mediaplan",message_id:new Date()})
    })
  }
  
  toggleStatus = (plan,assignment_id) => {
    this.setState({loading:true})
    let self = this
    let status = plan['status']=='active'?'inactive':'active'
    fetch(`${window._MDOMAIN}api/v0/mediaplan?rtype=togglestatus&assignment_id=${assignment_id}&status=${status}&id=${plan["id"]}`, {
      method: 'post',
      body: JSON.stringify(plan),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        if(data.data){
          plan['status'] = status
          let {plans} = self.state
          self.oplans = JSON.parse(JSON.stringify(plans))
          this.setState({cb:new Date()})
        }
      } else {
        self.setState({message:"Unable to Toggle Status",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Toggle Status",message_id:new Date()})
    })
  }
  
  createPlan = (assignment_id,duplicate_id,name) => {
    this.setState({loading:true})
    let self = this
    fetch(`${window._MDOMAIN}api/v0/mediaplan?rtype=create&assignment_id=${assignment_id}&duplicate_id=${duplicate_id}&name=${name}`, {
      method: 'post',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        self.getPlans(assignment_id)
      } else {
        self.setState({message:"Unable to Create Mediaplan",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Create Mediaplan",message_id:new Date()})
    })
  }
  
  deletePlan = (assignment_id,id) => {
    this.setState({loading:true})
    let self = this
    fetch(`${window._MDOMAIN}api/v0/mediaplan?rtype=delete&assignment_id=${assignment_id}&id=${id}`, {
      method: 'post',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        self.getPlans(assignment_id)
      } else {
        self.setState({message:"Unable to Delete Mediaplan",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Delete Mediaplan",message_id:new Date()})
    })
  }
  
  downloadPlan = (assignment_id,id) => {
    window.open(`${window._MDOMAIN}api/v0/mediaplan?rtype=download&assignment_id=${assignment_id}&id=${id}`)
  }
  
  savePlan = (plan,assignment_id) => {
    this.setState({loading:true})
    let self = this
    fetch(`${window._MDOMAIN}api/v0/mediaplan?rtype=save&assignment_id=${assignment_id}`, {
      method: 'post',
      body: JSON.stringify(plan),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        if(data.data){
          let mediaplan = data.data[0]
          let {plans} = self.state
          // plans = plans.map(o => o.id==mediaplan.id?mediaplan:o)
          self.oplans = JSON.parse(JSON.stringify(plans))
        	self.setState({cb:new Date()})
        }
      } else {
        self.setState({message:"Unable to Load Mediaplan",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load Mediaplan",message_id:new Date()})
    })
  }

	formatDate = (num) => {
    let [m,d,y] = new Date(num).toString().split(" ").slice(1,4)
    return `${d} ${m}`
  }
  
  componentDidMount() {
    let {assignment_id} = this.props.assignment
    this.getPlans(assignment_id)
  }

	render() {
    let {selected,edit_plan=-1,edit='',value='',columns,observations,planning,pcolumns,plantab='performance',phasetab='overall',plans} = this.state
    let {assignment} = this.props
    let {assignment_id} = assignment
    
    return (
      <div className={css`
width: 1200px;
table {
	margin: 5px;
	background: white;
}
table, td, th {
	border: 1px solid #ededed;
	border-collapse: collapse;
	text-align: left;
}
th {
	background: #6b7785;
	color: #fff;
	white-space: nowrap;
	font-size:12px;
	padding:5px;
}
td {
	padding:5px;
	width:100px;
	position: relative;
	input {
		left:0px;
		top:-1px;
		position: absolute;
		width: 100%;
		height:100%;
		outline:none;
		border: 1px solid #6b7785;
		background: #fff;
		border-radius: 3px;
		text-align:right;
		padding:4px;
		font-size:12px;
		font-family: Anarock;
	}
	.input {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border: 2px solid #1f69ff;
		background: #fff;
		left:0px;
		top:0px;
		padding: 3px;
		width: 100%;
		height: 100%;
		position: absolute;
	}
	white-space: nowrap;
	font-size:12px;
	text-align: right;
}
.btn-icon svg {
	cursor: pointer;
	:hover {
		fill: #000;
		transform: scale(1.2);
	}
}
`}>
        <div>
          <div>
            	<div className="btn" onClick={() => {
                let name = prompt("Provide name for new Plan")
                if(name){
                	this.createPlan(assignment.assignment_id,0,name)  
                }
              }}>Create Plan</div>
          </div>
          <div style={{marginTop:20}}>
            {
              plans.map((plan,_i) => (
                <div style={{marginBottom:50,border:'1px solid #ededed',padding:20,width:'fit-content',background:'#fbfbfb',borderRadius:3}}>
                  <div style={{display:'flex',marginBottom:10,alignItems:'center'}}>
                    <div>
                      <input 
                        style={{border:'none',outline:'none',background:'white',width:200,padding:10}}
                        value={plan['version']} onChange={(e) => {
                          plan['version'] = e.target.value
                          this.setState({plans:JSON.parse(JSON.stringify(plans))})
                        }} />
                    </div>
                    <div style={{margin:'0px 10px'}} onClick={() => this.toggleStatus(plan,this.props.assignment.assignment_id)}>
                      {plan.status=='active'?
                        <img style={{width:40}} src="https://cdn-icons-png.flaticon.com/512/889/889754.png"></img>:
                      	<img style={{width:40}} src="https://cdn-icons-png.flaticon.com/512/889/889758.png"></img>
                      }
                    </div>
                    <div style={{marginLeft:10,textDecoration:'underline',color:'#6b7785'}} onClick={() => this.setState({edit_plan:_i})} className="btn-icon">
                      <Ionicon icon="md-create" color="#6b7785"/>
                    </div>
                    <div style={{marginLeft:10,textDecoration:'underline',color:'#6b7785'}} onClick={() => {
                        let name = prompt("Provide Name for new Plan")
                        if(name){
                        	this.createPlan(assignment.assignment_id,plan.id,name)  
                        }
                      }} className="btn-icon">
                      <Ionicon icon="md-copy" color="#6b7785"/>
                    </div>
                    <div style={{marginLeft:10,textDecoration:'underline',color:'#6b7785'}} onClick={() => {
                        let confirm = prompt("Type 'yes' to delete the plan")
                        if(confirm=='yes'){
                        	this.deletePlan(assignment.assignment_id,plan.id)
                        }
                      }} className="btn-icon">
                      <Ionicon icon="md-trash" color="#6b7785"/>
                    </div>
                    <div style={{marginLeft:10,textDecoration:'underline',color:'#6b7785'}} onClick={() => {
                        this.downloadPlan(assignment.assignment_id,plan.id)
                      }} className="btn-icon">
                      <Ionicon icon="md-download" color="#6b7785"/>
                    </div>
                    <div style={{display:JSON.stringify(plan)!=JSON.stringify(this.oplans[_i])?'block':'none',color:'#fff',padding:'5px 10px',height:'fit-content',marginLeft:'20px'}} 
                      className="btn"
                      onClick={() => this.savePlan(plan,this.props.assignment.assignment_id)}>
                      save
                    </div>
                  </div>
                  <div style={{display:'flex'}}>
                    <div>
                      <DateRange key1="startdate" key2="enddate" handleDayClick={(k,v) => {plan[k]=v;this.setState({cb:new Date()})}} getValue={(k) => plan[k] || null}  />
                    </div>
                  </div>
                  <div style={{marginTop:20}}>
                  	<SimpleTable table={plan.summary} columns={['source','budget','leads','cpl','qleads','cpql','qrate','svdone','cpsv','svrate']}/>
                  </div>
                  {edit_plan==_i &&
                    <div 
                      style={{overflow:'auto',zIndex:100000,padding:10,position:'fixed',left:0,top:0,width:'100vw',height:'100vh',background:'rgba(0,0,0,0.5)',display:'flex',justifyContent:'center'}}>
                      <div style={{height:'fit-content'}}>
                        <Table 
                          close={() => {
                            this.getPlans(assignment_id)
                            this.setState({edit_plan:-1})
                          }}
                          id={plan.id}
                          assignment_id={this.props.assignment.assignment_id}
                          table={plan.json || []} columns={columns} update={(data) => {
                          plans[_i].json = data
                          this.oplans[_i].json = data
                          this.setState({plans:JSON.parse(JSON.stringify(plans))})
                        }} />
                      </div>
                    </div>
                  }
                </div>
              ))
            }
          </div>
        </div>
    </div>
    )
  }
}
