import React, {Component, Fragment} from 'react'
import ReactDOM from 'react-dom';
import {css} from 'react-emotion'
import Ionicon from 'react-ionicons'
import {Calendar,OptionGroupCheckBox,Option,DropDown, OptionGroupRadio} from '@anarock/pebble';
import {Table, Dropdown, Status} from './schema/ui-utils'
import Notify from './notify'
import { HotTable } from "@handsontable/react";
import styles from "handsontable/dist/handsontable.full.css";

const _table = css`
	background:#fff;
  border: 1px solid #ccc;
  border-collapse: collapse;
  th {
    background: #f7f7f7;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
  }
  td {
    padding: 10px;
    border: 1px solid #ccc;
		min-width: 100px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const _btn = css`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  badding: 5px;
  border-radius: 5px;
  background: #6161ff;
  color: #fff;
  cursor: pointer;
  :hover {
    filter: brightness(1.3);
  }
`;

const _sheet = css`
  position: fixed;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px #0005;
  left: 50%;
	top:80px;
  transform: translateX(-50%);
	z-index:10000002;
  .table {
    min-width: 600px;
    font-size: monospace;
    font-size: 12px;
    height: 70vh;
    overflow: hidden;
    margin-bottom: 30px;
  }
`;

const _overlay = css`
  background: #0003;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
	z-index:10000001;
`;

class ScriptOut extends Component {
  state={}
	render(){
    let {data=''} = this.props;
    let {stepopen=-1} = this.state
    const out = css`border: 1px solid #6b7785;padding:10px;margin-top:10px;border-radius:3px;min-height:100px;font-size:12px;color: #6b7785;font-style:monospace;`
    let messages = []
    let steps = []
    data.split('\n').map(o => {
      if(o.slice(0,6)=='html::'){
        messages.push(<div dangerouslySetInnerHTML={{__html:o.slice(6)}}></div>)
      } else if(o.slice(0,6)=='step::'){
        steps.push({label:o.slice(6),messages})
        messages = []
      } else {
        messages.push(<div>{o}</div>)
      }
    })
    if(steps.length==0){
      return <div>{data}</div>
    }
    return (
      <div className={out}>
        {steps.map((o,_i) => (
          <div>
            <div style={{whiteSpace:'nowrap',fontWeight:'bold',color:'#000',padding:'5px 0px',color:o.label.indexOf('Failed')!=-1?'#c00':'#000'}} 
              onClick={() => this.setState({stepopen:stepopen==_i?-1:_i})}>{_i+1}. {o.label}</div>
            {stepopen==_i &&
              <div style={{fontSize:10,padding:10,maxHeight:300,overflow:'auto',border:'1px solid #ededed'}}>
              {o.messages.map(o => (
                <div>{o}</div>
              ))}
              </div>
            }
          </div>
        ))}
      </div>
    )
  }
}

class UpdateSegments extends Component {
  state = {}
	runScript = (rtype) => {
    if(this.state.running) return;
    let {scriptout={}} = this.state;
    scriptout[rtype] = 'running...'
    this.setState({running:true,scriptout:{...scriptout}})
    let {assignment_id,segment_id} = this.props
    let self = this
    fetch(`${window._MDOMAIN}api/v0/runscript?rtype=${rtype}&segment_id=${segment_id}&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        scriptout[rtype] = data.data;
        self.setState({scriptout:{...scriptout},running:false})
        self.props.refresh()
      } else {
        self.setState({message:"Unable to run",message_id:new Date(),running:false})
      }
    }).catch(function(){
      self.setState({running:false})
      self.setState({message:"Unable to run",message_id:new Date()})
    })
  }
	render() {
    let {running,scriptout={}} = this.state
    const btn = css`padding:10px 20px;border-radius:3px;background:${running?'#e8e8ff':'#6161ff'};color:#fff;cursor:pointer;margin-top:20px;width:fit-content; ${running?'':':hover{filter:brightness(1.2);}'}`
    
    return (
      <div>
        <div className={css`background:#fff;width:500px;padding:0px;max-height:calc(100vh - 300px);overflow:auto;`}>
          <div>
            <div className={btn} onClick={() => this.runScript('process_segments')}>Process Segment</div>
            {scriptout['process_segments'] && <ScriptOut data={scriptout['process_segments']} />}
          </div>
        </div>
      </div>
    )
  }
}

export class Popper extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    setTimeout(() => document.addEventListener('click', this.handleClick, true),100)
    document.addEventListener('keydown',this.handleKeyPress,true)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true);
    document.removeEventListener('keydown',this.handleKeyPress,true)
  }
                              
	handleKeyPress = (e) => {
    try {
      if(e.repeat){
        return
      }
      let valid = false
      if(e.keyCode==27){
        this.props.close()
        valid = true
      }
      if(valid){
        e.preventDefault()
        e.stopPropagation()  
      }
    } catch(e) {  
    }
  }
  handleClick = (e) => {
    try{
    	if(!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.props.close()
        if(!this.props.allowClicks){
          e.preventDefault()
          e.stopPropagation()
        }
      }
    } catch(e) {
      console.log(e)
      // console.log(e,e.toString())
      // if(e.toString().indexOf('Unable to find node on an unmounted component')!=-1){
      //   console.log("removing unmounted node")
      //   document.removeEventListener('click', this.handleClick, true);
      // }
    }
  }
  render() {
    let {html} = this.props
    return (
      <div className={css`
z-index:1000000;
position: absolute;
top:0px;
left:0px;
box-shadow: 0px 8px 15px 0px rgba(0,0,0,.15);
`}>
        {html}
      </div>
    )
  }
}

export class ListFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: JSON.parse(JSON.stringify(this.props.filters || {"options":{}}))
    }
  }
  render() {
    let options = this.props.options || []
    let {filters,filtertext=''} = this.state
    return (
      <div style={{background:'#fff',zIndex:1000,border:'1px solid #ededed',position:'absolute',boxShadow:'0px 8px 15px 0px rgba(0,0,0,.15)'}}>
        <OptionGroupCheckBox
          searchBox
          searchBoxProps={{
            clearable: true,
            placeholder: 'Search',
            onChange: value => this.setState({filtertext:value})
          }}
          onChange={values => {
            filters["options"] = {}
            values.map(o => {
              filters["options"][o] = 1
            })
            console.log(filters)
            this.setState({cb:new Date(),filters:JSON.parse(JSON.stringify(filters))})
          }}
          selected={Object.keys(filters["options"])}
          onApply={() => {
            this.props.apply(filters)
          }}
          onClear={() => {
            filters['options'] = {}
            this.setState({filters:JSON.parse(JSON.stringify(filters))})
          }}
        >
          <div style={{display:'flex',marginLeft:20,marginTop:0,marginBottom:10}} className={css`.link{margin-right:10px;cursor:pointer; :hover{text-decoration:underline}}`}>
            <div className="link" onClick={() => {
                filters["options"] = filters["options"] || {}
                options
            		.filter(o => (o||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1)
                .map(o => {
                  filters["options"][o] = 1
                })
            		this.setState({cb:new Date(),filters:JSON.parse(JSON.stringify(filters))})
              }}>Select Visible</div>
            <div className="link" onClick={() => {
                filters["options"] = filters["options"] || {}
                options
            		.filter(o => (o["name"]||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1)
                .map(o => {
                  if(filters["options"][o["id"]]) delete filters["options"][o["id"]];
                })
            		this.setState({cb:new Date(),filters:JSON.parse(JSON.stringify(filters))})
              }}>Clear Visible</div>
          </div>
          {
            options
            	.filter(o => (o["name"]||'').toLowerCase().indexOf(filtertext.toLowerCase()||'')!=-1)
            	.slice(0,100)
              .map(o => (
              <Option value={o["id"]+''} label={(o["name"] || 'blank').split(":#")[0]}/>
              ))
          }
        </OptionGroupCheckBox>
      </div>
    )
  }
}

class Input extends Component {
  state={filtertext:''}
  render() {
    let {label,value,setValue} = this.props;
    let {filtertext,showSug=false} = this.state
    let suggestions = ['facebook','google','native','portals'];
    return (
      <div className={css`
position: relative;
width: 200px;
.label {
	color: #6b7785;
}
input {
	background: transparent;
	border: none;
	font-size:18px;
	padding:10px 30px;
}
.suggestions {
	background: #fff;
	position: absolute;
	top:20px;
	border: 1px solid #000;
	& > div {
		padding: 10px 30px;
		cursor: pointer;
		:hover {
			background: #ededed;
		}
	}
}
.value {
	cursor: pointer;
	padding: 10px 0px;
	min-height:40px;
	border-bottom: 1px solid #ededed;
}
`}>
        <div className="label">{label}</div>
        <div 
          className="value"
          onClick={e => this.setState({showSug:true})}>
          {value}
        </div>
        {showSug &&
          <div className="suggestions">
            <input value={filtertext} onChange={e => this.setState({filtertext:e.target.value})}/>
            {
              suggestions
                .filter(o => o.indexOf(filtertext || '')!=-1)
                .map(o => (
                <div onClick={() => {
                    setValue(o)
                    this.setState({filtertext:'',showSug:false})
                  }}>{o}</div>
              )) 
            }
          </div>
        }
      </div>
    )
  }
}

export class Filters extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  date = (d) => {
    let pts = new Date(d).toString().split(' ');
    return `${pts[2]}${pts[1]}${pts[3]}`
  }
  render() {
    let {filteroptions={},filters={},remove,html} = this.props
    let {last='',closed=new Date(),editfilter} = this.state
    return (
      <div className={css`
width:100%;
position: relative;
user-select: none;
margin-top:5px;
border: 1px solid #ededed;
background: #fbfbfb;
display: flex;
padding:6px;
font-weight:bold;
.css-1g3w8bf {
	max-height: 220px !important;
}
.remove {
	opacity: 0;
	color: #d32f02;
	position: absolute;
	font-size:12px;
	right:0px;
	top:0px;
	padding:3px;
	cursor:pointer;
	text-decoration: underline;
	:hover {
		opacity:1;
	}
}
.pi-arrow-drop-down, .pi-arrow-drop-up {
	font-size:8px;
}
.item.active {
	.chip {
		background:#c0c0ff;
	}
	.dot-border {
		border: 1px dashed #e0e0e0;
	}
}
.item {
	position: relative;
	color: #6b7785;
	padding:6px;
	padding-right:0px;
  font-size: 10px;
	.dot-border {
		position:relative;
		padding: 3px;
		border: 1px dashed transparent;
		:hover {
			border: 1px dashed #e0e0e0;
		}
		:hover .close {
			background: #fbfbfb;
			z-index: 1000;
			color: #efbbad !important;
			svg {
				fill: #efbbad !important;
			}
		}
		.close {
			div {
				margin-bottom: 2px;
			}
			padding-left:5px;
			display: flex;
			align-items: center;
			position:absolute;
			color: transparent;
			right:3px;
			top:3px;
			cursor: pointer;
			:hover{
				border-bottom: 1px dashed #d32f02;
				svg {
					fill: #d32f02 !important;
				}
				color: #d32f02 !important;
			}
		}
	}
  .name {
    padding-bottom:10px;
		text-overflow: ellipsis;
		overflow:hidden;
		max-width:80px;
  }
  .chip {
		cursor: pointer;
    color: #6161ff;
    display:flex;
    align-items: center;
    background: #e8e8ff;
    font-size: 10px;
    height: 28px;
    padding:10px;
    border-radius: 3px;
		border: 1px solid #e8e8ff;
		&.empty {
			background: transparent;
			border: 1px solid #6161ff;
			color: #6b7785;
			border: 1px solid #6b7785;
		}
		:hover {
			background:#c0c0ff;
		}
    .text {
      overflow:hidden;
      text-overflow: ellipsis;
      max-width:100px;
      margin-right:5px;
      white-space: nowrap;
			display: block;
    }
    .border {
      border: 1px solid #6161ff;
      border-radius: 3px;
      padding:0px 2px;
      margin-left:-2px;
			margin-right:5px;
    }
  }
}
.buttons {
	margin-top:20px;
	display: flex;
	> div {
		margin-right: 5px;
	}
}

.tab-item {
	padding: 5px;
	cursor: pointer;
	:hover {
		color: #000 !important;
		text-decoration: underline;
	}
}
`}>
        {remove && <div onClick={remove}
          className="remove">remove</div>}
        <div style={{flex:1,display:'flex',flexWrap:'wrap'}}>
          {Object.keys(filters).map(item => {
              let o = filters[item]
              let value = '';
              let more = 0;
            	if(o['type']=='inlist'){
              	let options = Object.keys(o['options']).filter(k => o['options'][k])
                if(options.length>0){
                	value = options[0]==''?'blank':options[0].split(":#")[0]
                	more = options.length - 1  
                }
              } else if(o['type']=='daterange'){
                let {start,end} = o
                let [s,s1,s2,s_] = new Date(start).toString().split(' ')
                let [e,e1,e2,e_] = new Date(end).toString().split(' ')
                if(start && end){
                	value = `${s1} ${s2} - ${e1} ${e2}`
                }
              }
            	
            	
            	let icon = editfilter==item?"up":"down"
              return (
                <div className={`item ${editfilter==item?"active":""}`}>
                  <div className="dot-border">
                    {value &&
                      <div
                      className="close"
                      onClick={(e) => {
                          e.stopPropagation()
                          let {conf} = this.props
                          if(filters[item]['type']=='daterange' && filters[item]['start']){
                            filters[item]={
                              type: 'daterange',
                              start: '',
                              end: ''
                            }
                          } else if(filters[item]['type']=='inlist' && Object.keys(filters[item]['options']).length>0){
                            filters[item]={
                              type: "inlist",
                              options:{}
                            }
                          } else {
                            delete filters[item]
                          }
                          this.props.update(JSON.parse(JSON.stringify(filters)))
                        }}
                      >
                      <div>{value!=''?'clear':'remove'}</div>
                      <Ionicon icon={`md-${value!=''?'close':'trash'}`}
                        color="transparent" 
                        fontSize="12px"/>
                    </div>}
                    <div className="name">{item=='channel'?'channel_type':item}</div>
                    <div onClick={() => this.setState({editfilter:new Date()-closed < 100 && last==item?"":item})}>
                      {
                        value!=''?
                        <div className="chip">
                          <div className="text">{value}</div>
                          {
                            more > 0 &&
                            <div className="border">+{more}</div>
                          }
                          <i className={`pi pi-arrow-drop-${icon}`}></i>
                        </div>:
                        <div className="chip empty">
                          <div className="text">{o['type']=='daterange'?'From - To':'Choose'}</div>
                          <i className={`pi pi-arrow-drop-${icon}`}></i>
                        </div>
                      }
                    </div>
                  </div>
                  {editfilter==item &&
                    <div style={{position:'absolute',}}>
                      {filters[item]["type"]=="inlist" &&
                        <Popper 
                          allowClicks={true}
                          close={() => this.setState({editfilter:'',closed: new Date(),last:item})}
                          html={
                            <ListFilter 
                              filters={filters[item]} 
                              options={filteroptions[item]}
                              close={() => this.setState({editfilter:''})}
                              apply={(f) => {
                                filters[item] = f
                                this.setState({editfilter:''})
                                this.props.update(JSON.parse(JSON.stringify(filters)))
                                // this.setState({filters:JSON.parse(JSON.stringify(filters))})
                                // EM.emit('runreport',id)
                              }}
                            />
                          }
                        />
                      }
                      {filters[item]["type"]=="daterange" &&
                        <Popper 
                          close={() => this.setState({editfilter:'',closed: new Date(),last:item})}
                          allowClicks={true}
                          html={
                            <div style={{zIndex:10000,border:'1px solid #ededed',position:'absolute',boxShadow:'0px 8px 15px 0px rgba(0,0,0,.15)',marginBottom:30}}>
                              <Calendar
                                //onChange={action('change')}
                                selected={[filters[item]["start"]?new Date(filters[item]["start"]):null,filters[item]["end"]?new Date(filters[item]["end"]):null]}
                                range={true}
                                onApply={(f) => {
                                  console.log("apply",f)
                                  let [start_date,end_date] = f
                                  filters[item]["start"] = this.date(start_date)
                                  filters[item]["end"] = this.date(end_date)
                                  console.log(filters)
                                  this.setState({editfilter:''})
                                  this.props.update(JSON.parse(JSON.stringify(filters)))
                                  // this.setState({filters:JSON.parse(JSON.stringify(filters))})
                                  // EM.emit('runreport',id)
                                }}
                              />
                            </div>
                          }
                        />
                      }
                    </div>
                  }
                </div>
              )
            })
          }
          {false &&
            <div className="item">
              <div style={{marginTop:34,border:'1px solid #e0e0e0',color:'#6b7785',height:28,display:'flex',padding:'5px 10px',alignItems:'center',borderRadius:3,background:'#fff'}}>add</div>
            </div>
          }
        </div>
        <div style={{}}>
            {html}
          </div>
      </div>
    )
  }
}

class Sheet extends Component {
  state = {}
  saveUsers = (key) => {
    let self = this
    this.setState({disabled:true})
    let {data} = this.state
    fetch(`${window._MDOMAIN}/api/v0/segments?rtype=save-users&segment_id=${this.props.segment_id}`, {
      method: 'post',
      credentials:"include",
      body:JSON.stringify(data)
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        // self.setState({data:data.data})
        self.props.update()
        alert('Added Successfully !!')
      }
      self.setState({disabled:false})
    }).catch(() => {
      alert('error occured')
      self.setState({disabled:false})
    })
  }
  render(){
    let {data=Array(40).fill(0).map(o => Array(3).fill("")),disabled} = this.state
  	return (
      <div>
        <div className={_overlay} />
        <div className={_sheet}>
          <div className="table">
            <HotTable
              afterChange = {(o) => {
                console.log(o)
                if(o && o[0]){
                	this.setState({data})
              	}
              }}
              stretchH="all"
              colHeaders={["Name", "Email", "Phone"]}
              data={data}
            />
          </div>
          <div style={{display:'flex',alignItems:'center'}}>
            
            <div style={{opacity:disabled?.4:1}} className={_btn} onClick={() => !disabled && this.saveUsers()}>save</div>
            <div onClick={this.props.close} style={{marginLeft:20,cursor:'pointer',color:'#6b7785'}}>close</div>
          </div>
        </div>
      </div>
    );  
  }
};

class DetailsPage extends Component {
  state = {details:JSON.parse(JSON.stringify(this.props.details))}
	saveSegment() {
    this.setState({loading:true})
    let self = this
    let {name,filters,id} = this.state.details
    let {assignment_id} = this.props
    fetch(`${window._MDOMAIN}api/v0/segments?rtype=save&assignment_id=${assignment_id}`, {
      method: 'post',
      credentials:"include",
      body: JSON.stringify(this.state.details)
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        self.state.details['id'] = data.data['id']
        self.setState({cb:new Date()})
        self.props.update()
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }
	handleChange = ({key,value}) => {
    let {details} = this.state
    details.filters[key] = value
    this.setState({cb:new Date()})
  }
  handleDetailsChange = ({key,value}) => {
    let {details} = this.state
    details[key] = value
    this.setState({cb:new Date()})
  }
  flatten = (obj) => {
    return Object.keys(obj).map(k => ({id:k,name:obj[k]}))
  }
  componentDidMount() {
  }
  render() {
    let {details} = this.state
    let {assignment_id,update} = this.props
    let {options={}} = this.props
    if(!details['status']){
      details['status'] = 'active'
    }
    if(!details['developer_id']){
      details['developer_id'] = options['did']
    }
    if(!details['json']){
      details['json'] = {}
    }
    if(!details['filters']){
      details['filters'] = {}
    }
    let filters = details['filters']
    if(!filters['type']){
      filters['type'] = 'all'
    }
    if(!filters['bucket']){
      filters['bucket'] = 'all'
    }
    
    let row = css`display:flex;align-items:center;margin-bottom:15px;`
    let tab = css`display:flex;border:1px solid #000;border-radius:3px;align-items:center;
			> div {padding:5px 10px;cursor:pointer;}
			.sel {background:#000;color:#fff;}
		`
    let input = css`width:200px;padding:5px;border:1px solid #ccc;border-radius:3px;`
    let sample = {projects:{type:'inlist',options:{}},status:{type:'inlist',options:{}},channel:{type:'inlist',options:{}},source:{type:'inlist',options:{}},subsource:{type:'inlist',options:{}},'ldate':{type:'daterange'},failurereasons:{type:'inlist',options:{}}}
    return (
      <div className={css`
width:600px;
background: #fff;
padding: 30px;
border-radius:3px;
.input {
	padding: 10px;
	border-radius: 3px;
	outline: none;
	border: 1px solid #ededed;
}
.heading {
	color: #6b7785;
	padding: 20px 0px;
}
.action {
	background: #ededed;
	padding: 10px 20px;
	border-radius: 3px;
	cursor: pointer;
	width: fit-content;
	margin-bottom: 10px;
	:hover {
		background: #dde8ff;
	}
	.selected {
		background: #1f69ff;
		color: #fff;
	}
}
`}>
        <div className={row}>
          <div style={{width:140,color:'#6b7785'}}>developer</div>
          <div>{options.dname}</div>
        </div>
        <div className={row}>
          <div style={{width:140,color:'#6b7785'}}>Name</div>
        	<input className={input} value={details['name']} onChange={(e) => {
              details['name'] = e.target.value
              this.setState({cd: new Date()})
            }}/>
        </div>
        <div className={row} style={{marginBottom:20}}>
          <div style={{width:140,color:'#6b7785'}}>Status</div>
        	<Status getValue={(k) => details[k]} name={'status'} handleChange={this.handleDetailsChange} />
        </div>
        <div style={{}}>
          <div className={row}>
            <div className={row}>
              <div style={{width:140,color:'#6b7785'}}>Segment Type</div>
              <div>
                <Dropdown single={true} 
                  all_options={this.flatten({manual:'Manual','all':'All Assignments','assignments':'Filter By Assignments','projects':'Filter By Projects'})} 
                  label="select segment type" 
                  getValue={(k) => filters[k]||[]} 
                  name={'type'}
                  handleChange={this.handleChange}/>
              </div>
            </div>
          </div>
          {filters.type=='assignments' && <div className={row}>
            <div style={{width:140,color:'#6b7785'}}>assignments</div>
            <div style={{}}>
              <Dropdown all_options={this.flatten(options['assignments'])} label="select assignments" getValue={(k) => filters[k]||[]} name={'assignments'} handleChange={this.handleChange}/>
            </div>
          </div>}
          {filters.type=='projects' && <div className={row}>
            <div style={{width:140,color:'#6b7785'}}>projects</div>
            <div style={{}}>
              <Dropdown 
                all_options={this.flatten(options['projects'])} 
                label="select projects" 
                getValue={(k) => filters[k]||[]} name={'projects'} handleChange={this.handleChange}/>
            </div>
          </div>}
          {filters.type!='manual' &&
            <div className={row}>
              <div style={{width:140,color:'#6b7785'}}>buckets</div>
              <div>
                <Dropdown single={true} all_options={['all','qualified','visited','booked','failed']} label="select buckets" getValue={(k) => filters[k]||[]} name={'bucket'}  handleChange={this.handleChange}/>
              </div>
            </div>
          }
        </div>
        
        <div style={{}}>
          <div style={{color:'#6b7785',marginBottom:5,fontSize:12}}>create user buckets in adwords for following accounts</div>
          <div style={{width:300}}>
            <Dropdown 
              all_options={this.flatten(options['adwords_accounts'])}
              label="select adwords accounts"
              getValue={(k) => details[k]||[]} name={'adwords_accounts'} handleChange={this.handleDetailsChange}/>
          </div>
          <div style={{color:'#6b7785',marginBottom:5,marginTop:20,fontSize:12}}>create user buckets in facebook for following accounts</div>
          <div style={{width:300}}>
            <Dropdown 
              all_options={this.flatten(options['fb_accounts'])}
              label="select fb accounts"
              getValue={(k) => details[k]||[]} name={'fb_accounts'} handleChange={this.handleDetailsChange}/>
          </div>
        </div>
        {details['id'] &&
        	<UpdateSegments segment_id={details['id']} assignment_id={assignment_id} refresh={() => update()}/>
        }
        <div style={{marginTop:20,display:'flex',alignItems:'center'}}>
          <div 
            onClick={() => this.saveSegment()}
            style={{padding:'5px 10px',border:'1px solid #e0e0e0',borderRadius:3,width:'fit-content'}}>save</div>
        </div>
      </div>
    )
  }
}

const schema = [
  {name:'name',type:'string'},
  {name:'created_at',type:'timestamp'},
  {name:'users',type:'int'},
]

export default class Segments extends Component {
  state = {options:{}}
  getSegments() {
    this.setState({loading:true})
    let self = this
    let {assignment} = this.props
    let {assignment_id} = assignment
    fetch(`${window._MDOMAIN}api/v0/segments?rtype=list&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        let segments = data.data
        segments.map(o => o['users']=(o.stats && o.stats.size)||0);
        self.setState({segments,options:data.options})
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }
  componentDidMount() {
    this.getSegments()
  }
  render() {
    let {segments=[],selitem,options,showsheet,message,message_id} = this.state
    let {assignment} = this.props
    let {assignment_id} = assignment
    return (
      <div className={css`
.create-btn {
	padding: 10px 20px;
	border-radius: 3px;
	background: #1f69ff;
	margin-bottom: 20px;
	color: #fff;
	width: fit-content;
	cursor: pointer;
	:hover {
		filter: brightness(1.2);
	}
}
`}>
        <Notify message={message} message_id={message_id}/>
        {showsheet &&
        	<Sheet 
            update={() => this.getSegments()}
            segment_id={showsheet['id']} 
            close={() => this.setState({showsheet:false})}
            />
        }
        <div className='create-btn' onClick={() => {
            this.setState({selitem:{}})
          }}>Create Segment</div>
        <Table 
          addclick={(showsheet) => this.setState({showsheet})}
          add={(row) => (row.filters||{type:[]}).type[0]=='manual'}
          select={(selitem) => this.setState({selitem})} 
          data={segments} 
          keys={['name','created_at','users']} actions="true"/>
        {selitem &&
          <div 
            onClick={() => this.setState({selitem:null})}
            style={{overflow:'auto',zIndex:10000011,paddingTop:20,top:0,left:0,display:'flex',justifyContent:'center',position:'fixed',background:'rgba(0,0,0,.5)',width:'100vw',height:'100vh'}}>
            <div onClick={e => e.stopPropagation()} style={{height:'fit-content'}}>
            	<DetailsPage options={options} details={selitem} assignment_id={assignment_id} update={() => this.getSegments()}/>
            </div>
          </div>
        }
      </div>
    )
  }
}