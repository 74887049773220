import { Modal, mixins, colors, Loader, Button, Toast } from "pebble-web";
import React, { PureComponent } from "react";
import RulesForm from "./components/rules/rules-form";
import { css, cx } from "react-emotion";
import RulesList from "./components/rules/rules-list";

const rulesMainBody = css({
  margin: "15px 0px",
  padding: "24px 24px 10px",
  background: colors.white.base,
  ...mixins.flexMiddleAlign,
  flexDirection: "column",
  height: 300,
});
const rulePage = css({
  margin: "40vh 0px",
  height: "30px",
  flexDirection: "column",
  ...mixins.flexMiddleAlign,
});
const modalBackDropStyle = css({
  bottom: 0,
  left: "310px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const modalContainerBody = css({
  background: colors.gray.lightest,
  position: "fixed",
  bottom: 0,
});
const createRulesBody = css({
  marginRight: 150,
  fontSize: "18px",
});
export default class Rules extends PureComponent {
  state = {
    mode: "list",
    rulesList: [],
    loading: true,
    ruleData: {},
  };
  mapRules = (rulesList) => {
    let rules = {};
    rulesList.forEach((rule) => {
      if (!rules[rule.parameter]) rules[rule.parameter] = [];
      rules[rule.parameter].push(rule);
    });
    return rules;
  };
  useCampaignData = (campaign_data) => {
    let campaigns = {};
    if (campaign_data) {
      if (campaign_data.facebook_data) {
        const fbCampaigns = campaign_data.facebook_data.map((item) => ({
          id: item.campaign.id,
          name: item.campaign.name,
        }));
        campaigns["facebook"] = fbCampaigns;
      }
      if (campaign_data.google_data) {
        const googleCampaigns = campaign_data.google_data.map((item) => ({
          id: item.campaign.id,
          name: item.campaign.name,
        }));
        campaigns["google"] = googleCampaigns;
      }
    }
    return campaigns;
  };
  saveChanges = (data) => {
    const { assignment_id } = this.props.assignment;
    if (data.mode === "create") {
      fetch(`${window._MDOMAIN}/api/v0/create_rule`, {
        method: "post",
        credentials: "include",
        body: JSON.stringify({ ...data.data, assignment_id: assignment_id }),
      })
        .then(function (response) {
          return response.json();
        })
        .then((response) => {
          if (response.status === 200) {
            this.getData();
            Toast.show("Rule Created Successfully");
            this.setState({ mode: "list" });
          } else if (response.status == 400) {
            Toast.show(response.message,"error");
            this.setState({ mode: "list" });
          }
        })
        .catch(function (e) {
          Toast.show("Error, Couldn't save changes","error");
          this.setState({ mode: "list", ruleData: {} });
        });
    } else if (data.mode === "edit") {
      fetch(`${window._MDOMAIN}/api/v0/update_rule`, {
        method: "post",
        credentials: "include",
        body: JSON.stringify(data.data),
      })
        .then(function (response) {
          return response.json();
        })
        .then((response) => {
          if (response.status === 200) {
            this.getData();
            Toast.show("Saved Successfully");
            this.setState({ mode: "list", ruleData: {} });
          } else if (response.status === 400) {
            Toast.show(response.message,"error");
          }
        })
        .catch(function (e) {
          Toast.show("Error, Couldn't save changes","error");
          this.setState({ mode: "list" });
        });
    }
  };
  toggleRule = (rule) => {
    fetch(`${window._MDOMAIN}/api/v0/update_rule`, {
      method: "post",
      credentials: "include",
      body: JSON.stringify(rule),
    })
      .then(function (response) {
        return response.json();
      })
      .then((response) => {
        if (response.status === 200) {
          Toast.show("Rule Toggled Successfully");
        }
      })
      .catch(function (e) {
        Toast.show("Error, couldn't save changes", "error");
      });
  };
  getData = () => {
    let { assignment_id } = this.props.assignment;
    let self = this;
    self.setState({ loading: true });

    Promise.all([
      fetch(
        `${window._MDOMAIN}/api/v0/get_rules?assignment_id=${assignment_id}`,
        {
          method: "get",
          credentials: "include",
        }
      ).then(function (response) {
        return response.json();
      }),
      fetch(
        `${window._MDOMAIN}/api/v0/get_campaign_data?assignment_id=${assignment_id}`,
        {
          method: "get",
          credentials: "include",
        }
      ).then(function (response) {
        return response.json();
      }),
      fetch(
        `${window._MDOMAIN}/api/v0/url-generator?rtype=get&assignment_id=${assignment_id}`,
        {
          method: "get",
          credentials: "include",
        }
      ).then(function (response) {
        return response.json();
      }),
    ])
      .then(([rulesInfo, campaignInfo, projectInfo]) => {
        let rulesList, campaigns, subsources;
        if (rulesInfo.status == 200) {
          rulesList = rulesInfo.data;
        }
        if (campaignInfo.status === 200) {
          campaigns = self.useCampaignData(campaignInfo.data.campaign_data);
        }
        if (projectInfo.status === 200) {
          subsources = projectInfo.data.subsources;
        }
        self.setState({
          rulesList: rulesList,
          campaigns,
          subsourceMapping: subsources,
          loading: false,
        });
      })
      .catch((e) => {
        Toast.show("Error, couldn't fetch rule data", "error");
        self.setState({ loading: false });
      });
  };
  deleteRule = (id) => {
    fetch(`${window._MDOMAIN}/api/v0/delete_rule?id=${id}`, {
      method: "post",
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then((response) => {
        if (response.status === 200) {
          this.getData();
          Toast.show("Rule Deleted Successfully");
        }
      })
      .catch(function (e) {
        Toast.show("Error, couldn't save changes", "error");
      });
  };
  componentDidMount() {
    this.getData();
  }
  render() {
    const { rulesList, mode, loading, ruleData, campaigns, subsourceMapping } =
      this.state;
    const { assignment } = this.props;
    const assignment_id = assignment.assignment_id;
    if (loading) {
      return (
        <div className={rulePage}>
          <Loader scale={1.5} color={colors.violet.base} />
          <Toast />
          <div className={css({ marginTop: "15px", color: colors.gray.base })}>
            Fetching Rules
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Toast />
          {rulesList.length === 0 ? (
            <div>
              <div>Rules</div>
              <div className={rulesMainBody}>
                <div
                  className={css({
                    marginBottom: "40px",
                    color: colors.gray.base,
                  })}
                >
                  Automated rules saves your time by monitoring the Campaigns
                  under the conditions you give and will perform actions
                  accordingly
                </div>
                <Button
                  type="primary"
                  onClick={() =>
                    this.setState({ ruleData: {}, mode: "create" })
                  }
                  disabled={!(campaigns.google || campaigns.facebook)}
                >
                  Create Rule
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div>
                {campaigns && (
                  <RulesList
                    onCreate={() => this.setState({ mode: "create" })}
                    rulesMapping={this.mapRules(rulesList)}
                    onEdit={(id) => {
                      const rule = rulesList.find(
                        (rule) => rule.rule_id === id
                      );
                      this.setState({ ruleData: rule, mode: "edit" });
                    }}
                    campaigns={campaigns}
                    updateRule={(rule) => this.toggleRule(rule)}
                    deleteRule={(id) => this.deleteRule(id)}
                    subsourceMapping={subsourceMapping}
                    assignment={assignment}
                  />
                )}
              </div>
            </div>
          )}
          {mode != "list" && (
            <Modal
              backDropClassName={modalBackDropStyle}
              modalClassName={cx(modalContainerBody, css({ width: "100%" }))}
              visible={mode != "list"}
            >
              <div className={createRulesBody}>
                <RulesForm
                  ruleData={ruleData}
                  assignment_id={assignment_id}
                  mode={mode}
                  onClose={() => this.setState({ mode: "list", ruleData: {} })}
                  campaigns={campaigns}
                  saveChanges={(data) =>
                    this.saveChanges({ data: data, mode: mode })
                  }
                  subsourceMapping={subsourceMapping}
                />
              </div>
            </Modal>
          )}
        </div>
      );
    }
  }
}
