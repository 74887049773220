import React, { PureComponent } from "react";
import Notify from "./components/notify";
import Moment from "react-moment";
import {
  BrowserRouter as Router,
  browserHistory,
  Route,
  Link,
} from "react-router-dom";
import moment from "moment";
import Ionicon from "react-ionicons";
import { css } from "react-emotion";
import { Tooltip as MUITooltip } from "@material-ui/core";

var md5 = require("crypto-js/sha256");

const css_labels = css`
  min-width: 200px;
  background: #fff;
  max-height: 200px;
  overflow: auto;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  background: #fff;
  z-index: 100000;
  > div {
    padding: 10px 5px;
    cursor: pointer;
    :hover {
      background: #fbfbfb;
    }
  }
`;

import DayPickerInput from "react-day-picker/DayPickerInput";
import styles from "react-day-picker/lib/style.css";

import {
  Mediaplan,
  DateRange,
  Tooltip,
  Budgetlog,
  Budgetplan,
  SpendsConfig,
} from "./components";

import ChannelMapping from "./channelmapping.js";

import { UnControlled as CodeMirror } from "react-codemirror2";

let reportingDetails = {
  header: {
    name: "Account Details",
  },
  items: [
    // {label:"Assignment Id (Read only)",type:"input",key:"assignment_id",readonly:true,tooltip:"Read only. This cannot be changed here"},
    // {label:"Assignment Name (Read only)",type:"input",key:"extra_details.assignment_name",tooltip:"Read only. This cannot be changed here"},
    // {label:"Assignment Type (Read only)",type:"input",key:"extra_details.assignment_type",tooltip:"Read only. This cannot be changed here"},
    // {label:"Offline POC",type:"input",key:"meta.offline_poc"},
    // {label:"Strategy POC",type:"input",key:"meta.strategy_poc"},
    // {label:"Google POC",type:"input",key:"meta.google_poc"},
    // {label:"Facebook POC",type:"input",key:"meta.facebook_poc"},
    // {label:"Digital Campaign Duration *",type:"daterange",key1:"start_date",key2:"end_date",tooltip:`This is the duration for which digital campaign for this project is being run.
    // This duration can be different from the following 'Mandate Panel Campaign Duration'`},
    // {label:"Mandate Panel Campaign Duration",readonly:true,type:"daterange",key1:"extra_details.start_date",key2:"extra_details.end_date",tooltip:`This is the Campaign duration from sales perspective.
    // This date duration can only be edited on the Mandates Panel - http://dashboard.anarock.com/mandates`},
    // {label:"Agency Type *",type:"select",key:"meta.agency_type",options:[{name:"Anarock",value:"anarock"},{name:"Developer",value:"developer"}]},
    {
      label: "Facebook Account Ids *",
      type: "input-checkbox",
      key: "meta.facebook_account_id",
      cKey: "meta.facebook_conversion_check",
    },
    {
      label: "Adwords Account Ids *",
      type: "input-checkbox",
      key: "meta.adwords_account_id",
      cKey: "meta.adwords_conversion_check",
    },
    { label: "Microsite Url", type: "input", key: "meta.microsite_url" },
    {
      label: "Agency Name *",
      type: "labels",
      key: "meta.agency_name",
      okey: "agency_name",
    },
    {
      label: "Average Unit Price *",
      type: "input",
      key: "meta.unit_price",
      showNum: true,
      dtype: "int",
    },
    { type: "more" },
    { label: "Digital POC", type: "labels", key: "poc", okey: "poc" },
    {
      label: "Digital Lead",
      type: "labels",
      key: "meta.approver",
      okey: "approver",
    },
    {
      label: "Locality",
      type: "labels",
      key: "meta.l_locality",
      okey: "l_locality",
    },
    {
      is_anarock: true,
      label: "Anarock Digital Account Type *",
      type: "select",
      key: "meta.ad_account_type",
      options: [
        { name: "Anarock Funded", value: "anarock_funded" },
        { name: "Anarock Imprest", value: "anarock_imprest" },
        { name: "Advance Payment", value: "advance_payment" },
        { name: "Client Credit Line", value: "client_credit_line" },
      ],
    },
    {
      is_anarock: true,
      label: "Anarock Digital Duration *",
      type: "daterange",
      key1: "meta.ad_start_date",
      key2: "meta.ad_end_date",
      tooltip: ``,
    },
    {
      is_anarock: true,
      label: "Anarock Digital Budget*",
      type: "input",
      key: "meta.ad_budget",
      showNum: true,
      dtype: "int",
    },
    {
      is_anarock: true,
      label: "Documents",
      type: "files",
      key: "meta.documents",
    },
    {
      label: "Archive Assignment",
      type: "checkbox",
      key: "meta.status",
      tooltip:
        "Archive assignments not in use. example include test,canceled assignments",
    },
    { 
      label: "Email for Alerts", 
      type: "input", 
      key: "meta.email_for_alerts",  
      tooltip: "Receive marketing alerts on this email id",
    },
    //{is_anarock:true,label:"Anarock Digital Commision*",type:"input",key:"meta.ad_commision",dtype:"float"},
    //{is_anarock:true,label:"Adwords Client Credit Line? *",type:"checkbox",key:"meta.adwords_client_credit_line"},
    //{is_anarock:true,label:"Facebook Client Credit Line? *",type:"checkbox",key:"meta.facebook_client_credit_line"},
    // {label:"Overall Target Units *",type:"input",key:"meta.overall_target_units",dtype:"int"},
    // {label:"Digital Target Units *",type:"input",key:"meta.digital_target_units",dtype:"int"},
    // {label:"Overall Budget *",type:"input",key:"meta.overall_budget",showNum:true,dtype:"int"},
    // {label:"Digital Budget *",type:"input",key:"meta.digital_budget",showNum:true,dtype:"int"},
    // {label:"Final Planned Spends *",type:"budgetplan",key:"meta.final_planned_spends"},
    // {label:"Spend Channels",type:"textarea",key:"spend_keys"},
  ],
};

let adDetails = {
  header: {
    name: "Anarock Digital Details",
  },
  items: [
    {
      label: "Commision",
      type: "table",
      key: "meta.commision_block",
      ttype: "commision",
    },
    {
      label: "Spends Exclusion from Finance",
      type: "table",
      key: "meta.spends_block",
      ttype: "spends",
    },
    {
      label: "Developer Panel",
      type: "developer-panel",
      key: "meta.developer_key",
    },
  ],
};

let misDetails = {
  header: {
    name: "MIS Upload",
  },
  items: [
    {
      label: "Missed Bookings Phone Numbers",
      type: "textarea",
      key: "meta.missed_bookings",
      tooltip: `Enter comma seperated phone numbers of Missed Bookings
    sample values: 1,2,3,4
    `,
    },
    {
      label: "Missed Walkin Phone Numbers",
      type: "textarea",
      key: "meta.missed_walkins",
      tooltip: `Enter comma seperated phone numbers of Missed Walkins
    sample values: 1,2,3,4
    `,
    },
    {
      label: "Missed Qualification Phone Numbers",
      type: "textarea",
      key: "meta.missed_qualifications",
      tooltip: `Enter comma seperated phone numbers of Missed Qualifications
    sample values: 1,2,3,4
    `,
    },
  ],
};

let thirdPartyDetails = {
  header: {
    name: "Third Party Access",
  },
  items: [
    {
      label: "Pivots Access Keys",
      type: "textarea",
      key: "meta.pivots_access_keys",
    },
    { label: "Agency Key", type: "random", key: "meta.agency_key" },
  ],
};

let mappingDetails = {
  header: {
    name: "Source Mapping",
  },
  items: [
    {
      label: "Source Mapping",
      type: "campaign_mapping",
      key: "campaign_mapping",
    },
  ],
};

let spendsDetails = {
  header: {
    name: "Spends Tracking",
  },
  items: [
    { label: "Manual Spends Keys", type: "input", key: "spend_keys" },
    { label: "CPL Spends Keys", type: "input", key: "meta.spends_keys_cpl" },
    { label: "CPQL Spends Keys", type: "input", key: "meta.spends_keys_cpql" },
    { label: "CPSV Spends Keys", type: "input", key: "meta.spends_keys_cpsv" },
    { label: "CPB Spends Keys", type: "input", key: "meta.spends_keys_cpb" },
    { label: "Scripts", type: "scripts", key: "" },
  ],
};

let config = [
  reportingDetails,
  //spendsDetails
];

const css_table = css`
  table {
    margin-top: 10px;
  }
  table,
  td,
  tr {
    border: 1px solid #ededed;
    border-collapse: collapse;
  }
  td {
    padding: 5px;
  }
  tr:first-child {
    color: #6b7785;
    font-size: 12px;
  }
  .remove {
    color: #eb7785;
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
  }
  .add {
    margin-right: 10px;
    width: fit-content;
    color: #6b7785;
    border: 1px solid #ededed;
    border-radius: 3px;
    padding: 3px 6px;
    cursor: pointer;
    font-size: 12px;
    background: #fff;
    :hover {
      filter: brightness(0.8);
    }
  }
  input {
    outline: none;
    border: 1px solid #ededed;
    border-radius: 3px;
    padding: 5px;
    width: 80px;
  }
  textarea {
    min-width: 200px !important;
    min-height: 100px !important;
  }
`;

const Table = (P) => {
  console.log(P.value);
  let conf = [
    {
      key: "type",
      type: "options",
      options: ["", "percent", "fixed", "setup"],
    },
    { key: "start_date", type: "date" },
    { key: "end_date", type: "date", cond: (o) => o.type == "percent" },
    { key: "value", type: "number" },
  ];
  if (P.ttype == "spends") {
    conf = [
      { key: "rule", type: "text" },
      { key: "start_date", type: "date" },
      { key: "end_date", type: "date" },
      { key: "exclude_commision", type: "checkbox" },
    ];
  }
  return (
    <div className={css_table} onClick={(e) => e.stopPropagation()}>
      <table>
        <tr>
          {conf.map((o) => (
            <td>{o.key}</td>
          ))}
          <td>actions</td>
        </tr>
        {P.value.map((o, _r) => (
          <tr>
            {conf.map((c) => (
              <td>
                {c.type == "date" && (!c.cond || c.cond(o)) && (
                  <DayPickerInput
                    value={o[c.key] ? new Date(o[c.key]) : null}
                    formatDate={(o) => {
                      let [m, d, y] = o.toString().split(" ").slice(1, 4);
                      return `${d} ${m}`;
                    }}
                    format="dd mmm"
                    onDayChange={(day) => {
                      if (!day) {
                        o[c.key] = null;
                      } else {
                        let pts = day
                          .toLocaleDateString("en-GB")
                          .split(",")[0]
                          .split("/");
                        if (Math.abs(parseInt(pts[2]) - 2019) < 10) {
                          o[c.key] = `${pts[2]}-${pts[1]}-${pts[0]}`;
                        } else {
                          o[c.key] = null;
                        }
                      }
                      P.handleChange({
                        type: "keyval",
                        key: P.name,
                        value: [...P.value],
                      });
                    }}
                  />
                )}
                {c.type == "number" && (
                  <input
                    type="number"
                    value={o[c.key] || ""}
                    onChange={(e) => {
                      o[c.key] = parseFloat(e.target.value);
                      if (P.ttype == "commision" && o.type == "percent") {
                        o[c.key] = Math.min(o[c.key], 100);
                      }
                      o[c.key] = Math.max(0, o[c.key]);
                      P.handleChange({
                        type: "keyval",
                        key: P.name,
                        value: [...P.value],
                      });
                    }}
                  />
                )}
                {c.type == "options" && (
                  <select
                    value={o.type || ""}
                    onChange={(e) => {
                      o.type = e.target.value;
                      if (P.ttype == "commision" && o.type != "percent") {
                        o.end_date = null;
                      }
                      P.handleChange({
                        type: "keyval",
                        key: P.name,
                        value: [...P.value],
                      });
                    }}
                  >
                    {c.options.map((o) => (
                      <option value={o}>{o}</option>
                    ))}
                  </select>
                )}
                {c.type == "checkbox" && (
                  <input
                    type="checkbox"
                    checked={o[c.key] || ""}
                    onChange={(e) => {
                      o[c.key] = e.target.checked;
                      P.handleChange({
                        type: "keyval",
                        key: P.name,
                        value: [...P.value],
                      });
                    }}
                  />
                )}
                {c.type == "text" && (
                  <textarea
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellCheck="false"
                    value={o[c.key] || ""}
                    onChange={(e) => {
                      o[c.key] = e.target.value;
                      P.handleChange({
                        type: "keyval",
                        key: P.name,
                        value: [...P.value],
                      });
                    }}
                  />
                )}
              </td>
            ))}
            <td>
              <div
                className="remove"
                onClick={() => {
                  let value = P.value.filter((_, _i) => _i != _r);
                  P.handleChange({ type: "keyval", key: P.name, value });
                }}
              >
                remove
              </div>
            </td>
          </tr>
        ))}
      </table>
      <div style={{ display: "flex", marginTop: 10 }}>
        <div
          className="add"
          onClick={() => {
            let value = [...P.value, {}];
            P.handleChange({ type: "keyval", key: P.name, value });
          }}
        >
          add
        </div>
        <div className="add">test</div>
      </div>
    </div>
  );
};

class Assignment extends PureComponent {
  constructor(props) {
    super(props);
    this.initialdata = JSON.parse(JSON.stringify(props.assignment));

    let { assignment_id } = props.assignment;

    this.state = {
      plan: [{ date: "2018-09-07", amount: 2500 }],
      assignment: JSON.parse(JSON.stringify(props.assignment)) || {},
      opentab: -1,
      campaignhtml: "",
      edit: (this.props.assignment || {}).dirty,
      loading: false,
    };
  }

  resetAssignment = () => {
    let { assignment } = this.state;
    this.setState({ assignment: JSON.parse(JSON.stringify(this.initialdata)) });
  };

  getOptions = (assignment_id) => {
    this.setState({ loading: true });
    let self = this;
    fetch(
      `${window._MDOMAIN}api/v0/assignments?rtype=label_options&assignment_id=${assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.setState({ loading: false });
        if (data.status == 200) {
          self.setState({ options: data.data });
        } else {
          self.setState({
            message: "Unable to Load Options",
            message_id: new Date(),
          });
        }
      })
      .catch(function (e) {
        self.setState({ loading: false });
        self.setState({
          message: "Unable to Load Options",
          message_id: new Date(),
        });
      });
  };

  getAssignment = (assignment_id) => {
    this.setState({ loading: true });
    let self = this;
    fetch(
      `${window._MDOMAIN}api/v0/assignments?rtype=get&assignment_id=${assignment_id}`,
      {
        // headers: {
        //   "Accept": "application/json",
        //   "Content-Type": "application/json"
        // },
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.setState({ loading: false });
        if (data.status == 403) {
          window.location.href = "/pivots";
        }
        if (data.status == 200) {
          let assignment = data.data[0];
          self.setState({ assignment });
          self.props.update(JSON.parse(JSON.stringify(assignment)));
          self.initialdata = JSON.parse(JSON.stringify(assignment));
        } else {
          self.setState({
            message: "Unable to Load Assignment",
            message_id: new Date(),
          });
        }
      })
      .catch(function (e) {
        self.setState({ loading: false });
        self.setState({
          message: "Unable to Load Assignment",
          message_id: new Date(),
        });
      });
  };

  saveAssignment = () => {
    this.setState({ loading: true });
    let self = this;
    if (!self.state.assignment.dirty) {
      self.setState({ message: "Nothing to save", message_id: new Date() });
      self.setState({ loading: false });
      return;
    }
    fetch(`${window._MDOMAIN}api/v0/assignments?rtype=post`, {
      method: "post",
      body: JSON.stringify(self.state.assignment),
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.setState({ loading: false });
        if (data.status == 200) {
          // let assignment = data.data
          let { assignment } = self.state;
          assignment["dirty"] = false;
          self.props.update(JSON.parse(JSON.stringify(assignment)));
          self.initialdata = JSON.parse(JSON.stringify(assignment));
          self.setState({
            assignment,
            cb: new Date(),
            message: "Saved Successfully",
            message_id: new Date(),
          });
        } else {
          self.setState({ message: "Unable to Save", message_id: new Date() });
        }
      })
      .catch(function (e) {
        self.setState({ loading: false });
        self.setState({ message: "Unable to Save", message_id: new Date() });
      });
  };

  getChannelMappings() {
    this.setState({ loading: true });
    let { campaign_mapping, assignment_id } = this.state.assignment;
    let self = this;
    fetch(
      `${window._MDOMAIN}api/v0/channelmapping?rtype=get&assignment_id=${assignment_id}`,
      {
        method: "post",
        body: JSON.stringify({ campaign_mapping }),
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.setState({ loading: false });
        if (data.status == 200) {
          self.setState({ campaignhtml: data.data });
        } else {
          self.setState({ message: "Unable to Save", message_id: new Date() });
        }
      })
      .catch(function () {
        self.setState({ loading: false });
        self.setState({ message: "Unable to Save", message_id: new Date() });
      });
  }

  runScript(rtype) {
    this.setState({ loading: true, scriptoutput: "" });
    let { assignment_id } = this.state.assignment;
    let self = this;
    fetch(
      `${window._MDOMAIN}api/v0/runscript?rtype=${rtype}&assignment_id=${assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.setState({ loading: false });
        if (data.status == 200) {
          self.setState({
            scriptoutput:
              "script started \n\n\n" + data.data + "\n\n\nscript ended",
          });
        } else {
          self.setState({ message: "Unable to run", message_id: new Date() });
        }
      })
      .catch(function () {
        self.setState({ loading: false });
        self.setState({ message: "Unable to run", message_id: new Date() });
      });
  }

  saveChannelMappings() {
    this.setState({ loading: true });
    let { campaign_mapping, assignment_id } = this.state.assignment;
    let self = this;
    fetch(
      `${window._MDOMAIN}api/v0/channelmapping?rtype=update&assignment_id=${assignment_id}`,
      {
        method: "post",
        body: JSON.stringify({ campaign_mapping }),
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.setState({ loading: false });
        if (data.status == 200) {
          self.setState({
            message: "Mappings Updated Successfully",
            message_id: new Date(),
          });
        } else {
          self.setState({ message: "Unable to Save", message_id: new Date() });
        }
      })
      .catch(function (e) {
        self.setState({ loading: false });
        self.setState({ message: "Unable to Save", message_id: new Date() });
      });
  }

  handleDayClick = (key, day) => {
    if (!day) {
      day = new Date("19700101");
    }
    let pts = day.toLocaleDateString("en-GB").split(",")[0].split("/");
    let { assignment, edit } = this.state;
    if (edit) {
      if (Math.abs(parseInt(pts[2]) - new Date().getFullYear()) < 2) {
        this.setValue(key, `${pts[2]}-${pts[1]}-${pts[0]}`);
      } else {
        this.setValue(key, null);
      }
      assignment["dirty"] = true;
    }
    this.setState({ assignment, cb: new Date() });
  };

  componentDidUpdate(props) {}

  handleKeyDown = (e) => {
    if (e.repeat) {
      return;
    }
    let valid = false;
    if (e.keyCode == 27) {
      this.setState({ opentab: -1 });
      valid = true;
    } else if (e.keyCode == 83 && (e.metaKey || e.ctrlKey)) {
      this.saveAssignment();
      valid = true;
    }
    if (valid) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  componentDidMount() {
    if (
      process.env.APP == "anarock" &&
      window.user_details &&
      window.user_details["type"] != "assignments"
    ) {
      config = [
        reportingDetails,
        // spendsDetails,
        mappingDetails,
        misDetails,
        thirdPartyDetails,
        adDetails,
      ];
    }

    if (window.location.href.indexOf("local.anarockdigital") != -1) {
      this.setState({
        opentab: 0,
      });
    }

    let { assignment_id } = this.props.assignment;
    this.getAssignment(assignment_id);
    this.getOptions(assignment_id);
    document.addEventListener("keydown", this.handleKeyDown);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleCheckBox = (accountId, conversionCheck) => {
    let { assignment } = this.state;
    if (
      this.props.assignment.meta &&
      this.props.assignment.meta[accountId] &&
      !this.props.assignment.meta[accountId].length == 0 &&
      assignment.meta &&
      assignment.meta[accountId] !== this.props.assignment.meta[accountId]
    ) {
      var conversion_check = assignment.meta[accountId];
      assignment.dirty = true;
      var check = assignment.meta[conversionCheck]
        ? assignment.meta[conversionCheck][0]
        : false;
      assignment.meta[conversionCheck] = [];
      if (conversion_check.length === 0) {
        assignment.meta[conversionCheck] = [];
      }
      for (var i = 0; i < conversion_check.length; i++) {
        assignment.meta[conversionCheck].push(check == null ? false : check);
      }
    }
  };

  handleChange = (event) => {
    let hchangetest1;
    let { assignment } = this.state;
    if (event.type == "keyval") {
      this.setValue(event.key, event.value);
    } else if (event.target.name == "meta.developer_key") {
      let key = event.target.value.replace(/-/g, "");
      let pkey = key;
      if (pkey.length < 3) {
        pkey += Math.random();
      }
      let pass = md5("jhdsb" + pkey)
        .words[0].toString(36)
        .replace(/-/g, "");
      this.setValue(event.target.name, key + "-" + pass);
    } else if (event.target.type == "checkbox") {
      this.setValue(event.target.name, event.target.checked);
    } else {
      let dtype = event.target.getAttribute("dtype");
      this.setValue(event.target.name, event.target.value, dtype);
    }

    this.setState({ assignment: JSON.parse(JSON.stringify(assignment)) });
  };

  setValue = (key, value, dtype) => {
    if (
      [
        "meta.missed_bookings",
        "meta.missed_walkins",
        "meta.missed_qualifications",
      ].indexOf(key) != -1
    ) {
      value = value.trim().replace(/[^0-9]+/g, ",");
    }
    // if(value && value.trim){
    //   value = value.trim()
    // }
    if (dtype == "int") {
      value = parseInt(value) || 0;
    } else if (dtype == "float") {
      value = parseFloat(value) || 0;
    }
    let { assignment, edit } = this.state;
    if (!edit) {
      this.setState({
        message: "Press Edit to start editing",
        message_id: new Date(),
      });
      return;
    }
    let output = assignment;
    let last = key.split(".").slice(-1);

    key
      .split(".")
      .slice(0, -1)
      .map((k) => {
        if (!output[k]) {
          output[k] = {};
        }
        output = output[k];
      });
    output[last] = value;
    assignment["dirty"] = true;
  };

  getValue = (key) => {
    let { assignment } = this.state;
    let output = assignment;
    if (typeof output["meta"] != "object") {
      output["meta"] = {};
    }
    key.split(".").map((k) => {
      if (output) {
        output = output[k];
      }
    });
    let value =
      typeof output == "object" ? JSON.parse(JSON.stringify(output)) : output;
    return value === undefined ? "" : value;
  };

  formatNumber = (num) => {
    if (num >= 10000000) {
      return `${(num / 10000000).toFixed(2)} Cr`.replace(".00", "");
    } else if (num >= 100000) {
      return `${(num / 100000).toFixed(2)} L`.replace(".00", "");
    } else {
      return parseFloat(num)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        .replace(".00", "");
    }
  };

  parseDate = (date) => {
    try {
      let [m, d, y] = new Date(date).toString().split(" ").slice(1, 4);
      console.log(m, d, y);
      return `${d}${m}${y.slice(-2)}`;
    } catch (e) {}
  };

  render() {
    let {
      loading,
      opentab,
      assignment,
      message,
      message_id,
      campaignhtml,
      edit,
      scriptoutput,
      options = {},
      more = false,
    } = this.state;
    let { assignment_id } = assignment;
    let { dirty } = assignment;
    let morecrossed = false;
    return (
      <div style={{ marginBottom: "30px" }}>
        <style dangerouslySetInnerHTML={{ __html: styles }} />
        <div
          style={{
            display: "flex",
            padding: 10,
            color: "#6b7785",
            display: "none",
          }}
        >
          <Link to={`/panel/${assignment_id}/configuration`}>
            <div style={{ marginRight: 20 }}>config</div>
          </Link>
          <Link to={`/panel/${assignment_id}/spends`}>
            <div style={{ marginRight: 20 }}>spends</div>
          </Link>
          {false && (
            <Link to={`/panel/${assignment_id}/checklist`}>
              <div style={{ marginRight: 20 }}>checklist</div>
            </Link>
          )}
        </div>
        <div>
          {config.map((details, index) => {
            let { header, items } = details;
            return (
              <div
                style={{
                  overflow: "hidden",
                  border: "1px solid #e0e0e0",
                  marginBottom: "5px",
                  marginRight: "10px",
                  width: 300,
                  borderRadius: 5,
                }}
              >
                <Notify message={message} message_id={message_id} />
                <div
                  style={{
                    padding: "15px",
                    background: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => this.setState({ opentab: index })}
                >
                  {header.name}
                  <div style={{ display: "flex" }}></div>
                </div>
                {opentab == index ? (
                  <div
                    onClick={(e) => this.setState({ opentab: -1 })}
                    style={{
                      overflowY: "scroll",
                      display: "flex",
                      zIndex: 10004,
                      position: "fixed",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0,0,0,0.5)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="customscrollbig __content"
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          minWidth: 800,
                          maxWidth: "100%",
                          top: 20,
                          background: "white",
                          position: "absolute",
                          borderRadius: 3,
                          overflow: "hidden",
                        }}
                      >
                        {loading && (
                          <div
                            style={{
                              position: "absolute",
                              zIndex: 100000,
                              width: "100%",
                              height: "100%",
                              background: "rgba(0,0,0,.2)",
                            }}
                          ></div>
                        )}
                        <div
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            cursor: "pointer",
                          }}
                        >
                          <Ionicon
                            onClick={() => this.setState({ opentab: -1 })}
                            icon="md-close"
                            fontSize="36px"
                            color="#ccc"
                          />
                        </div>
                        <div
                          style={{
                            padding: 30,
                            paddingBottom: 10,
                            background: "#fff",
                            color: "#000",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{ fontSize: 28, marginRight: 10, flex: 1 }}
                            >
                              <div>
                                {assignment.extra_details.assignment_name}
                              </div>
                              <div
                                className={css`
                                  font-size: 12px;
                                  color: #6161ff;
                                  display: flex;
                                  margin-top: 20px;
                                  > div {
                                    font-size: 12px;
                                  }
                                  .chip {
                                    min-width: 120px;
                                    font-size: 12px;
                                    white-space: nowrap;
                                    padding: 3px 10px;
                                    border-radius: 3px;
                                    border: 1px solid #e1e1ff;
                                    margin-right: 5px;
                                  }
                                `}
                              >
                                <div>
                                  <div>duration</div>
                                  <div className="chip">
                                    {this.parseDate(
                                      assignment.extra_details.start_date
                                    )}{" "}
                                    -{" "}
                                    {this.parseDate(
                                      assignment.extra_details.end_date
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <div>assignment type</div>
                                  <div className="chip">
                                    {assignment.extra_details.assignment_type}
                                  </div>
                                </div>
                                <div>
                                  <div>assignment id</div>
                                  <div className="chip">
                                    {assignment.extra_details.id}
                                  </div>
                                </div>
                                <div>
                                  <div>status</div>
                                  <div className="chip">
                                    {assignment.extra_details.status}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ marginRight: 20 }}>
                              {!edit ? (
                                <div
                                  className="btn"
                                  onClick={() => this.setState({ edit: !edit })}
                                >
                                  Edit
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="btn"
                                    onClick={this.saveAssignment}
                                    style={{
                                      color: dirty ? "red" : null,
                                      marginLeft: 10,
                                    }}
                                  >
                                    Save
                                  </div>
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      marginLeft: 5,
                                    }}
                                    onClick={() => this.resetAssignment()}
                                  >
                                    Reset
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div style={{ marginTop: 10 }}>{header.name}</div>
                        </div>
                        <div
                          style={{
                            padding: 30,
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderTop: "1px solid #e0e0e0",
                            overflow: "auto",
                            height: "80vh",
                          }}
                        >
                          {items
                            .filter(
                              (o) =>
                                !o.is_anarock ||
                                (this.getValue("meta.agency_name") || "")
                                  .toLowerCase()
                                  .indexOf("anarock") != -1
                            )
                            .map((o, index) => {
                              if (!more && morecrossed) {
                                return;
                              }
                              return (
                                <div style={{ display: "flex" }}>
                                  <div
                                    onClick={(e) => {
                                      if (!edit) {
                                        this.setState({
                                          message:
                                            "Press Edit to start editing",
                                          message_id: new Date(),
                                        });
                                      }
                                      let elems =
                                        e.currentTarget.querySelectorAll(
                                          "input"
                                        );
                                      if (elems.length == 1) elems[0].focus();
                                    }}
                                    key={index}
                                    style={{
                                      marginLeft: morecrossed ? 20 : 0,
                                      display: "flex",
                                      flexDirection: "column",
                                      padding: "6px",
                                      border: "1px solid #e0e0e0",
                                      borderRadius: 3,
                                      marginBottom: 10,
                                      width: "fit-content",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        fontSize: "10px",
                                        color: "#555",
                                      }}
                                    >
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {o.label}
                                      </div>
                                      {o.tooltip ? (
                                        <Tooltip text={o.tooltip} />
                                      ) : null}
                                    </div>
                                    <div style={{}}>
                                      {o.type == "input" && (
                                        <div style={{ position: "relative" }}>
                                          <input
                                            className="input"
                                            name={o.key}
                                            dtype={o.dtype}
                                            value={this.getValue(o.key)}
                                            onChange={this.handleChange}
                                            readOnly={
                                              !edit ||
                                              o.readonly ||
                                              o.key.indexOf("extra_details") !=
                                                -1
                                            }
                                          />
                                          {o.showNum && this.getValue(o.key) && (
                                            <div
                                              style={{
                                                position: "absolute",
                                                bottom: 0,
                                                right: 20,
                                                color: "#999",
                                              }}
                                            >
                                              {this.formatNumber(
                                                this.getValue(o.key)
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      {o.type == "input-checkbox" && (
                                        <div style={{ position: "relative" }}>
                                          <input
                                            className="input"
                                            name={o.key}
                                            dtype={o.dtype}
                                            value={
                                              this.getValue(o.key) &&
                                              this.getValue(o.key).toString()
                                            }
                                            onChange={(e) => {
                                              let { assignment } = {
                                                ...this.state,
                                              };
                                              assignment.dirty = true;
                                              var val = e.target.value;
                                              if (assignment["meta"] == null) {
                                                assignment["meta"] = {};
                                              }
                                              assignment["meta"][
                                                o.key.split(".")[1]
                                              ] = val || "";
                                              this.getValue(o.key);
                                              this.handleCheckBox(
                                                o.key.split(".")[1],
                                                o.cKey.split(".")[1]
                                              );
                                              this.setState({
                                                assignment: JSON.parse(
                                                  JSON.stringify(assignment)
                                                ),
                                              });
                                            }}
                                            readOnly={
                                              !edit ||
                                              o.readonly ||
                                              o.key.indexOf("extra_details") !=
                                                -1
                                            }
                                          />
                                          {o.showNum && this.getValue(o.key) && (
                                            <div
                                              style={{
                                                position: "absolute",
                                                bottom: 0,
                                                right: 20,
                                                color: "#999",
                                              }}
                                            >
                                              {this.formatNumber(
                                                this.getValue(o.key)
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {o.type == "developer-panel" && (
                                        <div style={{ position: "relative" }}>
                                          <input
                                            className="input"
                                            name={o.key}
                                            dtype={o.dtype}
                                            value={
                                              this.getValue(o.key).split("-")[0]
                                            }
                                            onChange={this.handleChange}
                                            readOnly={
                                              !edit ||
                                              o.readonly ||
                                              o.key.indexOf("extra_details") !=
                                                -1
                                            }
                                            placeHolder="Enter Developer Name"
                                          />
                                          {this.getValue(o.key) && !dirty && (
                                            <div
                                              style={{
                                                position: "absolute",
                                                bottom: 0,
                                                right: 20,
                                                color: "#999",
                                              }}
                                            >
                                              <a
                                                target="_blank"
                                                href={`https://developer.anarockdigital.com/?key=${this.getValue(
                                                  o.key
                                                )}`}
                                              >
                                                open
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {o.type == "random" && (
                                        <div style={{ position: "relative" }}>
                                          <input
                                            className="input"
                                            name={o.key}
                                            dtype={o.dtype}
                                            value={this.getValue(o.key)}
                                            onChange={this.handleChange}
                                            readOnly={true}
                                          />
                                          {edit && (
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                right: 20,
                                                top: -10,
                                                border: "1px solid #e0e0e0",
                                                borderRadius: 3,
                                                padding: 5,
                                              }}
                                              onClick={(e) => {
                                                this.setValue(
                                                  o.key,
                                                  this.getValue(
                                                    "extra_details.assignment_name"
                                                  )
                                                    .toLowerCase()
                                                    .replace(/[^a-z]+/g, "-")
                                                    .slice(0, 30) +
                                                    "-" +
                                                    parseInt(
                                                      Math.random() * 1000000000
                                                    )
                                                );
                                                this.setState({
                                                  cb: new Date(),
                                                });
                                              }}
                                            >
                                              generate
                                            </div>
                                          )}
                                          <div
                                            style={{
                                              width: 600,
                                              display: "flex",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            {this.getValue("meta.agency_key") &&
                                              [
                                                "agencydailysummary",
                                                "agencyleaddump",
                                              ].map((o) => (
                                                <a
                                                  style={{ padding: 5 }}
                                                  target="_blank"
                                                  href={`https://marketing-dashboards.anarock.com/webhooks/download-report?key=${this.getValue(
                                                    "meta.agency_key"
                                                  )}&report=${o}`}
                                                >
                                                  {o}
                                                </a>
                                              ))}
                                          </div>
                                        </div>
                                      )}
                                      {o.type == "checkbox" && (
                                        <input
                                          className="input"
                                          name={o.key}
                                          type="checkbox"
                                          checked={this.getValue(o.key)}
                                          onChange={this.handleChange}
                                          readOnly={
                                            !edit ||
                                            o.readonly ||
                                            o.key.indexOf("extra_details") != -1
                                          }
                                        />
                                      )}
                                      {o.type == "select" && (
                                        <select
                                          className="input"
                                          name={o.key}
                                          value={this.getValue(o.key)}
                                          onChange={this.handleChange}
                                          disabled={
                                            !edit ||
                                            o.readonly ||
                                            o.key.indexOf("extra_details") != -1
                                          }
                                        >
                                          <option value="">
                                            {" "}
                                            -- select an option --{" "}
                                          </option>
                                          {o.options.map((o) => (
                                            <option value={o.value}>
                                              {o.name}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                      {o.type == "date" && (
                                        <DayPickerInput
                                          value={
                                            this.getValue(o.key)
                                              ? new Date(this.getValue(o.key))
                                              : null
                                          }
                                          formatDate={(o) => {
                                            let [m, d, y] = o
                                              .toString()
                                              .split(" ")
                                              .slice(1, 4);
                                            return `${d} ${m}`;
                                          }}
                                          format="dd mmm"
                                          onDayChange={(day) =>
                                            this.handleDayClick(o.key, day)
                                          }
                                        />
                                      )}
                                      {o.type == "daterange" && (
                                        <DateRange
                                          {...o}
                                          handleDayClick={this.handleDayClick}
                                          getValue={this.getValue}
                                        />
                                      )}
                                      {o.type == "files" && (
                                        <Files
                                          val={this.getValue(o.key)}
                                          getValue={this.getValue}
                                          name={o.key}
                                          handleChange={this.handleChange}
                                        />
                                      )}
                                      {o.type == "labels" && (
                                        <div
                                          style={{ position: "relative" }}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <input
                                            className="input"
                                            style={{ width: 388 }}
                                            autocomplete="off"
                                            spellCheck={false}
                                            name={o.key}
                                            dtype={o.dtype}
                                            value={this.getValue(o.key)}
                                            onChange={this.handleChange}
                                            readOnly={
                                              !edit ||
                                              o.readonly ||
                                              o.key.indexOf("extra_details") !=
                                                -1
                                            }
                                            onFocus={() =>
                                              edit &&
                                              this.setState({ lkey: o.key })
                                            }
                                            onBlur={(e) => {
                                              let target = e.target;
                                              setTimeout(
                                                () =>
                                                  target.name ==
                                                    this.state.lkey &&
                                                  target !=
                                                    document.activeElement &&
                                                  this.setState({ lkey: "" }),
                                                100
                                              );
                                            }}
                                          />
                                          <Ionicon
                                            icon="md-close"
                                            fontSize="12"
                                            color="#6b7785"
                                            onClick={() => {
                                              //let value = [...this.getValue(o.key).split(',').slice(0,-1),l].join(',')
                                              this.handleChange({
                                                type: "keyval",
                                                key: o.key,
                                                value: "",
                                              });
                                            }}
                                          />
                                          {this.state.lkey == o.key && (
                                            <div className={css_labels}>
                                              {(options[o.okey] || [])
                                                .filter(
                                                  (l) =>
                                                    l &&
                                                    l
                                                      .toLowerCase()
                                                      .indexOf(
                                                        (
                                                          this.getValue(
                                                            o.key
                                                          ) || ""
                                                        ).toLowerCase()
                                                      ) != -1
                                                )
                                                .map((l) => (
                                                  <div
                                                    onClick={() => {
                                                      let value = l; //[...this.getValue(o.key).split(',').slice(0,-1),l].join(',')
                                                      this.handleChange({
                                                        type: "keyval",
                                                        key: o.key,
                                                        value,
                                                      });
                                                    }}
                                                  >
                                                    {l}
                                                  </div>
                                                ))}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {o.type == "more" && (
                                        <div
                                          className={css`
                                            cursor: pointer;
                                            color: #6161ff;
                                            display: flex;
                                            align-items: center;
                                          `}
                                          onClick={() =>
                                            this.setState({ more: !more })
                                          }
                                        >
                                          <div>
                                            {more ? "Hide More" : "Show More"}
                                          </div>
                                          {(morecrossed = true)}
                                          <Ionicon
                                            icon={`md-arrow-drop${
                                              more ? "up" : "down"
                                            }`}
                                            color="#6161ff"
                                            className={css`
                                              margin-top: 2px;
                                            `}
                                          />
                                        </div>
                                      )}
                                      {o.type == "table" && (
                                        <Table
                                          {...o}
                                          name={o.key}
                                          value={this.getValue(o.key) || []}
                                          handleChange={this.handleChange}
                                        />
                                      )}
                                      {o.type == "campaign_mapping" && (
                                        <div>
                                          <div style={{}}>
                                            <textarea
                                              name={o.key}
                                              value={assignment[o.key]}
                                              onChange={this.handleChange}
                                              readOnly={
                                                !edit ||
                                                o.readonly ||
                                                o.key.indexOf(
                                                  "extra_details"
                                                ) != -1
                                              }
                                              style={{
                                                height: 400,
                                                whiteSpace: "pre",
                                              }}
                                            />
                                            {false && (
                                              <div
                                                style={{
                                                  height: 800,
                                                  width: 800,
                                                  overflow: "hidden",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <CodeMirror
                                                  name={o.key}
                                                  value={assignment[o.key]}
                                                  onChange={(editor, input) => {
                                                    this.setValue(
                                                      o.key,
                                                      editor.getValue()
                                                    );
                                                    this.setState({
                                                      assignment: JSON.parse(
                                                        JSON.stringify(
                                                          assignment
                                                        )
                                                      ),
                                                    });
                                                  }}
                                                  style={{ flex: 1 }}
                                                  options={{
                                                    theme: "monokai",
                                                    readOnly:
                                                      !edit ||
                                                      o.readonly ||
                                                      o.key.indexOf(
                                                        "extra_details"
                                                      ) != -1,
                                                  }}
                                                />
                                              </div>
                                            )}
                                            <div style={{ display: "flex" }}>
                                              <div
                                                onClick={() =>
                                                  this.getChannelMappings()
                                                }
                                                style={{
                                                  width: 80,
                                                  cursor: "pointer",
                                                  padding: "5px 10px",
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                Preview
                                              </div>
                                              <div
                                                onClick={() =>
                                                  this.saveChannelMappings()
                                                }
                                                style={{
                                                  width: 80,
                                                  cursor: "pointer",
                                                  padding: "5px 10px",
                                                  marginLeft: "10px",
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                Update
                                              </div>
                                            </div>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: campaignhtml,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}
                                      {o.type == "budgetplan" && (
                                        <Budgetplan
                                          readOnly={
                                            !edit ||
                                            o.readonly ||
                                            o.key.indexOf("extra_details") != -1
                                          }
                                          setPlan={(plan) => {
                                            assignment["dirty"] = true;
                                            this.setValue(
                                              "meta.budgetplan",
                                              plan
                                            );
                                            this.setState({
                                              assignment: JSON.parse(
                                                JSON.stringify(assignment)
                                              ),
                                            });
                                          }}
                                          assignment={assignment}
                                        />
                                      )}
                                      {o.type == "textarea" && (
                                        <textarea
                                          name={o.key}
                                          value={this.getValue(o.key)}
                                          onChange={this.handleChange}
                                          readOnly={
                                            !edit ||
                                            o.readonly ||
                                            o.key.indexOf("extra_details") != -1
                                          }
                                        />
                                      )}
                                      {o.type == "channelmapping" && (
                                        <ChannelMapping
                                          name={o.key}
                                          value={this.getValue(o.key)}
                                          onChange={this.handleChange}
                                          readOnly={
                                            !edit ||
                                            o.readonly ||
                                            o.key.indexOf("extra_details") != -1
                                          }
                                        />
                                      )}
                                      {o.type == "mediaplan1" && (
                                        <Mediaplan assignment={assignment} />
                                      )}
                                      {o.type == "budgetlog" && (
                                        <Budgetlog
                                          edit={edit}
                                          value={this.getValue(o.key)}
                                          handleChange={(value) => {
                                            this.setValue(o.key, value);
                                            this.setState({ cb: new Date() });
                                          }}
                                        />
                                      )}
                                      {o.type == "scripts" && (
                                        <div
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <div
                                              onClick={() =>
                                                this.runScript("adwords_spends")
                                              }
                                              style={{
                                                cursor: "pointer",
                                                padding: "5px 10px",
                                                textDecoration: "underline",
                                              }}
                                            >
                                              update adwords spends
                                            </div>
                                            <div
                                              onClick={() =>
                                                this.runScript(
                                                  "facebook_spends"
                                                )
                                              }
                                              style={{
                                                cursor: "pointer",
                                                padding: "5px 10px",
                                                marginLeft: "10px",
                                                textDecoration: "underline",
                                              }}
                                            >
                                              update facebook spends
                                            </div>
                                          </div>
                                          <div>
                                            <textarea
                                              value={scriptoutput}
                                              readOnly={true}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {o.type == "input-checkbox" &&
                                    this.state.assignment["meta"] &&
                                    this.state.assignment["meta"][
                                      o.key.split(".")[1]
                                    ] &&
                                    this.state.assignment["meta"][
                                      o.key.split(".")[1]
                                    ].length != 0 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          marginTop: "16px",
                                        }}
                                      >
                                        <input
                                          className="input"
                                          style={{
                                            width: "60px",
                                            marginTop: "5px",
                                          }}
                                          name={o.cKey}
                                          type="checkbox"
                                          checked={
                                            this.getValue(o.cKey) &&
                                            this.getValue(o.cKey)[0]
                                          }
                                          onChange={(e) => {
                                            if (!edit) {
                                              this.setState({
                                                message:
                                                  "Press Edit to start editing",
                                                message_id: new Date(),
                                              });
                                              return;
                                            }
                                            let { assignment } = {
                                              ...this.state,
                                            };
                                            var conversion_check =
                                              assignment["meta"][
                                                o.key.split(".")[1]
                                              ] &&
                                              assignment["meta"][
                                                o.key.split(".")[1]
                                              ].split(",");
                                            assignment.dirty = true;
                                            assignment["meta"][
                                              o.cKey.split(".")[1]
                                            ] = [];
                                            for (
                                              var i = 0;
                                              i < conversion_check.length;
                                              i++
                                            ) {
                                              assignment["meta"][
                                                o.cKey.split(".")[1]
                                              ].push(e.target.checked);
                                            }
                                            this.getValue(o.cKey);
                                            this.setState({
                                              assignment: JSON.parse(
                                                JSON.stringify(assignment)
                                              ),
                                            });
                                          }}
                                          readOnly={
                                            !edit ||
                                            o.readonly ||
                                            o.key.indexOf("extra_details") != -1
                                          }
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            Enable for offline conversions
                                          </div>
                                          <MUITooltip
                                            interactive
                                            placement="top"
                                            classes={{
                                              tooltip: css({
                                                backgroundColor:
                                                  "black !important",
                                              }),
                                              popper: css({
                                                zIndex: "100000 !important",
                                              }),
                                            }}
                                            title={
                                              <span
                                                className={css({
                                                  fontSize: "12px",
                                                })}
                                              >
                                                <span>
                                                  {o.cKey ==
                                                  "meta.facebook_conversion_check"
                                                    ? "Facebook"
                                                    : "Google"}{" "}
                                                  offline conversions lets
                                                  businesses leverage their
                                                  offline events data – such as
                                                  qualified leads, site visits –
                                                  to help power their digital
                                                  campaigns.{" "}
                                                </span>
                                                <a
                                                  className={css({
                                                    color: "turquoise",
                                                    cursor: "pointer",
                                                  })}
                                                  onClick={() => {
                                                    const url =
                                                      o.cKey ==
                                                      "meta.facebook_conversion_check"
                                                        ? "https://en-gb.facebook.com/business/learn/facebook-offline-conversions"
                                                        : "https://support.google.com/google-ads/answer/7012522?hl=en&ref_topic=9941534";
                                                    window
                                                      .open(url, "_blank")
                                                      .focus();
                                                  }}
                                                >
                                                  Learn More
                                                </a>
                                              </span>
                                            }
                                          >
                                            <div>
                                              <Ionicon icon="md-information-circle" />
                                            </div>
                                          </MUITooltip>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {false && (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        style={{ width: 400, zIndex: 1000, minHeight: "100%" }}
                      >
                        <div
                          style={{
                            position: "fixed",
                            right: 6,
                            bottom: 0,
                            top: 0,
                            width: 400,
                            background: "#fff",
                          }}
                        >
                          <Chat />
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
        <div
          style={{
            position: "fixed",
            marginLeft: 10,
            bottom: 20,
            zIndex: 10001,
          }}
        >
          {opentab < 0 && (
            <div>
              {edit ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="btn"
                    onClick={this.saveAssignment}
                    style={{ color: dirty ? "red" : null, marginLeft: 10 }}
                  >
                    Save
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      marginLeft: 5,
                    }}
                    onClick={() => this.resetAssignment()}
                  >
                    Reset
                  </div>
                </div>
              ) : (
                <div
                  className="btn"
                  onClick={() => {
                    this.setState({ edit: !edit });
                  }}
                >
                  Edit Assignment
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ height: 100 }}></div>
      </div>
    );
  }
}

export default Assignment;

class Files extends PureComponent {
  uploadFile = (file) => {
    let self = this;
    let url = `${window._MDOMAIN}/api/v0/documents?rtype=upload&entity_id=${this.props.poid}&entity_type=po&tags=tag1,tag2`;
    let formData = new FormData();

    formData.append("file", file);
    // let fname = prefix+file.name
    fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        let files = self.props.getValue(self.props.name);
        if (data.status == 200) {
          for (let f of files) {
            if (f.name == file.name) {
              f.status = "done";
              f.link = data.data.link;
            }
          }
        } else {
          for (let f of files) {
            if (f.name == file.name) {
              f.status = "error";
            }
          }
          self.setState({
            message: "Unable to upload Files",
            message_id: new Date(),
          });
        }
        self.props.handleChange({
          type: "keyval",
          key: self.props.name,
          value: files,
        });
      })
      .catch(function () {
        let files = self.props.getValue(self.props.name);
        for (let f of files) {
          if (f.name == file.name) {
            f.status = "error";
          }
        }
        self.setState({
          message: "Unable to upload Files",
          message_id: new Date(),
        });
        self.setState({ cb: new Date() });
        self.props.handleChange({
          type: "keyval",
          key: self.props.name,
          value: files,
        });
      });
  };

  getLink = (name) => {
    let link = "https://anarock-digital.s3.amazonaws.com/" + name;
    if (
      ["xlsx", "docx", "ppt", "pptx", "doc"].indexOf(
        name.split(".").slice(-1)[0]
      ) != -1
    ) {
      return (
        "https://view.officeapps.live.com/op/view.aspx?src=" +
        encodeURIComponent(link)
      );
    }
    return link;
  };

  handleFileUpload = (e) => {
    let { handleChange, getValue, name } = this.props;
    let items = getValue(name) || [];

    e.preventDefault();
    e.stopPropagation();
    let obj = e.dataTransfer || e.currentTarget;
    if (!obj) return;
    Object.values(obj.files).forEach((file) => this.uploadFile(file));
    let files = Object.values(obj.files).map((o) => {
      return {
        name: o.name,
        size: o.size,
        link: "",
        preview: "",
        status: null,
      };
    });
    handleChange({ type: "keyval", key: name, value: [...items, ...files] });
  };

  render() {
    let { handleChange, getValue, name } = this.props;
    let items = getValue(name) || [];
    return (
      <div
        className={css`
          .delete {
            cursor: pointer;
            color: #6b7785;
            padding: 5px;
            :hover {
              color: red;
            }
          }
        `}
      >
        <div
          style={{
            background: "#fbfbfb",
            display: "flex",
            flexWrap: "wrap",
            width: 600,
            minHeight: 100,
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.currentTarget.querySelector("#file-upload").click();
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDrop={(e) => this.handleFileUpload(e)}
        >
          {items.map((o, index) => (
            <div
              onClick={(e) => e.stopPropagation()}
              style={{
                marginRight: 10,
                position: "relative",
                maxWidth: 100,
                border: o.status == "error" ? "1px solid red" : null,
                opacity: ["done", "error"].indexOf(o.status) != -1 ? 1 : 0.3,
              }}
            >
              <div
                className="delete"
                onClick={() => {
                  let files = getValue(name);
                  handleChange({
                    type: "keyval",
                    key: name,
                    value: files.filter((_, i) => i != index),
                  });
                }}
                style={{ position: "absolute", right: 5, top: 5 }}
              >
                x
              </div>
              <a target="_blank" href={this.getLink(o.link)}>
                <div
                  style={{
                    padding: 5,
                    height: 70,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: 100,
                  }}
                >
                  {o.name}
                </div>
              </a>
              <select
                value={o.type}
                onChange={(e) => {
                  o.type = e.target.value;
                  handleChange({ type: "keyval", key: name, value: items });
                }}
              >
                <option></option>
                <option>agreement</option>
                <option>email</option>
              </select>
            </div>
          ))}
          <div>
            <input
              id="file-upload"
              style={{ display: "none" }}
              onChange={(e) => this.handleFileUpload(e)}
              type="file"
              multiple
            ></input>
          </div>
        </div>
      </div>
    );
  }
}