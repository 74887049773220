import React, { Component } from "react";
import { css, cx } from "react-emotion";
import _ from "lodash";
import { mixins, colors, Switch, Modal, PopUp } from "pebble-web";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./agGridstyles.css";
import RuleCard from "./rule-card";

const campaignList = css({
  maxWidth: "250px",
  overflow: "hidden",
  whiteSpace: "no-wrap",
  textOverflow: "ellipsis",
});
const modalBackDropStyle = css({
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const modalContainerBody = css({
  background: colors.gray.lightest,
  position: "fixed",
  top: 150,
  borderRadius: 3,
});
export class RulesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoView: false,
      deletePopup: false,
      columnDefs: [
        {
          headerName: "On/Off",
          field: "status",
          suppressSizeToFit: true,
          cellRenderer: "actionCellComponent",
          cellRendererParams: {
            onClick: this.toggleCondition,
          },
          width: 80,
        },
        {
          headerName: "Sub Source",
          field: "subsource",
        },
        {
          headerName: "Apply Rule To",
          field: "campaign_ids",
          cellRenderer: "campaignCellComponent",
          cellRendererParams: {
            source: this.props.rule.source,
            campaigns: this.props.campaigns,
          },
          width: 250,
        },
        {
          headerName: `MediaPlan ${this.props.rule.parameter}`,
          field: "rule_params",
          cellRenderer: "mediaplanCellComponent",
        },
        {
          headerName: `Notify if ${this.props.rule.parameter} Crosses`,
          field: "rule_params",
          cellRenderer: "notifyCellComponent",
        },
        {
          headerName: `Turn off Campaign at ${this.props.rule.parameter}`,
          field: "rule_params",
          cellRenderer: "cutoffCellComponent",
          width: 250,
        },
      ],
      frameworkComponents: {
        actionCellComponent: ActionCellComponent,
        campaignCellComponent: CampaignCellComponent,
        mediaplanCellComponent: MediaPlanCellComponent,
        notifyCellComponent: NotifyCellComponent,
        cutoffCellComponent: CutoffCellComponent,
      },
    };
  }
  toggleCondition = (rowIndex) => {
    const { rule, updateRule } = this.props;
    const newRule = { ...rule };
    newRule.conditions[rowIndex].status = !newRule.conditions[rowIndex].status;
    updateRule(newRule);
  };
  render() {
    const { rule, onEdit, deleteRule, subsourceMapping, assignment } = this.props;
    const { columnDefs, infoView, deletePopup } = this.state;
    const onGridReady = (params) => {
      params.api.sizeColumnsToFit();
      params.columnApi.autoSizeColumns();
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    };
    if (this.gridApi) this.gridApi.sizeColumnsToFit();
    if (this.columnApi) this.columnApi.autoSizeColumns();
    const data = rule.conditions;
    return (
      <div>
        <div
          className={css({
            backgroundColor: colors.gray.lighter,
            borderRadius: "3px 3px 0px 0px",
            padding: "0px 10px",
            height: "50px",
            alignItems: "center",
            ...mixins.flexSpaceBetween,
          })}
        >
          <div className={css({ ...mixins.flexRow })}>
            <div className={css({ fontSize: "13px", marginRight: "10px" })}>
              {rule.name}
            </div>
          </div>
          <div
            className={css({
              ...mixins.flexMiddleAlign,
              fontSize: 18,
              color: colors.gray.dark,
            })}
          >
            <i
              className={cx(
                css({ marginRight: 32, cursor: "pointer" }),
                "pi",
                "pi-info"
              )}
              onClick={() => this.setState({ infoView: true })}
            />
            <i
              className={cx(
                css({ marginRight: 32, cursor: "pointer" }),
                "pi",
                "pi-edit"
              )}
              onClick={() => onEdit(rule.rule_id)}
            />
            <i
              className={cx(
                css({ marginRight: 22, cursor: "pointer" }),
                "pi",
                "pi-delete"
              )}
              onClick={() => this.setState({ deletePopup: true })}
            />
          </div>
        </div>

        <div
          className={cx(
            "ag-theme-alpine",
            "custom-theme",
            css({
              width: "100%",
              margin: "0px 0px 15px",
              fontSize: "12px",
            })
          )}
        >
          <AgGridReact
            suppressMovableColumns={true}
            suppressRowTransform={true}
            onGridReady={onGridReady}
            defaultColDef={{
              cellStyle: {
                border: `0.5px solid ${colors.gray.lighter}`,
                fontWeight: "lighter",
                backgroundColor: colors.white.base,
                fontSize: "12px",
                margin: "auto 0px",
              },
            }}
            domLayout="autoHeight"
            headerHeight={50}
            rowHeight={50}
            animateRows={false}
            rowSelection="multiple"
            columnDefs={columnDefs}
            rowData={data}
            frameworkComponents={this.state.frameworkComponents}
            pagination={false}
          />
        </div>
        <Modal
          backDropClassName={modalBackDropStyle}
          modalClassName={cx(modalContainerBody, css({ width: "45%" }))}
          visible={infoView}
        >
          <RuleCard
            subsourceMapping={subsourceMapping}
            rule={rule}
            onClose={() => this.setState({ infoView: false })}
            assignment={assignment}
          />
        </Modal>
        <PopUp
          visible={deletePopup}
          onApprove={() => deleteRule(rule.rule_id)}
          onReject={() => this.setState({ deletePopup: false })}
          onClose={() => this.setState({ deletePopup: false })}
        >
          <div>Confirm delete the rule?</div>
        </PopUp>
      </div>
    );
  }
}

class CampaignCellComponent extends Component {
  render() {
    const { value, campaigns, source } = this.props;
    const campaignValues = value.map((campaign) => {
      const campaignList =
        source === "facebook" ? campaigns.facebook : campaigns.google;
      const campaignItem = campaignList.find((item) => item.id === campaign);
      const campaignName =
        (campaignItem && campaignItem.name) || "Invalid Campaign";
      return campaignName;
    });
    return <div className={campaignList}>{campaignValues.join(",")}</div>;
  }
}

class ActionCellComponent extends Component {
  render() {
    const { onClick, value, rowIndex } = this.props;
    return (
      <div className={css({ width: 50 })}>
        <Switch
          disabled={false}
          initialValue={value}
          onChange={(value) => onClick(rowIndex)}
          label={""}
        />
      </div>
    );
  }
}
class MediaPlanCellComponent extends Component {
  render() {
    const { value } = this.props;
    return <div>&#8377; {value[0].mediaplan_param}</div>;
  }
}
class NotifyCellComponent extends Component {
  render() {
    const { value } = this.props;
    const paramValue = value[0];
    return (
      <div className={css({ ...mixins.flexRow })}>
        <div>&#8377; {paramValue.notify_param}</div>
        <ParamComponent
          mediaplan_param={paramValue.mediaplan_param}
          param={paramValue.notify_param}
        />
      </div>
    );
  }
}

class CutoffCellComponent extends Component {
  render() {
    const { value } = this.props;
    const paramValue = value[0];
    return (
      <div className={css({ ...mixins.flexRow })}>
        <div>&#8377; {paramValue.cutoff_param}</div>
        <ParamComponent
          mediaplan_param={paramValue.mediaplan_param}
          param={paramValue.cutoff_param}
        />
      </div>
    );
  }
}

class ParamComponent extends Component {
  render() {
    const { mediaplan_param, param } = this.props;
    const description =
      mediaplan_param === param
        ? "(at mediaplan)"
        : `(${((param / mediaplan_param) * 100).toFixed(1)}% of mediaplan)`;
    return (
      <div
        className={css({
          color: colors.gray.base,
          marginLeft: "5px",
          fontSize: "11px",
        })}
      >
        {description}
      </div>
    );
  }
}
