import React, { PureComponent } from 'react'
import {css} from 'react-emotion'

const colors = {
  "gray-darker": "#101721",
  "gray-dark": "#6B7785",
  "gray-base": "#9FA6B0",
  "gray-light": "#E0E0E0",
  "gray-lighter": "#EDEDED",
  "gray-lightest": "#F7F7F9",
  "violet-darker": "#4040BA",
  "violet-dark": "#5656ED",
  "violet-base": "#6161FF",
  "violet-light": "#7070FF",
  "violet-lighter": "#C0C0FF",
  "violet-lightest": "#E8E8FF"
}

export default class FixedGrid extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      sortby: ''
    }
    this.ranges = {}
  }
  
  formatNum = (num,name='') => {
    if((name || '').toLowerCase().indexOf('rate')!=-1){
      if(!num) return '';
      return num+' %'
    }
    if((name||'').toLowerCase().indexOf('date')!=-1){
      if(!num) return '';
      if(num=='Total') return 'Total';
      var d = new Date(0);
      d.setUTCSeconds(num/1000);
      let [w,m,d,y,_] = d.toString().split(' ');
      return `${m} ${d}`
    }
    if((name||'').toLowerCase().indexOf('week')!=-1){
      if(!num) return '';
      if(num=='Total') return 'Total';
      var d = new Date(0);
      d.setUTCSeconds(num/1000);
      let [w,m,d,y,_] = d.toString().split(' ');
      return `${m} ${d}`
    }
    if(['number'].indexOf(typeof(num))!=-1) {
      return (parseFloat(num) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\..*/,'');
    } else {
      return num
    }
  }
  
  getGrid = (data,keys) => {
    try{
      let rKey = keys[0]
      let cKey = keys[1]
      let vKeys = keys.slice(2)
    	let grid = {}
      let rowH = {}
      let colH = {}
      
      let {sortby} = this.state
      
      let rowsortVal = {}
      for(let item of data){
        if(!item[rKey] || !item[cKey]) continue;
        
        let r = this.formatNum(item[rKey],rKey)
        let c = this.formatNum(item[cKey],cKey)
        let vals = []
        for(let key of vKeys){
          vals.push(this.formatNum(item[key],key))
        }
        if(sortby==c){
          rowsortVal[r] = item[vKeys[0]]
        }
        if(sortby=='-'+c){
          rowsortVal[r] = -1*item[vKeys[0]]
        }
        if(!grid[r]) grid[r] = {}
        rowH[item[rKey]] = rowsortVal[r] || 0
        colH[item[cKey]] = 1
        grid[r][c] = vals
      }
      let rows = []
      for(let k in rowH){
        rows.push([k,rowH[k]])
      }
      console.log(rows)
      rowH = rows.sort((a,b) =>a[1]-b[1]).map(o => o[0]).map(o => this.formatNum(o,rKey)).filter(o => o!='Total')
      rowH = ['Total',...rowH]
      colH = Object.keys(colH).sort().map(o => this.formatNum(o,cKey))
      return {grid,rowH,colH}
    } catch(e){
      console.log(e)
    	return {}
    }
  }
  
  sortData = () => {
    let result = this.props.data
    if(result.length<2) return result
    let {sortby} = this.state
    if(sortby){
      let desc = 1;
      if(sortby[0]=="-"){
        sortby = sortby.slice(1)
        desc = -1;
      }
      return [...result.slice(0,1),...result.slice(1).sort((a,b) => desc*(a[sortby]>b[sortby]?1:-1))]
    }
    return result
  }
  
  render() {
    let data = this.props.data
    let {sortby} = this.state
    let {xkeys, ykeys} = this.props
    let {grid,rowH,colH} = this.getGrid(data,xkeys) || {}
    if(!grid){
      return null
    }
    return (
      <div className={css`
font-size:12px;
overflow: auto;
background: white;
color: #101721;
border-right: 1px solid #ededed;
border-bottom: 1px solid #ededed;
margin-bottom:100px;
.flex {
	display:flex;
}
.cell.sort {
  border-bottom: 3px solid #6b7785;
	padding-top:6px !important;
}
.cell.sortdesc {
	border-top: 3px solid #6b7785;
	padding-bottom:6px !important;
}

.rowh.cell {
	user-select: none;
	background: #fbfbfb;
	color: #6b7785;
	font-size: 10px;
	min-height: 40px;
	white-space: normal;
	text-align: center;
	padding: 3px;
	font-weight: bold;
}
.cell.col-s1 {
	width: 50px;
	min-width: 50px;
}
.cell.col-s3 {
	width: 150px;
	min-width: 150px;
}
.cell.colh.col1 {
}
.cell.colh {
	justify-content: flex-start;
	padding: 5px;
	color: #6b7785;
	height: inherit;
}
.row:hover {
	background: #E8E8FF !important;
	box-shadow: 1px 0 0 0 #c0c0ff,
    0 1px 0 0 #c0c0ff, 
    1px 1px 0 0 #c0c0ff,   /* Just to fix the corner */
    1px 0 0 0 #c0c0ff inset, 
    0 1px 0 0 #c0c0ff inset;
	z-index:2;
	.cell {
		box-shadow: 1px 0 0 0 #c0c0ff,
      0 1px 0 0 #c0c0ff, 
      1px 1px 0 0 #c0c0ff,   /* Just to fix the corner */
      1px 0 0 0 #c0c0ff inset, 
      0 1px 0 0 #c0c0ff inset;
	}
}
.row {
	width: fit-content;
	position: relative;
	box-shadow: 1px 0 0 0 #ededed,
    0 1px 0 0 #ededed, 
    1px 1px 0 0 #ededed,   /* Just to fix the corner */
    1px 0 0 0 #ededed inset, 
    0 1px 0 0 #ededed inset;
}
.row:nth-child(even) {
	position: relative;
	z-index:1;	
	background: #fbfbfb;
}
.cell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width:50px;
	min-width:50px;
	overflow: hidden;
	height:26px;
	min-height:26px;
	display: flex;
	align-items: center;
	justify-content: center;
box-shadow: 1px 0 0 0 #ededed,
    0 1px 0 0 #ededed, 
    1px 1px 0 0 #ededed,   /* Just to fix the corner */
    1px 0 0 0 #ededed inset, 
    0 1px 0 0 #ededed inset;
}
`}>
        <div className="flex">
          <div className="cell rowh colh col-s3 col1">{xkeys[0]}</div>
          <div className="cell rowh colh col-s1">{xkeys[1]}</div>
          {
          	colH.map((o) => (
              <div
                onClick={() => {
                  this.setState({sortby:sortby!="-"+o?"-"+o:o})
                }} 
                className={`${(sortby==o?"sort":"")+(sortby=="-"+o?"sortdesc":"")} cell rowh`}>{o}</div>
            ))
          }
        </div>
        {
         	rowH.map(r => (
            <div className="flex row">
              <div className="cell colh col-s3 col1">{r}</div>
              <div>
                {
                  xkeys.slice(2).map(o => (
                    <div className="cell colh col-s1">{o}</div>
                  ))
                }
              </div>
              {
                colH.map(c => (
                  <div>
                  {
                   	(grid[r][c] || xkeys.slice(2).map(o => '')).map(o => (
                      <div className="cell">{o}</div>
                    ))
                  }
                  </div>
                ))
              }
            </div>
          ))
        }
        <div>
          
        </div>
      </div>
    )
  }
}
