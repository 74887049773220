import React, {PureComponent, Fragment, Component} from 'react'
import ReactDOM from 'react-dom';
import {css} from 'react-emotion'
import Ionicon from 'react-ionicons'
import {Table, DetailCard} from './schema/ui-utils'
import {Filters, Popper} from './segments'
import Notify from '../components/notify'

const scheme = {
  campaign_name: {label:'Name',name:'name',type:'string'},
  status: {label:'Status',name:'status',type:'status'},
  send_as: {label:'Send As',name:'json.send_as',type:'string', tooltip:"Email support@anarock.com to add a new sender email" },
  segments: {label:'Segments',name:'segments',type:'dropdown',options:[],tooltip:"Create a segment of leads in the 'SEGMENTS' section on the main tab ( Tabs on the left-hand side)" },
  subject: {label:'Email Subject',name:'json.subject',type:'text' },
  template: {label:'Email Template',name:'json.template',type:'email',tooltip:<div><div className={css(`display:flex;`)}>a. <div className={css(`margin-left:4px;`)}>Use ‘[NAME], [EMAIL] or [PHONE]’ to dynamically use user’s details in SMS text. ex - Hi [NAME], As you done site visit on ABC project, we have an offer just for you …..</div></div><div className={css(`display:flex;margin-top:4px;`)}>b. <div className={css(`margin-left:4px;`)}>Add text or HTML code</div></div></div>},
  subsource: {label:'Subsource',name:'json.subsource',type:'dropdown',single:true,options:[]},
  campaignkey: {label:'Campaign Key',name:'json.campaignkey',type:'dropdown',single:true,options:[]},
  schedule: {label:'Schedule',name:'json.schedule',type:'schedule'}
}

class Report extends Component {
	state = {}
	getReport() {
    this.setState({loading:true})
    let self = this
    let {campaign_id} = this.props
    fetch(`${window._DIGIXDOMAIN}/api/v0/emails?rtype=stats&campaign_id=${campaign_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.success==true) {
        self.setState({report:data.data})
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }
	componentDidMount(){
    this.getReport()
  }
	render(){
    let {loading,message,message_id,report=[]} = this.state
    let {close} = this.props
    return (
      <div onClick={() => close()} className={css`display:flex;justify-content:center;;position:fixed;top:0px;left:0px;right:0px;bottom:0px;z-index:10001;background:#0003;`}>
        <Notify message={message} message_id={message_id}/>
        <div onClick={e => e.stopPropagation()} className={css`margin-top:100px;max-width:600px;max-height:calc(100vh - 200px);background:#fff;border-radius:5px;overflow:auto;`}>
        {loading && <div className={css`padding:10px;font-size:24px;`}>Loading ...</div>}
         <Table data={report} keys={['date','pending','sent','failed','clicks']} />
        {!loading && report.length==0 && <div className={css`padding:10px;font-size:24px;`}>No Stats ...</div>}
        </div>
      </div>
    )
  }
}


export default class Email extends PureComponent {
  state = {}
	getEmail() {
    this.setState({loading:true})
    let self = this
    let {assignment} = this.props
    let {assignment_id} = assignment
    fetch(`${window._DIGIXDOMAIN}/api/v0/emails?rtype=list&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.success==true) {
        self.setState({emaillist:data.data.data,options:data.data.options})
      } else {
        self.setState({message:"Unable to Load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({loading:false})
      self.setState({message:"Unable to Load",message_id:new Date()})
    })
  }
  saveEmail(email) {
    if (!(email.name && email.name.trim())) {
      this.setState({ message: "Error: Campaign name is a required field.", message_id: new Date() })
    } else {
      this.setState({loading:true})
      let self = this
      let {assignment_id} = this.props.assignment
      fetch(`${window._DIGIXDOMAIN}/api/v0/emails?rtype=save&assignment_id=${assignment_id}`, {
        method: 'post',
        credentials:"include",
        body: JSON.stringify(email)
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        self.setState({loading:false})
        if(data.success==true) {
          email['id'] = data.data['id']
          self.setState({cb:new Date()})
          self.getEmail()
          self.setState({message:"Saved Successfully",message_id:new Date() })
          self.setState({selitem:null})
        } else {
          if(data.message=="Validation Error"){
            const fields = data.errors && Object.keys(data.errors);
            self.setState({message:`Invalid field : ${fields.join(',')}`,message_id:new Date() })
          }
          else{
            self.setState({message:"Unable to Save",message_id:new Date() })
          }
        }
      }).catch(function(e){
        self.setState({loading:false})
        self.setState({message:"Unable to Save",message_id:new Date() })
      })
    }
  }
	componentDidMount(){
    this.getEmail()
  }
	flatten = (obj) => {
    return Object.keys(obj).map(k => ({id:k,name:obj[k]}))
  }
  render() {
    let {selitem,emaillist=[],options={},showstats,message,message_id} = this.state
    let {assignment_id} = this.props.assignment
    Object.keys(options).map(k => {
      if(options[k] instanceof Array){
      	scheme[k].options = options[k]  
      } else {
      	scheme[k].options = this.flatten(options[k])  
      }
    })
    return (
      <div className={css`
.create-btn {
	padding: 10px 20px;
	border-radius: 3px;
	background: #1f69ff;
	margin-bottom: 20px;
	color: #fff;
	width: fit-content;
	cursor: pointer;
	:hover {
		filter: brightness(1.2);
	}
}
`}>
        <div className='create-btn' onClick={() => this.setState({selitem:{}})}>Create New Email Campaign</div>
        <Table stats="true" statsclick={(row) => this.setState({showstats:row['id']})} select={(selitem) => this.setState({selitem})} data={emaillist} keys={['name']} actions="true"/>
        <Notify message={message} message_id={message_id}/>
        {showstats &&
          <Report close={() => this.setState({showstats:false})} campaign_id={showstats}/>
        }
        {selitem &&
          <div 
            onClick={() => this.setState({selitem:null})}
            style={{overflow:'auto',zIndex:1000000,paddingTop:20,top:0,left:0,display:'flex',justifyContent:'center',position:'fixed',background:'rgba(0,0,0,.5)',width:'100vw',height:'100vh'}}>
            <div onClick={e => e.stopPropagation()} style={{height:'fit-content'}}>
            	<DetailCard options={options} save={(email) => this.saveEmail(email)} data={selitem} scheme={scheme} ui={'campaign_name,status,send_as,segments,subject,template,schedule'.split(',')}/>
            </div>
          </div>
        }
      </div>
    )
  }
}