import "./style.scss";
import {TataPivotList} from '../../pivots/tata'
import {ManualLeads} from './components/manual_leads'
import React, {Suspense ,Component, Fragment, lazy} from 'react'
import { hot } from 'react-hot-loader'
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Loadable from 'react-loadable';
import { setConfig } from 'react-hot-loader'
setConfig({ logLevel: 'debug' })
import * as _ from './alwaysbuild'

window.time = _.time

// const pages = {
//   querytool : import('./querytool'),
//   // panel : import('./panel')
// }

// const Panel = Loadable({
//   loader: () => pages.panel,
//   loading: () => (<div>Loading...</div>)
// });

// const QueryTool = Loadable({
//   loader: () => pages.querytool,
//   loading: () => (<div>Loading...</div>)
// });

  
const Users = Loadable({
  loader: () => import('./users'),
  loading: () => (<div>Loading...</div>),
});
import Gsearch from './global-search'
import {css} from 'react-emotion'
import Home from './home'
import UrlGenerator from './url-generator'
import Panel from './panel-v2'
import QueryTool from './querytool'
import Vendor from './vendor'
import VendorAccess from "./vendor-access";
import { initializeTracker } from "./utils/tracker";

(function () {
    "use strict";

    var baseLogFunction = console.log;
  	var baseClear = console.clear;
    console.log = function(){
        baseLogFunction.apply(console, arguments);

        var args = Array.prototype.slice.call(arguments);
        for(var i=0;i<args.length;i++){
            var node = createLogNode(args[i]);
            // document.querySelector("#mylog") && 
            //   document.querySelector("#mylog").appendChild(node);
        }
    }
    console.clear = function(){
      baseClear.apply(console, arguments);
      if(document.querySelector("#mylog")){
        document.querySelector("#mylog").innerHTML='';
      }
    }

    function createLogNode(message){
        var node = document.createElement("div");
        var textNode = document.createTextNode(message);
        node.appendChild(textNode);
        return node;
    }

    window.onerror = function(message, url, linenumber) {
      if(document.querySelector("#mylog")){
        document.querySelector("#mylog").innerHTML = "JavaScript error: " + message + " on line " + linenumber + " for " + url;
      }
    };

});

window.Cookie = {
  get: (k) => {
    return localStorage[k]
    // return (document.cookie.split(k+"=")[1] || '').split("&")[0]  
  },
  set: (k,v) => {
    localStorage[k] = v
    // document.cookie = `${k}=${v}; expires=${(new Date(new Date().getTime() + 300 * 1000 * 60 * 60 * 24)).toGMTString()}; path=/,domain=.anarockdigital.com`
  }
}

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tab: "panel",
      sidebar: false
    }
  }
  componentDidMount() {
    initializeTracker();
  }
  componentWillUnmount() {
  }
  
  render() {
    let {tab, sidebar} = this.state
    return (
      <div className="App">
        <div id="mylog" className={css`
display:none;
position:absolute;
top:0px;
z-index:100000;
background: white;
padding:10px;
`}></div>
        {sidebar &&
        <div style={{minWidth:100}}>
          <div class="sidebar">
            <div style={{fontWeight:900,padding:10}}>
              Anarock <br/>Digital
            </div>
            {
              ["panel"].map((o,_i) => (
                <div 
                  class={"item "+(tab==o?"sel":"")}
                  onClick={() => this.setState({tab:o})}>
                  <div className="strip"></div>
                  {o}
                </div>
              ))
            }
          </div>
        </div>
        }
        <div class="content" style={{flex:1}}>
          <Gsearch />
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <Fragment>
                <Switch>
                  <Route exact path="/panel/:assignment_id/pivot" component={TataPivotList} />
                  <Route exact path="/panel/:assignment_id/:tab/:report_name" component={Panel} />
                  <Route exact path="/panel/:assignment_id" component={Panel} />
                  <Route exact path="/panel/:assignment_id/:tab" component={Panel} />
                  <Route exact path="/panel" component={Panel} />
                  <Route exact path="/manual-leads" component={ManualLeads} />
                  <Route exact path="/pivots" component={TataPivotList} />
                  <Route exact path="/pivots/:assignment_id/:report_name" component={TataPivotList} />
                  <Route exact path="/editor" component={QueryTool} />
                  <Route exact path="/users" component={Users} />
                  <Route exact path="/" component={QueryTool} />
                  <Route exact path="/home" component={Home} />
                  <Route exact path="/url-generator" component={UrlGenerator} />
                  <Route exact path="/vendor-access" component={VendorAccess} />
                  <Route exact path="/vendor/:vendor_details" component={Vendor} />
                </Switch>
              </Fragment>
            </Suspense>
          </Router>
        </div>
      </div>
    )
  }
}

// export default App
export default hot(module)(App)
