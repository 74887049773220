import React, { Component } from "react";
import { css, cx } from "react-emotion";
import _ from "lodash";
import { mixins, colors, Switch, Tooltip, Tag, Toast } from "pebble-web";
import "./fb-lead-forms-table.css";
import { LeadFormMenu } from "./lead-form-menu";
import copy from "copy-to-clipboard";
import { format } from "date-fns";

const tableFieldValue = css({
  ...mixins.flexRow,
  whiteSpace: "pre-line",
  overflowWrap: "break-word",
  wordBreak: "break-all"
});

const tagStyle = css({
  marginTop: "5px",
  padding: "4px 4px",
  fontSize: "10px",
  fontWeight: 600,
  height: "unset",
  textTransform: "uppercase",
});

export class FbLeadFormsTable extends React.Component {
  state = {
    columnDefs: [
      {
        headerName: "Sync Status",
        field: "sync_status",
      },
      {
        headerName: "Form Name",
        field: "form_name",
        tooltip: "Leads from last 24hrs will be synced",
      },
      {
        headerName: "Page Name",
        field: "page_name",
      },
      {
        headerName: "Campaign Id",
        field: "campaign_id",
      },
      {
        headerName: "Sub source",
        field: "subsource",
      },
      {
        headerName: "Actions",
        field: "actions",
      },
    ],
  };
  testLead = (item) => {
    let self = this;
    this.setState({ testLeadLoading: true });
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/integrate_lead_form?rtype=send_test_lead&form_id=${item.form_id}&page_id=${item.page_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          Toast.show(`Test lead sent - Lead form successfully integrated`, "success");
        }
        else {
         Toast.show(`${data.message}`, "error");
        }
        self.setState({ testLeadLoading: false });
      })
      .catch(() => {
        Toast.show(`Unable to send test lead`, "error");
        self.setState({ testLeadLoading: false });
      });
  };
  handleTableChanges = (rowIndex, key, value) => {
    const { data, handleChange } = this.props;
    const newData = [...data];
    newData[rowIndex][key] = value;
    handleChange("conditions", newData);
  };
  render() {
    const { data, loading, onStatusChange, onEdit, onViewLeads, onTestLead } = this.props;
    const { columnDefs } = this.state;
    return (
      <React.Fragment>
        <Toast />
        <table
          className={css({
            tableLayout: "fixed",
            width: "calc(100% - 10px)",
            marginRight: "10px",
            fontSize: "13px",
            marginTop: "25px",
          })}
        >
          <colgroup>
            <col className={css({ width: "7.5%" })} />
            <col className={css({ width: "22.5%" })} />
            <col className={css({ width: "22.5%" })} />
            <col className={css({ width: "17.5%" })} />
            <col className={css({ width: "17.5%" })} />
            <col className={css({ width: "12.5%" })} />
          </colgroup>
          <thead className={css({ height: "50px" })}>
            <tr>
              {columnDefs.map((column) => (
                <th key={column.field}>
                  <div className={css({ ...mixins.flexRow })}>
                    {column.headerName}
                    {column.tooltip && (
                      <Tooltip
                        placement="top-start"
                        label={(labelProps) => (
                          <div
                            {...labelProps}
                            className={css({
                              display: "flex",
                              opacity: 0.5,
                              alignItems: "baseline",
                            })}
                          >
                            <i
                              className={cx(
                                "pi pi-info",
                                css({ marginLeft: "5px" })
                              )}
                            />
                          </div>
                        )}
                        text={column.tooltip}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data.map((row, rowIndex) => (
                <tr className={css({ height: "45px" })} key={rowIndex}>
                  <td key="sync_status">
                    <SyncStatusCellComponent
                      value={row.status == "on" || false}
                      onChange={() => onStatusChange(row)}
                    />
                  </td>
                  <td key="form_name">
                    <FormNameCellComponent
                      value={{
                        name: row.form_name,
                        id: row.form_id,
                        date: row.created_at,
                      }}
                    />
                  </td>
                  <td key="page_name">
                    <PageNameCellComponent
                      value={{
                        name: row.page_name,
                        id: row.page_id,
                        status: row.page_status,
                      }}
                    />
                  </td>
                  <td key="campaign_id">
                    <FormValueCellComponent value={row.campaign_key} />
                  </td>
                  <td key="subsource">
                    <FormValueCellComponent value={row.source} />
                  </td>
                  <td key="actions">
                    <LeadFormMenu
                      onEdit={() => onEdit(row)}
                      onViewLeads={() => onViewLeads(row)}
                      onTestLead={() => this.testLead(row)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

class SyncStatusCellComponent extends Component {
  render() {
    const { value, onChange } = this.props;

    return (
      <div className={css({ paddingLeft: "15px" })}>
        <Switch
          disabled={false}
          initialValue={value}
          onChange={onChange}
          label={""}
        />
      </div>
    );
  }
}
class FormNameCellComponent extends Component {
  render() {
    const { value } = this.props;
    return (
      <div className={css({ flexDirection: "column" })}>
        <LabelComponent value={value} type="Form" />
        <div
          className={css({
            color: colors.gray.base,
            marginTop: "5px",
            fontSize: "11px",
          })}
        >
          Created on {format(value.date, "DD-MM-YY")}
        </div>
      </div>
    );
  }
}

class PageNameCellComponent extends Component {
  render() {
    const { value } = this.props;
    return (
      <div className={css({ flexDirection: "column" })}>
        <LabelComponent value={value} type="Page" />
      </div>
    );
  }
}

class LabelComponent extends Component {
  render() {
    const { value, type } = this.props;
    return (
      <div className={css({ ...mixins.flexRow })}>
        <div className={tableFieldValue}>{value.name}</div>
        <i
          className={cx(
            "pi",
            "pi-copy",
            css({
              cursor: "pointer",
              color: colors.gray.base,
              fontSize: "14px",
              marginTop: "4px",
              marginLeft: "10px",
            })
          )}
          onClick={() => {
            copy(value.id);
            Toast.show(`${type} Id - ${value.id} copied`, "success");
          }}
        />
      </div>
    );
  }
}
class FormValueCellComponent extends Component {
  render() {
    const { value } = this.props;
    return <div className={tableFieldValue}>{value}</div>;
  }
}
