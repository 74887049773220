import React, { Component } from "react";
import { css, cx } from "react-emotion";
import _ from "lodash";
import { mixins, colors, Popper, Button } from "pebble-web";

const actionStyles = css({
  color: colors.gray.darker,
  padding: "16px 20px",
  borderBottom: `1px solid ${colors.gray.light}`,
  backgroundColor: colors.white.base,
  fontWeight: 500,
  fontSize: 12,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: colors.gray.lightest,
  },
});

export class LeadFormMenu extends React.Component {
  render() {
    const { onEdit, onViewLeads, onTestLead } = this.props;
    return (
      <div className={css({ ...mixins.flexRow })}>
         <Button
            size="small"
            type="secondary"
            className={css({ marginRight: "5px"})}
            onClick={() => onViewLeads()}
          >
            View Leads
          </Button>
        <Popper
          closeOnOutsideClick
          popperBackgroundColor={colors.gray.lightest}
          popperClassName={css({})}
          placement="right"
          label={({ toggle, isOpen }) => (
            <div
              className={css({})}
              onClick={() => {
                toggle();
              }}
            >
              <i
                className={cx(
                  "pi",
                  "pi-more",
                  css({
                    ...mixins.flexMiddleAlign,
                    cursor: "pointer",
                    backgroundColor: isOpen ? colors.gray.light : colors.gray.lightest,
                    borderRadius: 2,
                    height: "40px",
                    width: "36px",
                  })
                )}
              />
            </div>
          )}
        >
          {({ toggle }) => (
            <div>
              <div
                className={actionStyles}
                onClick={() => {
                  onEdit();
                  toggle();
                }}
              >
                Edit
              </div>
              <div
                className={actionStyles}
                onClick={() => {
                  onTestLead();
                  toggle();
                }}
              >
                Test Lead
              </div>
            </div>
          )}
        </Popper>
      </div>
    );
  }
}
