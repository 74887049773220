import React, { Component } from "react";
import { css } from "react-emotion";
import Ionicon from "react-ionicons";
import { Table, DetailCard } from "./schema/ui-utils";
import Notify from "../components/notify";
import { mixins } from "@anarock/pebble";
import { Button } from "semantic-ui-react";
import { colors, Tooltip } from "@material-ui/core";

const scheme = {
  campaign_name: { label: "Name", name: "name", type: "string" },
  status: { label: "Status", name: "status", type: "status" },
  segments: {
    label: "Segments",
    name: "segments",
    type: "dropdown",
    options: [],
    tooltip:
      "Create a segment of leads in the 'SEGMENTS' section on the main tab ( Tabs on the left-hand side)",
  },
  display_name: {
    label: "Sender ID",
    name: "json.display_name",
    type: "senderID",
    single: true,
  },
  template: {
    label: "SMS Template",
    name: "json.template",
    type: "dropdown",
    single: true,
    tooltip:
      "To add a new template, please share DLT-approved SMS (Entity Id, Sender Id, Template-Id, and SMS Text) with Anarock Support (email - support@anarock.com)",
  },
  placeholders: {
    label: "Place Holders",
    name: "json.placeholder",
    type: "placeholders",
  },
  sms_text: { label: "SMS Text", name: "json.template", type: "sms" },
  schedule: { label: "Schedule", name: "json.schedule", type: "schedule" },
  subsource: {
    label: "Subsource",
    name: "json.subsource",
    type: "dropdown",
    single: true,
    options: [],
  },
  campaignkey: {
    label: "Campaign Key",
    name: "json.campaignkey",
    type: "dropdown",
    single: true,
    options: [],
  },
  gateway: {
    label: "Gateway",
    name: "gateway",
    type: "gateway",
    single: true,
  },
};

const tooltipTemplate = (
  <div className={css({ fontSize: "12px" })}>
    <div className={css(`display:flex;`)}>
      a.{" "}
      <div className={css(`margin-left:4px;`)}>Log on to portal.mobtexting.com and check if you have sufficient credits in the account
      </div>
    </div>
    <div className={css(`display:flex;margin-top:10px;`)}>
      b.{" "}
      <div className={css(`margin-left:4px;`)}>
      To add a new template, please share DLT-approved SMS (Entity Id, Sender Id, Template-Id, and SMS Text) with Anarock Support (email - support@anarock.com)      </div>
    </div>
    <div className={css(`display:flex;margin-top:10px;`)}>
      c.{" "}
      <div className={css(`margin-left:4px;`)}>
      Create a segment of leads in the "SEGMENTS" section
      </div>
    </div>
    <div className={css(`display:flex;margin-top:10px;`)}>
      d.{" "}
      <div className={css(`margin-left:4px;`)}>
        'Create new SMS Campaign' and select relevant details (Don't forget to
        test SMS before scheduling)
      </div>
    </div>
  </div>
);
class Report extends Component {
  state = {};
  getReport() {
    this.setState({ loading: true });
    let self = this;
    let { campaign_id } = this.props;
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/sms?rtype=stats&campaign_id=${campaign_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.setState({ loading: false });
        if (data.success == true) {
          self.setState({ report: data.data, options: data.options });
        } else {
          self.setState({ message: "Unable to Load", message_id: new Date() });
        }
      })
      .catch(function (e) {
        self.setState({ loading: false });
        self.setState({ message: "Unable to Load", message_id: new Date() });
      });
  }
  componentDidMount() {
    this.getReport();
  }
  render() {
    let { loading, message, message_id, report = [] } = this.state;
    let { close } = this.props;
    return (
      <div
        onClick={() => close()}
        className={css`
          display: flex;
          justify-content: center;
          position: fixed;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 10001;
          background: #0003;
        `}
      >
        <Notify message={message} message_id={message_id} />
        <div
          onClick={(e) => e.stopPropagation()}
          className={css`
            margin-top: 100px;
            max-width: 600px;
            max-height: calc(100vh - 200px);
            background: #fff;
            border-radius: 5px;
            overflow: auto;
          `}
        >
          {loading && (
            <div
              className={css`
                padding: 10px;
                font-size: 24px;
              `}
            >
              Loading ...
            </div>
          )}
          <Table
            data={report}
            keys={["date", "pending", "sent", "failed", "clicks"]}
          />
          {!loading && report.length == 0 && (
            <div
              className={css`
                padding: 10px;
                font-size: 24px;
              `}
            >
              No Stats ...
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default class SMS extends Component {
  state={};
  getSMS() {
    this.setState({ loading: true });
    let self = this;
    let { assignment } = this.props;
    let { assignment_id } = assignment;
    fetch(
      `${window._DIGIXDOMAIN}/api/v0/sms?rtype=list&assignment_id=${assignment_id}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.setState({ loading: false });
        if (data.success == true) {
          self.setState({ smslist: data.data.data, options: data.data.options });
        } else {
          self.setState({ message: "Unable to Load", message_id: new Date() });
        }
      })
      .catch(function (e) {
        self.setState({ loading: false });
        self.setState({ message: "Unable to Load", message_id: new Date() });
      });

    this.setState({ loading: true });
  }
  saveSMS(sms) {
    if (sms.json.template.toLowerCase().indexOf("{#var#}") > -1) {
      this.setState({
        message: "Error: Please add a variable in place of ‘{#var#}’",
        message_id: new Date(),
      });
    } else if (!(sms.name && sms.name.trim())) {
      this.setState({
        message: "Error: Campaign name is a required field.",
        message_id: new Date(),
      });
    } else {
      this.setState({ loading: true });
      let self = this;
      if (sms.segments === undefined) {
        sms.segments = [];
      }
      let { assignment_id } = this.props.assignment;
      fetch(
        `${window._DIGIXDOMAIN}/api/v0/sms?rtype=save&assignment_id=${assignment_id}`,
        {
          method: "post",
          credentials: "include",
          body: JSON.stringify(sms),
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          self.setState({ loading: false });
          if (data.success == true) {
            sms["id"] = data.data["id"];
            self.setState({ cb: new Date() });
            self.getSMS();
            self.setState({
              message: "Saved Successfully",
              message_id: new Date(),
              selitem: null
            });
          } else {
            self.setState({
              message: "Unable to Save",
              message_id: new Date()
            });
          }
        })
        .catch(function (e) {
          self.setState({ loading: false });
          self.setState({ message: "Unable to Save", message_id: new Date()});
        });
    }
  }
  componentDidMount() {
    this.getSMS();
  }
  render() {
    let {
      selitem,
      smslist = [],
      options = {},
      showstats,
      message,
      message_id,
      loading,
      show,
    } = this.state;
    return (
      <div
        className={css`
          .create-btn {
            padding: 10px 20px;
            border-radius: 3px;
            background: #1f69ff;
            margin-bottom: 20px;
            color: #fff;
            width: fit-content;
            cursor: pointer;
            :hover {
              filter: brightness(1.2);
            }
          }
        `}
      >
        <div
          className={css({
            alignItems: "center",
            ...mixins.flexRow,
          })}
        >
          <div
            className="create-btn"
            onClick={() => this.setState({ selitem: {} })}
          >
            Create New SMS Campaign
          </div>
          <Tooltip
            classes={{
              tooltip: css({ backgroundColor: colors.grey["800"] }),
              popper: css(`z-index:10000012 !important;`),
            }}
            title={
              <div className={css({ padding: "8px" })}>{tooltipTemplate}</div>
            }
            onOpen={() => this.setState({ show: true })}
            onClose={() => this.setState({ show: false })}
            placement="bottom-start"
            arrow
          >
            <div className={css(`margin-left:5px;margin-bottom:15px`)}>
              <Ionicon
                fontSize="16px"
                icon="md-information-circle"
                color={show ? "#000" : "#ccc"}
              ></Ionicon>
            </div>
          </Tooltip>
        </div>
        <Table
          statsclick={(row) => this.setState({ showstats: row["id"] })}
          select={(selitem) => this.setState({ selitem })}
          data={smslist}
          keys={["name"]}
          actions="true"
        />
        <Notify message={message} message_id={message_id} />
        {showstats && (
          <Report
            close={() => this.setState({ showstats: false })}
            campaign_id={showstats}
          />
        )}
        {selitem && (
          <div
            onClick={() => this.setState({ selitem: null })}
            style={{
              overflow: "auto",
              zIndex: 90000,
              paddingTop: 20,
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              position: "fixed",
              background: "rgba(0,0,0,.5)",
              width: "100vw",
              height: "100vh",
            }}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              style={{ height: "fit-content" }}
            >
              <SMSCard
                options={options}
                save={(sms) => this.saveSMS(sms)}
                data={selitem}
                scheme={scheme}
                ui={"campaign_name,status,segments,display_name,template,placeholders,sms_text,schedule".split(
                  ","
                )}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
class SMSCard extends Component {
  state = {
    options: this.props.options,
    templateLoading: false,
  };
  flatten = (obj) => {
    return Object.keys(obj).map((k) => ({ id: k, name: obj[k] }));
  };
  getSMSTemplates(senderId) {
    let self = this;
    self.setState({ templateLoading: true });
    fetch(`${window._DIGIXDOMAIN}/api/v0/get-sms-template?sender_id=${senderId}`, {
      method: "get",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success == true) {
          const template = data.data.response.templates.map(
            (ele) => ele.content
          );
          let sms_mapping = {};
          data.data.response.templates.forEach(
            (ele) => (sms_mapping[ele.content] = ele)
          );
          self.setState({
            options: { ...this.state.options, template },
            sms_mapping,
            templateLoading: false,
          });
        } else {
          self.setState({
            message: "Unable to Load",
            message_id: new Date(),
            templateLoading: false,
          });
        }
      })
      .catch(function (e) {
        self.setState({ templateLoading: false });
        self.setState({ message: "Unable to Load", message_id: new Date() });
      });
  }
  componentDidMount() {
    const { data } = this.props;
    if (data.json && data.json.display_name)
      this.getSMSTemplates(data.json.display_name);
  }
  saveSMS(sms) {
    const { sms_mapping } = this.state;
    let newSMS = sms;
    if (!sms.template_id) {
      const id =
        sms && sms.json && sms.json.template
          ? sms_mapping[sms.json.template].id
          : "";
      newSMS.template_id = id;
    }
    this.props.save(newSMS);
  }
  updateSMS() {
    const { data } = this.props;
    const {options} = this.state;
    if (!(data && data.json && data.json.display_name))
      this.setState({ sms_mapping: {}, options: {...options,template:[]} });
  }
  render() {
    const { options, templateLoading, sms_mapping } = this.state;
    const { ui, scheme, data } = this.props;
    Object.keys(options).map((k) => {
      if (options[k] instanceof Array) {
        scheme[k].options = options[k];
      } else {
        scheme[k].options = this.flatten(options[k]);
      }
    });
    return (
      <DetailCard
        ui={ui}
        scheme={scheme}
        data={data}
        options={options}
        save={(sms) => this.saveSMS(sms)}
        updateSMS={() => this.updateSMS()}
        mapping={sms_mapping}
        loading={templateLoading}
        fetchTemplates={(senderID) => this.getSMSTemplates(senderID)}
      />
    );
  }
}
