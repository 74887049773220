import React, { Component } from "react";
import { css } from "react-emotion";
import { DropDown, PresetCalendar } from "pebble-web";
import {
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfQuarter,
  endOfQuarter,
  format,
  subDays
} from "date-fns";

const DEFAULT_START_DATE = subDays(new Date(),90);

const PRESET_OPTIONS = [
  {
    label: "Last 90 Days",
    dateRange: [DEFAULT_START_DATE, new Date()],
  },
  {
    label: "This Month",
    dateRange: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "This Quarter",
    dateRange: [startOfQuarter(new Date()), endOfQuarter(new Date())],
  },
  {
    label: "FY",
    dateRange: [startOfYear(new Date()), endOfYear(new Date())],
  },
];
export class SpendsDateFilter extends Component {
  state = {
    start_date: this.props.start_date,
    end_date: this.props.end_date,
  };
  componentDidMount() {}
  renderButtonLabel = () => {
    const { start_date, end_date } = this.state;
    if (start_date && end_date) {
      const preset = PRESET_OPTIONS.find(
        (item) =>
          item.dateRange[0].toDateString() === start_date.toDateString() && item.dateRange[1].toDateString() === end_date.toDateString()
      );

      if (preset) return preset.label;
      else
        return `${format(start_date, 'DD MMM, YYYY')} - ${format(
          end_date,
          'DD MMM, YYYY'
        )}`;
    } else {
      return "Select Duration";
    }
  };
  render() {
    const { start_date, end_date } = this.state;
    const { setDate } = this.props;
    return (
      <div
        className={css({
          marginRight: "10px",
        })}
      >
        <DropDown
          buttonLabel={this.renderButtonLabel()}
          dropDownClassName={css({ zIndex: 2001 })}
        >
          {({ toggle }) => (
            <PresetCalendar
              onChange={([start_date, end_date]) => {
                this.setState({
                  start_date: start_date,
                  end_date: end_date,
                });
              }}
              maxDate={new Date()}
              onClear={() => {
                this.setState({
                  start_date: undefined,
                  end_date: undefined,
                });
              }}
              onApply={([start_date, end_date]) => {
                this.setState({
                  start_date: start_date,
                  end_date: end_date,
                });
                setDate(start_date, end_date);
                toggle();
              }}
              presetDateOptions={PRESET_OPTIONS}
              defaultValue={[start_date, end_date]}
            />
          )}
        </DropDown>
      </div>
    );
  }
}
