import React, { PureComponent } from 'react'
import {css} from 'react-emotion'
import Ionicon from 'react-ionicons'
import { BrowserRouter as Router, browserHistory, Route, Link } from "react-router-dom";

const colors = {
  "gray-darker": "#101721",
  "gray-dark": "#6B7785",
  "gray-base": "#9FA6B0",
  "gray-light": "#E0E0E0",
  "gray-lighter": "#EDEDED",
  "gray-lightest": "#F7F7F9",
  "violet-darker": "#4040BA",
  "violet-dark": "#5656ED",
  "violet-base": "#6161FF",
  "violet-light": "#7070FF",
  "violet-lighter": "#C0C0FF",
  "violet-lightest": "#E8E8FF"
}

const history = [
  {'tags': [{'is_stale': false, 'is_broker': false, 'is_archived': false, 'is_abandoned': false, 'is_duplicate': false}, {'is_stale': false, 'is_broker': false, 'is_archived': false, 'is_abandoned': false, 'is_duplicate': false, 'agent_deactivated': false}]},
  {'tags': [{'is_broker': false}, {'is_stale': false, 'is_broker': false, 'is_archived': false, 'is_abandoned': false, 'is_duplicate': false}], 'agent_id': [null, 13526], 'status_id': [1, 6], 'claimed_at': [null, '2019-12-23T13:14:17.974+05:30'], 'status_updated_at': ['2019-12-23T13:11:57.676+05:30', '2019-12-23T13:14:17.973+05:30']},
  {'recently_contacted_agent_ids': [[11446, 11348, 8516, 12240, 11336], [11313, 8383, 13526, 13324]], 'previously_contacted_agent_ids': [[10783, 10867, 8711, 10623, 10631], [10783, 10867, 8711, 10623, 10631, 11446, 11348, 8516, 12240, 11336]]},
  {'recently_contacted_agent_ids': [[10783, 10867, 8711, 10623, 10631], [11446, 11348, 8516, 12240, 11336]], 'previously_contacted_agent_ids': [[], [10783, 10867, 8711, 10623, 10631]]},
  {'recently_contacted_agent_ids': [null, [10783, 10867, 8711, 10623, 10631]], 'previously_contacted_agent_ids': [null, []]},
  {'id': [null, 2307840], 'name': [null, 'ABHISHEK SHARMA\t'], 'tags': [null, {'is_broker': false}], 'email': [null, 'abhisekhshar@gmail.com\t'], 'phone': [null, '8527786704'], 'source': [null, 'lead_portal'], 'status_id': [null, 1], 'country_id': [null, 49], 'created_at': [null, '2019-12-23T13:11:57.686+05:30'], 'assignment_id': [null, 1225], 'extra_details': [null, {'ip': '139.59.3.199', 'os': 'Windows 7', 'browser': 'Chrome', 'pathname': '/kharadi/vtp/pegasus', 'platform': 'Microsoft Windows', 'user_agent': 'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.88 Safari/537.36', 'utm_medium': 'icubeswire_cpql173', 'utm_source': 'affiliate', 'host_origin': 'http://pune.trespect.com', 'agent_string': 'isAuthoritative_isChrome_isDesktop_isWindows_', 'browser_version': '79.0.3945.88'}], 'status_updated_at': [null, '2019-12-23T13:11:57.676+05:30'], 'assignment_supply_entity_mapping_id': [null, 1283]},
]


class LeadHistory extends PureComponent {
  state = {}
  fetchLogs = () => {
    let {leadid} = this.props
    let self = this
    fetch(`${process.env.MRKT_URL}/api/v0/logs?rtype=list&lead_id=${leadid}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({logs:data.data.logs,meta:data.data.meta})
      }
      this.setState({loading:false})
    }).catch(() => {
      this.setState({loading:false})
    })
  }
  epochStr = (seconds) => {
    let d = new Date(0)
    d.setUTCSeconds(seconds)
    return d.toString().split('GMT')[0]
  }
  componentDidMount() {
    this.fetchLogs()
  }
  render() {
    let {leadid,close} = this.props
    let {logs=[],meta={}} = this.state
    const item = css`width:100%;position:relative;;padding:20px;border-left: 1px solid #6b7785; .text {font-size: 16px;padding-bottom:6px; color: #000; font-weight: bold;}
		.date { font-size:14px; display: flex; color: #6b7785;text-align: right;}
		`
    const overlay = css`width:100vw;height:100vh;position:fixed;left:0px;top:0px;display:flex;justify-content:center;padding-top:20px;background:#0002;z-index:1000001`
    const content = css`min-width:400px;padding:20px;min-height:400px;background:#fff;max-height:calc(100vh - 200px);overflow:auto;`
    return (
      <div className={overlay} onClick={() => close()}>
        <div className={content} onClick={e => e.stopPropagation()}>
          <div style={{fontSize:20,fontWeight:'bold'}}>
          	Lead History of &nbsp;<a target="_blank" href={meta.url}>#{leadid}</a>
          </div>
          <div style={{marginTop:10}}>
            {logs.map(o => (
              <div className={item}>
                <Ionicon icon="md-radio-button-on" fontSize="12px" style={{background:'#fff',position:'absolute',left:0,top:33,transform:'translateX(-50%)'}}/>
                <div className="text">{o['text']}</div>
                <div className="date">{this.epochStr(o['time'])}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default class Table extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      sortby: '-'+(this.props.xkeys[0] || 'Spends'),
      selcells: {}
    }
    this.getValue = this.getValue.bind(this);
    this.ranges = {}
  }
  componentDidMount(){
    this.loadRanges()
  }
  componentDidUpdate() {
    this.loadRanges()
  }
  formatNum = (num,name='') => {
    if((name || '').toLowerCase().indexOf('rate')!=-1){
      if(!num) return '';
      return num+' %'
    }
    if(num=='Total') return 'Total';
    if((name||'').toLowerCase().indexOf('month')!=-1){
      if(!num) return '';
      let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      const year = Math.floor((parseInt(num)/100));
      const month = months[parseInt(num)%100-1]; 
      return (month + " " + year); 
    }
    if((name||'').toLowerCase().indexOf('weekday')!=-1){
      let weeks = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
      return weeks[num-1]
    }
    if((name||'').toLowerCase().indexOf('date')!=-1 || (name||'').toLowerCase().indexOf('week')!=-1){
      if(!num) return '';
      var d = new Date(0);
      d.setUTCSeconds(num/1000);
      let [w,m,d,y,_] = d.toString().split(' ');
      return `${m} ${d}`
    }
    if(['number'].indexOf(typeof(num))!=-1) {
      return (parseFloat(num) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\..*/,'');
    } else {
      return num
    }
  }
  
  loadRanges = () => {
    let ranges = {}
    if(!this.props.data) return
    let result = this.props.data
    let keys = [...this.props.ykeys]//Object.keys(result[0]);
    for(let key of keys){
      ranges[key] = {min:10000000000,max:-10000000}
    }
    for(let row of result) {
      for(let key of keys) {
        ranges[key].max = Math.max(parseInt(row[key]),ranges[key].max)
        ranges[key].min = Math.min(parseInt(row[key]),ranges[key].min)
      }
    }
    this.ranges = ranges
  }
  
  getHeatmapColor = (key, val) => {
    if(val==0){
      return '#e0e0e0'
    }
    return 'rgb(16,23,33)';
    if(this.ranges[key]){
    	return hslToRgb(255,1,.8-.3*val/this.ranges[key].max)
    } else {
      return '#000'
    }
  }
  
  getBarSize = (key, val) => {
    if(val==0){
      return '0%'
    }
    if(this.ranges[key]){
    	return (100*val/this.ranges[key].max)+'%'
    } else {
      return '0%'
    }
  }
  
  sortData = (sortby) => {
    let result = this.props.data
    if(result.length<2) return result
    if(sortby){
      let desc = 1;
      if(sortby[0]=="-"){
        sortby = sortby.slice(1)
        desc = -1;
      }
      return [...result.slice(0,1),...result.slice(1).sort((a,b) => desc*(a[sortby]>b[sortby]?1:-1))]
    }
    return result
  }
  
  getValue(val,key){
    let {report_name,Link} = this.props
    let text = this.formatNum(val,key)
    let [name,url] = (text||'').split(':#')
    if(url){
      if(!Link) return name;
      return (
        <Link to={`/panel/${url}`}>
          {name}
        </Link>
      )
    }
    return text
  }

	Percent = ({actual,plan,column}) => {
    const todecrease = ['cpl','cpql','cpsv','cpb']
    let color = '#6b7785'
    let percent = parseInt((actual - plan)*100/plan)
    if(todecrease.indexOf(column)!=-1){
      console.log(column)    
    	if(percent>20){
        color = '#d32f02'
      }
      if(percent<-20){
        color = '#10ac60'
      }
    } else {
      if(percent>20){
        color = '#10ac60'
      }
      if(percent<-20){
        color = '#d32f02'
      }  
    }
    
    return (
      <div style={{width:40,color,fontSize:10,textAlign:'right'}}>
        {percent}%
      </div>    
    )
  }
  
  render() {
    console.log(this.props)
    let result = this.props.data
    let {sno,conf,mediaplanMode} = this.props
    let {sortby,selcells,value=[],pop} = this.state
    
    let keys = [...this.props.xkeys,...this.props.ykeys]
    if(sno){
      keys = ['sno',...keys]
    }
    if(keys.indexOf(sortby.replace('-',''))==-1){
      sortby = '-'+this.props.xkeys[0]
    }
    
    let data = this.sortData(sortby);
    let {fixedwidth} = this.props
    return (
    	<div className={css`
* {
	font-family: Lato;
}
background: white;
width: fit-content;
border:1px solid #ededed;
-webkit-overflow-scrolling: touch;
height: calc(100vh - ${fixedwidth?100:200}px);
width: calc(100vw - ${fixedwidth?fixedwidth:60}px) !important;
@media only screen and (max-width:700px){
	width: calc(100vw - 20px);
}
overflow:auto;

.header {
  display: table-row !important;
  font-size:10px;
  letter-spacing:1px;
	th.sort {
    border-bottom: 3px solid #6b7785;
  }
  th.sortdesc {
    border-top: 3px solid #6b7785;
  }
  th {
		background: #fbfbfb;
		color: #6b7785;
    border-right: 1px solid #ededed;
    border-bottom: 3px solid #fbfbfb;
    border-top: 3px solid #fbfbfb;
    min-width:40px;
    padding:10px;
    cursor: pointer;
    width: 50px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
  }
}
.row:first-child {
	
	td {
		position: sticky;
		top:45px;
		background: #fbfbfb;
		color: #6b7785 !important;
    font-weight: 700;
		border-top: 1px solid #ededed;
	}
}

.row {
  text-align: right;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  color: #f0101721;
  td {
		max-width:300px;
		overflow: hidden;
		color: #f0101721;
    padding:6px 10px;
    border-right: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
  }
}
table {
	white-space: nowrap;
  table-layout: fixed;
  tbody tr:hover {
    background: ${colors['violet-lightest']};
  }
  border-bottom: 1px solid ${colors['gray-light']};
}

td,tr,table {
  border-collapse: separate;
	border-spacing: 0px;
}
`}>
        {pop &&
        	<LeadHistory leadid={pop} close={() => this.setState({pop:null})}/>
        }
          <table>
            <tr class="header">
              {
                keys.map(o => (
                  <th 
                    style={{position:'sticky',top:0}}
                    class={
                      (sortby==o?"sort":"")+
                      (sortby=="-"+o?"sortdesc":"")}
                    onClick={() => {
                      this.setState({sortby:sortby!="-"+o?"-"+o:o})
                    }}>
                    <div style={{display:'flex'}}>
                      <div style={{flex:1}}>{o}</div>
                      {false &&
                        <Tooltip 
                          style={{position:'absolute',right:0,bottom:0}}
                          size="10"
                          dir="bottom"
                          text="this is a test tip">
                        </Tooltip>
                      }
                    </div>
                  </th>
                ))
              }
            </tr>
            <tbody>
            {data.slice(0,200)
              .filter(o => mediaplanMode=='all' || o[keys.slice(-1)[0]]!=null)
              .map((row,_r) => (
              <tr 
                className="row" >
                {
                  keys.map((o,_c) => (
                    <td data-o = {o} data-c = {_c} data-r = {row[o]}
                      title={this.formatNum(row[o])}
                      style={{textAlign:typeof(row[o])=='number'?'right':'left',color:this.getHeatmapColor(o,row[o])}}>
                      <div>
                      <div style={{display:'flex',minHeight:18,inlineSize:row[o] && row[o].length >=35 && "250px",whiteSpace:row[o] && row[o].length >=35 ? "normal": "nowrap",overflowWrap:"anywhere",wordBreak:"break-all",display:'flex',marginRight:row['compare']&&(_r==0 && mediaplanMode=='totals' || mediaplanMode=='all')&&row['compare'][o]?45:0}}>                          {o=='lead_ids'?
                            row[o].split(' : ').map(o => (
                              <div 
                                onClick={() => this.setState({pop:o})}
                                style={{textDecoration:'underline',cursor:'pointer',marginRight:5}}>{o}
                              </div>
                            ))
                          	:(o=='sno'?(_r==0?data.length-1:_r):this.getValue(row[o],o))
                          }
                        </div>
                        {(_r==0 && mediaplanMode=='totals' || mediaplanMode=='all') && row['compare']?
                          <div style={{display:'flex',justifyContent:'flex-end',marginTop:5,opacity:row['compare'][o]?1:0}}>
                            <div style={{fontSize:12,lineHeight:'10px',width:'fit-content',display:'flex',border:'1px dashed #6b7785',padding:5,borderRadius:3}}>
                              <div style={{color:'#6b7785'}}>{this.getValue(row['compare'][o],o)}</div>
                              <this.Percent plan={row['compare'][o]} actual={row[o]} column={o} />
                            </div>
                          </div>:null
                        }
                      </div>
                    </td>
                  ))
                }
              </tr>
            ))
            }
            {data.slice(200).length > 0 &&
              <tr className='row'>
                <td colSpan={100} style={{textAlign:'left',color:`${colors['gray-dark']}`}}>
                  {data.slice(200).length} more results hidden. Use sorting or filtering to see them.
                </td>  
              </tr>
            }
            </tbody>
          </table>
      </div>
    )
  }
}
