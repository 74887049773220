import React, { Component } from "react";
import { css, cx } from "react-emotion";
import _ from "lodash";
import {
  mixins,
  colors,
  DropDown,
  Option,
  OptionGroupCheckBox,
} from "pebble-web";

export const flexInBetween = css({
  display: "flex",
  justifyContent: "space-between",
});
export const multiEditModalWrapper = css({
  minWidth: "354px",
  height: "50px",
});
export default class CampaignDropdown extends Component {
  state = {
    isOpen: false,
    campaignList: this.props.value,
    filter: "",
  };
  render() {
    const { rowIndex, campaigns, source, handleChange } = this.props;
    const { campaignList, filter } = this.state;
    const sourceCampaigns = campaigns[source];  
    const campaignNames = sourceCampaigns.map((item) => item.name);    
    return (
      <DropDown
        closeOnOutsideClick
        dropDownClassName={css({
          zIndex: 10,
          height: "50px",
          width: "100%",
        })}
        labelComponent={({ toggleDropdown, isOpen }) => {
          return (
            <div
              onClick={() => {
                toggleDropdown();
              }}
              className={cx(
                flexInBetween,
                css({
                  alignItems: "center",
                  padding: "2px 16px",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  lineHeight: "normal",
                  cursor: "pointer",
                })
              )}
            >
              <div
                className={css({
                  ...mixins.flexRow,
                  lineHeight: "14px",
                })}
              >
                <div
                  className={cx(
                    campaignList,
                    css({
                      fontSize: "11px",
                      fontWeight: isOpen ? 600 : 500,
                    })
                  )}
                >
                  {campaignNames.length === campaignList.length
                    ? "All Campaigns enabled"
                    : campaignList.map((campaign_id) => {
                        const campaignItem = sourceCampaigns.find(
                          (item) => item.id === campaign_id
                        );
                        return campaignItem
                          ? campaignItem.name
                          : "Invalid Campaign";
                      })[0]}
                  {campaignList.length > 1 &&
                    campaignList.length != campaignNames.length && (
                      <span
                        className={css({
                          color: colors.gray.base,
                          fontSize: "10px",
                          marginLeft: "5px",
                        })}
                      >
                        + {campaignList.length - 1} more
                      </span>
                    )}
                </div>
              </div>
              <div
                className={css({
                  transform: isOpen ? "rotate(180deg)" : "",
                  ...mixins.flexMiddleAlign,
                })}
              >
                <i
                  className={cx(
                    "pi pi-arrow-drop-down",
                    css({ fontSize: "12px", color: colors.gray.light })
                  )}
                />
              </div>
            </div>
          );
        }}
      >
        {({ toggle }) => {
          return (
            <OptionGroupCheckBox
              wrapClassName={multiEditModalWrapper}
              onChange={(value) => this.setState({ campaignList: value })}
              searchBox
              searchBoxProps={{
                value: filter,
                onChange: (text) => this.setState({ filter: text }),
                placeholder: `Search`,
                showSearchIcon: false,
              }}
              selected={campaignList}
              onApply={() => {
                handleChange(rowIndex, "campaign_ids", campaignList);
                toggle();
              }}
              onClear={() => this.setState({ campaignList: [] })}
            >
              {sourceCampaigns
                .filter((option) =>
                  option.name.toLowerCase().includes(filter.toLowerCase())
                )
                .map((campaign) => (
                  <Option
                    className={css({
                      fontSize: "12px",
                      whiteSpace: "pre-line",
                      overflowWrap: "break-word"
                    })}
                    key={campaign.id}
                    value={campaign.id}
                    label={campaign.name}
                  />
                ))}
            </OptionGroupCheckBox>
          );
        }}
      </DropDown>
    );
  }
}
