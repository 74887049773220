import React, { PureComponent } from 'react'

class Notify extends PureComponent {

	constructor (props) {
		super(props)
		this.state = {
			show: false
		}
		this.timeout = null
		this.onClick = this.onClick.bind(this)
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (prevProps.message_id !== this.props.message_id) {
			this.setState({show: true})
			if (this.timeout) {
				clearTimeout(this.timeout)
				this.timeout = null
			}
			this.timeout = setTimeout(() => {
				this.setState({show: false})
			}, 5000)
		}
	}

	onClick () {
		if (this.timeout) {
			clearTimeout(this.timeout)
			this.timeout = null
		}
		this.setState({ show : false })
	}

	render () {
		const { message } = this.props
		const { show } = this.state

		return (
			<div className={`am-notif ${show ? 'show' : ''}`} onClick={this.onClick}>
				<div style={{zIndex:1000000}} className='am-notify-label am-button dark'>{message}</div>
			</div>
		)
	}
}

export default Notify