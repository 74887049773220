import React, {Component} from "react";
import { css } from "react-emotion";
import { HotTable } from "@handsontable/react";
import styles from "handsontable/dist/handsontable.full.css";

const _table = css`
	background:#fff;
  border: 1px solid #ccc;
  border-collapse: collapse;
  th {
    background: #f7f7f7;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
  }
  td {
    padding: 10px;
    border: 1px solid #ccc;
		min-width: 100px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const _btn = css`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  badding: 5px;
  border-radius: 5px;
  background: #6161ff;
  color: #fff;
  cursor: pointer;
  :hover {
    filter: brightness(1.3);
  }
`;

const _sheet = css`
  position: fixed;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px #0005;
  left: 50%;
	top:80px;
  transform: translateX(-50%);
	z-index:10000002;
  .table {
    min-width: 600px;
    font-size: monospace;
    font-size: 12px;
    height: 70vh;
    overflow: hidden;
    margin-bottom: 30px;
  }
`;

const _overlay = css`
  background: #0003;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
	z-index:10000001;
`;

const Table = P => {
  const keys = ['name','email','phone','status'];
  return (
    <table className={_table}>
      <tr>
        {keys.map(k => (
          <th>{k}</th>
        ))}
      </tr>
      {P.data.map((r, _r) => (
        <tr>
          {keys.map(k => (
            <td>{r[k]}</td>
          ))}
        </tr>
      ))}
    </table>
  );
};


const _tabs = css`
display:flex;
border: 1px solid #6b7785;
background: #fff;
border-radius: 3px;
overflow:hidden;
margin-bottom:20px;

.tab {
	padding:10px;
	border-right: 1px solid #6b7785;
	cursor: pointer;
	&:hover {
		background: #e8e8ff;	
	}
	&.sel {
		background: #e8e8ff;
		color: #6161ff;
	}
}
`;

const _addbox = css`
padding:10px;
border:1px solid #ededed;
margin-top:20px;
background:#fff;
box-shadow:0px 0px 10px #0003;
border-radius:3px;
overflow: hidden;

.ctrls {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}
.add {
	padding: 5px 15px;
	background: #6161ff;
	color: #fff;
	border-radius:5px;
	cursor: pointer;
}
.cancel{
	padding: 5px 15px;
	background: #f7f7f7;
  color: #6b7785;
	border-radius:5px;
	cursor: pointer;
	margin-right:10px;
}
.label {
	margin-top: 20px;
	margin-bottom: 5px;
	color: #6b7785;
}
`

const _card = css`
background: #fff;
padding: 10px;
border-radius: 3px;
margin-bottom: 5px;
cursor: pointer;
width:300px;
border: 2px solid transparent;
&.sel {
	border: 2px solid #6161ff;
	background: #fff;
}
:hover {
	background: #e8e8ff55;
}

.name1 {
	font-size:16px;
	margin-bottom:5px;
}
.name2 {
	font-size:12px;
  color: #6b7785;
}
.link {
	color: #6161ff;
	font-size:12px;
	text-decoration: underline;
}
`
export class ManualLeadsConfig extends Component {
  state = {}
  getOptions = () => {
    let self = this
    this.setState({sourcesloading:true})
    let {assignment} = this.props
    fetch(`${window._MDOMAIN}/api/v0/manual-leads?rtype=options&assignment_id=${assignment.assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState(data.data)
      }
      this.setState({sourcesloading:false})
    }).catch(() => {
      this.setState({sourcesloading:false})
    })
  }
  
  getConfig = () => {
    let self = this
    this.setState({sourcesloading:true})
    let {assignment} = this.props
    fetch(`${window._MDOMAIN}/api/v0/manual-leads?rtype=get-config&assignment_id=${assignment.assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({config:data.data})
      }
      this.setState({sourcesloading:false})
    }).catch(() => {
      this.setState({sourcesloading:false})
    })
  }
  
  saveConfig = () => {
    let self = this
    this.setState({sourcesloading:true})
    let {assignment} = this.props
    let {source,campaign_key,config=[]} = this.state
    if(!source || !campaign_key){
      alert('please select source and campaign_key.')
      return
    }
    fetch(`${window._MDOMAIN}/api/v0/manual-leads?rtype=save-config&assignment_id=${assignment.assignment_id}`, {
      method: 'post',
      credentials:"include",
      body: JSON.stringify({source,campaign_key})
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        config.push(data.data)
        self.setState({config})
      }
      this.setState({sourcesloading:false})
    }).catch(() => {
      this.setState({sourcesloading:false})
    })
  }
  
  
  componentDidMount(){
    this.getOptions()
    this.getConfig()
  }
	render() {
    const {assignment} = this.props
    const {sources=[], campaign_keys=[], config=[], leads= [],key,show_sheet,add} = this.state
  	
    return (
      <div style={{display:'flex'}}>
        <div style={{marginRight:30,minWidth:200}}>
          <div>
            {config.map(o => (
              <div className={_card+(o.key==key?' sel':'')} onClick={() => this.setState({key:o.key})}>
                <div class="name1">
                  {o.source} - <a target="_blank" href={`/manual-leads?key=${o.key}`} class="link">url</a>
                </div>
                <div class="name2">{o.campaign_key}</div>
              </div>
            ))}
          </div>
          {add?
          <div className={_addbox}>
            <div className="label">source</div>
            <select onChange={(e) => {
              this.setState({source:e.target.value})
            }}>
              <option disabled selected value> -- select source -- </option>
              {sources.map(s => (
                <option className="tab" value={s}>{s}</option>
              ))}
            </select>
            <div className="label">campaign key</div>
            <select onChange={(e) => {
              this.setState({campaign_key:e.target.value})
            }}>
              <option disabled selected value> -- select campaign key -- </option>
              {campaign_keys.map(s => (
                <option className="tab" value={s}>{s}</option>
              ))}
            </select>
            <div class="ctrls">
              <div class="cancel" onClick={() => this.setState({add:false})}>cancel</div>
            	<div class="add" onClick={this.saveConfig}>add</div>
          	</div>
          </div>
          :<div className={_btn} style={{marginTop:20}} onClick={() => this.setState({add:true})}>Add Sheet</div>
          }
        </div>
        {key &&
          <div style={{marginTop:-30}}>
          	<ManualLeads apikey={key}/>
          </div>
        }
    	</div>
    );
  }
}
export class ManualLeads extends Component {
  state = {}
	getLeads = (key) => {
    let self = this
    this.setState({sourcesloading:true})
    let {assignment} = this.props
    this.setState({leads:[]})
    fetch(`${window._MDOMAIN}/external/v0/manual-leads?rtype=get-leads&key=${key}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        let heading = data.obj['name']+' - '+data.obj['source']
        self.setState({leads:data.data,heading})
      }
      this.setState({sourcesloading:false})
    }).catch(() => {
      this.setState({sourcesloading:false})
    })
  }
  getKey = () => {
    return window.location.href.split('key=')[1].split('&')[0]
  }
  componentDidMount(){
    let {apikey=this.getKey()} = this.props
    this.getLeads(apikey)
  }
	componentWillReceiveProps(nprops) {
    let {apikey=this.getKey()} = nprops
    this.getLeads(apikey)
  }
  render(){
    let {show_sheet,leads=[],heading=""} = this.state
    let {apikey=this.getKey()} = this.props
		// starts with key
    return (
    <div style={{padding:30}}>
      <h1>{heading}</h1>
      <div style={{height:20}}></div>
      <div className={_btn} onClick={() => this.setState({show_sheet:true})}>Add Leads</div>
      <div style={{height:30}}></div>
      {show_sheet && <Sheet update={() => this.getLeads(apikey)} apikey={apikey} close={() => this.setState({show_sheet:false})}/> }
      <Table
        data={leads}
        apikey={apikey}
      />
    </div>
    )
  }
}
class Sheet extends Component {
  state = {}
  saveLeads = (key) => {
    let self = this
    this.setState({disabled:true})
    let {data} = this.state
    fetch(`${window._MDOMAIN}/external/v0/manual-leads?rtype=save-leads&key=${key}`, {
      method: 'post',
      credentials:"include",
      body:JSON.stringify(data)
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({data:data.data})
        self.props.update()
      }
      self.setState({disabled:false})
    }).catch(() => {
      alert('error occured')
      self.setState({disabled:false})
    })
  }
  render(){
    let {apikey} = this.props
    let {data=Array(40).fill(0).map(o => Array(4).fill("")),disabled} = this.state
  	return (
      <div>
        <div className={_overlay} />
        <div className={_sheet}>
          <div className="table">
            <HotTable
              afterChange = {(o) => {
                console.log(o)
                if(o && o[0]){
                	this.setState({data})
              	}
              }}
              stretchH="all"
              colHeaders={["Name", "Email", "Phone", "remarks"]}
              data={data}
            />
          </div>
          <div style={{display:'flex',alignItems:'center'}}>
            
            <div style={{opacity:disabled?.4:1}} className={_btn} onClick={() => !disabled && this.saveLeads(apikey)}>save</div>
            <div onClick={this.props.close} style={{marginLeft:20,cursor:'pointer',color:'#6b7785'}}>close</div>
          </div>
        </div>
      </div>
    );  
  }
};
