import React, { PureComponent, useState, useEffect} from 'react'
import Notify from './components/notify'
import Ionicon from 'react-ionicons'
import styles from 'handsontable/dist/handsontable.full.css';
import { BrowserRouter as Router, browserHistory, Route, Link } from "react-router-dom";
import {HotTable} from '@handsontable/react'
import { css, cx } from 'react-emotion'
import {Button} from './querytool'
import {DateRange} from './components'
import {Filters} from './components/segments'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { SpendsDateFilter } from './components/spends/spends-date-filter';
import { DropDown, colors, typography } from '@anarock/pebble';
import { format, subDays } from "date-fns";

const DEFAULT_START_DATE = subDays(new Date(),90);

const css_labels = css`
min-width:200px;
background:#fff;
max-height:200px;
overflow:auto;
box-shadow: 0px 8px 15px 0px rgba(0,0,0,.15);
position:absolute;
background:#fff;
z-index:100000;
> div > div {
  padding: 10px 5px;
	cursor: pointer;
	:hover {
		background: #fbfbfb;
	}
}
`

const listStyles = css`
cursor:pointer; 
white-space: nowrap;
td {padding:5px;} 
:hover{background:#e8e8ff;} 
&.active{background:#e8e8ff;color:#6161ff;}
`

const scriptOutBody = css({
  border: `1px solid ${colors.gray.light}`,
  padding: "10px",
  margin: "10px 0px",
  borderRadius: "3px",
  minHeight: "100px",
  fontSize: "12px",
  color: colors.gray.dark,
  fontStyle: "monospace"
})

const scriptText = css({
  whiteSpace:'pre-line', 
  overflowWrap: "break-word", 
  wordBreak: "break-all", 
  fontWeight:'bold',
  color:'#000',
  padding:'5px 0px'
})

const titleStyle = css({
  lineHeight: "18px"
});

export const toolTipContainer = css({
  padding: "8px",
  backgroundColor: colors.gray.lightest,
  width: "100%",
  ...typography.s.light,
  color: colors.gray.darker
});

const iconStyle = css({
  paddingTop: "3px",
  color: colors.violet.base,
  marginRight: "8px",
  fontSize: "14px"
});

class ScriptOut extends PureComponent {
  state={}
	render(){
    let {data=''} = this.props;
    let {stepopen=-1} = this.state
    let messages = []
    let steps = []
    data.split('\n').map(o => {
      if(o.slice(0,6)=='html::'){
        messages.push(<div dangerouslySetInnerHTML={{__html:o.slice(6)}}></div>)
      } else if(o.slice(0,6)=='step::'){
        steps.push({label:o.slice(6),messages})
        messages = []
      } else {
        messages.push(<div>{o}</div>)
      }
    })
    if(steps.length==0){
      return <div>{data}</div>
    }
    return (
      <div className={scriptOutBody}>
        {steps.map((o,_i) => (
          <div>
            <div className={scriptText} onClick={() => this.setState({stepopen:stepopen==_i?-1:_i})}>{_i+1}. {o.label}</div>
            {stepopen==_i &&
              <div style={{fontSize:10,padding:10,maxHeight:300,overflow:'auto',border:'1px solid #ededed'}}>
              {o.messages.map(o => (
                <div>{o}</div>
              ))}
              </div>
            }
          </div>
        ))}
      </div>
    )
  }
}

class UpdateSpends extends PureComponent {
  state = {}
	runScript = (rtype, start_date, end_date) => {
    if(this.state.running) return;
    let {scriptout={}} = this.state;
    scriptout[rtype] = 'running...'
    this.setState({running:true,scriptout:{...scriptout}})
    let {assignment_id} = this.props
    let self = this
    fetch(`${window._MDOMAIN}api/v0/runscript?rtype=${rtype}&assignment_id=${assignment_id}&start_date=${start_date}&end_date=${end_date}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({loading:false})
      if(data.status==200) {
        scriptout[rtype] = data.data;
        self.setState({scriptout:{...scriptout},running:false})
        self.props.refreshSpends()
      } else {
        self.setState({message:"Unable to run",message_id:new Date(),running:false})
      }
    }).catch(function(){
      self.setState({running:false})
      self.setState({message:"Unable to run",message_id:new Date()})
    })
  }
	render() {
    let {running,scriptout={}} = this.state
    const btn = css`padding:10px 20px;border-radius:3px;background:${running?'#e8e8ff':'#6161ff'};color:#fff;cursor:pointer;width:fit-content; ${running?'':':hover{filter:brightness(1.2);}'}`
    
    return (
      <DropDown
        className={css({ marginTop: "10px", marginRight: "10px" })}
        placement="bottom-end"
        buttonLabel="Update Spends"
        dropDownClassName={css({
          width: "600px",
          zIndex: 2000,
        })}
      >
        {() => (
          <div
            className={css({
              padding: "15px",
            })}
          >
            <div className={toolTipContainer}>
              <div className={css({ display: "flex" })}>
                <i className={cx("pi pi-info", iconStyle)} />
                <span className={titleStyle}>
                  Select duration for syncing spends
                </span>
              </div>
            </div>
            <div className={css({ marginTop: "15px" })}>
              {["facebook_spends", "adwords_spends"].map((rkey) => (
                <DynamicSpends
                  rkey={rkey}
                  runScript={(start_date, end_date) =>
                    this.runScript(rkey, start_date, end_date)
                  }
                  scriptout={scriptout}
                  btn={btn}
                />
              ))}
            </div>
          </div>
        )}
      </DropDown>
    );
  }
}

class DynamicSpends extends PureComponent {
  state = {
    start_date: DEFAULT_START_DATE,
    end_date: new Date(),
  };
  render() {
    const { rkey, runScript, scriptout, btn } = this.props;
    const { start_date, end_date } = this.state;

    return (
      <div className={css({ marginTop: "15px" })}>
        <div className={css({ display: "flex" })}>
          <SpendsDateFilter
            start_date={start_date}
            end_date={end_date}
            setDate={(start_date, end_date) =>
              this.setState({ start_date, end_date })
            }
          />
          <div
            className={btn}
            onClick={() =>
              runScript(
                format(start_date, "YYYY-MM-DD"),
                format(end_date, "YYYY-MM-DD")
              )
            }
          >
            Update {rkey.replace("_", " ")}
          </div>
        </div>
        {scriptout[rkey] && <ScriptOut data={scriptout[rkey]} />}
      </div>
    );
  }
}

class AddSpends extends PureComponent {
  state = {}
  loadConfig = () => {
    let self = this
    let {assignment_id} = self.props
		fetch(`${window._MDOMAIN}api/v0/spends-config?rtype=list&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({config:data.data})
        self.props.updateConfig(data.data)
        self.props.refreshSpends()
      } else {
        self.setState({message:"Unable to load Config",message_id:new Date()})
      }
    }).catch(function(){
      self.setState({message:"Unable to load Config",message_id:new Date()})
    })
  }
  loadSuggestions = () => {
    let self = this
    let {assignment_id} = self.props
    if(this.state.sources) return;
		fetch(`${window._MDOMAIN}api/v0/spends-config?rtype=sources&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({sources:data.data})
      }
    }).catch(function(){
    })
  }
  saveConfig = (conf) => {
    let self = this
    fetch(`${window._MDOMAIN}api/v0/spends-config?rtype=update`, {
      method: 'post',
      credentials:"include",
      body: JSON.stringify(conf)
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        let {config} = self.state
        if(conf['id']){
          config = config.map(o => o.id==data.data.id?data.data:o)
        } else {
          config = [...config,data.data]
          self.props.refreshSpends()
        }
        self.props.updateConfig(config)
        self.setState({config})
        self.setState({message:"Saved Successfully",message_id:new Date()})
      } else {
        self.setState({message:"Unable to Save Config",message_id:new Date()})
      }
    }).catch(function(e){
      console.error(e)
      self.setState({message:"Unable to Save Config",message_id:new Date()})
    })
  }
  componentDidMount() {
   	this.loadConfig() 
  }
  render() {
    let {assignment_id} = this.props
    let {editsource,custom,showlist,sources=[],searchtext='',filtertext='',conf,config=[],message,message_id} = this.state
    if(!conf){
      conf = {assignment_id,status:'active',json:[{type:'manual'}]}
    }
    let existing_configs = config.map(o => `${o.channel} - ${o.source} - ${o.subsource}`);
    return (
    <div>
      <Notify message={message} message_id={message_id}/>
      <Button 
        align="right"
        label="Add/Edit Columns"
        onOpen = {() => this.loadSuggestions()}
        popper={
          <div style={{background:'#fff',padding:10,display:'flex',borderRadius:3}}>
            {showlist && <div style={{borderRight:'2px solid #6b7785',paddingRight:10}}>
              <input value={filtertext} onChange={e => this.setState({filtertext:e.target.value})}
                placeholder="search" className={css`outline:none;border:1px solid #ededed;padding:5px;border-radius:3px;`}/>
              <div style={{height:300,overflow:'auto'}}>
                <table>
                  <tr className={css`td{padding:5px;font-weight:bold;color:#000;position:sticky;top:0px;background:#fff;box-shadow:0px 2px 0px #000;}`}>
                    <td>Channel</td>
                    <td>Source</td>
                    <td>Subsource</td>
                  </tr>
                  {config
                    .filter(o => (o['channel']+o['source']+o['subsource']).indexOf(filtertext)!=-1)
                    .map(o => (
                    <tr className={listStyles+(o.id==conf.id?' active':'')} onClick={() => this.setState({conf:JSON.parse(JSON.stringify(o))})}>
                      <td>{o['channel']}</td>
                      <td>{o['source']}</td>
                      <td>{o['subsource']}</td>
                    </tr>
                  ))}
                </table>
              </div>
              <div
                style={{background:!conf.id?'#6161ff':null}}
                onClick={() => this.setState({conf:JSON.parse(JSON.stringify({assignment_id}))})}
                className={css`padding:5px 10px;border-radius:3px;background:#6b7785;color:#fff;display:inline-block;margin-top:20px;`}>Add New Column </div>
            </div>}
            <div className={css`padding:10px;min-width:400px;min-height:300px;`}>
              <div style={{fontWeight:'bold',color:'#000',paddingBottom:10}}>{conf.id?'Edit':'Create New'} Spends Config</div>
              {(!conf.id || editsource) && <div style={{marginBottom:20}}>
                <input 
                  placeholder="search source/subsource to add" 
                  style={{width:'250px',padding:10,borderRadius:3,outline:'none',border:'1px solid #ededed'}} 
                  value={searchtext} 
                  onChange={e => this.setState({searchtext:e.target.value})} 
                  onFocus={() => this.setState({tpathsugg:true})}
                  onBlur={(e) => {
                    let target = e.target
                    setTimeout(() => target!=document.activeElement && this.setState({tpathsugg:false}),100)
                  }}
                  />
                <div className={css_labels}>
                  {this.state.tpathsugg && 
                    <div>
                      {sources
                      	.map(l => ({...l,text:`${l.channel} - ${l.source} - ${l.subsource}`}))
                        .filter(l => l && l.text.toLowerCase().indexOf(searchtext)!=-1 && existing_configs.indexOf(l.text)==-1)
                        .map(l => (
                        <div onClick={() => {    
                          conf.channel = l.channel
                          conf.source = l.source
                          conf.subsource = l.subsource
                          this.setState({conf:{...conf},searchtext:''})
                        }}>{l.text} - {l.leads}</div>
                      ))}
                      <div>
                        <button onClick={() => this.setState({custom:true})}>Add Custom</button>
                      </div>
                    </div>
                  }
                </div>
              </div>}
              {(conf.channel || custom) && 
              <div style={{position:'relative'}}>
                <div
                  style={{cursor:'pointer',position:'absolute',right:10}}
                  onClick={() => {
                      conf.status=conf.status=='active'?'inactive':'active'
                      this.setState({conf:{...conf}})
                    }}>
                  {conf.status=='active'?
                    <img style={{width:40}} src="https://image.flaticon.com/icons/png/512/889/889754.png"></img>
                    :
                  	<img style={{width:40}} src="https://image.flaticon.com/icons/png/512/889/889758.png"></img>
                  }
                </div>
                <table>
                {['channel','source','subsource'].map(o => (
                  <tr>
                    <td>{o}</td>
                    <td>
                      {custom?
                      <input 
                          value={conf[o] || ''} 
                          style={{padding:5,margin:3}} 
                          onChange={e => {
                            conf[o] = e.target.value
                            console.log(conf)
                            this.setState({cb:new Date(),conf})
                      }}/>:
                      <div style={{marginLeft:10,color:'#000'}}>{conf[o] || ''}</div>
                      }
                    </td>
                  </tr>
                ))}
                </table>
                <div onClick={() => this.setState({editsource:true})} className={css`text-decoration:underline;cursor:pointer;`}>edit source</div>
                <table>
                <tr>
                    <td>project name</td>
                    <td>
                      <input />
                    </td>
                  </tr>
                </table> 
                <div style={{paddingLeft:30,paddingTop:20}}>
                  {(conf['json'] || []).map((o,i) => (
                  <div className={css`display:flex; >div{margin:3px}`}>
                    <div style={{display:'flex',alignItems:'center',marginBottom:5}}>
                      <DateRange key1="start_date" key2="end_date" single={true} getValue={(k) => o[k] || ''} handleDayClick={(key,day) => {
                        if(!day && o[key]) {
                          delete o[key];
                        } else if(day) {
                          let pts = day.toLocaleDateString("en-GB").split(",")[0].split("/")
                          if(Math.abs(parseInt(pts[2])-2019) < 10 ){
                            o[key] = `${pts[2]}-${pts[1]}-${pts[0]}`
                          } else {
                            o[key] = null
                          }
                        }
                        console.log(conf)
                        this.setState({conf,cb:new Date()})
                        }}/>
                    </div>
                    <div>
                      <select value={o['type']} 
                        onChange={(e) => {
                          o['type'] = e.target.value
                          this.setState({conf,cb: new Date()})
                        }}
                        className={css`outline:none;background:#fff;border:1px solid #ededed;height:28px;`}>
                        <option>manual</option>
                        <option>bulk</option>
                        <option>cpl</option>
                        <option>cpql</option>
                        <option>cpsv</option>
                        <option>cpb</option>
                        <option>auto</option>
                      </select>
                    </div>
                    <div style={{opacity:o['type']=='manual'?0:1}}>
                      <input value={o['amount']}  
                        type="number"
                        onChange={(e) => {
                          o['amount'] = parseInt(e.target.value)
                          this.setState({conf,cb: new Date()})
                        }}
                        className={css`width:100px;height:28px;padding:5px;border:1px solid #ededed;outline:none;`} />
                    </div>
                    <div>
                      <Ionicon 
                        onClick={() => {
                          console.log('removing')
                          conf['json'] = conf['json'].filter((_,j) => j!=i)
                          this.setState({conf,cb:new Date()})
                        }}
                        icon="md-close-circle" color="#6b7785" fontSize="14px" className={css`margin-top:7px;cursor:pointer; :hover{fill: #eb7785;}`}/>
                    </div>
                  </div>
                  ))}
                  <div 
                  className={css`padding:5px 10px;background:#6b7785;border-radius:3px;cursor:pointer;color:#fff;display:inline-block;margin:10px 0px;`}
                  onClick={() => {
                    conf.json = conf.json || []
                    conf.json.push({type:'manual'})
                    this.setState({cb:new Date(),conf})
                  }}>add spend</div>
                </div>
                <div style={{display:'flex',marginBottom:30}}>
                  <div 
                    onClick={() => this.saveConfig(conf)}
                    style={{marginTop:20,marginRight:30,background:'#6161ff',color:'#fff',padding:'5px 10px',borderRadius:3,width:'fit-content',cursor:'pointer'}}>
                    {conf.id?'Update':'Save New'} Spends Config
                  </div>
                  <div 
                    onClick={() => this.setState({conf:null,custom:false,editsource:false})}
                    style={{marginTop:20,background:'#ededed',color:'#6b7785',padding:'5px 10px',borderRadius:3,width:'fit-content',cursor:'pointer'}}>
                    Cancel
                  </div>
                </div>
              </div>
              }
              <div className={css`position:absolute;bottom:10px;text-decoration:underline;color:#6b7785;`} onClick={() => this.setState({showlist:!showlist})}>List View</div>
            </div>
            
      		</div>
        }
        />
    </div>
  )
  }
}

class DaywiseSpends extends PureComponent {
  constructor (props) {
    super(props)
    let start = this.getDate(-20);
    let end = this.getDate(0);
    let defaultfilters = {'date':{type:'daterange',start,end},'channel':{type:'inlist',options:{online:1}},'source':{type:'inlist',options:{}},subsource:{type:'inlist',options:{}}}
    this.state = {
      assignment: this.props.assignment || {},
      entry: "0_0",
      data: [],
      dirty:{},
      dbdata:[],
      width:window.innerWidth-180,
      height:window.innerHeight-150,
      rangeStart:0,
      rangeEnd:10000,
      used: {},
      auto: {},
      filters:defaultfilters,
      developerProjects: []
    }
    
    this.rowtotals = []
    this.coltotals = []
  }
  
  getDate = (days) => {
    let pts = (new Date().toString().split(' '))
    let today = new Date(`${pts[2]}${pts[1]}${pts[3]}`)
    today.setDate(today.getDate() + days);
    return today;
  }

  getDeveloperOptions = () => {
    let self = this
    let {assignment_id} = this.state.assignment
    if(!assignment_id)return;

    fetch(`${window._MDOMAIN}/api/v0/url-generator?rtype=get-v2&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        var projects = data.data.projects;
        self.setState({developerProjects: projects});
      }
    }).catch(() => {
      this.setState({loading:false})
    })

  }
  getSpendsData = () => {
    let self = this
    let {assignment_id} = this.state.assignment
    let {start_date,end_date} = this.state.assignment.extra_details
    if(!assignment_id)return;

    fetch(`${window._MDOMAIN}/api/v0/spends?rtype=get&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        let dbdata = {}
        let keys = {}
        let used = {}
        let auto = {}
        let channels = {}
        data.data.map((o) => {
          dbdata[o.date+o.config_id] = o.spends
          keys[o.config_id] = 1
          if(o.auto){
            auto[o.config_id] = 1
          }
          if(o.used){
            used[o.config_id] = 1
          }
          channels[o.config_id] = o.channel
        })
        let {config=[]} = self.state
        let initialdata = self.getData(start_date,end_date,config.map(o => o.id))
        let header = [...initialdata[0],...Object.keys(keys).map(o => parseInt(o)).filter(o => initialdata[0].indexOf(o)==-1)].filter(o => o)
        
        let dates = self.getDates(new Date(start_date),new Date(end_date))
        let records = dates.map((d) => {
          return [d.toString(),...header.slice(1).map(() => 0)]
        })
        
        let newData = [
          header,
          ...records.map((row,r) => {
            return row.map((cell,c) => {
              if(c==0) return cell
              let key = row[0]+header[c]
              return dbdata[key]?dbdata[key]:0
            })
          })
        ]

        let rowtotals = newData.slice(1).map((o) => {
          let sum = 0
          o.slice(1).map((o) => sum+=o)
          return sum
        })

        let coltotals = newData[0].map(() => 0)
        newData.slice(1).map((o) => {
          o.slice(1).map((o,i) => {
            coltotals[0] += o
            coltotals[i+1] += o
          })
        })

        self.rowtotals = rowtotals
        self.coltotals = coltotals
        self.setState({channels,used,auto,data:newData,dirty:{},dbdata:JSON.parse(JSON.stringify(newData))})
      }
    })
  }

  saveSpends = () => {
    let self = this
    let {assignment_id} = this.state.assignment
    let {delete_keys={}} = this.state
    if(!assignment_id)return;

    let changes = {}
    let rows = this.state.data[0]
    this.state.data.slice(1).map((row,r) => {
      let date = row[0]
      row.slice(1).map((cell,c) => {
        let key = `${date}:${rows[c+1]}`
        if(this.state.dbdata[r+1][c+1] != this.state.data[r+1][c+1]) {
          changes[key] = this.state.data[r+1][c+1]
        }
      })
    })
    
    changes["delete"] = delete_keys
    changes["skey"] = "config_id"
    
    console.log("saving",JSON.stringify(changes))
    
    fetch(`${window._MDOMAIN}/api/v0/spends?rtype=post&assignment_id=${assignment_id}`, {
      method: 'post',
      body: JSON.stringify(changes),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.getSpendsData()
        self.setState({message:"Saved Successfully",message_id:new Date(),delete_keys:{}})
      } else {
        self.setState({message:"Unable to save",message_id:new Date()})
      }
    }).catch(function(){
      self.setState({message:"Unable to save",message_id:new Date()})
    })
  }

  presentEntry = null;
  
  removeEntry = () => {
    document.querySelectorAll(".entry").forEach((e) => {
      e.parentNode.innerHTML = e.value
    })
  }
  
  handlePaste = (event) => {
    let data = this.state.data
    let [rOffset,cOffset] = event.target.name.split("_").map((o) => parseInt(o))
    var text = event.clipboardData.getData('text')
    var rows = text.split("\n")
    let dirty = this.state.dirty
    rows.map((row,r) => {
      var cols = row.split("\t")
      cols.map((cell,c) => {
        try{
          let nr = rOffset+r
          let nc = cOffset+c
          if(data[nr][nc]!=undefined){
            data[nr][nc] = parseInt(cell.replace(',','').trim()) || 0
            dirty[nr+"_"+nc] = 1
          }
        } catch(e){}
      })
    })
    document.querySelector(".entry").blur()
    this.setState({data,entry:"0_0",dirty})
  }

  getDates(startDate, endDate) {
    let dates = [],
        currentDate = startDate,
        addDays = (cdate,days) => {
          var date = new Date(cdate.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
    while (currentDate <= endDate) {
      let pts = currentDate.toString().split(' ')
      let dateStr = `${pts[2]}${pts[1]}${pts[3]}`
      dates.push(dateStr);
      currentDate = addDays(currentDate, 1);
    }
    return dates;
  };

  getData(startdate,enddate,spend_keys) {
    let dates = this.getDates(new Date(startdate),new Date(enddate))
    let data = dates.map((d) => {
      return [d.toString(),...spend_keys.map(() => 0)]
    })
    return [['date',...spend_keys],...data]
  }

  handleChange = (event) => {
    let [r,c] = event.target.name.split("_")
    let data = this.state.data
    let newValue = parseInt(event.target.value.split("\n")[0].split("\t")[0].trim().replace(',',''))  || 0
    if(data[r][c]!=newValue){
      let dirty = this.state.dirty
      dirty[r+"_"+c] = 1
      this.setState({dirty})
    }
    data[r][c] = newValue
    this.setState({data})
  }

  handleKeyboard = (event) => {
    let [r,c] = this.state.entry.split("_").map((o) => parseInt(o))
    let data = this.state.data
    
    let change = {
      "ArrowDown":[1,0],
      "ArrowUp": [-1,0],
      "ArrowLeft": [0,-1],
      "ArrowRight": [0,1]
    }
    if(change[event.key]){
      let nr=change[event.key][0]+r,nc=change[event.key][1]+c
      if(nr>0 && nc>0 && data[nr] && data[nr][nc]!=undefined){
        this.startEdit(nr+"_"+nc)
      }
    }
  }
  
  formatVal(num) {
    if(['number'].indexOf(typeof(num))!=-1) {
      return (parseFloat(num) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\..*/,'');
    } else {
      return num
    }
  }
  
  formatPrice(val) {
    return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00','')
  }

  componentDidMount() {
    let {assignment_id} = this.state.assignment
    let {start_date,end_date} = this.state.assignment.extra_details
    
    let {config=[]} = this.state
    this.setState({data:this.getData(start_date,end_date,config.map(o => o.id))})
    this.getDeveloperOptions();
  }

  startEdit = (entry) => {
    setTimeout(() => document.querySelector(".entry").select(),100)
    this.setState({entry})
  }
  
  handleChange = (e) => {
    if(e.currentTarget.checked){
      let pts = (new Date().toString().split(' '))
      let today = `${pts[2]}${pts[1]}${pts[3]}`
      let start = 0
      this.state.data.map((o,i) => {
        if(o[0]==today) start = Math.max(i - 20,0)
      })
      this.setState({rangeStart:start,rangeEnd:start+20})
    } else {
      this.setState({rangeStart:0,rangeEnd:10000})
    }
  }
  
  updateConfig = (config) => {
    let idmap = {}
    let filteroptions = {source:{},subsource:{},channel:{}};
    config.map(o => {
      idmap[o.id] = o
      filteroptions['source'][o.source] = 1
      filteroptions['subsource'][o.subsource] = 1
      filteroptions['channel'][o.channel] = 1
    });
    ['source','subsource','channel'].map(k => {
      filteroptions[k] = Object.keys(filteroptions[k]).map(o => ({id:o,name:o}))
    })
    
    this.idmap = idmap
    this.filteroptions = filteroptions
    this.setState({config})
  }

  render() {
    let pts = (new Date().toString().split(' '))
    let today = `${pts[2]}${pts[1]}${pts[3]}`  
    let data = this.state.data
    let entry = this.state.entry
    let {delete_keys={}} = this.state
    let {assignment_id} = this.state.assignment
    let {start_date,end_date} = this.state.assignment.extra_details
    let unused = 0;
    // (data[0] || []).slice(1).map((k,_i) => {
    //   if(!delete_keys[k]){
    //     unused += this.coltotals[_i+1]
    //   }
    // });
    
    let {message,message_id,width,height,config=[],filters} = this.state
    let filteroptions = this.filteroptions || {source:[],subsource:[],channel:[]}
    let idmap = this.idmap || {}
    let columns = (this.state.data[0] || []).slice(1)
    	.map((k,_i) => {
        if(idmap[k] && idmap[k].status=='active'){
          let {source,subsource,channel} = idmap[k]
          let cf = filters['channel']['options']
          let sf = filters['source']['options']
          let ssf = filters['subsource']['options']
          if((!Object.keys(cf).length || cf[channel]) && (!Object.keys(sf).length || sf[source]) && (!Object.keys(ssf).length || ssf[subsource])) {
            return _i
          }
        }
        return null;
    	}).filter(o => o!=null)
    
    let rangeStart = 0
    let rangeEnd = 10000
    if(filters['date']['start']){
      rangeStart = Math.min(this.state.data.length-1,Math.max(parseInt((new Date(filters['date']['start']) - new Date(start_date))/86400000+1),0))
      rangeEnd = Math.min(this.state.data.length-1,Math.max(parseInt((new Date(filters['date']['end']) - new Date(start_date))/86400000+2),0))
    }
    return (
      assignment_id && this.state.data.length > 2?
      <div style={{paddingBottom:"0px"}} className={'spendssheet '+css`
.close {
	opacity: 0;
	transition: opacity .5s ease;
	:hover {
		opacity: 1;
	}
}
`}>
        <style dangerouslySetInnerHTML={{ __html: styles }} />
        <div style={{display:'flex',padding:10,color:'#6b7785',display:'none'}}>
          <Link to={`/panel/${assignment_id}/configuration`}><div style={{marginRight:20}}>config</div></Link>
          <Link to={`/panel/${assignment_id}/spends`}><div style={{marginRight:20}}>spends</div></Link>
          {false && <Link to={`/panel/${assignment_id}/checklist`}><div style={{marginRight:20}}>checklist</div></Link>}
      	</div>
        
        {
          Object.keys(delete_keys).length?
          <div style={{marginBottom:20,color:'#d32f02'}}>
            <b>{Object.keys(delete_keys).join(',')}</b> <span>will be deleted on save</span>
          </div>
          :null
        }
        
        <div>
          <div style={{marginTop:-10,fontSize:12,color:'#6b7785'}}>
            <div>sheet duration is <strong>{start_date} - {end_date}.</strong></div>
            <div style={{display:'flex',marginTop:5,alignItems:'center',display:'none'}}>  
              {unused?
                <div style={{color:'#fff',background:'#d32f02',marginLeft:20,padding:5,borderRadius:3}}>
                  {unused} unused spends
                </div>:null
							}
              <div>
                <AddSpends assignment_id={assignment_id} updateConfig={(config) => this.updateConfig(config)} refreshSpends={() => this.getSpendsData()}/>
              </div>
            </div>
        	<div>
            <Filters 
              filters={filters}
              filteroptions={filteroptions}
              update={(filters) => this.setState({filters})}
              html={
                <div style={{display:'flex'}}>
                  <UpdateSpends assignment_id={assignment_id} refreshSpends={() => this.getSpendsData()}/>
                  {false && <AddSpends assignment_id={assignment_id} updateConfig={(config) => this.updateConfig(config)} refreshSpends={() => this.getSpendsData()}/>}
                </div>
              }
              />
          </div>    
          <div style={{margin:2,marginTop:3,zIndex:1000,position:'absolute',padding:'10px 22px'}}className="btn" onClick={this.saveSpends}>save</div>
          </div>
          <div ref={o => {if(o){this.actionholder=o}}} style={{display:'flex',marginLeft:140,fontSize:12,width:width-152,overflow:'hidden',zIndex:1001,position:'absolute',pointerEvents:'none',paddingBottom:'40px'}}>
            {
              this.state.data[0].slice(1).filter((_,i) => columns.indexOf(i)!=-1).map((o,_i) => {
               return <div style={{overflow:'hidden',maxWidth:60,width:60,minWidth:60,display:'flex',justifyContent:'flex-start',alignItems:'flex-start',textAlign:'right'}}>
                  <div style={{fontSize:8,textAlign:'left',width:'100%',padding:5}} title={(idmap[o] || {}).subsource}>
                  <div style={{height: "30px", lineHeight:'10px',whiteSpace:'normal !important',wordBreak:'break-all'}}>
                    {(idmap[o].asem_id && typeof(idmap[o].asem_id) == 'number') ? this.state.developerProjects && this.state.developerProjects.filter(e => e.asem_id ==  idmap[o].asem_id).map(i => i.name)[0]:(idmap[o] || {}).asem_id}</div>
                  	<div style={{color:'#6161ff',lineHeight:'10px',whiteSpace:'nowrap'}}>{(idmap[o] || {}).channel}</div>
                  	<div style={{color:'#6161ff',lineHeight:'10px',whiteSpace:'nowrap'}}>{(idmap[o] || {}).source}</div>
                  </div>
                  {!this.state.used[o] &&
                    <div onClick={() => {
                        if(spend_keys.indexOf(o)!=-1){
                          alert("Please remove the spends key from manual spends keys entry in the spends details section of configuration to delete.")
                          return
                        }
                        let agree = (prompt(`Are you sure, you want to remove spends of ${o}?\n Type 'y' to confirm`, '').toLowerCase() == 'y');
                        if(agree){
                          delete_keys[o] = 1
                          this.setState({delete_keys:JSON.parse(JSON.stringify(delete_keys))})
                        }
                      }} className="close" style={{display:'flex',padding:2,background:'#ededed',margin:3,cursor:'pointer'}} title={o}>
                      <Ionicon icon="md-close" color="red" fontSize="10"/>
                    </div>
                  }
                </div>
                }) 
            }
          </div>
          <HotTable 
            className={css`tr:first-child th{ border-top:none;} th{background:#fbfbfb} td,th{border-color:#ededed !important} .handsontable .colHeader{padding-top:55px !important;}`}
            inputProps={{ style: { ".colHeader": {color:"red",paddingTop:"36px" }} }}
            renderAllRows={false}
            renderAllColumns={false}
            ref = {o => {
              if(o){
                this.hot=o
                this.wtholder = document.querySelector('.wtHolder') 
              }
            }}
            cells = {(r,c) => {
              let istoday=false,totals=false,change=false,auto=false,used=false
              // r,c will be relative numbers coming from state object. r,c to spends r,c mapping ie..,  r=0 => sr=200 also c=2 => sc=10.
              
              if(this.state.dbdata.length > 0  && this.state.data[r+rangeStart][columns[c-1]+1]!=this.state.dbdata[r+rangeStart][columns[c-1]+1]){
                change = true
              }
              if(this.state.auto[this.state.data[0][columns[c-1]+1]]){
                auto = true
              }
              if(c==0 || this.state.used[this.state.data[0][columns[c-1]+1]]){
                used = true
              }
              if(this.state.data[r+rangeStart][0]==today){
                istoday = true
              }
              if(r==0 || c==0) {
                totals=true
              }
              return {
                readOnly: r==0 || c==0 || auto,
                renderer: 
                  (instance, td, row, col, prop, value, cellProperties) => {
                    td.style.textAlign = 'right'
                    if(auto) {
                      td.style.background = '#e8e8ff';
                      td.style.color = '#6b7785';
                    }
                    if(!used) {
                      td.style.background = '#f9e0d9';
                    }
                    if(istoday)  td.style.background = '#CEC';
                    if(totals) {
                      td.style.background = '#fbfbfb';
                      td.style.fontWeight = 'bold';
                    }
                    if(change) td.style.color = 'red';
                    td.innerHTML = this.formatVal(value)
                  }
              }
              return null
            }}
            
            afterScrollHorizontally={() => {
              this.actionholder.scroll(this.wtholder.scrollLeft,0)
            }}

            afterChange = {(o) => {
              if(o && o[0]){
                o.filter((o) => o && o[0]>0 && o[1]>0).map((o) => {
                  let oldVal = o[2]
                  let newVal = parseInt(o[3].replace(',','').trim()) || 0
                  this.state.data[o[0]+rangeStart][columns[o[1]-1]+1] = newVal
                  this.coltotals[0] += newVal - oldVal
                  this.coltotals[columns[o[1]-1]+1] += newVal - oldVal
                  this.rowtotals[o[0]-1+rangeStart] += newVal - oldVal
                })
                this.setState({data:JSON.parse(JSON.stringify(this.state.data))})  
              }
            }}
            
            style={{fontSize:12}} 
            rowHeaderWidth={80} 
            columnHeaderHeight={40}
            colWidths={60}
            data={[[this.coltotals[0]||0,...this.coltotals.slice(1).filter((_,i) => columns.indexOf(i)!=-1)],...this.state.data.slice(1).slice(rangeStart,rangeEnd).map((o,r)=> [this.rowtotals[r+rangeStart]||0,...o.slice(1).filter((_,i) => columns.indexOf(i)!=-1)])]}
            sortIndicator={true}
            fixedColumnsLeft={1}
            fixedRowsTop={1}
            colHeaders={["Total",...this.state.data[0].slice(1).filter((_,i) => columns.indexOf(i)!=-1).map(k => (idmap[k] || {}).subsource) || '']}
            rowHeaders={["Total",...this.state.data.slice(1).slice(rangeStart,rangeEnd).map((o) => o[0]).map(o => `${o.slice(0,2)}-${o.slice(2,5)}-${o.slice(7,9)}`)]}
            width={width}
            height={height}
            undo={true}
            stretchH="none" />
        </div>
        <Notify message={message} message_id={message_id}/>
        {false &&
          <div>
            <div style={{paddingBottom:"20px"}}>
              <div className="btn" onClick={this.saveSpends}>save</div>
            </div>
            <div style={{position:"relative"}}>
              <div style={{}} onScroll={this.handleScroll}>
                <table>
                  <tbody>
                  {
                    data.map((row,r) => {
                      return (
                        <tr key={r} className={(today==row[0]?'today ':'')+(r==0?"row0":"")}>
                          {row.map((val,c) => {
                            if(r>0 && c>0 && entry==r+'_'+c){
                              return (
                                <td key={r+"_"+c}>
                                  <input 
                                    onKeyDown={this.handleKeyboard}
                                    onPaste={this.handlePaste} 
                                    className="entry" 
                                    name={r+'_'+c} 
                                    defaultValue={val} 
                                    onChange={this.handleChange}/>
                                </td>
                              )
                            } else {
                              let classname = ""
                              if(c==0 && r!=0)classname="col0"
                              
                              let color = val==0?'#ccc':'black'
                              color = this.state.dirty[r+"_"+c]?'red':color
                              return <td 
                                className={classname}
                                key={r+"_"+c} 
                                onClick={() => this.startEdit(r+"_"+c)}
                                style={{color}}
                                >{val.toFixed?this.formatPrice(val):val}</td>
                            }
                          })}
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
      </div>
      :
      <div>
        <div>Please provide Spend Keys and Digital Campaign Duration (Start date and End date) to enable spends tracking</div>
      </div>
    )
  }
}

const closestyle = css`
  cursor:pointer;
  right:0px;
  top:-5px;
  position:absolute;
  :hover{
    fill:#eb7785;
    }
`;

class InputDropdown extends PureComponent{
  constructor(props) {
    super(props);
    this.state={val:"",open:this.props.open}
    this.ref = React.createRef();
    this.inputRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  } 
	componentWillReceiveProps(nextProps) {
    this.setState({value:nextProps.value,open:nextProps.open});
  }
  handleClickOutside(event) {
    if (this.state.open && this.ref.current && !this.ref.current.contains(event.target)) {
      this.setState({open:false,val:""})
    }
  };
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  };
  render(){
    let {value,inputOnClick,dropdownOnClick,options,width,classname,onClick}  = this.props
    let {val,open} = this.state;
    return(
      <td className={classname}>
      <div ref={this.ref} className={css`position:relative;`}>
        <div className={css`width:${width};padding:10px;text-align:center;`}
          onClick= {inputOnClick}
        >{value}</div>
        {open &&
          <div className={css_dropdown} style={{minWidth:width}}          
            onClick={e=>{
              this.setState({val:""},()=>{
              this.inputRef.current && this.inputRef.current.focus();
            })}
          }
            >
          <div title={value} className="content">
            <Ionicon className='icon' icon="md-search" color="#6b7785"/>
            <input ref={this.inputRef} className='input'
              value={val}
              placeholder='search'
              spellCheck={false}
              onChange = {(e) => this.setState({val:e.target.value})}
              />
              {val && <div className='crossbutton' onClick={(e)=>{this.setState({val:""}),onClick(e)}}
            >x</div>}
          </div>
            {options
              .filter(o => (o||'').toLowerCase().indexOf(val && val.toLowerCase()||'')!=-1)
              .map(l => (  
                <div className="options" onClick={(e)=>dropdownOnClick(e,l)} >{l}</div> 
            ))}
          </div>
        }
        {value && <Ionicon onClick={(e)=>dropdownOnClick(e,"")} className={closestyle} icon="md-close" fontSize="16px" color="#ccc"/>}
        </div>
      </td>
    )
  }
}

class DatePicker extends PureComponent{
  render(){
    let{val,onDayChange,onClick} = this.props

    return(
      <td key={val} className={css_datePicker}>
        <div className="content"> 
          <DayPickerInput className='daypicker'
            placeholder = "DD-MM-YY"
            inputProps={{ style: { width: 100 } }}
            value={val}
            formatDate = {(o) => {
              let [m,d,y] = o.toString().split(" ").slice(1,4)
              return `${d}-${m}-${y}`
            }}
            onDayChange={onDayChange}
          />
          {val && <Ionicon onClick={onClick} className={closestyle} style={{top:"-15px"}} icon="md-close" fontSize="16px" color="#ccc"/>}
      </div>
      </td>
    )
  }
}

class AgencyTable extends PureComponent{
  state = {
    assignment: this.props.assignment || {},
    agencyName: '',
    message:"",
    agencyRows:[{}],
    total:[],
    spendsConfig:[{}],
    spendsData:[],   
    editfilter:'',
    dateOverlap:false,
    isEmpty:false,
    message_id: new Date()
  };

  componentDidMount(){
    let {assignment_id} = this.state.assignment

    fetch(`${window._MDOMAIN}/api/v0/url-generator?rtype=get-v2&assignment_id=${assignment_id}`, {
      method: 'post',
      credentials:"include",
    }).then(function(response) {
      return response.json();
    }).then(data => {
      var config = data.data && data.data.spends_config;
      this.setState({
        spendsConfig: config
      })
    })

    fetch(`${window._MDOMAIN}/api/v0/spends?rtype=get&assignment_id=${assignment_id}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(data => {
      this.setState({
        spendsData : data.data
      });
    });

  }

  calculateTotal = (amount,agencyRows) =>{
    var sum=0,costSum=0,tCostSum = 0;
    if(amount || amount==0){
      agencyRows.map(a => sum += a.amount || a.amount ==0?a.amount:0)
      this.setState(prevState => ({
        total: {
            ...prevState.total,
            amount: sum            
        }
      }))
    }
    agencyRows.map(a => costSum +=  a.cost| a.cost ==0?a.cost:0)
    agencyRows.map(a => tCostSum +=  a.totalCost||a.totalCost ==0?a.totalCost:0)
    this.setState(prevState => ({
      total: {
          ...prevState.total,
          cost: costSum,
          totalCost: tCostSum
      }
    }))
  }

  handleRemoveSpecificRowAgency = (index) => () => {
    const agencyRows = [...this.state.agencyRows]
    agencyRows.splice(index, 1)
    this.setState({ agencyRows })
    this.calculateTotal(agencyRows[index] && agencyRows[index].amount ?agencyRows[index].amount:0,agencyRows)   
  }

  handleAddRowAgency = () => {
    const item = {};
    this.setState({
      agencyRows: [...this.state.agencyRows, item]      
    });
  }; 

  checkDateOverlapping = (rows) => {

   const isUni = rows.map(function(value) {
        return value.asemId
      }).some(function(value, index, agencyRows) {
        return agencyRows.indexOf(value) !== agencyRows.lastIndexOf(value);  
      })
    if(isUni){
      return this.checkDates(rows,"isDateOverlap"); 
    } 
    else{
      return false;
    }
  }

  checkDates = (agencyRows,overlap) =>{
    agencyRows.forEach(a => a[overlap] = false)
    var dateValAgencyRows = JSON.parse(JSON.stringify(agencyRows))
    var dateOverlap = false;
    dateValAgencyRows.forEach(function(object){
      if(!('start' in object)){
        object['start'] = "01-Jan-21";
      }
      if(!('end' in object)){
        object['end'] = new Date();
      }             
    });

    const dupRows = dateValAgencyRows.sort((a, b) => new Date(a.start) - new Date(b.start))
      .filter(a => a['asemId'] !== undefined)
      .map(e => e['asemId']).map((e, i, final) => final.indexOf(e) !== i && i)
      .filter(obj => dateValAgencyRows[obj])
      .map(e => dateValAgencyRows[e]['asemId']);

    let updateAgencyRows = JSON.parse(JSON.stringify(agencyRows));
    dateValAgencyRows =dateValAgencyRows && dateValAgencyRows.sort((a,b)=>new Date(a.start) - new Date(b.start));

    if(dupRows.length > 0){
      for(var i = 0; i < dateValAgencyRows.length; i++){
        for(var j = i + 1; j < dateValAgencyRows.length; j++){
          var dateComparision = ((new Date(dateValAgencyRows[i].start).getTime() <= new Date(dateValAgencyRows[j].start).getTime() && new Date(dateValAgencyRows[i].end).getTime() >= new Date(dateValAgencyRows[j].end).getTime()));          

          if((dateValAgencyRows[i].asemId == dateValAgencyRows[j].asemId) && 
            (((dateValAgencyRows[i].source == dateValAgencyRows[j].source) && dateComparision )|| 
            ((dateValAgencyRows[i].source == undefined || dateValAgencyRows[i].source == '') && 
            (dateValAgencyRows[j].source==undefined || dateValAgencyRows[j].source == '') && dateComparision))){
                updateAgencyRows[dateValAgencyRows[i].id][overlap] = true
                updateAgencyRows[dateValAgencyRows[j].id][overlap] = true
                dateOverlap = true;
              }              
            
        }
      }
    }
    this.setState({dateOverlap:dateOverlap,agencyRows:updateAgencyRows})

    return dateOverlap;

  }
 
  saveAgency = () => {
    
    var newRows = this.state.agencyRows.filter(e => Object.keys(e).length !== 0)
    this.setState({ agencyRows: newRows });

      var config = newRows.map(o=>{
        let {feesName,typeOfFees,asemId,channelType,tax} = o        
        return {feesName,typeOfFees,asemId,channelType,tax};
      });

      const emptyAction = config && config.map(data => {
        return Object.values(data).some(x =>   x == '' || x==undefined)
      });
      const isEmpty = emptyAction.length > 0 && emptyAction.reduce((a,b) => a|| b)
      this.setState({isEmpty:isEmpty})
      const dateValidation = newRows.map(o => {
        if (o.start && o.end && new Date(o.start).getTime() >= new Date(o.end).getTime()) {
          return true;
        } else {
          return false;
        }
      });

      let updateAgencyRows = JSON.parse(JSON.stringify(newRows));
      config && config.map((data,index) => {
        if(config[index]&& Object.values(config[index]).some(x =>  x == '' || x==undefined)){          
          updateAgencyRows[index].empty = true
        }else {
          updateAgencyRows[index].empty = false
        }
      });      
           
    newRows.map((val,index) =>{     
        if(newRows[index].start && newRows[index].end && newRows[index].start !== null && newRows[index].end !== null && new Date(newRows[index].start).getTime() >= new Date(newRows[index].end).getTime()){       
          updateAgencyRows[index].date = true
        }
         else{
           updateAgencyRows[index].date = false
        }     
      })

    this.setState({ agencyRows:updateAgencyRows});
    var dateOverlap = !isEmpty && !dateValidation.includes(true) && this.checkDateOverlapping(newRows);
  
    if(dateOverlap){
        this.setState({message:"Duplicate Dates not allowed",message_id:new Date()}); 
    }
    if (dateValidation.includes(true) || isEmpty) {
      this.setState({message: `${dateValidation.includes(true) ? "Start date is not smaller than End date  ": ""} ${isEmpty ? "Required All fields  " :""} `,message_id:new Date()});
    }
    if(!dateOverlap  && !isEmpty && !dateValidation.includes(true)){
      this.setState({message:"Saved Successfully",message_id:new Date()});
    } 
  }     

  calculateMultipleSpendSum = (channel, source,startDate,endDate) => {
    var sum =0;
    var config = this.state.spendsConfig.map(o=>{
      o.spends = (parseFloat(o.spends) || 0).toFixed(2);
      return o;
    });

    var spendsList = [];
    var id = [];
    var spendData = []
    config.filter(a =>{
      if(channel && a.channel == channel && a.spends && (!source || (source && a.source == source))){
        id = [...id,a.id]
      }      
    });
    spendData = this.state.spendsData.filter(item => id.indexOf(item.config_id) != -1);
    if(startDate && !endDate){
      spendData = spendData.filter((item) => {
        return new Date(item.date).getTime() >= new Date(startDate).getTime()})
      }
    else if(endDate && !startDate){      
      spendData = spendData.filter((item) => {
        return  new Date(item.date).getTime() <= new Date(endDate).getTime()})
    }
    else if(startDate && endDate){
      spendData =  spendData.filter((item) => { 
        return new Date(item.date).getTime() >= new Date(startDate).getTime() &&  new Date(item.date).getTime() <= new Date(endDate).getTime()})
    }
    else{
      spendData = this.state.spendsData.filter(item => id.indexOf(item.config_id) != -1);
    }
    spendData.map(a =>{
      spendsList=[...spendsList,a.spends];
    })
    var numberArray = spendsList && spendsList.map(Number);
    numberArray.map(x => sum += x)
    return sum;
  }

  calculateCost = (amount,tax) => {
    var cost = (amount && tax) && amount + ((tax/100) * amount);
    return ~~cost;
  }

  calculateTotalCost = (cost,tax) => {
    var tCost = (cost && tax) && +cost + ((tax/100) * cost);
    return ~~tCost;
  }


  handleSpensWithDate = (updateAgencyRows,index) =>{
    var amount = this.calculateMultipleSpendSum(updateAgencyRows[index].channelType,updateAgencyRows[index].source,updateAgencyRows[index].start,updateAgencyRows[index].end);
    updateAgencyRows[index].amount = amount;
    if(updateAgencyRows[index].tax){
      updateAgencyRows[index].cost = this.calculateCost(amount, updateAgencyRows[index].tax);
      updateAgencyRows[index].totalCost = this.calculateTotalCost(updateAgencyRows[index].cost, updateAgencyRows[index].tax);
    }    
  }

  handleChange = (event) =>{
    var index = event.index;

    let updateAgencyRows = JSON.parse(JSON.stringify(this.state.agencyRows));
    if(event.type == 'feesName' || event.type == 'cap' ){
      updateAgencyRows[index][event.type] = event.value;
    }
    if(event.type == 'asemId'){
      updateAgencyRows[index].asemId = event.value;
      updateAgencyRows[index].id = index;
    }
    if(event.type == 'typeOfFees'){
      updateAgencyRows[index].typeOfFees = event.value;
    }
    if(event.type == 'channelType'){
      updateAgencyRows[index].channelType = event.value;
      updateAgencyRows[index].source = "";
      this.handleSpensWithDate(updateAgencyRows,index)     
    }
    if(event.type == 'source'){
      updateAgencyRows[index].source = event.value;
      this.handleSpensWithDate(updateAgencyRows,index)      
    }
    if(event.type == 'start' || event.type == 'end'){
      updateAgencyRows[index][event.type] = event.value;
      this.handleSpensWithDate(updateAgencyRows,index)
    }   
    if(event.type == 'tax'){
      updateAgencyRows[index].tax = event.value;
      updateAgencyRows[index].cost = this.calculateCost(updateAgencyRows[index].amount, event.value);
      updateAgencyRows[index].totalCost = this.calculateTotalCost(updateAgencyRows[index].cost, event.value);
    }
    this.calculateTotal(updateAgencyRows[index],updateAgencyRows)
    this.setState({
      agencyRows:updateAgencyRows
    })

    var isEmpty = updateAgencyRows[index].feesName &&  updateAgencyRows[index].typeOfFees && updateAgencyRows[index].asemId &&  updateAgencyRows[index].channelType &&  updateAgencyRows[index].tax
    isEmpty && this.checkDates(updateAgencyRows,"isOverlap");    
  }
  render(){
    const {agencyName,message,message_id,assignment} = this.state;
    const columns = ['Fees Name','Type Of fees','Project Name','Channel Type','Source','Start Date','End Date','Amount','Tax','Cost','Total Cost (Inc Tax)','Cap']
    var overlappingDates = this.state.agencyRows.filter(a => a.isDateOverlap == true && a.isOverlap == true)
    return(
      <div className={css_agencytable}>
      {agencyName &&
        <div>
        <h3 className='name'>{agencyName}</h3>
      <table className={css_table}
      >
        <thead>
          <tr>
            {columns.map(data =>{
              return <th>{data}</th>
            })}
          </tr>
        </thead>
        <tbody>
        <tr style={{background: "none"}}>
        <td style={{background:"none"}}>Total</td>
        {["","","","","",""].map(data =>{
          return <td style={{background:"none"}}>{data}</td>
        })}
        <td style={{background:"none"}}>{this.state.total && this.state.total.amount}</td>
        <td style={{background:"none",textAlign:"center"}}></td>
        <td style={{background:"none"}}>{this.state.total && this.state.total.cost}</td>
        <td style={{background:"none"}}>{this.state.total && this.state.total.totalCost}</td>
        {[""].map(data =>{
          return <td style={{background:"none"}}>{data}</td>
        })}
        </tr>
          {this.state.agencyRows.map((item, index) => {      
            
             return <AgencyRow 
               agencyRow={this.state.agencyRows[index]}  
               index={index}
               overlappingDates={overlappingDates}
               assignment={assignment} 
               onHandle={this.handleChange}
               handleRemoveSpecificRowAgency={this.handleRemoveSpecificRowAgency} />            
          })}
        </tbody>
      </table>
      <Notify message={message} message_id={message_id}/>
      <div className='texts'>
      <div  className='newrow' onClick={this.handleAddRowAgency}>
        + New Row
      </div>
      <button className='save' onClick={this.saveAgency}>
        Save
      </button>
    </div>
        </div>
      }
      <div className='addAgency' onClick={() => {
        let name = prompt("Provide name of agency")
        this.setState({
          agencyName: name
        })
        }}>+ Add Agency</div>
      </div>
    )
  }
}

class SpendsReport extends PureComponent{
  constructor (props) {
    super(props)
    this.state = {
      data:[
        {project:"Microtek Greenburg",channel: "Online",source:"Google",month:"Mar'22",feetype:"Media Spends",spends:"10,000",gst:"18%",total:"11,800"},
        {project:"Microtek Greenburg",channel: "Agency",source:"Print",month:"Mar'22",feetype:"Commission",spends:"15,000",gst:"18%",total:"17,600"}
      ],
      total:["Total","-","-","-","-","25,000","-","29,400"]
     }
  }
  render(){
    let columns = ['Project','Channel','Source','Month','Fee Type','Spends','GST','Total']

    return(
      <div>
      <table className={css_table} style={{borderSpacing:"0",borderCollapse:"separate"}}>
        <thead>
          <tr>
            {columns.map(data =>{
              return <th>{data}</th>
            })}
          </tr>
        </thead>        
        <tbody>
        {this.state.data.map((item) =>{        
          return <tr>
              <td>{item.project}</td>
              <td>{item.channel}</td>
              <td>{item.source}</td>
              <td>{item.month}</td>
              <td>{item.feetype}</td>
              <td>{item.spends}</td>
              <td>{item.gst}</td>
              <td>{item.total}</td>
            </tr>
        })
        }
        <tr>
            {this.state.total.map(data =>{
              return <td>{data}</td>
            })}
            </tr>
        </tbody>
      </table>
      </div>
    );
  }
}

class AgencyRow extends PureComponent{
  constructor (props) {
    super(props);
    this.state={
      assignment: this.props.assignment || {},
      channelOptions:[],
      asemIdOptions : [],
      sourceOptions: [],
      asemIdOpen:false,
      feesOpen: false,
      channelOpen: false,
      sourceOpen: false,
      spendsConfig:[]
    }
  }
  componentDidMount(){
    let {assignment_id} = this.state.assignment

    fetch(`${window._MDOMAIN}/api/v0/url-generator?rtype=get-v2&assignment_id=${assignment_id}`, {
      method: 'post',
      credentials:"include",
    }).then(function(response) {
      return response.json();
    }).then(data => {
      var config = data.data && data.data.spends_config;
      var channelList =  config && config.map(data => data.channel)
      var projects = data.data && data.data.projects;
      var asemIdList =  projects && projects.map(data => data.name.trim())
      this.setState({
        spendsConfig: config,
        channelOptions: channelList.filter((item, index) => channelList.indexOf(item) == index),
        asemIdOptions: asemIdList
      })
    })   
  }

  setDropdowns= () => {
    this.setState({ 
      asemIdOpen :false,
      channelOpen : false, 
      sourceOpen :false,
      feesOpen : false
    });
  }

  render(){
    const {asemIdOptions,channelOptions,sourceOptions,asemIdOpen,feesOpen,channelOpen,sourceOpen,spendsConfig}= this.state;
    const {agencyRow,onHandle,index,handleRemoveSpecificRowAgency,overlappingDates} =this.props;
    const feesOptions = ['Percentage of Media','Setup/One time Fees','Retainer (Monthly)','Percentage of Media with fix min cap','Percentage of Media with fix max cap'];
    var isValDate = agencyRow.end  && agencyRow.start && new Date(agencyRow.start).getTime() >= new Date(agencyRow.end).getTime() ? true : false;
    return(
      <tr className={"rows " + (((agencyRow.date && isValDate) ||(!isValDate && overlappingDates.length>=2 && agencyRow.isDateOverlap && agencyRow.isOverlap))  ? "highlight" : "")}
        key={index}>
        <td className={agencyRow.empty && !agencyRow.feesName ? "borderHighlight" : ""}>
          <input style={{width:"120px",textAlign:'center'}}
            name='feesName'
            value={agencyRow.feesName}
            autoComplete='off'
            type="text"
            onChange={(e)=>{
              e.preventDefault();
              e.stopPropagation();
              onHandle({index:index,type:"feesName",value:e.target.value})
              this.setDropdowns()
            }}
          />
        </td>

        <InputDropdown
          classname={agencyRow.empty && !agencyRow.typeOfFees ? "borderHighlight" : ""}
          value={agencyRow.typeOfFees}
          inputOnClick={(e) => {
            e.preventDefault()
            this.setState({ feesOpen : !feesOpen })
          }}
          onClick={e=>{
            e.preventDefault();
            e.stopPropagation();
            this.setState({feesOpen:true})
          }}
          dropdownOnClick={(e,l)=>{    
            e.preventDefault();
            e.stopPropagation();
            onHandle({index:index,type:"typeOfFees",value:l})
            this.setDropdowns()
            
          }}    
          open={feesOpen}
          options={feesOptions}
          width="250px"    
        />

        <InputDropdown
        classname={agencyRow.empty && !agencyRow.asemId ? "borderHighlight" : ""}
          value={agencyRow.asemId}
          inputOnClick={(e) => {
            e.preventDefault()
            this.setState({ asemIdOpen : !asemIdOpen })
          }}
          onClick={e=>{
            e.preventDefault();
            e.stopPropagation();
            this.setState({asemIdOpen:true})
          }}
          dropdownOnClick={(e,l)=>{    
            e.preventDefault()
            onHandle({index:index,type:"asemId",value:l})
            this.setDropdowns()   
          
          }}
          open={asemIdOpen}
          options={asemIdOptions}
          width="250px"
        />

        <InputDropdown
          classname={agencyRow.empty && !agencyRow.channelType ? "borderHighlight" : ""}
          value={agencyRow.channelType}
          inputOnClick={(e) => {
            e.preventDefault()
            this.setState({ channelOpen :!channelOpen})
          }}
          onClick={e=>{
            e.preventDefault();
            e.stopPropagation();
            this.setState({channelOpen:true})
          }}
          dropdownOnClick={(e,l)=>{
            e.preventDefault()
            onHandle({index:index,type:"channelType",value:l})
            this.setDropdowns()
          }}   
          open={channelOpen}
          options={channelOptions}
          width="100px"
        />
   
        <InputDropdown
          value={agencyRow.source}
          inputOnClick={(e) => {
            e.preventDefault()
            var source = spendsConfig.filter(a => a.channel== agencyRow.channelType).map(a => a.source);
            this.setState({
              sourceOpen : !sourceOpen,
              sourceOptions: source.filter((item, index) => source.indexOf(item) == index) 
            })    
          }}
          onClick={e=>{
            e.preventDefault();
            e.stopPropagation();
            this.setState({sourceOpen:true})
          }}
          dropdownOnClick={(e,l)=>{
            e.preventDefault();
            onHandle({index:index,type:"source",value:l})
            this.setDropdowns()            
          }}   
          open={sourceOpen}
          options={sourceOptions}
          width="130px"
        />
  
        <DatePicker val={agencyRow.start}
          onDayChange={day =>{
            if(day){
              let [m,d,y] = day.toString().split(" ").slice(1,4)
              var pt =`${d}-${m}-${y.slice(2)}`
              onHandle({index:index,type:"start",value:pt})
              
            }
            if(!day) {       
              onHandle({index:index,type:"start"})
            }
          }}
          onClick={e=>{   
            onHandle({index:index,type:"start"})    
          }
        }
        />

        <DatePicker val={agencyRow.end !='' && agencyRow.end}
          onDayChange={day =>{
            if(day){
              let [m,d,y] = day.toString().split(" ").slice(1,4)
              var pt =`${d}-${m}-${y.slice(2)}`
              onHandle({index:index,type:"end",value:pt})      
            }
            if(!day) {
              onHandle({index:index,type:"end"})
            }  
          }}
          onClick={e=>{  
            onHandle({index:index,type:"end"}) 
          }}
        />
 
        <td>
        <div style={{width:'100px'}}>{(agencyRow.amount || agencyRow.amount == 0) ? agencyRow.amount:''}</div>
        </td>
 
        <td className={agencyRow.empty && !agencyRow.tax ? "borderHighlight" : ""}>
          <input style={{width:"50px",textAlign:'center'}}
            value={agencyRow.tax ? agencyRow.tax : ""}
            type="number"
            onChange={(e)=>{
              e.preventDefault();
              onHandle({index:index,type:"tax",value:e.target.value})
              this.setDropdowns()
            }}
          />
        </td>

        <td>
          <div>{agencyRow.cost && agencyRow.cost}</div>
        </td>

        <td>
          <div>{agencyRow.totalCost && agencyRow.totalCost}</div>
        </td>

        {agencyRow.typeOfFees && agencyRow.typeOfFees.includes('cap') ? 
           <td><input style={{width:"50px",textAlign:'center'}}
            value={agencyRow.cap}
            type="number"
            onChange={(e)=>{
              e.preventDefault();
              onHandle({index:index,type:"cap",value:e.target.value})
              this.setDropdowns()
            }}
          /></td>
          : <td></td>
        }

        <div title="Delete" style={{cursor:"pointer",border:"none"}} onClick={handleRemoveSpecificRowAgency(index)}><Ionicon title="Delete" style={{margin:"20px"}} icon="md-trash" color="#6b7785"/></div>

      </tr>
    )
  }
}

class Spends extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      assignment: this.props.assignment || {},
      toggleState: 1
    }
  }

  render(){
    let {toggleState} = this.state;
    let {assignment} = this.props
      return (
        <div className={css`
        display:flex;
        flex-direction:column;
        .tabs {
          color:#6b7785;
          margin-right:20px;
          cursor:pointer;
          :hover {
            color:#6161ff;
          }
        }
        .active-tabs {
          color:#6161ff;
        }
        `
        }>
          <ul style={{display:'flex',listStyleType:'none',marginRight:'5px',marginBottom:'20px'}}>
            <li
              className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
              onClick={() => {
                this.setState({toggleState: 1})
              }}>
              Daywise Spends
            </li>
          </ul>
    
          <div className="content-tabs">
          {toggleState === 1 && 
            <DaywiseSpends assignment={assignment}/>
          }
          {toggleState === 2 &&
            <AgencyTable assignment={assignment}/>
          }
          {toggleState === 3 &&
            <SpendsReport/>
          }
          </div>
        </div>
      );
  }
}

export default Spends;

const css_dropdown = css`
  min-width:200px;
  background:#fff;
  max-height:300px;
  overflow:auto;
  text-align:start;
  box-shadow: 0px 8px 15px 0px rgba(0,0,0,.15);
  position:absolute;
  background:#fff;
  padding:0px 15px;
  z-index:100000;
  .content{
    width:100%;
    margin-bottom:10px;
    background:#F7F7F9;
    border-radius:3px;
    display:flex;
    align-items:center;
    margin-top:20px;
    padding:0 20px;
  }
  .icon{
    font-size:12px;
    padding-right:5px;
  }
  .input{
    padding:15px;
    text-align:start;
    flex-grow:1;
    font-weight:400;
  }
  .crossbutton{
    height:16px;
    width:16px;
    border-radius:16px;
    font-size:14px;
    cursor:pointer;
  }
  .options{
    padding: 10px 5px;
    cursor: pointer;
  }
`
const css_datePicker = css`
width:150px;
.content{
  display:flex;
  justify-content:space-around;
  position:relative;
}
.daypicker{
  text-align:center;
}
`;
const css_table = css`
border-spacing:0;
border-collapse:separate;
tr{
  font-size:15px;
}
th{
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  position: sticky;
  cursor: pointer;
  height: 48px;
  color:#6B7785;
}
td{
  border:1px solid #ddd;
  padding:13px 15px;
  font-size:15px;
  white-space: nowrap;
  min-width:100px;
  text-align:center;
  text-overflow:ellipsis;
  z-index : 0;
  background:#ffffff
}
.highlight td{
  border:1px solid red;
}
.borderHighlight{
  border: 1px solid red;
}
input{
  border:none;
  background:none;
}
.rows td:last-child {
  border: none;
}
.button{
  padding:8px;
  border:1px solid #0000ff;
  border-radius:5px;
  margin:5px;
  :hover {
    background:#0000ff;
    color:white;
  }
}
.css-poiah input{
  width: 100px;
}
`
const css_agencytable=css`
  margin:10px;
  .name{
    color:blue;
    margin:5px 0px 20px 0px;
  }
  .texts{
    display:flex;
    justify-content:space-between;
  }
  .newrow{
    margin:15px 10px;
    cursor:pointer;
    line-height:23px;
    height:35px;
    font-weight:400;
    color:#6161FF;
    font-size:14px;
  }
  .save{
    padding:15px 20px;
    margin:15px 10px;
    border-radius:5px;
    cursor:pointer;
    overflow:hidden;
    color:#FFFFFF;
    background:#6161FF;
    font-size:12px;
    border:none;
  }
  .addAgency{
    display:inline-block;
    padding:15px 20px;
    margin:0px 10px;
    border-radius:5px;
    cursor:pointer;  
    overflow:hidden;
    color:#FFFFFF;
    background:#6161FF;
    font-size:15px;
    border:none;
  }
`
