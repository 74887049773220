import React,{PureComponent} from 'react';
import {css} from 'react-emotion';
import Ionicons from 'react-ionicons'
import {EM} from '../events'

export class ChatView extends PureComponent {
  state = {chats:[]}
	componentDidMount(){
    this.listener = EM.addListener('receive:message',this.onMessage)
    this.getMessages(this.props)
  }
  componentWillUnmount(){
    EM.removeListener('receive:message',this.onMessage)
  }
	
	componentWillReceiveProps(newProps){
    let {channel} = newProps
    if(this.textarea){
      this.textarea.focus()
    }
    if(this.props.channel!=channel){
      this.getMessages(newProps)
    }
  }

	onMessage = (o) => {
    let {chats} = this.state
    let {channel} = this.props
    o = JSON.parse(o)
    if(o.channel!=channel) return;
    chats = [...chats,{json:o.message,created_at:new Date()}]
    this.setState({chats})
    setTimeout(() => this.chatsWrapper.scrollTo(0,1000000),300)
  }
  addMessage = (message) => {
    let {tab,channel,assignment_id} = this.props
    let {chats} = this.state
    this.setState({message:'',chatHeight:40});
    let self = this
    fetch(`${window._MDOMAIN}api/v0/messages?rtype=put`, {
      method: 'post',
      body: JSON.stringify({message,channel,assignment_id}),
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        // self.setState({assignment})
      } else {
        // self.setState({message:"Unable to Send Message",message_id:new Date()})
      }
    }).catch(function(e){
      // self.setState({message:"Unable to Send Message",message_id:new Date()})
    })
    // EM.emit('send:message',JSON.stringify({message}))
  }
  
  getMessages = (props) => {
    let {chats} = this.state
    let {channel,assignment_id} = props
    let self = this
    fetch(`${window._MDOMAIN}api/v0/messages?rtype=get&assignment_id=${assignment_id}&channel=${channel}`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({chats:data.data.map(o => o)})
        setTimeout(() => self.chatsWrapper.scrollTo(0,1000000),300)
      } else {
        self.setState({message:"Unable to Load Messages",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({message:"Unable to Load Messages",message_id:new Date()})
    })
  }

	handleKeyDown = (e) => {
    if(e.repeat){
      return
    }
    let height = e.currentTarget.scrollHeight
    this.setState({chatHeight:Math.max(height,40)})
    
    let valid = false
    if(e.keyCode==27){
    } if(e.keyCode==13 && (e.metaKey || e.ctrlKey || e.shiftKey)){
      let {chats=[],message} = this.state
      if(!message) return;
      this.addMessage({user:window.user_details.name,message,type:'text'})
    } else if(e.keyCode==83 && (e.metaKey || e.ctrlKey)){
      
    }
    if(valid){
      e.preventDefault()
      e.stopPropagation()
    }
  }
  
  getTime = (t) => {
    let pts = (new Date(t)).toString().split(' ')
    pts = pts[4].split(':')
    return pts[0]+":"+pts[1]
  }
  
  getDate = (t) => {
    let pts = (new Date(t)).toString().split(' ')
    return `${pts[0]}, ${pts[1]} ${pts[2]} ${pts[3]}`
  }
  
  
  render() {
    let {chats=[],message='',chatHeight=40,minimize=false} = this.state
    let {channel,assignment_id,changeTab,header=true} = this.props
    let date = '';
    return (
      <div className={css`
box-shadow:rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
background: #fff;
border-radius:3px 3px 0px 0px;
overflow:hidden;
width:400px;
margin-right: 10px;
max-width:400px;
.user {
	font-size:12px;
	padding-left:10px;
	color: #6b7785;
}
.chats-wrapper {
	overflow: auto;
	max-height:calc(100vh - 300px);
	height: fit-content;
	min-height:200px;
	margin-bottom:20px;
	padding:0px;
}
.message {
	background: #fff;
	border-radius:3px;
	font-size:14px;
	padding:0px 30px;
	margin-bottom:20px;
	:hover {
		// background: #ededed;
	}
}
.box {
	width: 100%;
	display: flex;
	padding:5px;
	.input {
		flex:1;
		border: 1px solid #ededed;
		padding:10px;
    border-radius: 3px;
		
		textarea{
      white-space: pre-wrap;
      width: 100%;
      border: none;
      resize:none;
      outline: none;
    }
	}
	.send {
		margin-left:5px;
		border-radius: 3px;
		height:40px;
		width:40px;
		display: flex;
		justify-content:center;
		align-items: center;
		background: #1f69ff;
		cursor: pointer;
		:hover {
			filter: brightness(1.1);
		}
	}
}
.header {
	display: flex;
	flex-direction: column;
	background: #1f69ff;
	padding:5px;
	color: white;
	.title {
		flex:1;
	}
	.users {
		padding-top:5px;
		display: flex;
		font-size:10px;
		> div {
			background: #dde8ff;
			color: #1f69ff;
			padding:0px 3px;
			margin:2px;
			border-radius:3px;
		}
	}
}
`}>	
        {header&&
          <div className="header">
          <div style={{display:'flex'}}>
          	<div className="title">{channel}</div>
            <div><Ionicons icon="md-remove" color="#fff" fontSize="16px" onClick={() => this.setState({minimize:!minimize})}/></div>
            <div><Ionicons icon="md-close" color="#fff" fontSize="16px"  onClick={() => changeTab(channel)}/></div>
          </div>
          <div className="users">
            <div>sravan</div>
            <div>amrit</div>
            <div>arpit</div>
          </div>
        </div>
        }
        {!minimize && 
        <div>
          <div ref={o => {
              if(o){
                this.chatsWrapper=o
                if(o){
                	this.chatsWrapper.scrollTo(0,1000000)  
                }
              }
            }} className="chats-wrapper">
            {chats.map(o => (
              <div>
                {date!=this.getDate(o.created_at) &&
                	<div style={{padding:5,textAlign:'center',fontWeight:'bold',borderBottom:'1px solid #ededed'}}>
                    {date=this.getDate(o.created_at)}
                  </div>  
                }
                
                <div style={{display:'flex',padding:10,alignItems:'center'}}>
                  <div style={{display:'flex',alignItems:'center',flex:1}}>
                    <Ionicons icon="md-person" color="#6b7785" />
                    <div className="user">{o.json.user || 'user'}</div>
                  </div>
                  <div style={{color:'#6b7785'}}>{this.getTime(o.created_at)}</div>
                </div>
                <div style={{}}>
                {o.json.type=='text' &&
                  <div className="message">
                    {
                      o.json.message
                        .trim()
                        .split('\n')
                        .map(o => (
                        <div style={{minHeight:16,marginBottom:5}}>{o}</div>
                      ))
                    }
                  </div>
                }
              </div>
              </div>
            ))}
          </div>
          <div className="box" onClick={() => {
              if(this.textarea){
                this.textarea.focus()
              }
            }}>
          <div className="input">
            <textarea 
              style={{height:chatHeight}}
              ref={o => {
                if(o){
                  this.textarea=o
                }
              }}
              onPaste={(e) => console.log(e)}
              dropable
              onDrop={(e) => {
                console.log(e)
                e.preventDefault()
                e.stopPropagation()
              }}
              onKeyDown={this.handleKeyDown}
              onChange={(e) => this.setState({message:e.target.value})} value={message}>
            </textarea>
          </div>
          <div className="send" onClick={() => {
              window.navigator.vibrate([100,75,75,100])
              this.textarea.focus()
              if(!message) return;
              this.addMessage({user:window.user_details.name,message,type:'text'})
            }}>
            <Ionicons icon="md-send" fontSize="22px" color="#fff" />
          </div>
        </div>
      	</div>
        }
      </div>
    )
  }
}

class ChatList extends PureComponent {
  render() {
    let {channels,changeTab} = this.props
    return (
      <div className={this.props.className+' '+css`
padding:10px 0px;
background:#fff;
border: 1px solid #ededed;
width:100px;
overflow:hidden;
white-space:nowrap;
height:fit-content;
.item {
	color: #000;
	padding:10px 20px;
	cursor: pointer;
	transition: background .3s ease;
	:hover {
		background: #ededed;
	}
	&.selected {
		background: #e8e8ff;
		color: #6161ff;
	}
}
`}>
        {["general","facebook","google","others"].map(o => (
          <div className={`item ${channels.indexOf(o)!=-1?'selected':''}`} onClick={() => {
              changeTab(o)
            }}>{o}</div>
        ))}
      </div>
    )
  }
}

export class Chat extends PureComponent {
  state = {}
	changeTab = (channel) => {
    let {channels=[]} = this.state
    if(channels.indexOf(channel)!=-1){
      channels = channels.filter(c => c!=channel)
    } else {
      channels.push(channel)  
    }
    this.setState({channels:JSON.parse(JSON.stringify(channels))})
  }
  render() {
    let {show=false,channels=[]} = this.state
    return (
      <div className={css`
user-select: none;
position:fixed;
bottom:0px;
right:0px;
display:flex;
justify-content: flex-end;
flex-direction:column;
align-items:flex-end;
.btn {
	background: #000;
  color: #fff;
  z-index:100000;
  padding:10px;
  border-radius:3px;
  cursor:pointer;
}
.flex {
	display: flex;
	align-items: flex-end;
	transform-origin: right bottom;
}
`}>
        {show?
          <div className="flex">
            {channels.map((channel) => (
              <ChatView channel={channel} changeTab={this.changeTab}/>
            ))}
            <ChatList className="chatlist" channels={channels}
              changeTab={this.changeTab} />
          </div>
          :
        	<div onClick={() => this.setState({show:!show})} className="btn">Chats</div>
        }
      </div>
    )
  }
}
