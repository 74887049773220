import "./style.scss";

import React, { PureComponent } from 'react'
import {EM} from './events'
import Ionicon from 'react-ionicons'
import {PivotController,FilterBar,Filters} from './pivotcontroller'
import {css} from 'react-emotion'
import Tooltip from './tooltip';
import {Button,OptionGroupCheckBox,Option,DropDown, OptionGroupRadio} from '@anarock/pebble';

const colors = {
  "gray-darker": "#101721",
  "gray-dark": "#6B7785",
  "gray-base": "#9FA6B0",
  "gray-light": "#E0E0E0",
  "gray-lighter": "#EDEDED",
  "gray-lightest": "#F7F7F9",
  "violet-darker": "#4040BA",
  "violet-dark": "#5656ED",
  "violet-base": "#6161FF",
  "violet-light": "#7070FF",
  "violet-lighter": "#C0C0FF",
  "violet-lightest": "#E8E8FF"
}


import Table from './components/table'
import FixedGrid from './components/fixed-grid'

let _cache = {}

export class Pivot extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      html: '',
      table: [],
      headers: {},
      filtertext: {},
    }
    this.test
    this.getReportData
  }
  componentDidMount() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
    setTimeout(() => {
      this.getReportData(this.props.id)
    })
    EM.addListener('runreport',this.getReportData)
    EM.addListener('copyTable',this.copyTable)
    EM.addListener('copytable',this.copyTable)
  }
  componentWillUnmount(){
    EM.removeListener('runreport',this.getReportData)
    EM.removeListener('copyTable',this.copyTable)
    window.removeEventListener("resize", this.handleResize)
    
  }
  componentWillReceiveProps(props) {
   	this.getReportData(props.id)
  }

  copyTable = e => {
    if(e!=this.props.id) return;
    const str = this.state.html
    let el = document.createElement('div');  
    el.className='dataframe'
    el.style['white-space'] = 'nowrap'
    el.style['font-size'] = '12px'
    el.innerHTML = str;
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);

    const range = document.createRange()
    range.selectNodeContents(el)
    const selection = window.getSelection() 
    selection.removeAllRanges() 
    selection.addRange(range)
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  handleResponse = (data) => {
    let {conf} = this.props
    conf['filteroptions'] = data.filteroptions
    conf['last_updated'] = data.last_updated
    this.props.setState({conf,cb: new Date()})
    this.setState({html:data.html,headers:data.headers})
    this.setState({table:data.table})
    this.setState({loading:false})
  }
  
  
  test() {
    console.log("test v13")
  }

  getReportData = (e) => {
    console.log("get reports version v23");
    (() => this.test())()
    if(e!=this.props.id) return;
    let {conf} = this.props
    let {filters,rows,columns,values,look,options,report,type} = conf
    if(!report) report = 'assignmentdailysummary'
    let newUrl = ''
    
    let {assignment_id} = this.props
    if(!assignment_id)return;
    const refreshParam = conf.refreshParam ? "&refresh=true" : "";

    newUrl = `${window._MDOMAIN}/api/v1/pivot?apikey=${window.apikey}&type=${type}&rtype=overview&look=${look}&report=${report}&mode=${['totals','all'].indexOf(this.props.mediaplanMode)!=-1?'t':'f'}&assignment_id=${assignment_id}${refreshParam}&columns=${encodeURIComponent(JSON.stringify(columns))}`+':::'+JSON.stringify(filters)
    console.log("refresh request recived")
    
    if (this.url!=newUrl) {
      console.log("refresh request processed")
      conf.downloadLink = newUrl //.split(':::')[0]+`&filters=${encodeURIComponent(JSON.stringify(filters))}`
      conf.refresh = () => {
        conf.refreshParam = true
        this.url=''
        console.log("refresh started",_cache[newUrl]);
        if(_cache[newUrl]){
          delete _cache[newUrl]
        }
        EM.emit('runreport',this.props.id)
        this.props.setState({cb:new Date()})
      }
      this.props.setState({cb:new Date()})
      
      this.setState({table:[]})
      this.setState({html:''})
      this.setState({downloadlink:newUrl+'&download=true'})
      let self = this
      this.url = newUrl
      this.setState({loading:true})
      console.log("ignoring refresh 1",new Date() - new Date(conf.last_updated),conf)
      if(_cache[newUrl]){
        this.handleResponse(_cache[newUrl])
        console.log("ignoring refresh 2",new Date()/1000 - conf.last_updated)
        if(new Date()/1000 - conf.last_updated < 600) {
          console.log("ignoring refresh 3",new Date()/1000 - new Date(conf.last_updated))
          return
        }
      }
      fetch(newUrl.split(':::')[0], {
        method: 'post',
        credentials:"include",
        body: JSON.stringify({filters})
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        self.setState({loading:false})
        if(data.status==200) {
          if(self.url==newUrl){
            self.handleResponse(data)
          }
          _cache[newUrl] = data
        }
      }).catch((e) => {
        console.log(e)
        self.setState({loading:false})
      })
    }
  }

  
  handleResize = () => {
    let tablewidth = window.innerWidth-80
    let tableheight = window.innerHeight*.9
    this.setState({tableheight,tablewidth})
  }

  render() {
    let {table,html,headers={},loading,overlaywidth=0,tablewidth=0,tableheight=0} = this.state
    this.getReportData(this.props.id)
    tablewidth = tablewidth - this.props.controllerWidth
    let {options,filters={},rows,columns,values,look='table',filteroptions={}} = this.props.conf
    let tableprops = {html,headers,tablewidth,tableheight}
    let chartprops = {xkeys:columns,ykeys:[],data:table}
    let {offsetwidth} = this.props
    return (
      <div class="pivot customscroll">
        {loading &&
          <div>Loading...</div>
        }
        {look=='table' &&
          <Table
            {...this.props}
            {...chartprops}
          />
        }
        {look=='fixedgrid' &&
          <FixedGrid
            {...this.props}
            {...chartprops}
          />
        }
      </div>
    )
  }
}

export class TataPivotList extends PureComponent {
  constructor (props) {
    super(props)
    console.log('props',props)
    this.state = {
      pivotindex: -1,
      controllerWidth:0,
      pivots: [],
      assignment_id: this.getAssignmentId()
    }
  }

  getAssignmentId() {
    if(this.props.assignment_id) return this.props.assignment_id;
    return this.props.match?parseInt(this.props.match.params.assignment_id):null
  }
  

  componentDidMount() {
    try {
    	window.apikey = window.location.href.split('key=')[1].split('&')[0].trim()
    } catch(e) {
    }
    this.getAssignments()
  }
  
  getAssignments = () => {
    let self = this
    fetch(`${window._MDOMAIN}api/v1/pivot?apikey=${window.apikey}&rtype=getassignments`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data)
      if(data.status==200) {
        self.setState({assignments:data.data})
        if(self.state.assignment_id){
          let obj = data.data.filter(o => o.assignment_id==self.state.assignment_id)
          if(obj.length==1){
          	self.setState({assignment_id:obj[0].assignment_id,assignment_name:obj[0].name})  
          }
        } else if(data.data.length==1) {
          self.setState({assignment_id:data.data[0].assignment_id,assignment_name:data.data[0].name})
        }
        self.getPivots()
      } else if(data.status==403){
        window.location.href="https://marketing-dashboards.anarock.com/login"
      } else {
        self.setState({message:"Unable to load",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({message:"Unable to load",message_id:new Date()})
    })
  }
  
  getPivots = () => {
    let self = this
    fetch(`${window._MDOMAIN}api/v1/pivot?apikey=${window.apikey}&rtype=getconf`, {
      method: 'get',
      credentials:"include"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        let pivots = data.data.map(o => {
          o.conf['original_columns'] = o.conf['columns']
          return o;
        })
        self.setState({pivots})
        if(self.state.assignment_id){
          self.setState({pivotindex:0})
        }
      } else {
        self.setState({message:"Unable to fetch",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({message:"Unable to fetch",message_id:new Date()})
    })
  }
  savePivots = () => {
    let self = this
    fetch(`${window._MDOMAIN}api/v1/pivot?apikey=${window.apikey}&rtype=saveconf`, {
      method: 'post',
      body: JSON.stringify(self.state.pivots),
      credentials:"include",
      mode: "no-cors"
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      if(data.status==200) {
        self.setState({message:"Saved Successfully",message_id:new Date()})
      } else {
        self.setState({message:"Unable to Save",message_id:new Date()})
      }
    }).catch(function(e){
      self.setState({message:"Unable to Save",message_id:new Date()})
    })
  }
  render() {
    let {filterassign,filterreport,openAssign,assignment_name,assignments=[],assignment_id,pivots,controllerWidth,pivotindex,message,message_id,mediaplanMode} = this.state
    let selectpivot = pivotindex
    if(!assignment_id) assignment_id=null;
    if(this.props.assignment_id){
      assignment_id = this.props.assignment_id
    }
    let {hidecontrols,fixedwidth,report_name} = this.props
    return (
      <div className={css`
.shortcut {
	margin-right: 20px;
	color: #6b7785;
	cursor: pointer;
	:hover {
		color: #6161ff;
	}
}

`} style={{flex:1,width:`calc(100vw - ${fixedwidth}px)`,background:'#f7f7f9',padding:fixedwidth>0?0:30,paddingBottom:0,minHeight:'100vh',overflow:'auto',position:'relative'}}>
        {
          <div>
            {!hidecontrols &&
            <div style={{display:'flex',marginBottom:15}}>
              <DropDown buttonLabel={assignment_name || 'Select Assignment'} onClick={() => this.setState({openAssign:true})}>
                {() => (
                  <OptionGroupRadio 
                    searchBox
                    searchBoxProps={{
                      placeholder: 'Search',
                      value: filterassign,
                      onChange: value => this.setState({filterassign:value}),
                      reset: true
                    }}
                    onChange={(value) => {
                      let assignment = assignments.filter(o => o.assignment_id==value)[0]
                      let {assignment_id,name} = assignment
                      this.setState({openAssign:false,assignment_id,assignment_name:name})
                    }}>
                    {
                      assignments
                      .filter(o => o.name.toLowerCase().indexOf((filterassign||'').toLowerCase())!=-1)
                      .slice(0,30)
                      .map((o) => (
                        <Option value={o.assignment_id} label={o.name} />
                      ))
                    }
                  </OptionGroupRadio>
                )}
              </DropDown>
              <div style={{width:20}}></div>
              {assignment_id &&
                <DropDown buttonLabel={pivotindex>=0?pivots[pivotindex].conf.name:'Select Report'} open={openAssign}>
                  {() => (
                    <OptionGroupRadio onChange={(value) => this.setState({pivotindex:value})} 
                      //searchBox
                      searchBoxProps={{
                        placeholder: 'Search',
                        onChange: value => this.setState({filterreport:value})
                      }}
                      >
                      {
                        pivots
                        .filter(o => o.conf.name.toLowerCase().indexOf((filterreport||'').toLowerCase())!=-1)
                      	.map((o,pivotindex) => (
                          <Option value={pivotindex} label={o.conf.name} />
                        ))
                      }
                    </OptionGroupRadio>
                  )}
                </DropDown>
              }
            </div>
            }
            {
              pivots.map((o,index) => (
                (o.conf.short_name && (o.conf.short_name||'').toLowerCase().replace(/ /g,'-')==(report_name||'').toLowerCase() || (!report_name && index==pivotindex)) &&
                <div>
                  {pivots[index].conf.shortcuts &&
                    <div style={{display:'flex'}}>
                      <div className="shortcut"
                        onClick={() => {
                          pivots[index].conf.columns = pivots[index].conf.original_columns
                          pivots[index].shortcut = 'default'
                          this.setState({cb:new Date()})
                        }}
                        style={{marginRight:10,color:(!pivots[index].shortcut)?'#6161ff':null}}>default</div>
                      {
                        Object.keys(pivots[index].conf.shortcuts).map(k => {
                          let shortcuts = pivots[index].conf.shortcuts[k]
                          return (
                            <div onClick={() => {
                                let columns = JSON.parse(JSON.stringify(pivots[index].conf.original_columns))
                                shortcuts.remove.map(o => {
                                  columns.splice(o,1)
                                })
                                columns = [...shortcuts.add,...columns]
                                pivots[index].conf.columns = columns
                                pivots[index].shortcut = k
                                this.setState({cb:new Date()})
                              }} className="shortcut" style={{color:pivots[index].shortcut==k?'#6161ff':null}}>{k}</div>
                          )
                        })
                      }
                  	</div>
                  }
                  <Filters plan={mediaplanMode} id={index}
                      assignment_id={assignment_id} 
                      report_name={report_name}
                      setMediaMode={(mode) => {
                      this.setState({mediaplanMode:mode})
                      EM.emit('runreport',index)
                    }} close={() => this.setState({pivotindex:-1,controllerWidth:0})} {...pivots[index]} setState={(state) => this.setState(state)} />
                  <Pivot 
                    {...this.props}
                    //shortcut={shortcut}
                    assignment_id={assignment_id}
                    id={index}
                    setState={(state) => this.setState(state)}
                    conf={o.conf}
                    mediaplanMode = {mediaplanMode}
                  />
                </div>
              ))
            }
          </div>
        }
      </div>
    )
  }
}



function hslToRgb(h, s, l){
    var r, g, b;

    if(s == 0){
        r = g = b = l; // achromatic
    } else{
        var hue2rgb = function hue2rgb(p, q, t){
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }
    return `rgb(${Math.round(r * 255)},${Math.round(g * 255)},${Math.round(b * 255)})`;
}


